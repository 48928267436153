import { ImmersivePanelContextProvider, ImmersivePanelContextProviderProps } from "./ImmersivePanelContext";
import { ImmersivePanelUi } from "./ImmersivePanelUi";

type ImmersivePanelProps = ImmersivePanelContextProviderProps;

export const ImmersivePanel = (props: ImmersivePanelProps) => {
  return (
    <ImmersivePanelContextProvider {...props}>
      <ImmersivePanelUi />
    </ImmersivePanelContextProvider>
  );
};
