import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const getFeedState = ({ feed }: RootState) => feed;

export const streamFeedSelector = createSelector([getFeedState], ({ stream }) => stream);

export const nftFeedSelector = createSelector([getFeedState], ({ nft }) => nft);
export const userStreamFeedSelector = (userId: string) =>
  createSelector([getFeedState], ({ userStreams }) => userStreams[userId] || {});
