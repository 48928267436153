import { useState } from "react";
import { AmazonIVSBroadcastClient } from "amazon-ivs-web-broadcast";

// Keeps track of layer state on the canvas.

export interface Layer {
  name: string;
  index: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  deviceId?: string;
  type?: string;
  imageSrc?: string;
  visible?: boolean;
  stream?: MediaStream;
}

const useLayers = (initialLayer: any) => {
  const [layers, setLayers] = useState([initialLayer]);

  // Set layer
  // Sets a layer given a layer reference. Returns void.
  const setLayer = (layer: Layer) => {
    const foundIndex = layers.findIndex((l) => l.name === layer.name);
    setLayers((prevState) => {
      prevState[foundIndex] = layer;
      return prevState;
    });
  };

  // Updates a layer
  const updateSDKLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    try {
      const { name, type, ...layerProps } = layer;
      await client.updateVideoDeviceComposition(name, layerProps);
      setLayer(layer);
    } catch (error: any) {
      throw Error(error);
    }
  };

  // Update Layer
  // Adds a layer to the layer array and draws it to the canvas
  const updateLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    switch (layer.type) {
      case "VIDEO":
        updateSDKLayer(layer, client);
        break;
      case "SCREENSHARE":
        updateSDKLayer(layer, client);
        break;
      case "IMAGE":
        updateSDKLayer(layer, client);
        break;
      default:
        break;
    }
  };

  // Remove layer
  // Removes a layer from the layer array and removes it from the canvas
  const removeLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    if (!layer) return;
    try {
      const { name } = layer;
      if (!name) return;
      switch (layer.type) {
        case "VIDEO":
        case "SCREENSHARE":
          if (client.getVideoInputDevice(name)) {
            client
              .getVideoInputDevice(name)
              .source.getVideoTracks()
              .map((track: any) => track.stop());
          }
          client.removeVideoInputDevice(name);
          break;
        case "IMAGE":
          client.removeImage(name);
          break;
        default:
          break;
      }
      setLayers((prevState) => prevState.filter((layer) => layer.name !== name));
    } catch (err) {
      console.error(err);
    }
  };

  // Adds a video layer
  const addVideoLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    try {
      if (layer.visible) {
        const { name, deviceId, ...layerProps } = layer;

        // If a layer with the same name is already added, remove it
        if (client.getVideoInputDevice(layer.name)) {
          await removeLayer(layer, client);
        }

        // Width: 1920, Height: 1080 is 16:9 "1080p"
        // Width: 3840, Height: 2160 is 16:9 "4k"
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { exact: deviceId },
            width: {
              ideal: 1920,
              max: 3840,
            },
            height: {
              ideal: 1080,
              max: 2160,
            },
          },
          audio: true,
        });

        await client.addVideoInputDevice(cameraStream, name, layerProps);
      }
      setLayers((prevState) => [...prevState, layer]);
    } catch (error: any) {
      throw Error(error);
    }
  };

  // Adds a screenshare layer
  const addScreenshareLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    try {
      if (layer.visible) {
        const { name, stream, ...layerProps } = layer;

        // If a layer with the same name is already added, remove it
        if (client.getVideoInputDevice(layer.name)) {
          await removeLayer(layer, client);
        }

        await client.addVideoInputDevice(stream!, name, layerProps);
      }
      setLayers((prevState) => [...prevState, layer]);
    } catch (error: any) {
      throw Error(error);
    }
  };

  // Adds an image layer
  const addImageLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    try {
      const { name, imageSrc, type, ...layerProps } = layer;

      // If a layer with the same name is already added, throw an error
      if (client.getVideoInputDevice(layer.name)) {
        await removeLayer(layer, client);
      }

      const img = new Image();
      img.src = `${imageSrc}`;

      img.addEventListener(
        "load",
        async () => {
          await client.addImageSource(img, name, layerProps);
          setLayers((prevState) => [...prevState, layer]);
        },
        { once: true }
      );
    } catch (error: any) {
      throw Error(error);
    }
  };

  // Add Layer
  // Adds a layer to the layer array and draws it to the canvas
  const addLayer = async (layer: Layer, client: AmazonIVSBroadcastClient) => {
    try {
      switch (layer.type) {
        case "VIDEO":
          await addVideoLayer(layer, client);
          break;
        case "SCREENSHARE":
          await addScreenshareLayer(layer, client);
          break;
        case "IMAGE":
          await addImageLayer(layer, client);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Removes all layers and resets to default state
  const resetLayers = async (layers: any, client: AmazonIVSBroadcastClient) => {
    await Promise.all(
      layers.map(async (layer: any) => {
        if (layer) {
          try {
            await removeLayer(layer, client);
            // setLayers((prevState) => prevState.filter((layer) => layer.name !== name));
          } catch (error) {
            console.error(error);
          }
        }
      })
    );
  };

  return {
    layers,
    updateLayer,
    addLayer,
    removeLayer,
    resetLayers,
  };
};

export default useLayers;
