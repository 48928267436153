import { Container, Grid } from "@mui/material";

import { NAV_BAR_HEIGHT } from "../../constants";
import { ScrollToTopButton } from "../../components";
import { CardsColumn } from "../home/CardsColumn";
import FeedDetails from "./FeedDetails";
import { HomePageContextProvider } from "../home/HomePageContext";

type Props = {};

export const Feed = (props: Props) => {
  return (
    <HomePageContextProvider showLiveOnBoltInCardsColumn>
      <Container maxWidth="lg" sx={{ pt: { xs: 2, sm: 3 } }}>
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Grid item xs={12} sm={8} md={6}>
            <FeedDetails />
          </Grid>

          <Grid
            item
            md={6}
            lg={5}
            xl={4.5}
            display={{ xs: "none", md: "block" }}
            sx={{ position: "sticky", alignSelf: "flex-start", top: NAV_BAR_HEIGHT }}
          >
            <CardsColumn />
          </Grid>
        </Grid>

        <ScrollToTopButton />
      </Container>
    </HomePageContextProvider>
  );
};
