import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useBloombergEpisodesPageContext } from "../episodes-context";

export const useCurrentEpisode = () => {
  const { categorySlug, channelSlug } = useParams();

  const { data, isLoadingData } = useBloombergEpisodesPageContext();

  const result = useMemo(() => {
    const order = (data?.channels || []).findIndex((item) => {
      return item.slug === channelSlug;
    });

    const currentEpisode = (data?.channels || [])[order];

    const prevEpisode =
      order > 0 ? (data?.channels || [])[order - 1] : (data?.channels || [])[(data?.channels || []).length - 1];

    const nextEpisode =
      order < (data?.channels || []).length - 1 ? (data?.channels || [])[order + 1] : (data?.channels || [])[0];

    return { currentEpisode, prevEpisode, nextEpisode };
  }, [data?.channels, channelSlug]);

  return { ...result, isLoadingData, categorySlug, channelSlug };
};
