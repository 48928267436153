import { useMemo } from "react";

import { UserProfilePage, ChannelPage } from "../../pages";
import { ImmersivePanelLayout } from "../immersive-panel-layout";
import { useEntityLayoutContext } from "./EntityLayoutContext";

export const EntityLayoutUi = () => {
  const { entity } = useEntityLayoutContext();

  const immersivePanel__hidden = useMemo(() => {
    // if (entity && entity.data && entity.data.enableChat !== undefined && entity.data.enableChat === false) {
    //   return true;
    // }

    if (entity && entity.data) {
      const { enableChat, enableShop, enableReward, enableAI, enableRead } = entity.data;
      if (enableChat || enableShop || enableReward || enableAI || enableRead) {
        return false;
      }
      return true;
    }

    return false;
  }, [entity]);

  // if (entity && entity.data) {
  //   console.log("entity", immersivePanel__hidden);
  // }

  return (
    <ImmersivePanelLayout immersivePanel__hidden={immersivePanel__hidden}>
      {entity?.type === "user" && !!entity?.data?.streamProfile && <UserProfilePage />}

      {entity?.type === "channel" && <ChannelPage />}
    </ImmersivePanelLayout>
  );
};
