import { createAction } from "redux-actions";
import { START } from "../common";

export const CREATE_STREAM_PROFILE = "CREATE_STREAM_PROFILE";
export const UPDATE_STREAM_PROFILE = "UPDATE_STREAM_PROFILE";
export const SET_STREAM_PROFILE = "SET_STREAM_PROFILE";
export const SET_DEVICES = "SET_DEVICES";
export const SET_IS_STREAMING = "SET_IS_STREAMING";
export const SET_STREAM_PROFILE_STATUS = "SET_STREAM_PROFILE_STATUS";
export const GET_STREAMING_TAGS = "GET_STREAMING_TAGS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const FOLLOW_USER = "FOLLOW_USER";
export const JOIN_WORLD_CUP_CAMPAIGN = "JOIN_WORLD_CUP_CAMPAIGN";
export const INIT_IVS_BROADCAST_CLIENT = "INIT_IVS_BROADCAST_CLIENT";
export const SET_DEVICE_PERMISSIONS = "SET_DEVICE_PERMISSIONS";
export const ATTACH_PREVIEW_TO_IVS_BROADCAST_CLIENT = "ATTACH_PREVIEW_TO_IVS_BROADCAST_CLIENT";
export const SET_ACTIVE_VIDEO_DEVICE_ID = "SET_ACTIVE_VIDEO_DEVICE_ID";
export const SET_ACTIVE_AUDIO_DEVICE_ID = "SET_ACTIVE_AUDIO_DEVICE_ID";
export const SET_CAM_MUTED = "SET_CAM_MUTED";
export const SET_MIC_MUTED = "SET_MIC_MUTED";
export const SET_CAPTURE_STREAM = "SET_CAPTURE_STREAM";
export const SET_LAYERS = "SET_LAYERS";
export const SET_MIXER_DEVICES = "SET_MIXER_DEVICES";

export const createStreamProfileAction = createAction(CREATE_STREAM_PROFILE + START);
export const updateStreamProfileAction = createAction(UPDATE_STREAM_PROFILE + START);
export const setStreamProfileAction = createAction(SET_STREAM_PROFILE);
export const setDevicesAction = createAction(SET_DEVICES);
export const setIsStreamingAction = createAction(SET_IS_STREAMING);
export const setStreamProfileStatusAction = createAction(SET_STREAM_PROFILE_STATUS);
export const getTrendingTagsAction = createAction(GET_STREAMING_TAGS + START);
export const getUserProfileAction = createAction(GET_USER_PROFILE + START);
export const setUserProfileAction = createAction(SET_USER_PROFILE);
export const followUserAction = createAction(FOLLOW_USER + START);
export const joinWorldCupCampaignAction = createAction(JOIN_WORLD_CUP_CAMPAIGN + START);
export const initIvsBroadcastClientAction = createAction(INIT_IVS_BROADCAST_CLIENT + START);
export const attachPreviewToIvsBroadcastClientAction = createAction(ATTACH_PREVIEW_TO_IVS_BROADCAST_CLIENT);
export const setDevicePermissionsAction = createAction(SET_DEVICE_PERMISSIONS);
export const setActiveVideoDeviceIdAction = createAction(SET_ACTIVE_VIDEO_DEVICE_ID);
export const setActiveAudioDeviceIdAction = createAction(SET_ACTIVE_AUDIO_DEVICE_ID);
export const setCamMutedAction = createAction(SET_CAM_MUTED);
export const setMicMutedAction = createAction(SET_MIC_MUTED);
export const setCaptureStreamAction = createAction(SET_CAPTURE_STREAM);
export const setLayersAction = createAction(SET_LAYERS);
export const setMixerDevicesAction = createAction(SET_MIXER_DEVICES);
