import React, { useEffect } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, List, ListItemButton, ListItemText, Typography, useTheme } from "@mui/material";

import { ReactComponent as SettingIcon } from "../../assets/svgs/settingGradient.svg";
import { LIVELAYOUT_SIDEBAR_WIDTH, NAV_BAR_HEIGHT } from "../../constants";
import { FlexCol, FlexRow } from "../../components";
import { UserSettings } from "../../types";
import { settingsSelector } from "../../store/settings/selector";
import { fetchUserSettings, updateUserSettings } from "../../store/settings/actions";

const menu = [
  { label: "Email Subscription", path: "email", index: 0 },
  // { label: "Security", path: "security", index: 1 },
  // { label: "Notificaitons", path: "notifications", index: 1 },
  // { label: "Analytics", path: "analytics", index: 2 }
];

type ContextType = {
  data: UserSettings | null;
  updateSettings: Function;
};

export const SettingsLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { data, loading } = useSelector(settingsSelector);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, []);

  const updateSettings = (data: any) => {
    dispatch(updateUserSettings(data));
  };

  return (
    <FlexRow>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: LIVELAYOUT_SIDEBAR_WIDTH,
          bgcolor: "#1E2034",
          height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
          position: "fixed",
          top: NAV_BAR_HEIGHT,
          overflowY: "auto",
        }}
      >
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", mt: "40px", mb: "10px" }}>
          <SettingIcon width={34} height={34} />

          <Typography fontSize="24px" fontWeight={600} marginLeft="7px">
            Settings
          </Typography>
        </FlexRow>

        <FlexCol
          sx={{
            width: "100%",
            display: { md: "flex" },
            alignItems: "center",
          }}
        >
          <List component="div" aria-label="main mailbox folders" sx={{ width: "90%" }}>
            {menu.map(({ label, path, index }) => (
              <ListItemButton
                key={index}
                sx={{
                  borderRadius: "5px",
                  mt: "10px",
                  "&.Mui-selected": {
                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3C436F",
                  },
                  "&.Mui-selected:hover": {
                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3C436F",
                  },
                }}
                selected={selectedIndex === index}
                onClick={() => {
                  setSelectedIndex(index);
                  navigate(`${path}`);
                }}
              >
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "24px",
                    textAlign: "center",
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </FlexCol>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: LIVELAYOUT_SIDEBAR_WIDTH,
        }}
      />

      <Box
        sx={{
          width: { xs: "100%", md: `calc(100% - ${LIVELAYOUT_SIDEBAR_WIDTH}px)` },
        }}
      >
        <Outlet context={{ data, updateSettings }} />
      </Box>
    </FlexRow>
  );
};

export function useUserSettings() {
  return useOutletContext<ContextType>();
}
