import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentRequest, CanMakePaymentResult } from "@stripe/stripe-js";

import { DIRECT_TO_TYPE, PAYMENT_STATUS, RECEIVER_TYPE, STRIPE_TRANSACTION_STATUS } from "../types";
import { createStripePayOrder } from "../services";
import { updatePayStatus, updateStripePayStatus } from "../store/payment/actions";

type UseStripePaymemntsButton = {
  amount: number;
  label: string;
  currency: string;
  country: string;
  receiverId: string;
  receiverType: RECEIVER_TYPE;
  directTo?: string;
  directToType?: DIRECT_TO_TYPE;
};

export const useStripePaymentButtons = ({
  amount,
  country,
  currency,
  label,
  receiverType,
  receiverId,
  directTo,
  directToType,
}: UseStripePaymemntsButton) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [stripeAvailableButtons, setStripeAvailableButtons] = useState<CanMakePaymentResult>();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    if (!stripe || !elements || stripeAvailableButtons || amount <= 0) return;
    const pr = stripe.paymentRequest({
      country,
      currency,
      total: {
        label,
        amount: amount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        pr.canMakePayment().then((available) => setStripeAvailableButtons(available as CanMakePaymentResult));
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements, amount]);

  useEffect(() => {
    if (!stripe || !paymentRequest) return;

    // eslint-disable-next-line consistent-return
    paymentRequest.on("paymentmethod", async (e: any) => {
      dispatch(updatePayStatus(PAYMENT_STATUS.INITIALIZED));
      const options = {
        currency,
        amount,
        receiverType,
        receiver: receiverId,
        directTo,
        directToType,
      };

      const { data } = await createStripePayOrder(options);
      const clientSecret = data.client_secret;

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false }
      );

      if (error) {
        e.complete("fail");
        dispatch(updatePayStatus(PAYMENT_STATUS.PAY_CLOSED));
        return null;
      }

      // close payment modal
      e.complete("success");
      if (paymentIntent?.status === "requires_action") {
        stripe.confirmCardPayment(clientSecret);
      }
      dispatch(updatePayStatus(PAYMENT_STATUS.PAY_SUCCESS));
    });
  }, [stripe, paymentRequest, amount]);

  return { stripeAvailableButtons, paymentRequest, setStripeAvailableButtons };
};
