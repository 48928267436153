export const reshape = (arr: any[], size = 4) => {
  const newArr = [];

  while (arr.length) {
    newArr.push(arr.splice(0, size));
  }

  const reshapedArr = newArr.map((rowItems) => {
    if (rowItems.length === size) {
      return rowItems;
    }

    const filledArr = [...rowItems, ...Array(size - rowItems.length)];

    return filledArr;
  });

  return reshapedArr;
};
