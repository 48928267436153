import React from "react";
import { Typography, Button, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FlexCol, FlexRow } from "../../components";
import { AppLogo } from "./index";

interface Props {
  onBack?: () => void;
  onAgree: () => void;
}

export const TOSForm = ({ onBack, onAgree }: Props) => {
  return (
    <FlexCol sx={{ width: "100%", height: "100%", pt: 5, alignItems: "center", justifyContent: "center" }}>
      <FlexRow sx={{ width: "90%" }}>
        <IconButton aria-label="back" onClick={onBack}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <AppLogo style={{ marginRight: '40px', flex: 1 }} />
      </FlexRow>
      <FlexCol sx={{ flexGrow: 1, alignItems: "center" }} height='400px'>
        <Typography sx={{ fontSize: 20, fontWeight: 600, mt: 2, mb: 1 }}>Terms & Conditions</Typography>
        <Typography variant="caption">Date last revised: <strong>18 July 2022</strong></Typography>
        <FlexCol className="-webkit-chatbox-scrollbar chatbox-scrollbar" sx={{ mt: 1.5, px: { xs: 4, sm: 6 }, pb: 2, flexGrow: 1, overflowY: 'auto', minHeight: 0 }}>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ mr: 2 }}>1.</Typography>
            Introduction
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.1</Typography>
            <strong>BOLT GLOBAL MEDIA UK LTD</strong> and its affiliates (“we”, “us” or “BOLT Global) are technology-based companies that builds web3-enabled platforms and services for the next generation of the internet. BOLT Global operates three core businesses across web3 media and entertainment, self-custody non-fungible tokens (“NFT”) and digital asset wallet management, and a web3-commerce marketplace, which are known as Bolt+, BoltX, and BoltZap respectively.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.2</Typography>
            We are pleased to provide you with our Services which are accessible through our Platforms.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            “Platforms” 	means any and all platforms offered by BOLT Global, these include but are not limited to: Bolt+, BoltX, Bolt Zap and boltplus.tv. Any subsequent website, mobile application or software published by BOLT Global will also fall within this definition. Please note that this is not an exhaustive summary and the Platforms may be added or suspended at the discretion of BOLT Global.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            “Services”	means the services offered to you by BOLT Global on our Platforms. This includes Content, web3 media and entertainment, self-custody NFT and digital asset wallet management, and a web3-commerce marketplace, media campaigns, competitions, quizzes and any other services published by BOLT Global including those by third parties. Please note that this is not an exhaustive list and the Services may be added or suspended at the discretion of BOLT Global.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            “Content” 	means all audio, audio-visual, videos, entertainment or informational programming, music, games, chats, texts, posts, comments, messages, articles, photographs, images, graphics, creative works and all other content, information, and materials available on or through the Services, whether posted, uploaded, transmitted, shared, sent or otherwise made available by us, our licensors, vendors, and/or service providers, or by you, and/or other users or third parties, including any such content uploaded manually or bookmarked by you and/or other users. Some Content may be free or paid, depending on the Service options selected or subscribed by you. The term “User-Generated Content” shall refer to the Content posted, submitted, uploaded, transmitted, shared, communicated, sent or otherwise made available by you on our Platforms.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.2</Typography>
            This Terms of Service governs your use of our Services via any means or media. By accessing, visiting and using the same (whether or not as a registered user), you agree to be bound by 1) this Terms of Service; 2) our Privacy Policy and 3) Community Guidelines. Please note that some products, services and/or Content may be subject to additional terms and conditions and/or rules and guidelines which will be deemed incorporated into this Terms of Service by reference.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.3</Typography>
            By downloading, installing or using our App or accessing our Services, you represent that you are at least 13 years old and if you are between 13 to the ages of legal majority in your jurisdiction of residence, you must seek parental or legal guardian approval before using or accessing the Services. We consider it the responsibility of parents and legal guardians to monitor their children’s use of the Services or any part thereof. However, for Paid Subscriptions (as hereinafter defined) and “BOLT X”, you must be at least 18 years old or the age of majority in your jurisdiction and be able to form a legally binding contract or 13 years or older with a parent or guardian consent. If you are a legal entity, you represent that you are the authorised representative to act on its behalf and that their use the service is permitted under the laws of your jurisdiction.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2</Typography>
            Registration and Account/Profile Creation

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.1</Typography>
            You may be required to register and set up an account or profile to access, visit and/or use the Services or any part thereof by creating a user ID and a password. We collect information such as your first and last name, postal address, email address, telephone number, IP address, User ID, Device ID and payment processing information (if relevant) and other information such as gender, marital status, nationality, and country of origin (demographic information), geo-location data, website or app activity data and preferences (collectively “Information”). Please refer to our Privacy Policy on how we may use and process your personal data.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.2</Typography>
            You agree, represent, warrant and covenant that all personal Information provided by you are true, accurate, complete and up-to-date. If there are changes in your Information changes, you are responsible for updating it promptly by using the mechanism or contact information on the Services that allows you to change or update your Information, if available, or as described in our Privacy Policy, as the case may be. We are not responsible to verify your Information registered nor be liable in any way from for your failure to maintain accurate, completed and up to date Information or your failure to receive any critical information.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.3</Typography>
            You agree not to impersonate or misrepresent your affiliation with any person or entity, including using another person’s username, password or other account information, or another person’s name or likeness, or provide false details for a parent or guardian.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.4</Typography>
            You will be solely responsible for maintaining the confidentiality of your password, personal Information, access and usage of your account including by any persons who use your Information, with or without authorisation, or who have access to any device on which your account resides or is accessible. You may not sub-license, transfer, sell, rent or assign your Information to any third party without our written approval. Any attempt to do so will be considered a material breach of this Terms of Service.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.5</Typography>
            If you have reason to believe that your account is no longer secure for any reasons whatsoever, you are responsible for promptly changing the affected Information, user ID and/or password of your account.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.6</Typography>
            As a feature of our Bolt Zap platform, you own a unique domain name NFT which acts as a means to log in to your Account(s) as well as to other sections of the site. This NFT login will be subject to the same terms described within the Terms of Service and the Community Guidelines. You agree that you are responsible for the security of your domain name NFT and if lost BOLT Global cannot recover it for you. Some of our Services will require you to connect your external wallet(s) to our Platforms which allow you to participate in the products and services offered therein. We reserve the right to remove, suspend and/or ban the use of any wallets which may be controversial or associated with illicit activities.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3</Typography>
            User-Generated Content

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3.1</Typography>
            Where the Services or activities therein allow you to post, submit, upload, transmit, share, communicate, send or otherwise made available any Content (“User-Generated Content”), you agree to ensure that the use thereof will comply with this Terms of Service, our Community Guidelines, applicable laws and other terms and policies.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3.2</Typography>
            You retain the rights in your User-Generated Content and you grant us and our users a perpetual non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, store, display, reproduce, copy, modify, translate, subtitle, create derivative works, distribute, redistribute and promote your User-Generated Content. You can end this license anytime by deleting your User-Generated Content. However, your User-Generated Content may continue to appear as you have shared them with others and they have not deleted them.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3.3</Typography>
            In respect of any new, enhancement or improvement ideas and suggestions you may have provided to us in relation to the Services, you agree that we will be entitled to use the same for any purpose without providing compensation or credit to you.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.</Typography>
            Prohibited Content

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.1</Typography>
            You are responsible for the use of the Services and any User-Generated Content you submit, post, upload, share or communicate. You warrant and agree that you are the creator or have cleared the requisite rights and have the authority to share the same and to grant the rights herein and that your User-Generated Content will not include:-
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            inappropriate language or scenes and/or any content promoting bigotry, racism or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            elements which may render it unlawful, threatening, offensive, malicious, harmful, obscene, pornographic, misleading, abusive, unethical, morally, seditious, blasphemous, religiously or racially offensive or otherwise prohibited for distribution.

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            anything that shall libel, slander or defame any person, or violate, infringe upon or give rise to any claim with respect to any copyright, right of privacy, trademark, moral right, proprietary right or any interest of any third party, contractual right, the common law right or violate any law.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            any form of advertising or commercial solicitation or incorporated any sponsored post or material.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.2</Typography>
            Although we are not responsible to screen or monitor your User-Generated Content and any of the content thereof, we reserve the right to modify, remove or take down your User-Generated Content and/or to suspend your profile/account indefinitely for any reasons. This includes instances where we believe your User-Generated Content has violated this our Terms of Service, Community Guidelines, applicable laws, or other policies.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.3</Typography>
            If you believe your copyrights in your User-Generated Content have been exploited by other users in such a manner that constitutes infringement, you may notify us at the Contact Details below whereupon we will notify the alleged infringer to obtain proof of ownership within a stipulated period, failing which we will proceed to remove or take down the affected User-Generated Content.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.</Typography>
            Usage Rules and Prohibited Activities

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.1</Typography>
            You may not use the Services to violate the security, integrity or operations of our systems and network or software applications. Such prohibited activities include:-

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>(i)</Typography>
            uploading any viruses, malicious codes, malware or other material which is malicious or technologically harmful that could disrupt, overburden, harm or impair the proper working of our Services.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>(ii)</Typography>
            collecting any data from our Services using automatic means or accessing any data which you do not have permission to access.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.2</Typography>
            You must obtain our written permission or be under an open-source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.3</Typography>
            Our Services may include hyperlinks or deep links to other websites which are not controlled or maintained by us. As such, we are not responsible and make no warranties in respect of the contents of such third-party Apps, websites, products or services.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.4</Typography>
            You are permitted to download and install the Apps offered on our Services for your use as permitted under this Terms of Service. You may not reverse engineer, decompile, or disassemble the Apps or use it in such a manner that will cause us harm or violate any applicable laws.


          </Typography>


          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.5</Typography>
            You are responsible for any internet connection or mobile data charges that you incur when assessing our Services.


          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.6</Typography>
            We reserve the rights to change or discontinue, suspend or any part of our Services and/or crypto asset, restrict or cancel your access to any of the Services at any time at our discretion.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.</Typography>
            Bolt Zap and Bolt X
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.1</Typography>
            Bolt Zap is a peer-to-peer Web3 social platform that offers extensive NFT services to its users. By using the Bolt Zap platform, you consent and agree to the Terms of Service, Community Guidelines, Privacy Policy and the related terms of service relating to BOLT Global’s services. This includes Bolt Zap (through boltplus.tv) which services currently include but are not limited to the following:

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Allowing users to mint their unique domain name NFT which can be used to log in and access exclusive content;

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Transfer and sale of NFT; and

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            The creation of exclusive NFT collections and pages which can be accessed through NFT domain name logins.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.2</Typography>
            Bolt X Is a non-custodial wallet capable of storing and transferring a plethora of cryptoassets, among those are NFT. This is done through both the proprietary technology of BOLT Global and through the use of several third parties partners that aid in the trade and transfer of NFT. By using the Bolt X wallet, you agree to this Terms of Service, Community Guidelines, Privacy Policy and the related terms of service relating to BOLT Global’s services. You also agree that the use of the Bolt X wallet entails the use of third parties whose services are governed by their terms and conditions, to which you also consent in the context of the services offered on the Bolt X wallet.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.</Typography>
            Assumption of Risk on the Bolt Zap platform

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.1</Typography>
            You agree and acknowledge that:

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            The value of an NFT is subjective. Prices of cryptoassets are subject to volatility and fluctuations in the price of the cryptocurrency can also materially and adversely affect cryptoassets prices. You acknowledge that you fully understand this subjectivity and volatility and that you may lose money.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            The regulatory regime governing blockchain technologies, cryptoassets, cryptocurrency, and other crypto-based items is uncertain, and new regulations or policies may materially adversely affect the development of the Service and the utility of cryptoassets. As such, if we determine that any service or product which is offered or displayed on our Platforms might put us within a regulatory framework regardless of the nature (Security Tokens, E-Money, etc), we reserve the right to remove such cryptoassets from our Platforms.

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            You are solely responsible for determining what, if any, taxes apply to your transactions. BOLT Global is not responsible for determining the taxes that apply to your cryptoassets.


          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            There are risks associated with purchasing items with content created by third parties through peer-to-peer transactions, including but not limited to, the risk of purchasing counterfeit items, mislabelled items, items that are vulnerable to metadata decay, items on smart contracts with bugs, and items that may become untransferable. You represent and warrant that you have done sufficient research before making any decisions to sell, obtain, transfer or otherwise transact any cryptoassets activities.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.</Typography>
            Payments
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.1</Typography>
            You have the right to buy, sell, trade, transact and list NFT, cryptoassets and cryptocurrencies on the Platforms. BOLT Global also reserves the right to charge a handling/transaction fee on every transaction that is made through our Platforms.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.2</Typography>
            You agree to not hold BOLT Global liable for any issues which may arise from NFT, cryptoassets or crypto transactions that happen through the use of an outside wallet or third-party payment systems.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.3</Typography>
            You are solely responsible for determining what, if any, taxes apply to all your transactions including those arising from the Revenue (as hereinafter defined). BOLT Global is not responsible for determining the taxes that apply to your payments, be they fiat, cryptoassets, cryptocurrencies or otherwise.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.</Typography>
            Creators and/or Users’ Revenue
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.1</Typography>
            As a content creator and user, you may receive or be entitled to gifts, tipping, any other rewards, and revenue from sponsorships of your Content (collectively “Users’ Revenue”). You agree that such Users’ Revenue may be subject to sharing with BOLT Global and/or third-party service providers; and/or subject to transaction fees. You authorise BOLT Global and/or third-party service providers to make such deductions from the Revenue by such payment means and methods as may be implemented by BOLT Global on its Platforms to receive their respective portions of the Revenue thereof.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.2</Typography>
            You further acknowledge and agree that we may, and authorise us to, process all transactions, including without limitation, tokens earned or rewarded to you for your content and user engagements, given or tipped to other users, received from other users, purchased, that are initiated by use of your Information and/or account. You also authorised us to deduct such charges for tipping and/or commission (on a per token transaction basis) at the prevailing rate.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.3</Typography>
            BOLT Global shall be entitled to revise such percentages of sharing and/or transaction fees it charges from time to time at its sole discretion.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.</Typography>
            Creators and/or Users’ Revenue
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            Free Service and Paid Subscriptions

          </Typography>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ mr: 2 }}>10.1</Typography>
            Our service options may include Services (such as “Bolt+ Premium”) provided on a free basis where it does not require payment (“Free Service”); other Services and/or Content that require payment before you can access them (the "Paid Subscriptions"). There may also be other promotional services or packages (including offerings of third-party products and services in conjunction with or through our Free Service or Paid Subscriptions Services) (collectively “other Promotional Services”). Please note that we will not responsible for such third-party products and services.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.2</Typography>
            We reserve the right to modify, terminate or otherwise amend our Services, Content, Service Options or any part thereof at any time.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.3</Typography>
            If you have purchased or subscribed to Paid Subscriptions or other Promotional Services including free Trials, separate terms and conditions will apply to your access to the relevant Services thereof and you agree to comply with any such additional terms and conditions. To the extent that there is any irreconcilable conflict between the terms herein and the additional terms, the latter shall prevail in so far as it relates to the Paid Subscriptions or other Promotional Services.

          </Typography>


          <Typography variant="caption" sx={{ mt: 3 }}>
            Trials

          </Typography>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ mr: 2 }}>10.4</Typography>
            From time to time, we or third parties on our behalf, may offer trials of Paid Subscriptions or other Promotional Services for a specified period without payment (“Free Trial Offers”) or at a reduced rate ("Paid Trial Offers"). Such Trials may be subject to eligibility criteria stipulated by us and we may withdraw or modify a Trial at any time without prior notice nor liability to you.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.5</Typography>
            You may be required to provide your payment details to start the Trial. By providing such details, you agree that we may automatically begin charging you for the Paid Subscription immediately following the end of the Trial period on a recurring basis (weekly, monthly or other intervals that we disclose to you in advance). If you do not want this charged, you must cancel the applicable Paid Subscription before the end of the Trial by stipulated time or period in accordance with the stipulated instructions or mechanism.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            Payment And Cancellations
          </Typography>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ mr: 2 }}>10.6</Typography>
            Paid Trial and Paid Subscription will be charged either directly by us or through a third party authorised by us. It may be a pre-payment or recurring subscription basis at such intervals as may be disclosed to you in the additional Terms and Conditions attached to the relevant Services Options.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.7</Typography>
            Users can pay using cryptoassets, credit cards, or through their telco payment provider on our website. Paid Trial and Paid Subscriptions can also be purchased in-app on IOS/Android using Apple Pay or Google Pay.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.8</Typography>
            Such charges may be revised by us from time to time as may be communicated to you and if applicable, accepted by you in the manner stipulated by us. If you do not agree with the revised price, you have the right to reject it by unsubscribing from the Paid Subscription or not accept the same prior to the effective date of the price change. If the absence of these actions(s), your continued use of our Services after the price change shall be deemed acceptance by you to the extent permitted by law.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>11.</Typography>
            Quizzes, Contests, Games and in-App Purchases

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>11.1</Typography>
            Your participation in quizzes, contests, games and purchase of any in-App products and services on our Platforms (whether or not they are integrated with a partner/third party website, service, application, platform and/or content), may be subject to additional rules, terms and conditions that are supplemental to this Terms of Service.

          </Typography>


          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>12.</Typography>
            Intellectual Property
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>12.1</Typography>
            The Content trademarks, service marks and all graphical elements including the look and feel appearing on the Services, are protected by copyright laws. You are not allowed to modify, copy, distribute, reproduce or commercially deal with such trademarks and copyrights, unless permitted by law or with the prior consent from the intellectual property rights owners. You do not acquire a license or any ownership to the same through your access to the Content and/or Services.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>12.2</Typography>
            You agree to let us display your NFT on our platforms Bolt+.TV and Bolt X, this includes but is not limited to promotional material, marketing campaigns, the display of your NFT on exclusive pages on our Platforms and all relevant activities which include the transaction, sale and display of your NFT.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>12.3</Typography>
            You represent that you own, control or are licensed to use your Content, Login ID, domain names used on the Platforms and the publication of the same shall not infringe any copyrights, proprietary or intellectual property rights of any parties. You shall keep BOLT Global fully indemnified in this respect. In the event of any claims of such breach against BOLT Global, without prejudice to BOLT Global rights against you hereunder, we shall have the right to remove your User-Generated Content, suspend and/or ban your account and wallet(s).

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.</Typography>
            Disclaimer

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.1</Typography>
            We do not make any express or implied warranties, representations, endorsements or guarantees of any kind including without limitation, the usefulness, completeness, adequacy or accuracy, in respect of the Services and the Content (including the User-Generated Content) or that the Services will operate without any interruption or error or that it will be free of virus and other harmful components. Any use of reliance of any Content, User-Generated Content or materials obtained by you through the Services is at your own risk. We disclaim all conditions, representations and warranties not expressly stated in this Terms of Service to the fullest extent permitted by law.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.2</Typography>
            You are responsible to evaluate and verify the usefulness, completeness, adequacy or accuracy of any information provided by us or any third parties about our BOLT tokens and/or cryptoassets prior to making any investment, business or commercial decisions in respect of your collection, giving, purchase and/or sale thereof. You also agree and acknowledge that cryptoassets are volatile in nature and that their price, volume or performance can also materially and adversely affect your assets. You acknowledge that you fully understand this subjectivity and volatility and that you may lose capital and that you do not hold BOLT Global liable for any losses you may incur.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.3</Typography>
            You agree that we will not be liable for any unauthorised copying, use or distribution of your User-Generated Content by any third parties and that you will release and forever waive any claims you may have against us for such unauthorised use.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.4</Typography>
            You agree and consent to not holding BOLT Global or its affiliates responsible or liable for any transaction which employs the use of third-party payment systems. This includes but is not limited to: any sort of fiat or E-Money transfers, on and off ramping, crypto to crypto swaps, crypto to fiat swaps and vice versa. This applies to all Services on our Platform.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.5</Typography>
            You understand that we do not control the public blockchains that you are interacting with and we do not control certain smart contracts and protocols that may be integral to your ability to complete transactions on these public blockchains.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.6</Typography>
            You also agree that there are risks associated with using Internet and blockchain-based products, including, but not limited to, the risk associated with hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to your third-party wallet, Account(s) on the BOLT Global Platforms. You accept and acknowledge that BOLT Global will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Service or any Blockchain network, however caused.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>13.7</Typography>
            We shall not be liable to you for any indirect, consequential, special, incidental, punitive loss and damages, or for any loss of business opportunity, profits, revenue, property damage or any loss of data howsoever arising in connection with your use, access or inability to use our Services. This includes any conduct or content of users and third parties on the Services which may be defamatory, offensive or unlawful. In no event shall our total liability to you for all damages, losses and causes of actions exceed USD500.00. You agree that any cause of action arising out of or related to the Services must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>14.</Typography>
            Consequence of Violation
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>14.1</Typography>
            If you breach or violate any terms of this Terms of Service (and its related guidelines, policies and other terms and conditions referenced herein) as determined by us, we may terminate, discontinue, suspend, curtail, block and/or restrict your account/profile and/or Content, your ability to access, visit, and/or use the Services or any part thereof, with or without notice, in addition to our other remedies.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>14.2</Typography>
            You agree to indemnify and hold us harmless from and against all damages, losses, and expenses of any kind (including reasonable legal fees and costs) arising out of or related to your breach of the Terms of Service or any additional terms and conditions or Agreements; your Post(s) or any other content you otherwise contribute; any activity in which you engage on or through the Service; and your violation of any law or the rights of a third party.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>15.</Typography>
            Infringement Claims

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>15.1</Typography>
            For us to process your infringement claims regarding the Content on the Services, you must be the rightsholder or someone legally authorised to act on behalf of the rightsholder. Your notice to us must include:

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Identification of the copyrighted work(s), trademark, publicity rights, or other intellectual property rights that you claim are being infringed;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Identification of the allegedly infringing material that is requested to be removed, including a description of the specific location (i.e., URLs) on the Service of the material claimed to be infringing, so that we may locate the material;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Your contact information – at a minimum, your full legal name (not a pseudonym) and email address;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            A legal declaration that contains all of the following:
          </Typography>

          <Typography variant="caption" sx={{ mt: 1, ml: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the intellectual property rights owner, its agent, or the law;

          </Typography>

          <Typography variant="caption" sx={{ mt: 1, ml: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            A statement that the information in the notice is accurate; and

          </Typography>

          <Typography variant="caption" sx={{ mt: 1, ml: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            A statement under penalty of perjury that you are authorized to act on behalf of the intellectual property owner of the intellectual property that is allegedly being infringed.

          </Typography>


          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Your physical or electronic signature (of your full legal name).

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>15.2</Typography>
            We will be entitled (but not obligated) to serve an alleged infringer an infringement notice and request for proof of intellectual property rights. If we do not hear back from the alleged infringer within the stipulated period, we will be entitled (but not obligated) to remove the alleged infringed User-Generated Content. For this purpose, please note that we will be entitled to forward your notice of intellectual property infringement to the alleged infringer and/or request that the dispute by directly handled between the parties. We are not your mediators and will not be held responsible if the alleged infringer refuses to take down the alleged infringed User-Generated Content.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.</Typography>
            General terms
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.1</Typography>
            Failure or delay by us to exercise or enforce any of our rights under this Terms of Service shall not constitute a waiver of such rights.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.2</Typography>
            If any part of this Terms of Service is adjudged to be invalid or unenforceable, the remainder of its terms will continue in full force and effect, and if any part is held to be contrary to law, then such provision shall be construed as nearly as possible to reflect the intentions of the parties.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.3</Typography>
            This Terms of Service shall be governed by and be construed in accordance with the laws of England and Wales without reference to conflict of laws. You agree to the exclusive jurisdiction of the Courts in England in all disputes arising out of or relating to the Services and expressly waive all defences to jurisdiction. You further agree that any action taken by you will be in your individual capacity and not as a class or representative action.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.4</Typography>
            You consent to receive notices and other communications, electronically from us at the email address you have provided and to receive updated versions of our Apps and related party third-party software.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.5</Typography>
            We reserve the right to amend or modify any of the terms and conditions hereunder and correct any errors and omissions. The last revised date will be put at the top of this document. Your continued access, visitation and/or use of the Services will constitute your acceptance of any changes or revisions to this Terms of Service.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>16.6</Typography>
            Unless otherwise provided herein to the contrary, in the event of any conflict or inconsistency between the terms and conditions of this Terms of Service, and any other terms and/or conditions applicable to the Services, we shall determine which rules, restrictions, limitations, terms and/or conditions shall control and prevail in our sole discretion, and you specifically waive any right to challenge or dispute such determination.
          </Typography>


          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>17.</Typography>
            Contact Information
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>17.1</Typography>
            Should you have any questions concerning this Terms of Service or our Services, please contact us at contact@bolt.global, or write to us at BOLT GLOBAL MEDIA UK LTD at SOHO Works 2, Television Centre 101 Wood Lane White City W12 7FR.
          </Typography>
        </FlexCol>
      </FlexCol>
      <FlexRow sx={{ px: 3, alignItems: "center", width: "100%", height: "80px", backgroundColor: '#171B3A', boxShadow: '0px -10px 24px 5px #11142D' }}>
        <Typography sx={{ fontSize: 12, fontWeight: 300, mr: 1, flex: 1 }}>I have read and hereby agree to BOLT's Terms and Conditions.</Typography>
        <Button variant="contained" sx={{ height: 30, background: '#6535E9', px: 5, borderRadius: 4 }} onClick={onAgree}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: 14 }}>Accept</Typography>
        </Button>
      </FlexRow>
    </FlexCol>
  );
};
