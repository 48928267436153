import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* getNftCreators(): any {
  while (true) {
    const { payload } = yield take(actions.GET_NFT_CREATOR + START);
    try {
      const { data } = yield apiClient.get(`/nftcreators/${payload}`);

      yield put({
        type: actions.GET_NFT_CREATOR + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.GET_NFT_CREATOR + FAIL,
        payload: error.response.data,
      });
    }
  }
}

function* fetchNFTTweets() {
  while (true) {
    const { payload } = yield take(actions.FETCH_NFT_TWEETS + START);
    const { channelId, nextPageToken = "" } = payload;
    try {
      const { data } = yield apiClient.get(
        `/feed/nftCommunity/${channelId}/tweets?limit=10&pageToken=${nextPageToken}`
      );
      yield put({
        type: actions.FETCH_NFT_TWEETS + SUCCESS,
        payload: { ...data, channelId },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_NFT_TWEETS + FAIL,
        payload: { ...BoltError(error), channelId },
      });
    }
  }
}

function* fetchNftCommunityFeeds(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_NFT_COMMUNITY_FEEDS + START);
    const { channelId, page = 1, limit = 10 } = payload;
    try {
      const { data } = yield apiClient.get(`/feed/nftCommunity/${channelId}?limit=${limit}&page=${page}`);
      yield put({
        type: actions.FETCH_NFT_COMMUNITY_FEEDS + SUCCESS,
        payload: { channelId, ...data },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_NFT_COMMUNITY_FEEDS + FAIL,
        payload: { channelId, ...BoltError(error) },
      });
    }
  }
}

function* followNFT() {
  while (true) {
    const { payload } = yield take(actions.FOLLOW_NFT + START);
    const { channelId, follow = true } = payload;
    try {
      const { data } = yield apiClient.post(`/nftcreators/${channelId}/follow`, { follow });
      yield put({
        type: actions.FOLLOW_NFT + SUCCESS,
        payload: { channelId, follow },
      });
    } catch (error) {
      yield put({
        type: actions.FOLLOW_NFT + FAIL,
        payload: { ...BoltError(error), channelId, follow },
      });
    }
  }
}

export default function* nftCreatorSaga() {
  yield all([fork(getNftCreators), fork(fetchNFTTweets), fork(followNFT), fork(fetchNftCommunityFeeds)]);
}
