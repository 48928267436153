import React from "react";
import { Backdrop, CircularProgress, SxProps } from "@mui/material";

type Props = {
  sx?: SxProps;
  visible?: boolean;
} & React.HTMLProps<HTMLElement>;

export const LoadingView = ({ children, sx, visible = false }: Props) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }} open={visible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
