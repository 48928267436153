import React, { useState } from "react";
import ReactPlayer from "react-player";
import { InView } from "react-intersection-observer";
import { FlexCol } from "./FlexCol";

type Props = {
  url?: string;
  thumbnail?: string;
  onClick: (e: any) => void;
  zoomedViewOpen?: boolean;
};

const FeedVideo = ({ url = "", thumbnail, onClick = () => { }, zoomedViewOpen = false }: Props) => {
  const [videoControl, setVideoControl] = useState(false);
  const [muted] = useState(true);

  return (
    <FlexCol
      sx={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
        bgcolor: "background.paper",
        position: "relative",
        cursor: "pointer"
      }}
      onMouseLeave={() => setVideoControl(false)}
      onMouseOver={() => setVideoControl(true)}
    >
      <InView rootMargin="-40% 0px -40% 0px">
        {({ inView, ref }) => {
          return (
            <div ref={ref} style={{ height: "100%", width: "100" }}>
              <ReactPlayer
                playing={inView && !zoomedViewOpen}
                playsinline
                muted={muted}
                controls={videoControl}
                url={url}
                light={thumbnail}
                width="100%"
                height="100%"
                style={{ display: "flex", backgroundColor: "black", objectFit: "cover" }}
                pip
              />
            </div>
          );
        }}
      </InView>
      {/* backdrop */}
      <div style={{ position: "absolute", inset: 0 }} role="presentation" onClick={onClick} />
    </FlexCol>
  );
};

export default FeedVideo;
