import React from "react";
import { ModalProps } from "@mui/material";

import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";
import { BaseWithdrawModal } from "./BaseWithdrawModal";
import { WithdrawForm } from "../../WithdrawForms";

interface Props extends Partial<ModalProps> {}

export const WithdrawFormModal = ({ ...rest }: Props) => {
  const { values, setValues } = useWithdraw();

  return (
    <BaseWithdrawModal
      {...rest}
      open={values.modal === WithdrawModalType.FORM}
      loading={values.loadings.mfaSettingUp}
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <WithdrawForm />
    </BaseWithdrawModal>
  );
};
