import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";

import { CHANNEL_TYPE, RECEIVER_TYPE } from "../../types";
import { defaultChatContainerId } from "../../constants";
import { ChatProps } from "./chat";

type ImmersivePanelContextType = {
  activeTab: number;
  //
  chatProps: ChatProps;
  //
  updateContext: (payload: Partial<Omit<ImmersivePanelContextType, "updateContext">>) => void;
};

const defaultImmersivePanelContextValue: ImmersivePanelContextType = {
  activeTab: 0,
  chatProps: {
    containerId: defaultChatContainerId,
    channelId: "",
    channelType: CHANNEL_TYPE.CHANNEL,
    receiverName: "",
    receiverType: RECEIVER_TYPE.CHANNEL,
  },
  //
  updateContext: () => {},
};

export const ImmersivePanelContext = createContext<ImmersivePanelContextType>(defaultImmersivePanelContextValue);

export type ImmersivePanelContextProviderProps = {
  chatProps: ImmersivePanelContextType["chatProps"];
  onChangeTab?: (value: number) => void;
};

export const ImmersivePanelContextProvider = ({
  children,
  chatProps,
  onChangeTab = () => {},
}: PropsWithChildren<ImmersivePanelContextProviderProps>) => {
  const [contextValue, setContextValue] = useState<ImmersivePanelContextType>(defaultImmersivePanelContextValue);

  const updateContext: ImmersivePanelContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    onChangeTab(contextValue.activeTab);
  }, [contextValue.activeTab]);

  const memoizedValue = useMemo(() => {
    return { ...contextValue, chatProps, updateContext };
  }, [contextValue, chatProps]);

  return <ImmersivePanelContext.Provider value={memoizedValue}>{children}</ImmersivePanelContext.Provider>;
};

export const useImmersivePanelContext = () => {
  const context = useContext(ImmersivePanelContext);

  return context;
};
