import { createAction } from "redux-actions";
import { START } from "../common";

export const INIT_WALLET = "INIT_WALLET";
export const UPDATE_WALLET = "UPDATE_WALLET";
export const CONNECT_ACCOUNT = "CONNECT_ACCOUNT";
export const UPDATE_BALANCES = "FETCH_BALANCES";
export const UPDATE_TOKENS = "FETCH_TOKENS";
export const FETCH_NFT = "FETCH_NFT";

export const DISCONNECT_ACCOUNT = "DISCONNECT_ACCOUNT";

export const initWalletAction = createAction(INIT_WALLET + START);
export const updateWalletAction = createAction(UPDATE_WALLET);
export const fetchNftAction = createAction(FETCH_NFT + START);

export const disconnectAccountAction = createAction(DISCONNECT_ACCOUNT + START)
