import React from "react";
import { Button, CircularProgress, ModalProps, Typography } from "@mui/material";

import { BaseMFAModal } from "../BaseMFAModal";
import { FlexCol } from "../../FlexCol";
import { useWithdraw } from "../../../hooks/WithdrawContext";
import { ReactComponent as MFAVerifiedIcon } from "../../../assets/svgs/mfaVerified.svg";
import { WithdrawModalType } from "../../../types";

interface Props extends Partial<ModalProps> {}

export const WithdrawMFASetupSuccessModal = ({ ...rest }: Props) => {
  const { values, setValues } = useWithdraw();
  const [animating, setAnimating] = React.useState(false);
  const isSetup = values.modal === WithdrawModalType.CODE_SETUP_SUCCESS;
  const isVerify = values.modal === WithdrawModalType.CODE_VERIFY_SUCCESS;

  React.useEffect(() => {
    if (isVerify) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
        setValues({ ...values, modal: WithdrawModalType.WITHDRAW_CONFIRM });
      }, 1000);
    }
  }, [isVerify, values, setValues]);

  return (
    <BaseMFAModal
      {...rest}
      open={isSetup || isVerify}
      showTitle={false}
      disableClose={isVerify}
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <FlexCol sx={{ px: 4 }}>
        <FlexCol alignItems="center">
          <MFAVerifiedIcon />
          <Typography variant="subtitle1" textAlign="center" color="#A3AED0" fontWeight="600" sx={{ mt: 1 }}>
            {isSetup ? "Two Factor Authentication Successfully enabled" : "Two Factor Authentication Verified"}
          </Typography>
        </FlexCol>
        <FlexCol alignItems="center" sx={{ mt: 3, position: "relative" }}>
          {isSetup && (
            <Button
              variant="contained"
              sx={{ borderRadius: 40, width: "50%" }}
              onClick={() => setValues({ ...values, modal: WithdrawModalType.BACKUP_CODE })}
            >
              Next
            </Button>
          )}
          {isVerify && animating && <CircularProgress color="primary" />}
        </FlexCol>
      </FlexCol>
    </BaseMFAModal>
  );
};
