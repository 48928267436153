import { Box } from "@mui/material";

import { BoltPlayer } from "../../components";
import { usePrivateLayoutContext } from "../../layouts";
import "./styles.css";
import { useCollectionPageContext } from "./context";

export const Background = () => {
  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  const { bgVideo } = useCollectionPageContext();

  if (!bgVideo) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: navbar__height,
        left: { xs: 0, md: leftMenubar__width },
        right: 0,
        bottom: 0,
      }}
    >
      <BoltPlayer url={bgVideo} controls={false} className="player-bg-mode" />
    </Box>
  );
};
