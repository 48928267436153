import { useEffect, useRef, useState } from "react";
import { Box, Button, Fab, LinearProgress, Typography, alpha } from "@mui/material";
import { MonetizationOn, SendRounded } from "@mui/icons-material";

import { Message } from "../../../types";
import { FlexRow } from "../../FlexRow";
import BoltMentionInput from "../../BoltMentionInput";
import { GiphyModal } from "../../modals/GiphyModal";
import GiftBoltsModal from "../../GiftBoltsModal";
import { DisplayChatMessage } from "./DisplayChatMessage";
import { SenderPopover } from "./SenderPopover";
import { useChatContext } from "./ChatContext";
import { useChatUi } from "./useChatUi";
import { TyperIndicator } from "./TyperIndicator";
import { apiClient } from "../../../config";

export const ChatUi = () => {
  const { containerId, channelId, receiverName, receiverType, messages, isLoadingMessages, loadMore } =
    useChatContext();

  const {
    editorState,
    resetCreator,
    msgSender,
    showGiphyModal,
    showGiftBox,
    anchorElPopover,
    sendMessage,
    sendGiphy,
    onDonate,
    updateChatState,
  } = useChatUi();

  const loader = useRef();

  useEffect(() => {
    const root = document.getElementById(containerId || "");

    const options = { root, rootMargin: "0px", threshold: 0.25 };
    const observer = new IntersectionObserver(loadMore, options);

    if (loader?.current) {
      observer.observe(loader?.current);
    }

    return () => {
      if (loader?.current) {
        observer?.unobserve(loader?.current);
      }
    };
  }, [loadMore]);

  interface Ad {
    assetUrl: string;
    placement: string;
  }

  const [chatAds, setChatAds] = useState<Ad[]>([]);
  const [chatAdIndex, setChatAdIndex] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apiClient.get(`/advertisements/get?limit=10&page=1&skip=0`);
      setChatAds(data?.data.filter((ad: any) => ad.placement === "chat"));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setChatAdIndex((prevIndex) => (prevIndex + 1) % chatAds.length);
    }, 5000); // 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [chatAds]);

  const renderChatAd = (index: number) => {
    // if (chatAds.length === 0) return null;
    // return (
    //   <div>
    //     {chatAds[chatAdIndex] && <img src={chatAds[chatAdIndex].assetUrl} alt="Chat Ad" style={{ width: "100%" }} />}
    //   </div>
    // );

    if (chatAds.length === 0) return null; // No ads available

    if ((index + 1) % 8 === 0) {
      return (
        <div>
          {chatAds[chatAdIndex] && <img src={chatAds[chatAdIndex].assetUrl} alt="Chat Ad" style={{ width: "100%" }} />}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <LinearProgress
        ref={loader}
        sx={{
          width: 1,
          height: "1px",
          opacity: isLoadingMessages ? 1 : 0,
          position: "sticky",
          top: 70,
          zIndex: 10,
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            mt: "auto",
          }}
        >
          {messages.map((item: Message, index) => (
            <Box key={index} sx={{ px: 1 }}>
              <DisplayChatMessage
                item={item}
                handleClickProfile={(anchorElPopover, msgSender) => {
                  updateChatState({
                    anchorElPopover,
                    msgSender,
                  });
                }}
              />
              {renderChatAd(index)}
            </Box>
          ))}

          <Box
            ref={loader}
            sx={{
              width: 1,
              height: "1px",
              mb: messages.length > 0 ? 1 : 0,
            }}
          />
        </Box>

        <Box
          sx={{
            py: 1,
            borderTop: "1px solid rgba(255, 255, 255, 0.05)",
            position: "sticky",
            bottom: 0,
            width: "100%",
            bgcolor: alpha("#13162C", 0.6),
            backdropFilter: "blur(40px)",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <TyperIndicator />

          <FlexRow
            className="chat-input"
            sx={{
              px: 1,
            }}
          >
            <BoltMentionInput
              placeholder="Type something..."
              containerStyle={{ backgroundColor: "#373947" }}
              resetCreator={resetCreator}
              showGifIcon
              onChangeText={(message: string) => {
                updateChatState({ message });
              }}
              onChangeDraftContent={(draftContent) => {
                updateChatState({ draftContent });
              }}
              onChangeEditorState={(editorState) => {
                updateChatState({ editorState });
              }}
              onEnterKey={sendMessage}
              onClickGiphy={() => {
                updateChatState({
                  showGiphyModal: true,
                });
              }}
            />
            <Fab
              variant="circular"
              color="primary"
              sx={{
                width: 44,
                height: 44,
                flexShrink: 0,
                px: 2.3,
                background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                borderRadius: 2,
                ml: 1,
                zIndex: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <SendRounded sx={{ width: 18, height: 18, color: "white" }} />
            </Fab>
          </FlexRow>

          <FlexRow sx={{ px: 1, alignItems: "center", gap: 1 }}>
            <Button
              disableElevation
              variant="contained"
              startIcon={<MonetizationOn />}
              sx={{ borderRadius: 1.5 }}
              onClick={() => {
                onDonate(true);
              }}
            >
              <Typography sx={{ fontWeight: "600" }} variant="body2">
                Donate
              </Typography>
            </Button>

            <Typography sx={{ fontSize: 12 }}>to support your favourite streamers!</Typography>
          </FlexRow>
        </Box>
      </Box>

      <SenderPopover
        sender={msgSender}
        anchorElPopover={anchorElPopover}
        setAnchorElPopover={(anchorElPopover) => {
          updateChatState({
            anchorElPopover,
          });
        }}
      />

      <GiphyModal
        open={showGiphyModal}
        inputPlaceholder="Type something..."
        initialEditorState={editorState}
        onClose={() => {
          updateChatState({ showGiphyModal: false });
        }}
        onSelectItem={(data) => {
          sendGiphy({ ...data });
        }}
      />

      <GiftBoltsModal
        open={showGiftBox}
        onClose={() => onDonate(false)}
        receiverId={channelId}
        receiverName={receiverName}
        receiverType={receiverType}
      />
    </>
  );
};
