import React from "react";
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ModalProps,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { BaseModal } from "./BaseModal";
import { TermsAndConditions } from "./TermsAndConditions";
import { joinWorldCupCampaignAction } from "../../store/profile/actions";
import { ReactComponent as CheckedIcon } from "../../assets/svgs/checked.svg";

interface Props extends Partial<ModalProps> {
  containerStyle?: SxProps;
  onConfirm?: () => void;
  onClose?: () => void;
}

enum APPLY_STEP {
  intro,
  t_c,
  confirm,
}

export const WorldCupCampaignModal = ({
  containerStyle,
  children,
  onConfirm = () => {},
  onClose = () => {},
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(APPLY_STEP.intro);
  const [agreed, setAgreed] = React.useState(false);

  const acceptContest = React.useCallback(() => {
    dispatch(joinWorldCupCampaignAction());
    onConfirm();
  }, []);

  return (
    <BaseModal {...rest} open={!!rest.open} onClose={onClose}>
      <Box
        component={Paper}
        sx={{
          px: 0,
          py: 0,
          borderRadius: 4,
          position: "relative",
          flexDirection: "column",
          maxWidth: 600,
          maxHeight: 800,
          minHeight: 800,
          ...containerStyle,
        }}
      >
        {step === APPLY_STEP.intro && (
          <FlexCol>
            <FlexCol>
              <Avatar
                src="https://cdn.boltplus.tv/assets/2022_world_cup.png"
                sx={{
                  minHeight: "750px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  borderRadius: "0px",
                }}
              >
                2022 World Cup Campaign
              </Avatar>
              <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </FlexCol>
            <FlexCol component={Paper} sx={{ p: 2, borderRadius: 2.5, width: "100%" }}>
              <Button
                variant="contained"
                sx={{ textTransform: "none", borderRadius: 2, mx: "auto" }}
                onClick={() => setStep(APPLY_STEP.t_c)}
              >
                Join the contest
              </Button>
            </FlexCol>
          </FlexCol>
        )}
        {step === APPLY_STEP.t_c && (
          <FlexCol sx={{ height: "100%", width: "100%" }}>
            <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
            <TermsAndConditions />
            <FlexCol sx={{ justifyContent: "flex-end" }}>
              <FlexRow
                sx={{
                  p: 2,
                  backgroundColor: "secondary.dark",
                  justifyContent: "space-between",
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <FormControlLabel
                  componentsProps={{ typography: { variant: "tiny" } }}
                  label="I have read and hereby agree to the contest's Terms and Conditions"
                  control={<Checkbox size="small" id="allowUserTracking" checked={agreed} />}
                  onChange={(event: any) => setAgreed(event.target.checked)}
                />
                <Button
                  variant="contained"
                  sx={{ height: 30, px: 5, borderRadius: 2 }}
                  onClick={() => agreed && setStep(APPLY_STEP.confirm)}
                >
                  <Typography variant="subtitle2">Accept</Typography>
                </Button>
              </FlexRow>
            </FlexCol>
          </FlexCol>
        )}
        {step === APPLY_STEP.confirm && (
          <FlexCol>
            <Avatar
              src="https://boltplus.s3.ap-southeast-1.amazonaws.com/assets/2022_world_cup.png"
              sx={{
                minHeight: "750px",
                height: "100%",
                width: "100%",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              2022 World Cup Campaign
            </Avatar>
            <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
            <FlexRow component={Paper} sx={{ p: 2, borderRadius: 2.5, width: "100%" }}>
              <CheckedIcon />
              <FlexCol sx={{ ml: 1 }}>
                <Typography variant="subtitle2">You have joined the contest already.</Typography>
                <FlexRow>
                  <Typography variant="subtitle2" sx={{ mr: 1 }}>
                    Here are the
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setStep(APPLY_STEP.t_c)}
                  >
                    Terms and Conditions
                  </Typography>
                </FlexRow>
              </FlexCol>
              <Button
                variant="contained"
                sx={{ textTransform: "none", borderRadius: 2, ml: 1 }}
                onClick={() => acceptContest()}
              >
                Start Posting
              </Button>
            </FlexRow>
          </FlexCol>
        )}
      </Box>
    </BaseModal>
  );
};
