import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { History } from "history";
import { connectRouter } from "connected-react-router";

import { RootState } from "../types";
import templateReducer from "./template/reducer";
import authReducer from "./auth/reducer";
import profileReducer from "./profile/reducer";
import paymentReducer from "./payment/reducer";
import uiReducer from "./ui/reducer";
import walletReducer from "./wallet/reducer";
import nftCreatorReducer from "./nftCreator/reducer";
import socketReducer from "./socket/reducer";
import radioStationReducer from "./radio/reducer";
import feedReducer from "./feed/reducer";
import feedDetailsReducer from "./feedDetails/reducer";
import channelReducer from "./channel/reducer";
import searchInputReducer from "./search/reducer";
import streamerReducer from "./streamer/reducer";
import userSettingsReducer from "./settings/reducer";
import notificationReducer from "./notification/reducer";
import userSteamReducer from "./users/reducer";
import entityReducer from "./entity/reducer";
import categoryReducer from "./categories/reducer";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "isLoggedIn", "token"],
};

const profilePersistConfig = {
  key: "profile",
  storage,
  whitelist: ["streamProfileStatus", "user"],
};

const entityePersistConfig = {
  key: "entity",
  storage,
  whitelist: ["recordedVideo"],
};

const streamPersistConfig = {
  key: "streamer",
  storage,
  whitelist: ["streamData"],
};

const categoryPersistConfig = {
  key: "categories",
  storage,
};

export default function rootReducer(history: History) {
  return combineReducers<RootState>({
    template: templateReducer,
    router: connectRouter(history),
    auth: persistReducer<any, any>(authPersistConfig, authReducer),
    profile: persistReducer<any, any>(profilePersistConfig, profileReducer),
    payment: paymentReducer,
    ui: uiReducer,
    wallet: walletReducer,
    nftCreator: nftCreatorReducer,
    socket: socketReducer,
    radioStation: radioStationReducer,
    feed: feedReducer,
    feedDetails: feedDetailsReducer,
    channel: channelReducer,
    search: searchInputReducer,
    streamer: persistReducer<any, any>(streamPersistConfig, streamerReducer),
    userSettings: userSettingsReducer,
    notification: notificationReducer,
    userSteam: userSteamReducer,
    entity: persistReducer<any, any>(entityePersistConfig, entityReducer),
    categories: persistReducer<any, any>(categoryPersistConfig, categoryReducer),
  });
}
