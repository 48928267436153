import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_ANALYTICS_OVERVIEW = "FETCH_ANALYTICS_OVERVIEW";
export const FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS = "FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS";
export const FETCH_ANALYTICS_FOLLOWERS = "FETCH_ANALYTICS_FOLLOWERS";
export const FETCH_ANALYTICS_FOLLOWERS_STATISTICS = "FETCH_ANALYTICS_FOLLOWERS_STATISTICS";
export const FETCH_ANALYTICS_POSTS = "FETCH_ANALYTICS_POSTS";
export const FETCH_ANALYTICS_POSTS_CONTENT = "FETCH_ANALYTICS_POSTS_CONTENT";
export const FETCH_ANALYTICS_POSTS_STATISTICS = "FETCH_ANALYTICS_POSTS_STATISTICS";
export const FETCH_ANALYTICS_LIVESTREAM = "FETCH_ANALYTICS_LIVESTREAM";
export const FETCH_ANALYTICS_LIVESTREAM_STATISTICS = "FETCH_ANALYTICS_LIVESTREAM_STATISTICS";

export const FETCH_EARNING_TOTAL = "FETCH_EARNING_TOTAL";
export const FETCH_EARNING_LIST = "FETCH_EARNING_LIST";
export const FETCH_EARNING_WITHDRAW_TOTAL = "FETCH_EARNING_WITHDRAW_TOTAL";
export const FETCH_EARNING_WITHDRAW_LIST = "FETCH_EARNING_WITHDRAW_LIST";
export const FETCH_EARNING_BALANCE_TOTAL = "FETCH_EARNING_BALANCE_TOTAL";


export const fetchAnalyticsOverviewAction = createAction(FETCH_ANALYTICS_OVERVIEW + START);
export const fetchAnalyticsOverviewTopFeedsAction = createAction(FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + START);
export const fetchAnalyticsFollowersAction = createAction(FETCH_ANALYTICS_FOLLOWERS + START);
export const fetchAnalyticsFollowersStatistics = createAction(FETCH_ANALYTICS_FOLLOWERS_STATISTICS + START);
export const fetchAnalyticsPostsAction = createAction(FETCH_ANALYTICS_POSTS + START);
export const fetchAnalyticsPostsContentAction = createAction(FETCH_ANALYTICS_POSTS_CONTENT + START);
export const fetchAnalyticsPostsStatisticsAction = createAction(FETCH_ANALYTICS_POSTS_STATISTICS + START);
export const fetchAnalyticsLiveStreamAction = createAction(FETCH_ANALYTICS_LIVESTREAM + START);

export const fetchEarningTotalAction = createAction(FETCH_EARNING_TOTAL + START);
export const fetchEarningListAction = createAction(FETCH_EARNING_LIST + START);
export const fetchEarningWithdrawTotalAction = createAction(FETCH_EARNING_WITHDRAW_TOTAL + START);
export const fetchEarningWithdrawListAction = createAction(FETCH_EARNING_WITHDRAW_LIST + START);
export const fetchEarningBalanceTotalAction = createAction(FETCH_EARNING_BALANCE_TOTAL + START);
