import { createAction } from "redux-actions";
import { START } from "../common";

export const INIT_CHANNEL = "INIT_CHANNEL";
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const FETCH_HOME_CAROUSEL = "FETCH_HOME_CAROUSEL";
export const FETCH_HOME_CHANNELS = "FETCH_HOME_CHANNELS";
export const FETCH_CHANNELS_BY_CAGETORY = "FETCH_CHANNELS_BY_CAGETORY";
export const FETCH_CHANNEL_TWEETS = "FETCH_CHANNEL_TWEETS";
export const CHECK_CHANNEL_FOLLOW = "CHECK_CHANNEL_FOLLOW";
export const CHANGE_CHANNEL_FOLLOW = "CHANGE_CHANNEL_FOLLOW";

export const initChannelAction = createAction(INIT_CHANNEL);
export const fetchChannelAction = createAction(FETCH_CHANNEL + START);
export const fetchHomeCarousel = createAction(FETCH_HOME_CAROUSEL + START);
export const fetchHomeChannels = createAction(FETCH_HOME_CHANNELS + START);
export const fetchChannelsByCategory = createAction(FETCH_CHANNELS_BY_CAGETORY + START);
export const fetchChannelTweets = createAction(FETCH_CHANNEL_TWEETS + START);
export const checkChannelFollowAction = createAction(CHECK_CHANNEL_FOLLOW + START);
export const changeChannelFollowAction = createAction(CHANGE_CHANNEL_FOLLOW + START);
