import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { PageLoader, PageNotFound, StepScrollContainer } from "../../components";
import { usePrivateLayoutContext } from "../../layouts";
import { Background } from "./Background";
import { Content } from "./Content";
import { Overlay } from "./Overlay";
import { LiveChannels } from "./LiveChannels";
import { LiveNow } from "./LiveNow";
import { Rewinds } from "./Rewinds";
import { useCollectionPageContext } from "./context";

export const CollectionPageUi = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  const { isPageLoading, mainInfo, channels, streamers, rewinds, noData } = useCollectionPageContext();

  if (isPageLoading) {
    return <PageLoader />;
  }

  if (!mainInfo) {
    return <PageNotFound sx={{ top: navbar__height, left: leftMenubar__width }} />;
  }

  return (
    <>
      <Background />

      <Overlay />

      <StepScrollContainer
        containerSx={{
          height: `calc(100vh - ${navbar__height}px - ${isMobile ? "65px" : "0px"})`,
        }}
      >
        <Content />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 4, px: { xs: 4, md: 6, xl: 8 } }}>
          {noData ? (
            <Box>
              <Typography color="gray">No collection items</Typography>
            </Box>
          ) : (
            <>
              {channels.data.length > 0 && <LiveChannels />}

              {streamers.data.length > 0 && <LiveNow />}

              {rewinds.data.length > 0 && <Rewinds />}
            </>
          )}
        </Box>
      </StepScrollContainer>
    </>
  );
};
