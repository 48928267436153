import { ReactNode, useEffect, useLayoutEffect, useRef } from "react";
import { Avatar, Box, Button, ButtonBase, CircularProgress, Typography, alpha, useMediaQuery } from "@mui/material";
import { KeyboardArrowDown, Videocam } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useCollectionPageContext } from "../context";
import { reshape } from "./reshape";
import { RowAnimation } from "./RowAnimation";

type CollectionItemsProps = {
  title?: ReactNode;
  itemBadge?: ReactNode;
  items?: CollectionItemProps[];
  isLoadingMore?: boolean;
  hideShowMore?: boolean;
  onShowMore?: () => void;
};

export const CollectionItems = ({
  title = null,
  itemBadge = null,
  items = [],
  isLoadingMore = false,
  hideShowMore = false,
  onShowMore = () => {},
}: CollectionItemsProps) => {
  const isXs = useMediaQuery("(max-width:600px)");

  const isMd = useMediaQuery("(max-width:900px)");

  const isLg = useMediaQuery("(max-width:1200px)");

  const rows = reshape(items, isXs ? 1 : isMd ? 2 : isLg ? 3 : 4);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, md: 2, xl: 3 } }}>
        {rows.map((rowItems, rowIndex) => (
          <RowAnimation key={`collections-row-${rowIndex}`}>
            {rowIndex === 0 && title}

            <Box sx={{ display: "flex", alignItems: "center", gap: { xs: 4, md: 2, xl: 4 } }}>
              {rowItems.map((item, index) =>
                item ? (
                  <CollectionItem
                    key={`collection-item-${rowIndex}-${index}`}
                    {...item}
                    itemBadge={itemBadge}
                    shouldCalcHeight={rowIndex === 0 && index === 0}
                  />
                ) : (
                  <Box key={`empty-collection-item-${rowIndex}-${index}`} sx={{ width: "100%" }} />
                )
              )}
            </Box>

            {rowIndex === rows.length - 1 && !hideShowMore && (
              <Box sx={{ display: "flex", alignItems: "flex-end", height: 50 }}>
                <Button
                  startIcon={
                    <Box
                      sx={{ width: 20, height: 20, display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      {isLoadingMore ? (
                        <CircularProgress size={15} sx={{ color: "inherit" }} />
                      ) : (
                        <KeyboardArrowDown sx={{ fontSize: 20 }} />
                      )}
                    </Box>
                  }
                  sx={{ color: "rgba(255, 255, 255, 0.50)", px: 2, borderRadius: "10px" }}
                  onClick={onShowMore}
                  disabled={isLoadingMore}
                >
                  Show more
                </Button>
              </Box>
            )}
          </RowAnimation>
        ))}
      </Box>
    </Box>
  );
};

export type CollectionItemProps = {
  thumbnail?: string;
  avatar?: string;
  title?: string;
  description?: string;
  itemBadge?: ReactNode;
  shouldCalcHeight?: boolean;
  slug?: string;
};

const CollectionItem = ({
  thumbnail = "",
  avatar = "",
  title = "",
  description = "",
  slug = "",
  itemBadge = null,
  shouldCalcHeight = false,
}: CollectionItemProps) => {
  const navigate = useNavigate();

  const { itemHeight, updateContext: updateCollectionPageContext } = useCollectionPageContext();

  const itemRef = useRef<HTMLButtonElement>(null);

  function setItemHeight() {
    if (itemRef.current && shouldCalcHeight) {
      updateCollectionPageContext({ itemHeight: itemRef.current.clientWidth / 1.4 });
    }
  }

  useEffect(() => {
    setItemHeight();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", setItemHeight);

    setItemHeight();

    return () => {
      window.removeEventListener("resize", setItemHeight);
    };
  }, []);

  return (
    <ButtonBase
      ref={itemRef}
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        bgcolor: "#1C2039",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        height: itemHeight,
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
      }}
      onClick={() => {
        navigate(`/${slug || ""}`);
      }}
    >
      <Avatar
        src={thumbnail}
        sx={{ width: "100%", flex: 1, borderRadius: "10px 10px 0 0", bgcolor: "rgb(35, 37, 51)" }}
      >
        <Videocam sx={{ fontSize: 60, color: "#666" }} />
      </Avatar>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
          textAlign: "left",
          px: 2,
          py: 1,
          width: "100%",
        }}
      >
        <Avatar src={avatar} />

        <Box sx={{ overflow: "hidden" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "semibold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: 12,
              color: "#ffffff80",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>

      {itemBadge}
    </ButtonBase>
  );
};
