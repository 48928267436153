import { SxProps, Typography } from "@mui/material";

import { FlexCol } from "./FlexCol";

type PageNotFoundProps = {
  sx?: SxProps;
};

export const PageNotFound = ({ sx = {} }: PageNotFoundProps) => {
  return (
    <FlexCol
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        py: 6,
        ...sx,
      }}
    >
      <Typography variant="h5">Page Not Found</Typography>
    </FlexCol>
  );
};
