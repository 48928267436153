import React from "react";
import { Button } from "@mui/material";

import { FlexCol } from "../../components";
import { ReactComponent as VoteIcon } from "../../assets/svgs/studio/vote.svg";
import { ReactComponent as IssueIcon } from "../../assets/svgs/studio/issue.svg";

export const StudioActions = () => {

  return (
    <FlexCol sx={{ width: "100%", height: "100%" }}>
      <FlexCol sx={{ width: "100%", flexGrow: 1 }} height='50px'>
        <FlexCol className="-webkit-chatbox-scrollbar" sx={{ mt: 1, px: 1, pb: 0.5, width: "100%", minHeight: 50, gap: 1, flexGrow: 1, overflowY: 'auto' }}>
          <Button
            variant="contained"
            startIcon={<VoteIcon style={{ width: 18, height: 18 }} />}
            sx={{ bgcolor: "#3C436F", borderRadius: 20, fontWeight: 600, fontSize: 14, width: 160, py: 0.5 }}
          >
            Create Poll
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "#3C436F", borderRadius: 20, fontWeight: 600, fontSize: 14, width: 140, py: 0.5 }}
          >
            Donate
          </Button>

          <Button
            variant="contained"
            startIcon={<IssueIcon style={{ width: 22, height: 22 }} />}
            sx={{ bgcolor: "#3C436F", borderRadius: 20, fontWeight: 600, fontSize: 14, width: 230, py: 0.5 }}
          >
            Report Stream issue
          </Button>
        </FlexCol>
      </FlexCol>
    </FlexCol>
  );
};