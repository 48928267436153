import React from "react";
import { Button, TextField, Typography, useTheme } from "@mui/material";
import { FlexRow } from "..";
import { CustomSelector, Option } from "../CustomSelector";
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert.svg";
import { AddBankAccountModal } from "../modals/AddBankAccountModal";

const walletList: Option[] = [
  { label: "Select a Bank account", value: "" },
  { label: "Bank account", value: "account" },
  { label: "Bank account 2", value: "account2" },
];

export const WithdrawTabBank = () => {
  const theme = useTheme();
  const [openAddBankAccount, setOpenAddBankAccount] = React.useState(false);
  return (
    <>
      <FlexRow sx={{ alignItems: "center", justifyContent: "space-between", mb: "9px" }}>
        <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
          Amount to withdraw
        </Typography>
        <FlexRow>
          <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>Balance</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: 10, lineHeight: "15px", ml: "3px" }}>$350.4</Typography>
        </FlexRow>
      </FlexRow>
      <TextField
        name="amount"
        placeholder="$0.00"
        inputProps={{
          style: {
            backgroundColor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
            border: "0.5px solid rgba(255, 255, 255, 0.15)",
            fontWeight: 300,
            fontSize: 20,
            lineHeight: "30px",
            height: "24px",
            padding: "8px",
            paddingLeft: "17px",
            paddingRight: "17px",
            borderRadius: "6px",
          },
        }}
      />
      <FlexRow sx={{ alignItems: "center", justifyContent: "space-between", mt: "8px" }}>
        <FlexRow sx={{ alignItems: "center" }}>
          <AlertIcon />
          <Typography sx={{ color: "#A3AED0", fontWeight: 300, fontSize: 8, lineHeight: "15px", ml: "5px" }}>
            Minimum amount to withdraw is $2
          </Typography>
        </FlexRow>
        <Typography sx={{ fontWeight: 600, fontSize: 10, lineHeight: "15px" }}>$1 ⁓ 0.96 BUSD</Typography>
      </FlexRow>
      <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mb: "6px", mt: "8px" }}>
        Destination
      </Typography>
      <CustomSelector options={walletList} onClick={(val) => console.info(val)} />
      <Typography
        sx={{
          color: "#A3AED0",
          fontWeight: 400,
          fontSize: 10,
          lineHeight: "15px",
          textAlign: "center",
          mb: "7px",
          mt: "8px",
        }}
      >
        or
      </Typography>

      <Button
        sx={{
          borderRadius: "8px",
          bgcolor: theme.palette.mode === "dark" ? "#282C46" : "secondary.light",
          borderColor: "transparent !important",
          width: "100%",
          ":hover": { bgcolor: "secondary.light" },
        }}
        onClick={() => setOpenAddBankAccount(true)}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.75)",
            fontSize: 12,
            lineHeight: "18px",
          }}
        >
          Add a Bank account
        </Typography>
      </Button>
      <AddBankAccountModal open={openAddBankAccount} onClose={() => setOpenAddBankAccount(false)} />
    </>
  );
};
