import { StreamsContextProvider } from "./StreamsContext";
import { StreamsUi } from "./StreamsUi";

export const Streams = () => {
  return (
    <StreamsContextProvider>
      <StreamsUi />
    </StreamsContextProvider>
  );
};
