import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { sendTypingStatus } from "../../../store/socket/actions";

type Timer = ReturnType<typeof setTimeout>;

type Props = {
  message: string;
  channelId: string;
  channelType: string;
};

export const useTypingIndicator = ({ message, channelId, channelType }: Props) => {
  const dispatch = useDispatch();
  const typingTimerRef = useRef<Timer>();

  const emitTyping = useMemo(
    () =>
      debounce(
        (isTyping: boolean) => {
          if (!channelId || !channelType) {
            return;
          }
          dispatch(sendTypingStatus({ channelId, channelType, isTyping }));
        },
        1000,
        { leading: true }
      ),
    [channelId, channelType]
  );

  useEffect(() => {
    if (message?.trim()) {
      emitTyping(true);

      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }

      typingTimerRef.current = setTimeout(() => {
        emitTyping(false);
      }, 3000);
    } else {
      emitTyping(false);
    }
    return () => {
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }
    };
  }, [emitTyping, message]);

  return null;
};
