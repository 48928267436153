import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Box, Button, Typography } from "@mui/material";

import { FlexCol, FeedCard, PostBar } from "../../components";
import { authSelector } from "../../store/auth/selector";
import { createStreamFeed, fetchHomeStreamFeed, likeStreamFeed } from "../../store/feed/actions";
import { streamFeedSelector } from "../../store/feed/selector";
import { EmptySkeleton } from "./EmptySkeleton";
import { RefreshToSeeNewPosts } from "./RefreshToSeeNewPosts";
import Sponsored from "../../assets/svgs/sponsored.svg";

interface Props {
  hidePostBar?: boolean;
  postAds: any[];
  postAdIndex: number;
}

export const StreamFeedList = ({ hidePostBar = false, postAds, postAdIndex }: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const {
    items,
    loading,
    page,
    hasMore,
    action: { loading: creatingFeed },
  } = useSelector(streamFeedSelector);

  console.log("postAds", postAds);

  const [resetCreator, setResetCreator] = React.useState(0);

  const onSubmit = (data: { files?: File[]; text?: string; draftContent?: string; uploadId?: number }) => {
    dispatch(
      createStreamFeed({
        ...data,
        onSuccess: () => {
          setResetCreator(Date.now());
        },
      })
    );
  };

  React.useEffect(() => {
    dispatch(fetchHomeStreamFeed());
  }, [dispatch]);

  const renderPostAd = (index: number) => {
    if (postAds.length === 0) return null; // No ads available

    if ((index + 1) % 8 === 0 && postAds[postAdIndex]) {
      return (
        <div
          style={{
            width: "100%",
            background: "#13162C",
            color: "#000",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <img src={postAds[postAdIndex].assetUrl} alt="Post Ad" style={{ width: "100%" }} />

          <Box width="100%" sx={{ color: "white" }}>
            <Box width="100%" padding="10px 18px">
              <Typography variant="subtitle2" fontSize="14px">
                {postAds[postAdIndex]?.title}
              </Typography>
              {postAds[postAdIndex]?.description && <p>{postAds[postAdIndex]?.description}</p>}
            </Box>
            <Box
              borderTop="1px solid rgba(255, 255, 255, 0.25)"
              width="100%"
              padding="10px 18px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                padding="2px 10px"
                gap="5px"
                bgcolor="rgba(255, 255, 255, 0.08)"
                borderRadius="4px"
              >
                <img src={Sponsored} alt="Sponsored" />
                <Typography variant="subtitle2" fontSize="14px">
                  Sponsored
                </Typography>
              </Box>
              <Button
                variant="contained"
                style={{ backgroundColor: "white", color: "#333333", borderRadius: "18px" }}
                onClick={() => window.open(postAds[postAdIndex].link)}
              >
                <Typography variant="subtitle2">Learn More</Typography>
              </Button>
            </Box>
          </Box>
        </div>
      );
    }
    return null;
  };

  return (
    <FlexCol sx={{ rowGap: 2 }}>
      {!hidePostBar && (
        <>
          <div style={{ position: "relative" }}>
            <PostBar
              showAvatar
              loading={creatingFeed}
              avatarUrl={user?.photoUrl}
              resetCreator={resetCreator}
              placeHolder="Create a post.."
              onSubmit={onSubmit}
            />
            {user?.username === "guest" && <div className="guest_overlayBg" />}
          </div>

          <hr
            style={{
              width: "100%",
              marginTop: 0,
              marginBottom: 0,
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
              borderBottom: "0.25px solid rgba(255, 255, 255, 0.05)",
            }}
          />
        </>
      )}

      <RefreshToSeeNewPosts />

      <InfiniteScroll
        pageStart={1}
        loadMore={() => !loading && dispatch(fetchHomeStreamFeed({ page: page + 1 }))}
        hasMore={hasMore}
        useWindow
      >
        <FlexCol sx={{ rowGap: 2 }}>
          {/* {items.map((item, index) => (
            <FeedCard key={index} item={item} />
          ))} */}

          {items.map((item, index) => (
            <React.Fragment key={index}>
              <FeedCard item={item} />
              {renderPostAd(index)}
            </React.Fragment>
          ))}
        </FlexCol>
      </InfiniteScroll>

      {loading && (
        <FlexCol sx={{ rowGap: 2, mt: items.length === 0 ? -2 : 0 }}>
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
        </FlexCol>
      )}
    </FlexCol>
  );
};
