import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ModalProps, Typography, Paper, IconButton, Box, Button } from "@mui/material";
import { BaseModal } from "./BaseModal";
import { FlexRow } from "../FlexRow";

import { ReactComponent as BoltXIcon } from "../../assets/svgs/boltXImage.svg";

// const WalletConnectIcon = require("../../assets/pngs/walletConnect.png");
const MetamaskIcon = require("../../assets/pngs/metaMask.png");

interface Props extends Partial<ModalProps> {
  open: boolean;
  handleWalletConnect?: () => void;
  handleMetamask?: () => void;
  handleBoltX?: () => void;
  onClose?: () => void;
}

export const WalletConnectModal = ({
  open,
  handleWalletConnect = () => {},
  handleMetamask = () => {},
  handleBoltX = () => {},
  onClose = () => {},
  ...rest
}: Props) => {
  return (
    <BaseModal {...rest} open={open} onClose={onClose}>
      <Box
        component={Paper}
        elevation={0}
        sx={{ p: 4, pt: 2, borderRadius: 4, position: "relative", flexDirection: "column", width: 300 }}
      >
        <FlexRow sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" fontWeight="600">
            My Wallet
          </Typography>
          <IconButton sx={{ mr: -2 }} onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </FlexRow>
        <Typography variant="tiny" sx={{ color: "gray", mt: 0.5, lineHeight: "16px", display: "block" }}>
          Connect with one of our available wallet
          <br />
          providers or create a new one.
        </Typography>
        {/* <Button
          variant="contained"
          color="darker"
          sx={{ width: "100%", height: 40, mt: 3 }}
          onClick={handleWalletConnect}
        >
          <img src={WalletConnectIcon} alt="walletConnect" style={{ height: 22 }} />
        </Button> */}
        <Button variant="contained" color="darker" sx={{ width: "100%", height: 40, mt: 2.5 }} onClick={handleMetamask}>
          <img src={MetamaskIcon} alt="metamask" style={{ height: 34 }} />
        </Button>
        <Button variant="contained" color="darker" sx={{ width: "100%", height: 40, mt: 2.5 }} onClick={handleBoltX}>
          <BoltXIcon />
        </Button>
      </Box>
    </BaseModal>
  );
};
