import React from "react";
import { colors, createTheme } from "@mui/material";
import { deepPurple, yellow } from "@mui/material/colors";

export const darkTheme = createTheme({
  palette: {
    primary: {
      ...deepPurple,
      main: "#6535E9",
      dark: deepPurple.A200,
      light: "#7A7C8A",
      contrastText: "#ffffff",
    },
    secondary: { ...deepPurple, main: "#13162C", dark: "#1C2039", light: "#363954" },
    darker: { main: "#0E0F1F", contrastText: "#fff" },
    warning: { ...yellow, main: "#F8BD32", dark: "#BF953F", 600: "#F2C94C" },
    success: {500: "#219653"},
    error: {500: "#EB5757"},
    background: {
      paper: "#13162C",
      darker: "#0E0F1F",
      default: "#0A0B19",
    },
    mode: "dark",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    tiny: {
      fontSize: 10,
    },
    caption: {},
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: { ...deepPurple, main: "#6535E9", light: "#7A7C8A", contrastText: "#000000" },
    secondary: { ...deepPurple, main: "#ffffff", dark: "#ffffff", light: "#d3d3d3" },
    darker: { main: "#64748B", contrastText: "#fff" },
    warning: { ...yellow, dark: "#BF953F" },
    background: {
      paper: "#ffffff",
      darker: "lightgray",
      default: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    tiny: {
      fontSize: 10,
    },
    caption: {},
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    darker: Palette["primary"];
  }
  interface PaletteOptions {
    darker: PaletteOptions["primary"];
  }
  interface TypeBackground {
    darker?: string;
  }
  interface TypographyVariants {
    tiny: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tiny?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tiny: true;
  }
}

declare module "@mui/material/Button" {
  export interface ButtonPropsColorOverrides {
    darker: true;
  }
}
