export enum PeriodFilterType {
  today = "today",
  last_week = "last_week",
  last_month = "last_month",
  this_year = "this_year",
  last_year = "last_year",
  all = "all",
}
export interface CustomOption {
  value: string;
  label: string;
}

export interface PeriodOption {
  value: PeriodFilterType;
  label: string;
}

export enum STATUS {
  ACTIVE = "active",
  ARCHIVED = "archived",
  PENDING = "pending",
  DELETED = "deleted",
  BLOCKED = "blocked",
}