import { Box, Drawer, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { NAV_BAR_HEIGHT } from "../../../../constants";
import { SearchLanding, SearchResult } from "../../../search";
import { usePrivateNavbarContext } from "../PrivateNavbarContext";

export const SearchResultDrawer = () => {
  const theme = useTheme();

  const { searchKeyword, activeDrawer, updateContext: updateNavbarContext } = usePrivateNavbarContext();

  return (
    <Drawer
      disableEnforceFocus
      anchor="bottom"
      open={activeDrawer === "search-result"}
      onClose={() => {
        updateNavbarContext({
          searchKeyword: "",
          activeDrawer: undefined,
        });
      }}
      PaperProps={{
        sx: {
          width: { xs: "100%", md: "calc(100% - 64px + 1px)" },
          borderLeft: `1px solid rgba(255, 255, 255, 0.05)`,
          display: "block",
          left: "auto",
          height: "100%",
        },
      }}
      sx={{
        zIndex: theme.zIndex.drawer + 5,
      }}
    >
      <Box sx={{ height: NAV_BAR_HEIGHT }} />

      <Box sx={{ py: 3, position: "relative" }}>
        {searchKeyword.trim() ? (
          <Box sx={{ px: 2 }}>
            <SearchResult />
          </Box>
        ) : (
          <SearchLanding />
        )}

        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 40,
            display: { xs: "none", sm: "block", md: "none", lg: "block" },
          }}
        >
          <IconButton
            onClick={() => {
              updateNavbarContext({ activeDrawer: undefined });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};
