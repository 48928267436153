import { IsNotEmpty, IsString, MinLength, IsEmail } from "class-validator";

export class RequestResetPasswordDto {
  @IsEmail({}, { message: "Invalid email address" })
  @IsNotEmpty({ message: "Add Email" })
  public email: string;
}

export class ResetPasswordDto {
  @IsString()
  @MinLength(6, { message: "Weak password" })
  @IsNotEmpty({ message: "Add password" })
  public password: string;

  @IsString()
  @MinLength(6, { message: "Weak password" })
  @IsNotEmpty({ message: "Confirm password" })
  public confirmPassword: string;
}
