import React from "react";

import { Avatar, Button, Paper, Typography } from "@mui/material";
import { FlexCol } from ".";
import { FollowedRadioStation, RadioStation } from "../types";

interface Props {
  item?: RadioStation | FollowedRadioStation;
  selected: boolean;
  onClick: () => void;
}

export const RadioCard = ({ item, selected = false, onClick }: Props) => {
  return (
    <FlexCol sx={{ width: { xs: 91, md: 121 }, mr: 2, mb: 2 }}>
      <Button
        component={Paper}
        sx={{
          bgcolor: "secondary.dark",
          borderRadius: 4,
          height: { xs: 91, md: 121 },
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "solid",
          borderColor: "primary",
          borderWidth: selected ? 4 : 0,
        }}
        onClick={onClick}
      >
        <Avatar variant="rounded" src={item?.logo} alt={item?.title} sx={{ height: 75, width: 75 }} />
      </Button>
      <Typography
        variant="caption"
        sx={{
          width: "100%",
          fontWeight: "600",
          mt: 0.5,
          WebkitLineClamp: 1,
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          fontSize: { xs: "9px", md: "12px" },
        }}
      >
        {item?.title}
      </Typography>
    </FlexCol>
  );
};
