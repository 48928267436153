import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ModalProps, Typography, Paper, IconButton, SxProps, Button, Grid } from "@mui/material";
import { BaseModal } from "../BaseModal";

interface Props extends Partial<ModalProps> {
  onDelete?: () => void;
  onClose?: () => void;
}

export const VideoRecordDeleteConfirmModal = ({
  children,
  onDelete = () => {},
  onClose = () => {},
  ...rest
}: Props) => {
  return (
    <BaseModal
      {...rest}
      open={!!rest.open}
      onClose={onClose}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      <Grid
        container
        component={Paper}
        sx={{
          borderRadius: 4,
          maxWidth: 420,
          overflow: "hidden",
          color: "#A3AED0",
        }}
      >
        <Grid container p={1} spacing={1} direction="row" alignItems="center">
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon sx={{ color: "#A3AED0" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight="600">
              Delete Video
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ backgroundColor: "secondary.dark" }} p={3} rowGap={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Are you sure you want to delete this video?</Typography>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                sx={{ borderRadius: 40, fontWeight: 600 }}
                color="error"
                onClick={onDelete}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ borderRadius: 40, fontWeight: 600, bgcolor: "secondary.light" }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  );
};
