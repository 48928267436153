export const PAKISTAN_TAX = 0.195;
export const PAKISTAN_CURRENCY = "PKR";
export const PAKISTAN_DAILY_BEFORE_TAX = 8;
export const PAKISTAN_WEEKLY_BEFORE_TAX = 50;

export const PAKISTAN_PRICE = {
  daily: {
    title: "Daily",
    frequency: "daily",
    priceBeforeTax: PAKISTAN_DAILY_BEFORE_TAX.toString(),
    tax: (PAKISTAN_DAILY_BEFORE_TAX * PAKISTAN_TAX).toString(),
    currencyCode: "PKR",
  },
  weekly: {
    title: "Weekly",
    frequency: "weekly",
    priceBeforeTax: PAKISTAN_WEEKLY_BEFORE_TAX.toString(),
    tax: (PAKISTAN_WEEKLY_BEFORE_TAX * PAKISTAN_TAX).toString(),
    currencyCode: "PKR",
  },
};

export const subscribedPlan = (frequency: string) => {
  let planType = "Daily";
  if (frequency === "weeklyNew") {
    planType = "Weekly";
  }
  return planType;
};
const PRICES: any = {
  AD: {
    countryName: "Andorra",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  AE: {
    countryName: "United Arab Emirates",
    tier: 1,
    symbol: "AED",
    symbol_native: "د.إ.‏",
    currencyCode: "AED",
  },
  AF: {
    countryName: "Afghanistan",
    tier: 3,
    symbol: "Af",
    symbol_native: "؋",
    currencyCode: "AFN",
  },
  AG: {
    countryName: "Antigua and Barbuda",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  AI: {
    countryName: "Anguilla",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  AL: {
    countryName: "Albania",
    tier: 1,
    symbol: "ALL",
    symbol_native: "Lek",
    currencyCode: "ALL",
  },
  AM: {
    countryName: "Armenia",
    tier: 3,
    symbol: "AMD",
    symbol_native: "դր.",
    currencyCode: "AMD",
  },
  AO: {
    countryName: "Angola",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "AOA",
  },
  AQ: {
    countryName: "Antarctica",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "",
  },
  AR: {
    countryName: "Argentina",
    tier: 2,
    symbol: "AR$",
    symbol_native: "$",
    currencyCode: "ARS",
  },
  AS: {
    countryName: "American Samoa",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  AT: {
    countryName: "Austria",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  AU: {
    countryName: "Australia",
    tier: 1,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  AW: {
    countryName: "Aruba",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "AWG",
  },
  AX: {
    countryName: "Åland",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  AZ: {
    countryName: "Azerbaijan",
    tier: 3,
    symbol: "man.",
    symbol_native: "ман.",
    currencyCode: "AZN",
  },
  BA: {
    countryName: "Bosnia and Herzegovina",
    tier: 3,
    symbol: "KM",
    symbol_native: "KM",
    currencyCode: "BAM",
  },
  BB: {
    countryName: "Barbados",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "BBD",
  },
  BD: {
    countryName: "Bangladesh",
    tier: 3,
    symbol: "Tk",
    symbol_native: "৳",
    currencyCode: "BDT",
  },
  BE: {
    countryName: "Belgium",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  BF: {
    countryName: "Burkina Faso",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  BG: {
    countryName: "Bulgaria",
    tier: 2,
    symbol: "BGN",
    symbol_native: "лв.",
    currencyCode: "BGN",
  },
  BH: {
    countryName: "Bahrain",
    tier: 2,
    symbol: "BD",
    symbol_native: "د.ب.‏",
    currencyCode: "BHD",
  },
  BI: {
    countryName: "Burundi",
    tier: 3,
    symbol: "FBu",
    symbol_native: "FBu",
    currencyCode: "BIF",
  },
  BJ: {
    countryName: "Benin",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  BL: {
    countryName: "Saint Barthélemy",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  BM: {
    countryName: "Bermuda",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "BMD",
  },
  BN: {
    countryName: "Brunei",
    tier: 2,
    symbol: "BN$",
    symbol_native: "$",
    currencyCode: "BND",
  },
  BO: {
    countryName: "Bolivia",
    tier: 3,
    symbol: "Bs",
    symbol_native: "Bs",
    currencyCode: "BOB",
  },
  BQ: {
    countryName: "Bonaire",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  BR: {
    countryName: "Brazil",
    tier: 3,
    symbol: "R$",
    symbol_native: "R$",
    currencyCode: "BRL",
  },
  BS: {
    countryName: "Bahamas",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "BSD",
  },
  BT: {
    countryName: "Bhutan",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "BTN",
  },
  BV: {
    countryName: "Bouvet Island",
    tier: 2,
    symbol: "Nkr",
    symbol_native: "kr",
    currencyCode: "NOK",
  },
  BW: {
    countryName: "Botswana",
    tier: 3,
    symbol: "BWP",
    symbol_native: "P",
    currencyCode: "BWP",
  },
  BY: {
    countryName: "Belarus",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "BYR",
  },
  BZ: {
    countryName: "Belize",
    tier: 3,
    symbol: "BZ$",
    symbol_native: "$",
    currencyCode: "BZD",
  },
  CA: {
    countryName: "Canada",
    tier: 1,
    symbol: "CA$",
    symbol_native: "$",
    currencyCode: "CAD",
  },
  CC: {
    countryName: "Cocos [Keeling] Islands",
    tier: 3,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  CD: {
    countryName: "Democratic Republic of the Congo",
    tier: 3,
    symbol: "CDF",
    symbol_native: "FrCD",
    currencyCode: "CDF",
  },
  CF: {
    countryName: "Central African Republic",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  CG: {
    countryName: "Republic of the Congo",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  CH: {
    countryName: "Switzerland",
    tier: 1,
    symbol: "CHF",
    symbol_native: "CHF",
    currencyCode: "CHF",
  },
  CI: {
    countryName: "Ivory Coast",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  CK: {
    countryName: "Cook Islands",
    tier: 3,
    symbol: "NZ$",
    symbol_native: "$",
    currencyCode: "NZD",
  },
  CL: {
    countryName: "Chile",
    tier: 2,
    symbol: "CL$",
    symbol_native: "$",
    currencyCode: "CLP",
  },
  CM: {
    countryName: "Cameroon",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  CN: {
    countryName: "China",
    tier: 2,
    symbol: "CN¥",
    symbol_native: "CN¥",
    currencyCode: "CNY",
  },
  CO: {
    countryName: "Colombia",
    tier: 3,
    symbol: "CO$",
    symbol_native: "$",
    currencyCode: "COP",
  },
  CR: {
    countryName: "Costa Rica",
    tier: 2,
    symbol: "₡",
    symbol_native: "₡",
    currencyCode: "CRC",
  },
  CU: {
    countryName: "Cuba",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "CUP",
  },
  CV: {
    countryName: "Cape Verde",
    tier: 2,
    symbol: "CV$",
    symbol_native: "CV$",
    currencyCode: "CVE",
  },
  CW: {
    countryName: "Curacao",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "ANG",
  },
  CX: {
    countryName: "Christmas Island",
    tier: 1,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  CY: {
    countryName: "Cyprus",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  CZ: {
    countryName: "Czechia",
    tier: 1,
    symbol: "Kč",
    symbol_native: "Kč",
    currencyCode: "CZK",
  },
  DE: {
    countryName: "Germany",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  DJ: {
    countryName: "Djibouti",
    tier: 3,
    symbol: "Fdj",
    symbol_native: "Fdj",
    currencyCode: "DJF",
  },
  DK: {
    countryName: "Denmark",
    tier: 1,
    symbol: "Dkr",
    symbol_native: "kr",
    currencyCode: "DKK",
  },
  DM: {
    countryName: "Dominica",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  DO: {
    countryName: "Dominican Republic",
    tier: 3,
    symbol: "RD$",
    symbol_native: "RD$",
    currencyCode: "DOP",
  },
  DZ: {
    countryName: "Algeria",
    tier: 3,
    symbol: "DA",
    symbol_native: "د.ج.‏",
    currencyCode: "DZD",
  },
  EC: {
    countryName: "Ecuador",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  EE: {
    countryName: "Estonia",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  EG: {
    countryName: "Egypt",
    tier: 3,
    symbol: "EGP",
    symbol_native: "ج.م.‏",
    currencyCode: "EGP",
  },
  EH: {
    countryName: "Western Sahara",
    tier: 3,
    symbol: "MAD",
    symbol_native: "د.م.‏",
    currencyCode: "MAD",
  },
  ER: {
    countryName: "Eritrea",
    tier: 3,
    symbol: "Nfk",
    symbol_native: "Nfk",
    currencyCode: "ERN",
  },
  ES: {
    countryName: "Spain",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  ET: {
    countryName: "Ethiopia",
    tier: 3,
    symbol: "Br",
    symbol_native: "Br",
    currencyCode: "ETB",
  },
  FI: {
    countryName: "Finland",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  FJ: {
    countryName: "Fiji",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "FJD",
  },
  FK: {
    countryName: "Falkland Islands",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "FKP",
  },
  FM: {
    countryName: "Micronesia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  FO: {
    countryName: "Faroe Islands",
    tier: 1,
    symbol: "Dkr",
    symbol_native: "kr",
    currencyCode: "DKK",
  },
  FR: {
    countryName: "France",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  GA: {
    countryName: "Gabon",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  GB: {
    countryName: "United Kingdom",
    tier: 1,
    symbol: "£",
    symbol_native: "£",
    currencyCode: "GBP",
  },
  GD: {
    countryName: "Grenada",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  GE: {
    countryName: "Georgia",
    tier: 3,
    symbol: "GEL",
    symbol_native: "GEL",
    currencyCode: "GEL",
  },
  GF: {
    countryName: "French Guiana",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  GG: {
    countryName: "Guernsey",
    tier: 1,
    symbol: "£",
    symbol_native: "£",
    currencyCode: "GBP",
  },
  GH: {
    countryName: "Ghana",
    tier: 3,
    symbol: "GH₵",
    symbol_native: "GH₵",
    currencyCode: "GHS",
  },
  GI: {
    countryName: "Gibraltar",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "GIP",
  },
  GL: {
    countryName: "Greenland",
    tier: 1,
    symbol: "Dkr",
    symbol_native: "kr",
    currencyCode: "DKK",
  },
  GM: {
    countryName: "Gambia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "GMD",
  },
  GN: {
    countryName: "Guinea",
    tier: 3,
    symbol: "FG",
    symbol_native: "FG",
    currencyCode: "GNF",
  },
  GP: {
    countryName: "Guadeloupe",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  GQ: {
    countryName: "Equatorial Guinea",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  GR: {
    countryName: "Greece",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  GS: {
    countryName: "South Georgia and the South Sandwich Islands",
    tier: 3,
    symbol: "£",
    symbol_native: "£",
    currencyCode: "GBP",
  },
  GT: {
    countryName: "Guatemala",
    tier: 3,
    symbol: "GTQ",
    symbol_native: "Q",
    currencyCode: "GTQ",
  },
  GU: {
    countryName: "Guam",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  GW: {
    countryName: "Guinea-Bissau",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  GY: {
    countryName: "Guyana",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "GYD",
  },
  HK: {
    countryName: "Hong Kong",
    tier: 1,
    symbol: "HK$",
    symbol_native: "$",
    currencyCode: "HKD",
  },
  HM: {
    countryName: "Heard Island and McDonald Islands",
    tier: 1,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  HN: {
    countryName: "Honduras",
    tier: 3,
    symbol: "HNL",
    symbol_native: "L",
    currencyCode: "HNL",
  },
  HR: {
    countryName: "Croatia",
    tier: 2,
    symbol: "kn",
    symbol_native: "kn",
    currencyCode: "HRK",
  },
  HT: {
    countryName: "Haiti",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "HTG",
  },
  HU: {
    countryName: "Hungary",
    tier: 2,
    symbol: "Ft",
    symbol_native: "Ft",
    currencyCode: "HUF",
  },
  ID: {
    countryName: "Indonesia",
    tier: 3,
    symbol: "Rp",
    symbol_native: "Rp",
    currencyCode: "IDR",
  },
  IE: {
    countryName: "Ireland",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  IL: {
    countryName: "Israel",
    tier: 1,
    symbol: "₪",
    symbol_native: "₪",
    currencyCode: "ILS",
  },
  IM: {
    countryName: "Isle of Man",
    tier: 1,
    symbol: "£",
    symbol_native: "£",
    currencyCode: "GBP",
  },
  IN: {
    countryName: "India",
    tier: 3,
    symbol: "Rs",
    symbol_native: "টকা",
    currencyCode: "INR",
  },
  IO: {
    countryName: "British Indian Ocean Territory",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  IQ: {
    countryName: "Iraq",
    tier: 3,
    symbol: "IQD",
    symbol_native: "د.ع.‏",
    currencyCode: "IQD",
  },
  IR: {
    countryName: "Iran",
    tier: 3,
    symbol: "IRR",
    symbol_native: "﷼",
    currencyCode: "IRR",
  },
  IS: {
    countryName: "Iceland",
    tier: 1,
    symbol: "Ikr",
    symbol_native: "kr",
    currencyCode: "ISK",
  },
  IT: {
    countryName: "Italy",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  JE: {
    countryName: "Jersey",
    tier: 1,
    symbol: "£",
    symbol_native: "£",
    currencyCode: "GBP",
  },
  JM: {
    countryName: "Jamaica",
    tier: 3,
    symbol: "J$",
    symbol_native: "$",
    currencyCode: "JMD",
  },
  JO: {
    countryName: "Jordan",
    tier: 3,
    symbol: "JD",
    symbol_native: "د.أ.‏",
    currencyCode: "JOD",
  },
  JP: {
    countryName: "Japan",
    tier: 1,
    symbol: "¥",
    symbol_native: "￥",
    currencyCode: "JPY",
  },
  KE: {
    countryName: "Kenya",
    tier: 3,
    symbol: "Ksh",
    symbol_native: "Ksh",
    currencyCode: "KES",
  },
  KG: {
    countryName: "Kyrgyzstan",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "KGS",
  },
  KH: {
    countryName: "Cambodia",
    tier: 3,
    symbol: "KHR",
    symbol_native: "៛",
    currencyCode: "KHR",
  },
  KI: {
    countryName: "Kiribati",
    tier: 3,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  KM: {
    countryName: "Comoros",
    tier: 3,
    symbol: "CF",
    symbol_native: "FC",
    currencyCode: "KMF",
  },
  KN: {
    countryName: "Saint Kitts and Nevis",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  KR: {
    countryName: "South Korea",
    tier: 2,
    symbol: "₩",
    symbol_native: "₩",
    currencyCode: "KRW",
  },
  KW: {
    countryName: "Kuwait",
    tier: 2,
    symbol: "KD",
    symbol_native: "د.ك.‏",
    currencyCode: "KWD",
  },
  KY: {
    countryName: "Cayman Islands",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "KYD",
  },
  KZ: {
    countryName: "Kazakhstan",
    tier: 2,
    symbol: "KZT",
    symbol_native: "тңг.",
    currencyCode: "KZT",
  },
  LA: {
    countryName: "Laos",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "LAK",
  },
  LB: {
    countryName: "Lebanon",
    tier: 3,
    symbol: "L.L.",
    symbol_native: "ل.ل.‏",
    currencyCode: "LBP",
  },
  LC: {
    countryName: "Saint Lucia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  LI: {
    countryName: "Liechtenstein",
    tier: 1,
    symbol: "CHF",
    symbol_native: "CHF",
    currencyCode: "CHF",
  },
  LK: {
    countryName: "Sri Lanka",
    tier: 3,
    symbol: "SLRs",
    symbol_native: "SL Re",
    currencyCode: "LKR",
  },
  LR: {
    countryName: "Liberia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "LRD",
  },
  LS: {
    countryName: "Lesotho",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "LSL",
  },
  LT: {
    countryName: "Lithuania",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  LU: {
    countryName: "Luxembourg",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  LV: {
    countryName: "Latvia",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  LY: {
    countryName: "Libya",
    tier: 3,
    symbol: "LD",
    symbol_native: "د.ل.‏",
    currencyCode: "LYD",
  },
  MA: {
    countryName: "Morocco",
    tier: 3,
    symbol: "MAD",
    symbol_native: "د.م.‏",
    currencyCode: "MAD",
  },
  MC: {
    countryName: "Monaco",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  MD: {
    countryName: "Moldova",
    tier: 3,
    symbol: "MDL",
    symbol_native: "MDL",
    currencyCode: "MDL",
  },
  ME: {
    countryName: "Montenegro",
    tier: 3,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  MF: {
    countryName: "Saint Martin",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  MG: {
    countryName: "Madagascar",
    tier: 3,
    symbol: "MGA",
    symbol_native: "MGA",
    currencyCode: "MGA",
  },
  MH: {
    countryName: "Marshall Islands",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  MK: {
    countryName: "Macedonia",
    tier: 3,
    symbol: "MKD",
    symbol_native: "MKD",
    currencyCode: "MKD",
  },
  ML: {
    countryName: "Mali",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  MM: {
    countryName: "Myanmar [Burma]",
    tier: 3,
    symbol: "MMK",
    symbol_native: "K",
    currencyCode: "MMK",
  },
  MN: {
    countryName: "Mongolia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "MNT",
  },
  MO: {
    countryName: "Macao",
    tier: 1,
    symbol: "MOP$",
    symbol_native: "MOP$",
    currencyCode: "MOP",
  },
  MP: {
    countryName: "Northern Mariana Islands",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  MQ: {
    countryName: "Martinique",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  MR: {
    countryName: "Mauritania",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "MRO",
  },
  MS: {
    countryName: "Montserrat",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  MT: {
    countryName: "Malta",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  MU: {
    countryName: "Mauritius",
    tier: 2,
    symbol: "MURs",
    symbol_native: "MURs",
    currencyCode: "MUR",
  },
  MV: {
    countryName: "Maldives",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "MVR",
  },
  MW: {
    countryName: "Malawi",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "MWK",
  },
  MX: {
    countryName: "Mexico",
    tier: 2,
    symbol: "MX$",
    symbol_native: "$",
    currencyCode: "MXN",
  },
  MY: {
    countryName: "Malaysia",
    tier: 2,
    symbol: "RM",
    symbol_native: "RM",
    currencyCode: "MYR",
  },
  MZ: {
    countryName: "Mozambique",
    tier: 3,
    symbol: "MTn",
    symbol_native: "MTn",
    currencyCode: "MZN",
  },
  NA: {
    countryName: "Namibia",
    tier: 3,
    symbol: "N$",
    symbol_native: "N$",
    currencyCode: "NAD",
  },
  NC: {
    countryName: "New Caledonia",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XPF",
  },
  NE: {
    countryName: "Niger",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  NF: {
    countryName: "Norfolk Island",
    tier: 1,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  NG: {
    countryName: "Nigeria",
    tier: 3,
    symbol: "₦",
    symbol_native: "₦",
    currencyCode: "NGN",
  },
  NI: {
    countryName: "Nicaragua",
    tier: 3,
    symbol: "C$",
    symbol_native: "C$",
    currencyCode: "NIO",
  },
  NL: {
    countryName: "Netherlands",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  NO: {
    countryName: "Norway",
    tier: 1,
    symbol: "Nkr",
    symbol_native: "kr",
    currencyCode: "NOK",
  },
  NP: {
    countryName: "Nepal",
    tier: 3,
    symbol: "NPRs",
    symbol_native: "नेरू",
    currencyCode: "NPR",
  },
  NR: {
    countryName: "Nauru",
    tier: 2,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  NU: {
    countryName: "Niue",
    tier: 1,
    symbol: "NZ$",
    symbol_native: "$",
    currencyCode: "NZD",
  },
  NZ: {
    countryName: "New Zealand",
    tier: 1,
    symbol: "NZ$",
    symbol_native: "$",
    currencyCode: "NZD",
  },
  OM: {
    countryName: "Oman",
    tier: 2,
    symbol: "OMR",
    symbol_native: "ر.ع.‏",
    currencyCode: "OMR",
  },
  PA: {
    countryName: "Panama",
    tier: 2,
    symbol: "B/.",
    symbol_native: "B/.",
    currencyCode: "PAB",
  },
  PE: {
    countryName: "Peru",
    tier: 3,
    symbol: "S/.",
    symbol_native: "S/.",
    currencyCode: "PEN",
  },
  PF: {
    countryName: "French Polynesia",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XPF",
  },
  PG: {
    countryName: "Papua New Guinea",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "PGK",
  },
  PH: {
    countryName: "Philippines",
    tier: 3,
    symbol: "₱",
    symbol_native: "₱",
    currencyCode: "PHP",
  },
  PK: {
    countryName: "Pakistan",
    tier: 3,
    symbol: "PKRs",
    symbol_native: "₨",
    currencyCode: "PKR",
  },
  PL: {
    countryName: "Poland",
    tier: 2,
    symbol: "zł",
    symbol_native: "zł",
    currencyCode: "PLN",
  },
  PM: {
    countryName: "Saint Pierre and Miquelon",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  PN: {
    countryName: "Pitcairn Islands",
    tier: 1,
    symbol: "NZ$",
    symbol_native: "$",
    currencyCode: "NZD",
  },
  PR: {
    countryName: "Puerto Rico",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  PS: {
    countryName: "Palestine",
    tier: 3,
    symbol: "₪",
    symbol_native: "₪",
    currencyCode: "ILS",
  },
  PT: {
    countryName: "Portugal",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  PW: {
    countryName: "Palau",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  PY: {
    countryName: "Paraguay",
    tier: 3,
    symbol: "₲",
    symbol_native: "₲",
    currencyCode: "PYG",
  },
  QA: {
    countryName: "Qatar",
    tier: 1,
    symbol: "QR",
    symbol_native: "ر.ق.‏",
    currencyCode: "QAR",
  },
  RE: {
    countryName: "Réunion",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  RO: {
    countryName: "Romania",
    tier: 2,
    symbol: "RON",
    symbol_native: "RON",
    currencyCode: "RON",
  },
  RS: {
    countryName: "Serbia",
    tier: 3,
    symbol: "din.",
    symbol_native: "дин.",
    currencyCode: "RSD",
  },
  RU: {
    countryName: "Russia",
    tier: 2,
    symbol: "RUB",
    symbol_native: "₽.",
    currencyCode: "RUB",
  },
  RW: {
    countryName: "Rwanda",
    tier: 3,
    symbol: "RWF",
    symbol_native: "FR",
    currencyCode: "RWF",
  },
  SA: {
    countryName: "Saudi Arabia",
    tier: 2,
    symbol: "SR",
    symbol_native: "ر.س.‏",
    currencyCode: "SAR",
  },
  SB: {
    countryName: "Solomon Islands",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SBD",
  },
  SC: {
    countryName: "Seychelles",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SCR",
  },
  SD: {
    countryName: "Sudan",
    tier: 3,
    symbol: "SDG",
    symbol_native: "SDG",
    currencyCode: "SDG",
  },
  SE: {
    countryName: "Sweden",
    tier: 1,
    symbol: "Skr",
    symbol_native: "kr",
    currencyCode: "SEK",
  },
  SG: {
    countryName: "Singapore",
    tier: 1,
    symbol: "S$",
    symbol_native: "$",
    currencyCode: "SGD",
  },
  SH: {
    countryName: "Saint Helena",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SHP",
  },
  SI: {
    countryName: "Slovenia",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  SJ: {
    countryName: "Svalbard and Jan Mayen",
    tier: 1,
    symbol: "Nkr",
    symbol_native: "kr",
    currencyCode: "NOK",
  },
  SK: {
    countryName: "Slovakia",
    tier: 3,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  SL: {
    countryName: "Sierra Leone",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SLL",
  },
  SM: {
    countryName: "San Marino",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  SN: {
    countryName: "Senegal",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  SO: {
    countryName: "Somalia",
    tier: 3,
    symbol: "Ssh",
    symbol_native: "Ssh",
    currencyCode: "SOS",
  },
  SR: {
    countryName: "Suriname",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SRD",
  },
  SS: {
    countryName: "South Sudan",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SSP",
  },
  ST: {
    countryName: "São Tomé and Príncipe",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "STD",
  },
  SV: {
    countryName: "El Salvador",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  SX: {
    countryName: "Sint Maarten",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "ANG",
  },
  SY: {
    countryName: "Syria",
    tier: 2,
    symbol: "SY£",
    symbol_native: "ل.س.‏",
    currencyCode: "SYP",
  },
  SZ: {
    countryName: "Swaziland",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "SZL",
  },
  TC: {
    countryName: "Turks and Caicos Islands",
    tier: 2,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  TD: {
    countryName: "Chad",
    tier: 3,
    symbol: "FCFA",
    symbol_native: "FCFA",
    currencyCode: "XAF",
  },
  TF: {
    countryName: "French Southern Territories",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  TG: {
    countryName: "Togo",
    tier: 3,
    symbol: "CFA",
    symbol_native: "CFA",
    currencyCode: "XOF",
  },
  TH: {
    countryName: "Thailand",
    tier: 3,
    symbol: "฿",
    symbol_native: "฿",
    currencyCode: "THB",
  },
  TJ: {
    countryName: "Tajikistan",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "TJS",
  },
  TK: {
    countryName: "Tokelau",
    tier: 1,
    symbol: "NZ$",
    symbol_native: "$",
    currencyCode: "NZD",
  },
  TL: {
    countryName: "East Timor",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  TM: {
    countryName: "Turkmenistan",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "TMT",
  },
  TN: {
    countryName: "Tunisia",
    tier: 3,
    symbol: "DT",
    symbol_native: "د.ت.‏",
    currencyCode: "TND",
  },
  TO: {
    countryName: "Tonga",
    tier: 3,
    symbol: "T$",
    symbol_native: "T$",
    currencyCode: "TOP",
  },
  TR: {
    countryName: "Turkey",
    tier: 2,
    symbol: "TL",
    symbol_native: "TL",
    currencyCode: "TRY",
  },
  TT: {
    countryName: "Trinidad and Tobago",
    tier: 2,
    symbol: "TT$",
    symbol_native: "$",
    currencyCode: "TTD",
  },
  TV: {
    countryName: "Tuvalu",
    tier: 3,
    symbol: "AU$",
    symbol_native: "$",
    currencyCode: "AUD",
  },
  TW: {
    countryName: "Taiwan",
    tier: 2,
    symbol: "NT$",
    symbol_native: "NT$",
    currencyCode: "TWD",
  },
  TZ: {
    countryName: "Tanzania",
    tier: 3,
    symbol: "TSh",
    symbol_native: "TSh",
    currencyCode: "TZS",
  },
  UA: {
    countryName: "Ukraine",
    tier: 3,
    symbol: "₴",
    symbol_native: "₴",
    currencyCode: "UAH",
  },
  UG: {
    countryName: "Uganda",
    tier: 3,
    symbol: "USh",
    symbol_native: "USh",
    currencyCode: "UGX",
  },
  UM: {
    countryName: "U.S. Minor Outlying Islands",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  US: {
    countryName: "United States",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  UY: {
    countryName: "Uruguay",
    tier: 2,
    symbol: "$U",
    symbol_native: "$",
    currencyCode: "UYU",
  },
  UZ: {
    countryName: "Uzbekistan",
    tier: 3,
    symbol: "UZS",
    symbol_native: "UZS",
    currencyCode: "UZS",
  },
  VA: {
    countryName: "Vatican City",
    tier: 1,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  VC: {
    countryName: "Saint Vincent and the Grenadines",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XCD",
  },
  VE: {
    countryName: "Venezuela",
    tier: 2,
    symbol: "Bs.F.",
    symbol_native: "Bs.F.",
    currencyCode: "VEF",
  },
  VG: {
    countryName: "British Virgin Islands",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  VI: {
    countryName: "U.S. Virgin Islands",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "USD",
  },
  VN: {
    countryName: "Vietnam",
    tier: 3,
    symbol: "₫",
    symbol_native: "₫",
    currencyCode: "VND",
  },
  VU: {
    countryName: "Vanuatu",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "VUV",
  },
  WF: {
    countryName: "Wallis and Futuna",
    tier: 1,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "XPF",
  },
  WS: {
    countryName: "Samoa",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "WST",
  },
  XK: {
    countryName: "Kosovo",
    tier: 3,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  YE: {
    countryName: "Yemen",
    tier: 3,
    symbol: "YR",
    symbol_native: "ر.ي.‏",
    currencyCode: "YER",
  },
  YT: {
    countryName: "Mayotte",
    tier: 2,
    symbol: "€",
    symbol_native: "€",
    currencyCode: "EUR",
  },
  ZA: {
    countryName: "South Africa",
    tier: 3,
    symbol: "R",
    symbol_native: "R",
    currencyCode: "ZAR",
  },
  ZM: {
    countryName: "Zambia",
    tier: 3,
    symbol: "$",
    symbol_native: "$",
    currencyCode: "ZMW",
  },
  ZW: {
    countryName: "Zimbabwe",
    tier: 3,
    symbol: "ZWL$",
    symbol_native: "ZWL$",
    currencyCode: "ZWL",
  },
};

const TIERS: any = {
  1: 2.99,
  2: 1.99,
  3: 0.99,
};

export const getPlan = (countryCode: string) => {
  const plan = PRICES[countryCode];
  plan.price = TIERS[plan.tier];
  return plan;
};
