import { Box, ButtonBase, IconButtonProps, Typography } from "@mui/material";
import React from "react";
import { Formatter } from "../utils";
import { FlexRow } from "./FlexRow";

type Props = { small?: boolean; count?: number; label?: string; textVibrant?: boolean } & IconButtonProps;

const CustomIconButton = ({ small, count, label, textVibrant, children, sx, ...rest }: Props) => {
  return (
    <ButtonBase
      sx={{
        ":hover": {
          backgroundColor: small ? "transparent" : "rgba(255, 255, 255, 0.05)",
        },
        px: small ? 1 : 1.5,
        borderRadius: 5,
        ...sx,
      }}
      {...rest}
    >
      <FlexRow sx={{ alignItems: "center", gap: 1 }}>
        <FlexRow sx={{ alignItems: "center" }}>
          <Box
            sx={{
              py: small ? 0.5 : 1,
              maxHeight: small ? 23 : undefined,
            }}
          >
            {children}
          </Box>
          {!!count && count > 0 && (
            <Typography fontWeight="600" variant="tiny" color="primary.contrastText">
              {Formatter.formatCount(count)}
            </Typography>
          )}
        </FlexRow>
        <Typography
          sx={{
            fontSize: small ? 10 : 12,
            lineHeight: "100%",
            fontWeight: textVibrant ? 600 : 300,
            color: textVibrant ? "white" : "rgba(255, 255, 255, 0.5)",
          }}
        >
          {label}
        </Typography>
      </FlexRow>
    </ButtonBase>
  );
};

export default CustomIconButton;
