import { ImmersivePanelLayout } from "../../../../layouts";
import { BloombergEpisodesPageProvider } from "../episodes-context";
import { OneFootballEpisodePageUi } from "./OneFootballEpisodePageUi";

export const OneFootballEpisodePage = () => {
  return (
    <BloombergEpisodesPageProvider>
      <ImmersivePanelLayout>
        <OneFootballEpisodePageUi />
      </ImmersivePanelLayout>
    </BloombergEpisodesPageProvider>
  );
};
