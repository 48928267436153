import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import * as authActions from "../auth/actions";
import { FAIL, START, SUCCESS } from "../common";
import { ERC20Balance, ERC20BalanceList } from "../../types";

export interface WalletState {
  loading: boolean;
  success: boolean;
  error: null;
  accounts: any;
  connectedTo: string;
  balances: any;
  tokens: any;
  nft: Array<any>;
}

const initialState: WalletState = {
  loading: false,
  success: false,
  error: null,
  accounts: [],
  connectedTo: "METAMASK",
  balances: {},
  tokens: {},
  nft: [],
};

const reducer = handleActions<WalletState, any>(
  {
    [authActions.LOG_OUT]: () => initialState,
    [actions.INIT_WALLET + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
        draft.accounts = {};
      }),
    [actions.INIT_WALLET + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
      }),
    [actions.INIT_WALLET + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),

    [actions.CONNECT_ACCOUNT]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.accounts = payload.accounts.reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.walletAddress]: item,
          };
        }, {});
      }),

    [actions.UPDATE_BALANCES]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.accounts = {
          ...state.accounts,
          [payload.address]: {
            ...state.accounts[payload.address],
            balance: payload.balance,
          },
        };
      }),

    [actions.UPDATE_TOKENS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tokens = {
          ...payload.ethToken,
          ...payload.erc20Tokens,
        };
      }),

    [actions.FETCH_NFT + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
        draft.nft = [];
      }),
    [actions.FETCH_NFT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
        draft.nft = payload;
      }),
    [actions.FETCH_NFT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),

    [actions.DISCONNECT_ACCOUNT + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [actions.DISCONNECT_ACCOUNT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
        draft.accounts = Object.values(state.accounts)
          .filter((item: any) => item.walletAddress !== payload.address)
          .reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.walletAddress]: item,
            };
          }, {});
      }),
    [actions.DISCONNECT_ACCOUNT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
  },
  initialState
);

export default reducer;
