import axios from "axios";
import { all, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import * as streamerActions from "../streamer/actions";
import * as channelActions from "../channel/actions";

let cancelSource = axios.CancelToken.source();

function* fetchEntity({ payload }: any) {
  try {
    if (typeof cancelSource !== typeof undefined) {
      cancelSource.cancel("Operation canceled due to new request.");
    }
    cancelSource = axios.CancelToken.source();
    const { data } = yield apiClient.get(`/entity/${payload}`, { cancelToken: cancelSource.token });
    yield put({ type: actions.FETCH_ENTITY + SUCCESS, payload: data });
    switch (data.type) {
      case "user":
        yield put({ type: streamerActions.GET_STREAMER + SUCCESS, payload: data.data });
        break;
      case "channel":
        yield put({ type: channelActions.FETCH_CHANNEL + SUCCESS, payload: data.data });
        break;
      default:
        break;
    }
  } catch (error: any) {
    ToastService.showErrorMessage(BoltError(error).message);
    yield put({ type: actions.FETCH_ENTITY + FAIL, payload: BoltError(error) });
  }
}

export default function* channelSaga() {
  yield all([takeLatest([actions.FETCH_ENTITY + START], fetchEntity)]);
}
