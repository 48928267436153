import React, { useCallback, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import { SkipNext, SkipPrevious } from "@mui/icons-material";
import { EpisodeItem } from "./EpisodeItem";

interface EpisodesCarouselProps {
  data: any[]; // Replace 'any' with the appropriate type if known
  setCurrentId: any;
  currentId: any;
  setCurrentIndex: React.Dispatch<any>;
}

export const EpisodesCarousel: React.FC<EpisodesCarouselProps> = ({
  data,
  setCurrentId,
  currentId,
  setCurrentIndex,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      dragFree: true, 
      align: 'start'
    }
    // [AutoScroll({ speed: 1, startDelay: 100, stopOnFocusIn: false })]
  );
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    if (isPlaying) {
      autoScroll.play();
    } else {
      autoScroll.stop();
    }
  }, [emblaApi, isPlaying]);

  const onMouseEnter = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex: any) => (prevIndex + 1) % (data?.length ?? 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex: any) => (prevIndex - 1 + (data?.length ?? 1)) % (data?.length ?? 1)); // Handle wrapping around
  };

  // console.log("data", data);
  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <Box ref={emblaRef} sx={{ overflow: "hidden" }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Box sx={{ display: "flex" }}>
            {data.map((item, index) => (
              <Box key={`browse-item-${index}`}>
                <EpisodeItem
                  itemData={item}
                  active={item?.id === currentId}
                  setCurrentId={setCurrentId}
                  setCurrentIndex={setCurrentIndex}
                  index={index}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 10,
            height: "100%",
            width: "10%",
            background: "linear-gradient(90deg, rgba(15,16,39,1) 0%, rgba(15,16,39,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
            height: "100%",
            width: "10%",
            background:
              "linear-gradient(270deg, rgba(15,16,39,1) 0%, rgba(15,16,39,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
          margin: "10px 20px 0",
        }}
      >
        <Button
          variant="contained"
          startIcon={<SkipPrevious />}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.10)",
            borderRadius: 100,

            "&:hover": { bgcolor: "rgba(255, 255, 255, 0.20)" },
          }}
          onClick={handlePrev}
        >
          Prev
        </Button>

        <Button
          variant="contained"
          endIcon={<SkipNext />}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.10)",
            borderRadius: 100,

            "&:hover": { bgcolor: "rgba(255, 255, 255, 0.20)" },
          }}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
