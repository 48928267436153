import { Box } from "@mui/material";

import { usePrivateLayoutContext } from "../../../../layouts";
import { BoltPlayer } from "../../../../components";
import { useBloombergEpisodesPageContext } from "../episodes-context";

import "./styles.css";

export const Background = () => {
  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  const { data } = useBloombergEpisodesPageContext();

  return (
    <Box
      sx={{
        position: "fixed",
        top: navbar__height,
        left: { xs: 0, md: leftMenubar__width },
        right: 0,
        bottom: 0,
      }}
    >
      <BoltPlayer url={data?.channels[0]?.media.url} controls={false} className="player-bg-mode" />
    </Box>
  );
};
