import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Grid, Typography } from "@mui/material";

import { FlexCol, FlexRow, VideoCard, defaultSorts, VideoSortFilter } from "../../../components";
import { deleteStreamerRecordAction, fetchStreamerRecordAction } from "../../../store/streamer/actions";
import { streamerRecordsSelector } from "../../../store/streamer/selector";
import { StreamVideo } from "../../../types";
import { VideosSkeleton } from "./VideosSkeleton";
import { VideoRecordDeleteConfirmModal, VideoRecordEditModal } from "../../../components/modals";

type Props = {
  userId: string;
};

export const VideosTab = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const { data, loading, hasMore, page, total } = useSelector(streamerRecordsSelector);
  const [sortMode, setSortMode] = useState(defaultSorts[0]);
  const [selectedVideo, setSelectedVideo] = useState<StreamVideo>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const loadUserVideos = useCallback((data: any) => dispatch(fetchStreamerRecordAction(data)), [dispatch]);
  const sortValue = sortMode.value === "recent" ? -1 : "";

  useEffect(() => {
    loadUserVideos({ userId });
  }, [userId]);

  useEffect(() => {
    // loadUserVideos({ userId, page: 1, sort: sortValue });
  }, [sortMode]);

  const handleDeleteItem = (item: StreamVideo) => {
    dispatch(deleteStreamerRecordAction({ id: item.id }));
    setOpenDeleteModal(false);
    setOpenEditModal(false);
    setSelectedVideo(undefined);
  };

  return (
    <FlexCol>
      <FlexRow sx={{ justifyContent: "end", alignItems: "center", display: "none" }}>
        <Typography variant="subtitle2" sx={{ marginRight: 1, color: "#FFFFFF80" }}>
          Sort by
        </Typography>
        <VideoSortFilter mode={sortMode} setMode={setSortMode} />
      </FlexRow>
      {data.length === 0 && !loading && (
        <Typography variant="subtitle2" sx={{ textAlign: "center", mt: 3, mb: 3, color: "#FFFFFF80" }}>
          No videos
        </Typography>
      )}
      <InfiniteScroll
        pageStart={1}
        loadMore={() => !loading && loadUserVideos({ userId, page: page + 1 })}
        hasMore={hasMore}
        useWindow
      >
        <Grid container spacing={2} rowSpacing={6} sx={{ marginTop: -2 }}>
          {data.map((item, index) => (
            <VideoCard
              key={index}
              item={item}
              onDelete={() => {
                setSelectedVideo(item);
                setOpenDeleteModal(true);
              }}
              onEdit={() => {
                setSelectedVideo(item);
                setOpenEditModal(true);
              }}
            />
          ))}
          {loading && <VideosSkeleton />}
        </Grid>
      </InfiniteScroll>
      <VideoRecordDeleteConfirmModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={() => handleDeleteItem(selectedVideo!)}
      />
      <VideoRecordEditModal
        open={openEditModal}
        item={selectedVideo}
        onClose={() => setOpenEditModal(false)}
        onDelete={() => setOpenDeleteModal(true)}
      />
    </FlexCol>
  );
};
