import { useEffect, useRef } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { FlexRow } from "../../../FlexRow";
import { usePrivateNavbarContext } from "../PrivateNavbarContext";
import { SearchInput } from "./SearchInput";

export const SearchInputMobile = () => {
  const { updateContext } = usePrivateNavbarContext();

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  return (
    <FlexRow
      sx={{
        display: { xs: "flex", sm: "none", md: "flex", lg: "none" },
        position: "absolute",
        top: 0,
        left: 0,
        columnGap: 1.5,
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <ArrowBackIosNewIcon
        sx={{ fontSize: 20, cursor: "pointer" }}
        onClick={() => {
          updateContext({ activeDrawer: undefined, searchKeyword: "" });
        }}
      />

      <SearchInput ref={inputRef} containerSx={{ flex: 1 }} />
    </FlexRow>
  );
};
