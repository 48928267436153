import { GoogleLoginResponse } from "react-google-login";
import { Dispatch } from "redux";
import { all, fork, take, put, select, call } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, StorageService, ToastService } from "../../services";
import { uploadProfileImage } from "../../services/s3";
import { removeWalletConnectionState } from "../../services/storage";
import { APPLE, EMAIL, FAIL, GOOGLE, START, SUCCESS, WALLET } from "../common";
import { FILE_UPLOAD_PROGRESS } from "../ui/actions";
import * as actions from "./actions";

function* LoginEmail() {
  while (true) {
    const { payload } = yield take(actions.FETCH_LOGIN + EMAIL + START);
    try {
      const { data } = yield apiClient.post("/auth/login", payload);
      yield StorageService.setAuthToken(data?.token);
      yield put({
        type: actions.FETCH_LOGIN + EMAIL + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_LOGIN + EMAIL + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* SignupEmail(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_SIGNUP + EMAIL + START);
    try {
      const { data } = yield apiClient.post("/auth/register", {
        ...payload,
        product: "BOLT_PLUS",
      });
      yield StorageService.setAuthToken(data?.token);
      yield put({
        type: actions.FETCH_SIGNUP + EMAIL + SUCCESS,
        payload: { ...data },
      });

      // if (localStorage.getItem("cid")) {
      //   // yield updateCampaignClickAction(localStorage.getItem("cid") as string, "signup", payload.data.accessToken);
      //   yield tempUpdateCampaignClickAction(payload.data.uid, localStorage.getItem("cid") as string, "signup");
      // }
    } catch (error: any) {
      yield put({
        type: actions.FETCH_SIGNUP + EMAIL + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* fetchUser(): any {
  while (true) {
    yield take(actions.FETCH_USER + START);
    try {
      const { data } = yield apiClient.get("/users/me");
      yield put({
        type: actions.FETCH_USER + SUCCESS,
        payload: { data },
      });
      // if (payload.data) {
      //   yield put({
      //     type: actions.FETCH_USER + SUCCESS,
      //     payload: payload,
      //   });
      //   instance.defaults.headers.common["Verify-Id"] =
      //     payload.data.stsTokenManager.accessToken;
      // } else {
      //   yield put({
      //     type: actions.FETCH_USER + FAIL,
      //     payload: { error: "User not logged in", ipInfo: payload.ipInfo },
      //   });
      // }
    } catch (error: any) {
      yield put({
        type: actions.FETCH_USER + FAIL,
        payload: error.response.data,
      });
      yield put({ type: actions.LOG_OUT });
    }
  }
}

function* logout() {
  while (true) {
    yield take(actions.LOG_OUT);
    yield removeWalletConnectionState();
    yield StorageService.removeAuthToken();
  }
}

function* googeLogin(): any {
  while (true) {
    const { payload }: { payload: { response: GoogleLoginResponse; onboardingCallback: () => void } } = yield take(
      actions.FETCH_LOGIN + GOOGLE + START
    );
    try {
      const { data } = yield apiClient.post("/auth/google", {
        token: payload.response?.tokenId,
      });
      if (data.registerToken) {
        payload.onboardingCallback();
        yield StorageService.setRegisterAuthToken(data.registerToken);
      }
      if (data.token) {
        yield StorageService.setAuthToken(data.token);
      }
      yield put({
        type: actions.FETCH_LOGIN + GOOGLE + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_LOGIN + GOOGLE + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* walletEmail() {
  while (true) {
    const { payload } = yield take(actions.FETCH_LOGIN + WALLET + START);
    try {
      const { data } = yield apiClient.post("/auth/wallet", payload);
      yield StorageService.setAuthToken(data?.token);
      yield put({
        type: actions.FETCH_LOGIN + WALLET + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_LOGIN + WALLET + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* appleLogin(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_LOGIN + APPLE + START);
    try {
      const { data } = yield apiClient.post("/auth/apple", {
        token: payload.token,
      });
      if (data.registerToken) {
        payload?.onboardingCallback();
        yield StorageService.setRegisterAuthToken(data.registerToken);
      }
      if (data.token) {
        yield StorageService.setAuthToken(data.token);
      }
      yield put({
        type: actions.FETCH_LOGIN + APPLE + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_LOGIN + APPLE + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* verifyTv(): any {
  while (true) {
    const { payload } = yield take(actions.VERIFY_TV);
    try {
      yield apiClient.post("/auth/verifyTv", {
        token: payload,
      });
    } catch (error: any) {
      console.error(error);
    }
  }
}

function* fetchOnboarding(): any {
  while (true) {
    const { payload } = yield take(actions.FETCH_ONBOARDING + START);
    try {
      const registerToken = yield call(StorageService.getRegisterAuthToken);
      const { data } = yield apiClient.post("/auth/onboarding", payload, {
        headers: { authorization: `Bearer ${registerToken}` },
      });
      yield StorageService.setAuthToken(data?.token);
      yield put({
        type: actions.FETCH_ONBOARDING + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_ONBOARDING + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* syncUser(): any {
  while (true) {
    yield take(actions.SYNC_USER + START);
    try {
      const { data } = yield apiClient.get("/users/me");
      yield put({
        type: actions.SYNC_USER + SUCCESS,
        payload: { data },
      });
    } catch (error: any) {
      yield put({
        type: actions.SYNC_USER + FAIL,
        payload: error.response.data,
      });
      yield put({ type: actions.LOG_OUT });
    }
  }
}

function* updateProfile(dispatch: Dispatch): any {
  while (true) {
    const {
      payload: { data, onSuccess = () => {} },
    }: any = yield take(actions.UPDATE_PROFILE + START);
    try {
      if (data.coverImage && typeof data.coverImage === "object") {
        const timestamp = new Date().getTime();
        const splitedByDotName = data.coverImage.name.split(".");
        const fileType = splitedByDotName[splitedByDotName.length - 1];
        const fileName = data.coverImage.name.split(".")[0].toLowerCase().replace(/\s/g, "");
        const newFileName = `${timestamp}_${fileName}.${fileType}`;

        const coverImgUrl: string = yield call(uploadProfileImage, {
          folder: "cover",
          file: data.coverImage,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          newFileName,
        });
        if (coverImgUrl) {
          data.coverImage = coverImgUrl;
        }
      }

      if (data.photoUrl && typeof data.photoUrl === "object") {
        const timestamp = new Date().getTime();
        const splitedByDotName = data.photoUrl.name.split(".");
        const fileType = splitedByDotName[splitedByDotName.length - 1];
        const fileName = data.photoUrl.name.split(".")[0].toLowerCase().replace(/\s/g, "");
        const newFileName = `${timestamp}_${fileName}.${fileType}`;

        const photoImgUrl: string = yield call(uploadProfileImage, {
          folder: "avatar",
          file: data.photoUrl,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          newFileName,
        });
        if (photoImgUrl) {
          data.photoUrl = photoImgUrl;
        }
      }

      const { data: res } = yield apiClient.post("/users/profile", data);
      yield put({
        type: actions.UPDATE_PROFILE + SUCCESS,
        payload: res,
      });
      onSuccess(res);
    } catch (error: any) {
      yield put({
        type: actions.UPDATE_PROFILE + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

export default function* authSaga(dispatch: Dispatch) {
  yield all([
    fork(googeLogin),
    fork(appleLogin),
    fork(walletEmail),
    fork(fetchOnboarding),
    fork(fetchUser),
    fork(syncUser),
    fork(SignupEmail),
    fork(logout),
    fork(LoginEmail),
    fork(updateProfile, dispatch),
    fork(verifyTv),
  ]);
}
