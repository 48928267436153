import React from "react";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import { PublicNavbar } from "../../components/navbar";

export const PublicLayout = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      <PublicNavbar />

      <Container maxWidth="xs" sx={{ height: "100%", display: "flex" }}>
        <Outlet />
      </Container>
    </Box>
  );
};
