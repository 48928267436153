import { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { DrawerProps, useMediaQuery } from "@mui/material";

import { CHANNEL_TYPE, RECEIVER_TYPE } from "../../types";
import { defaultChatContainerId } from "../../constants";
import { ChatProps } from "../../components/immersive-panel/chat";

type ImmersivePanelLayoutContextType = {
  immersivePanel__open: boolean;
  immersivePanel__variant: DrawerProps["variant"];
  immersivePanel__width: number;
  immersivePanel__hidden: boolean;
  //
  chatProps: ChatProps;
  //
  updateContext: (payload: Partial<Omit<ImmersivePanelLayoutContextType, "updateContext">>) => void;
};

const defaultImmersivePanelLayoutContextValue: ImmersivePanelLayoutContextType = {
  immersivePanel__open: true,
  immersivePanel__variant: "persistent",
  immersivePanel__width: 550,
  immersivePanel__hidden: false,
  //
  chatProps: {
    containerId: defaultChatContainerId,
    channelId: "",
    channelType: CHANNEL_TYPE.CHANNEL,
    receiverName: "",
    receiverType: RECEIVER_TYPE.CHANNEL,
  },
  //
  updateContext: () => {},
};

export const ImmersivePanelLayoutContext = createContext<ImmersivePanelLayoutContextType>(
  defaultImmersivePanelLayoutContextValue
);

export type ImmersivePanelLayoutContextProviderProps = {
  immersivePanel__hidden?: ImmersivePanelLayoutContextType["immersivePanel__hidden"];
};

export const ImmersivePanelLayoutContextProvider = ({
  children,
  immersivePanel__hidden = false,
}: PropsWithChildren<ImmersivePanelLayoutContextProviderProps>) => {
  const [contextValue, setContextValue] = useState<ImmersivePanelLayoutContextType>(
    defaultImmersivePanelLayoutContextValue
  );

  const updateContext: ImmersivePanelLayoutContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const isXs = useMediaQuery("(max-width:400px)");

  const isSm = useMediaQuery("(max-width:768px)");

  const isMd = useMediaQuery("(max-width:1200px)");

  const isLg = useMediaQuery("(max-width:1536px)");

  const immersivePanel__variant: DrawerProps["variant"] = useMemo(() => {
    if (isSm) {
      updateContext({ immersivePanel__open: true });
      return "temporary";
    }

    if (isXs) {
      updateContext({ immersivePanel__open: true });

      return "temporary";
    }

    return "persistent";
  }, [isSm, isXs]);

  const immersivePanel__width = useMemo(() => {
    if (isSm) {
      return 350;
    }

    if (isMd) {
      return 400;
    }

    if (isLg) {
      return 450;
    }

    return 550;
  }, [isMd, isLg]);

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      //
      immersivePanel__width,
      immersivePanel__variant,
      immersivePanel__hidden,
      //
      updateContext,
    };
  }, [contextValue, immersivePanel__width, immersivePanel__variant, immersivePanel__hidden]);

  return <ImmersivePanelLayoutContext.Provider value={memoizedValue}>{children}</ImmersivePanelLayoutContext.Provider>;
};

export const useImmersivePanelLayoutContext = () => {
  const context = useContext(ImmersivePanelLayoutContext);

  return context;
};
