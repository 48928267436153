import { handleActions } from "redux-actions";
import produce from "immer";
import { AmazonIVSBroadcastClient } from "amazon-ivs-web-broadcast";

import * as actions from "./actions";
import * as authActions from "../auth/actions";
import { FAIL, START, SUCCESS } from "../common";
import { Channel, User } from "../../types";

interface Option {
  label: string;
  value: string;
}

interface Devices {
  video: Option[];
  audio: Option[];
}

export interface ProfileState {
  loading: boolean;
  loaded: boolean;
  streamProfile: Channel | null;
  devices: Devices;
  streamProfileStatus: string;
  trendingTags: any;
  liveStream: {
    isStreaming: boolean;
    ivsBroadcastClient?: AmazonIVSBroadcastClient;
    devicePermissions: {
      video: boolean;
      audio: boolean;
    };
    activeVideoDeviceId?: string;
    activeAudioDeviceId?: string;
    camMuted: boolean;
    micMuted: boolean;
    captureStream?: MediaStream;
    layers?: any;
    mixerDevices?: any;
  };
  user?: User;
}

const initialState: ProfileState = {
  loading: false,
  loaded: true,
  streamProfile: null,
  devices: {
    video: [],
    audio: [],
  },
  streamProfileStatus: "",
  trendingTags: [],
  liveStream: {
    isStreaming: false,
    devicePermissions: {
      video: false,
      audio: false,
    },
    camMuted: false,
    micMuted: false,
  },
  user: undefined,
};

const reducer = handleActions<ProfileState, any>(
  {
    [authActions.LOG_OUT]: () => initialState,
    [actions.SET_USER_PROFILE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user = payload;
      }),
    [actions.GET_USER_PROFILE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user = payload;
      }),
    [actions.FOLLOW_USER + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user.followed = payload.follow;
        }
      }),
    [actions.FOLLOW_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user.followers = (state.user?.followers || 0) + (payload.follow ? 1 : -1);
        }
      }),
    [actions.FOLLOW_USER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user.followed = !payload.follow;
          draft.user.followers = (state.user?.followers || 0) + (payload.follow ? -1 : 1);
        }
      }),
    [actions.CREATE_STREAM_PROFILE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.loaded = false;
      }),
    [actions.CREATE_STREAM_PROFILE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loaded = true;
      }),
    [actions.SET_STREAM_PROFILE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.streamProfile = payload;
      }),
    [actions.SET_DEVICES]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.devices = payload;
      }),
    [actions.SET_IS_STREAMING]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.isStreaming = payload;
      }),
    [actions.SET_STREAM_PROFILE_STATUS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.streamProfileStatus = payload;
      }),
    [actions.GET_STREAMING_TAGS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.trendingTags = payload.tags;
      }),
    [actions.JOIN_WORLD_CUP_CAMPAIGN + START]: (state, { payload }) =>
      produce(state, (draft) => {
        // draft.user = payload;
      }),
    [actions.JOIN_WORLD_CUP_CAMPAIGN + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        // draft.user = payload;
      }),
    [actions.INIT_IVS_BROADCAST_CLIENT + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.ivsBroadcastClient = undefined;
      }),
    [actions.INIT_IVS_BROADCAST_CLIENT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.ivsBroadcastClient = undefined;
      }),
    [actions.INIT_IVS_BROADCAST_CLIENT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.ivsBroadcastClient = payload;
      }),
    [actions.SET_DEVICE_PERMISSIONS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.devicePermissions = payload;
      }),
    [actions.SET_ACTIVE_VIDEO_DEVICE_ID]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.activeVideoDeviceId = payload;
      }),
    [actions.SET_ACTIVE_AUDIO_DEVICE_ID]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.activeAudioDeviceId = payload;
      }),
    [actions.ATTACH_PREVIEW_TO_IVS_BROADCAST_CLIENT]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.ivsBroadcastClient?.attachPreview(payload);
      }),
    [actions.SET_CAM_MUTED]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.camMuted = payload;
      }),
    [actions.SET_MIC_MUTED]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.micMuted = payload;
      }),
    [actions.SET_CAPTURE_STREAM]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.captureStream = payload;
      }),
    [actions.SET_LAYERS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.layers = payload;
      }),
    [actions.SET_MIXER_DEVICES]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.liveStream.mixerDevices = payload;
      }),
  },

  initialState
);

export default reducer;
