import React from "react";
import { BoxProps } from "@mui/material";
import { FlexCol } from "../FlexCol";

type VContainerProps = {
  hasItem?: boolean;
  activeOnlyOne?: boolean;
} & BoxProps

export const VContainer = ({ hasItem = true, activeOnlyOne = false, sx, children }: VContainerProps) => {

  return (
    <FlexCol sx={{ display: hasItem ? "flex" : "none", gap: activeOnlyOne ? 0 : 1.5, flex: 1, ...sx }}>
      {children}
    </FlexCol>
  )
}