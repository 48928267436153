import React from "react";

import { FlexCol, LiveNavigationBar } from "../../components";
import { NAV_BAR_HEIGHT } from "../../constants";
import { TvCategoryTabContent } from "./tvTabs/TvCategoryTabContent";

export const LiveTVPage = () => {
  return (
    <FlexCol sx={{ height: `calc(100vh - ${NAV_BAR_HEIGHT}px)` }}>
      <FlexCol sx={{ display: { md: "none" } }}>
        <LiveNavigationBar />
      </FlexCol>
      <FlexCol
        sx={{
          overflow: "auto",
          position: "relative",
          width: "100%",
          flex: 1,
        }}
      >
        <TvCategoryTabContent />
      </FlexCol>
    </FlexCol>
  );
};
