import React, { useEffect } from "react";

const useExternalLinkStopPropagation = () => {
  useEffect(() => {
    const externalLinks = document.getElementsByClassName("external-link");
    const myFunction = (event: any) => {
      event.stopPropagation();
    };
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < externalLinks.length; i++) {
      externalLinks[i].addEventListener("click", myFunction, false);
    }
    return () => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < externalLinks.length; i++) {
        externalLinks[i].removeEventListener("click", myFunction, false);
      }
    };
  }, []);
};

export default useExternalLinkStopPropagation;
