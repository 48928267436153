import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { feedDetailsSelector } from "../../store/feedDetails/selector";
import { fetchFeedDetails } from "../../store/feedDetails/actions";
import FeedSkeleton from "./FeedSkeleton";
import { FeedContentContainer } from "./FeedContentContainer";

type Props = {
  disabled?: boolean;
};

const FeedDetails = ({ disabled }: Props) => {
  const theme = useTheme();
  const { feedId = null } = useParams();
  const dispatch = useDispatch();
  const feedDetails = useSelector(feedDetailsSelector);

  useEffect(() => {
    if (feedId) dispatch(fetchFeedDetails({ streamFeed: feedId }));
  }, [dispatch, feedId]);

  if (feedDetails.loading) return <FeedSkeleton />;

  return (
    <Box sx={{ pb: 2 }}>
      <FeedContentContainer {...{ feedDetails }} />
    </Box>
  );
};

export default FeedDetails;
