import { Box, ButtonBase, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { FlexRow } from "./FlexRow";
import { ReactionType } from "../types";
import { apiClient } from "../config";
import { reactionToRender } from "../helpers";
import ReactionUsersPopover from "./ReactionUsersPopover";

export type ReactionUsersObjectType = {
  username: string;
  id: string;
  photoUrl: string;
  fullName: string;
  type: ReactionType["type"];
}[];
type Props = {
  small?: boolean;
  large?: boolean;
  reactions?: ReactionType[];
  reactionCount?: number;
  streamFeedId?: string;
  commentId?: string;
  replyCount?: number;
  reboltCount?: number;
};

const ReactionButtons = ({
  small,
  large,
  reactions,
  reactionCount,
  streamFeedId,
  commentId,
  replyCount,
  reboltCount,
}: Props) => {
  const [reactedAnchor, setReactedAnchor] = React.useState<HTMLButtonElement | null>(null);

  const [loading, setLoading] = useState(false);

  const [reactionUsers, setReactionUsers] = useState<ReactionUsersObjectType>([]);

  const fetchReactionUsers = useCallback(
    (reaction: ReactionType["type"]) => {
      let url = "";
      if (streamFeedId) {
        url = `/v2/feed/stream/${streamFeedId}/reaction/users?type=${reaction}`;
      }
      if (commentId) {
        url = `/v2/feed/stream/comment/${commentId}/reaction/users?type=${reaction}`;
      }
      return apiClient.get(url);
    },
    [streamFeedId, commentId]
  );

  const uniqueReactions = useMemo(() => {
    const newArray: { type: ReactionType["type"]; count: number }[] = [];
    reactions?.forEach((reactionItem) => {
      if (newArray.findIndex((arrayItem) => arrayItem.type === reactionItem.type) === -1) {
        if (reactionItem.count > 0) {
          newArray.push({ type: reactionItem.type, count: reactionItem.count });
        }
      }
    });
    return newArray;
  }, [reactions]);

  const fetchAllReaction = useCallback(async () => {
    if (uniqueReactions.length) {
      const promiseArray: Promise<AxiosResponse<any, any>>[] = [];
      setLoading(true);
      try {
        uniqueReactions.forEach(({ type, count }) => {
          if (count > 0) {
            promiseArray.push(fetchReactionUsers(type));
          }
        });
        const result = await Promise.all(promiseArray);
        let preparedArr: ReactionUsersObjectType = [];
        result.forEach(({ data }, index) => {
          const tempArr: ReactionUsersObjectType = [];
          const type = uniqueReactions[index]?.type;
          data.forEach((temp: any) => {
            tempArr.push({
              ...temp,
              type,
            });
          });
          preparedArr = [...preparedArr, ...tempArr];
        });
        setReactionUsers(preparedArr);
      } catch (error) {
        console.error({ message: "Reaction Users Fetch failed!", reason: error });
      }
      setLoading(false);
    }
  }, [reactions, fetchReactionUsers, uniqueReactions]);

  let gapSize = 0.625;
  let minHeight = 32;
  let buttonPx = 1.25;
  let buttonPy = 0.875;
  let reactionFontSize = 12;
  let fontSize = 11;

  if (small) {
    gapSize = 0.5;
    minHeight = 17;
    buttonPx = 0.9;
    buttonPy = 0.6875;
    reactionFontSize = 9;
    fontSize = 8;
  }
  if (large) {
    gapSize = 1.125;
    minHeight = 44;
    buttonPx = 2.095;
    buttonPy = 1.46625;
    reactionFontSize = 20;
    fontSize = 18.4;
  }

  return (
    <FlexRow sx={{ gap: gapSize, alignItems: "center", minHeight }}>
      {reactionCount !== undefined && reactionCount > 0 && (
        <ButtonBase
          sx={{
            px: buttonPx,
            py: buttonPy,
            borderRadius: "50px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setReactedAnchor(e.currentTarget);
            fetchAllReaction();
          }}
        >
          {uniqueReactions?.map(({ type, count }, index) => {
            if (count === 0) return null;
            return (
              <Box key={type} sx={{ fontSize: reactionFontSize, lineHeight: "100%", marginRight: "2px" }}>
                {reactionToRender(type)}
              </Box>
            );
          })}
          <Typography sx={{ marginLeft: "5px", fontWeight: 600, fontSize, lineHeight: "100%", color: "white" }}>
            {reactionCount}
          </Typography>
        </ButtonBase>
      )}
      {!!replyCount && (
        <>
          {!!reactionCount && (
            <Box
              sx={{ width: "3px", height: "3px", borderRadius: "10px", backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            />
          )}
          <Typography sx={{ fontWeight: 300, fontSize, lineHeight: "100%", color: "rgba(255, 255, 255, 0.5)" }}>
            <span style={{ fontWeight: 600, display: "inline-block" }}>{replyCount}</span> Replies
          </Typography>
        </>
      )}
      {!!reboltCount && (
        <>
          {(!!reactionCount || !!replyCount) && (
            <Box
              sx={{ width: "3px", height: "3px", borderRadius: "10px", backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            />
          )}
          <Typography sx={{ fontWeight: 300, fontSize, lineHeight: "100%", color: "rgba(255, 255, 255, 0.5)" }}>
            <span style={{ fontWeight: 600, display: "inline-block" }}>{reboltCount}</span> Rebolts
          </Typography>
        </>
      )}
      <ReactionUsersPopover
        {...{ reactedAnchor, setReactedAnchor, reactionUsers, loading, onClose: () => setReactionUsers([]) }}
      />
    </FlexRow>
  );
};

export default ReactionButtons;
