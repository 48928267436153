import { Typography } from "@mui/material";

export type DetailTextProps = {
  text: string;
};

export const DetailText = ({ text }: DetailTextProps) => {
  return (
    <Typography
      sx={{
        fontSize: 12,
        color: "rgba(255, 255, 255, 0.50)",
      }}
    >
      {text}
    </Typography>
  );
};
