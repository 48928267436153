import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, BoxProps, Paper, FormControl, MenuItem, Select, Skeleton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js";
import moment from "moment";

import { ReactComponent as FileIcon } from "../../../assets/svgs/users/file.svg";
import { ReactComponent as FaceIcon } from "../../../assets/svgs/users/face.svg";
import { ReactComponent as MessageIcon } from "../../../assets/svgs/users/message.svg";
import { ReactComponent as RepostIcon } from "../../../assets/svgs/repost.svg";
import { ReactComponent as EyeWhiteIcon } from "../../../assets/svgs/users/eyeWhite.svg";
import { ReactComponent as BoltsIcon } from "../../../assets/svgs/users/bolts.svg";

import { FlexCol, FlexRow, PeriodSelect, periodPostFilters } from "../../../components";
import { fetchAnalyticsPostsAction, fetchAnalyticsPostsContentAction, fetchAnalyticsPostsStatisticsAction } from "../../../store/users/actions";
import { userStreamSelector } from "../../../store/users/selector";
import { AnalyticsData, PeriodFilterType } from "../../../types";
import { ContentFeedItem, StatusBox } from "./components";
import { Formatter, getLabels, getCharData } from "../../../utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const renderSkeleton = () => (
  [...Array(5)].map((_, index) => (
    <TableRow key={index} sx={{ '&:last-child td, &:last-child th, & td': { border: 0 } }}>
      <TableCell><Skeleton variant="text" sx={{ fontSize: '1.5rem' }} /></TableCell>
      <TableCell><Skeleton variant="text" sx={{ fontSize: '1.5rem' }} /></TableCell>
      <TableCell sx={{ textAlign: '-webkit-right' }}><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 60 }} /></TableCell>
      <TableCell sx={{ textAlign: '-webkit-right' }}><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 60 }} /></TableCell>
      <TableCell sx={{ textAlign: '-webkit-right' }}><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 60 }} /></TableCell>
      <TableCell sx={{ textAlign: '-webkit-right' }}><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 60 }} /></TableCell>
    </TableRow>
  ))
);

const AnalyticsPost = ({ sx, ...other }: BoxProps) => {
  const dispatch = useDispatch();
  const { loading, post } = useSelector(userStreamSelector);
  const { statistics, performanceFeeds, total, page = 1, totalPosts, totalReactions, totalReplies, totalRebolts, impressions, totalBolts } = post;
  const [filter, setFilter] = useState('highest_first');
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(periodPostFilters[0]);

  useEffect(() => {
    const handler = () => setIsOpen(false);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAnalyticsPostsAction());
  }, [dispatch]);

  useEffect(() => {
    const parmas = {
      startDate: 0,
      endDate: Date.now(),
    };

    const date = new Date();
    switch (period.value) {
      case PeriodFilterType.last_week:
        parmas.startDate = Date.now() - 6 * 24 * 60 * 60 * 1000;
        break;
      case PeriodFilterType.last_month:
        parmas.startDate = Date.now() - 30 * 24 * 60 * 60 * 1000;
        break;
      case PeriodFilterType.this_year:
        date.setMonth(0);
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        parmas.startDate = date.getTime();
        break;
      case PeriodFilterType.last_year:
        parmas.startDate = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
        parmas.endDate = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
        break;
      default: break;
    }

    dispatch(fetchAnalyticsPostsStatisticsAction(parmas));
    dispatch(fetchAnalyticsPostsContentAction(parmas));
  }, [dispatch, period]);

  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
  };

  const chartLabels = useMemo(() => getLabels(period.value), [period]);

  const chartOptions: any = {
    responsive: true,
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (tooltipItem: any) => moment(chartLabels[tooltipItem[0]?.dataIndex]).format("DD/MM") || `${tooltipItem[0]?.label}`,
          // label: (yDatapoint: any) => ` ${yDatapoint.raw}`,
        }
      }
    },
    scales: {
      y: {
        grid: { display: false },
        ticks: { display: false },
        beginAtZero: true,
      },
      x: {
        grid: { display: false },
        ticks: {
          color: "#fff",
          font: { size: 12 },
        },
        beginAtZero: true,
      }
    }
  };

  const chartData = (data: AnalyticsData[], hoverColor: string = "#4E33E9", dataLabel: string = "Impressions") => {
    return {
      labels: chartLabels.map(one => moment(one).format(period.value === PeriodFilterType.last_month ? "DD" : "DD/MM")),
      datasets: [{
        label: dataLabel,
        data: getCharData(data, period.value),
        backgroundColor: "#282C46",
        hoverBackgroundColor: hoverColor,
        borderRadius: 3,
        maxBarThickness: 30
      }],
    }
  };

  return (
    <Box sx={{ pt: 3, pb: { xs: 10, sm: 3 }, ...sx }} {...other}>
      <FlexRow sx={{ ml: { xs: 2, sm: 0 }, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
        <PeriodSelect sx={{ mr: 4.5 }} mode={period} setMode={setPeriod} hasFullOption={false} />
        <FlexRow
          sx={{
            flexWrap: "wrap",
            display: { xs: "block", sm: "flex" },
            justifyContent: { xs: "center", sm: "start" },
            gap: "8px",
          }}>
          <StatusBox Icon={FileIcon} label="Total Posts" value={totalPosts} />
          <StatusBox Icon={FaceIcon} label="Total Reactions" value={totalReactions} />
          <StatusBox Icon={MessageIcon} label="Total Replies" value={totalReplies} />
          <StatusBox Icon={RepostIcon} label="Total Rebolts" value={totalRebolts} />
          <StatusBox Icon={EyeWhiteIcon} label="Total Impressions" value={impressions} />
          <StatusBox Icon={BoltsIcon} label="Bolts Received(Posts)" value={totalBolts} sx={{ width: 265 }} />
        </FlexRow>
      </FlexRow>

      <FlexRow sx={{ width: "100%", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" }, gap: 2, mt: 3, px: { xs: 1.5, sm: 0 } }}>
        <FlexCol sx={{ width: { md: "100%", lg: "70%", xl: "70%" } }}>
          <Box component={Paper} sx={{ p: { xs: 1.5, sm: 3 }, pt: { xs: 1, sm: 2 }, width: "100%" }}>
            <Typography sx={{ color: "#A3AED0" }}>Impressions</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600, mb: 2.5 }}>{Formatter.formatNumber(impressions)}</Typography>
            <Bar options={chartOptions} data={chartData(statistics.impressions, "#4E33E9", "Impressions")} height={50} />
          </Box>

          <FlexCol component={Paper} sx={{ mt: 2, p: { xs: 1, sm: 3 }, width: "100%" }}>
            <FlexRow sx={{ mx: { xs: 0, sm: 2 }, justifyContent: "space-between" }}>
              <Typography sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 600 }}>Content Performance</Typography>
              <FormControl
                sx={{
                  fontSize: 12,
                  ml: 1,
                  minWidth: 100,
                  borderRadius: 2,
                  backgroundColor: '#282C46',
                  display: 'none',
                  "& fieldset": { borderWidth: 0 },
                  '& .MuiOutlinedInput-root': {
                    '& .MuiSelect-select': { py: 0.2, textAlign: 'center', fontSize: 14 },
                    '&.Mui-focused fieldset': { borderWidth: 0 }
                  }
                }}
                size='small' color='secondary'>
                <Select
                  value={filter} open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  onChange={handleFilterChange}
                  displayEmpty={false}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="highest_first">Highest First</MenuItem>
                  <MenuItem value='recent_first'>Recent First</MenuItem>
                </Select>
              </FormControl>
            </FlexRow>
            <TableContainer>
              <Table aria-label="overview table">
                <TableHead>
                  <TableRow sx={{ '& th': { color: 'rgba(255, 255, 255, 0.5)', fontSize: 12 }, }}>
                    <TableCell>Post</TableCell>
                    <TableCell align="right">Impressions</TableCell>
                    <TableCell align="right">Reactions</TableCell>
                    <TableCell align="right">Replies</TableCell>
                    <TableCell align="right">Rebolts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && renderSkeleton()}
                  {!loading && performanceFeeds.map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th, & td': { border: 0, borderTopWidth: index === 0 ? 0 : 0.5, borderTopColor: "#ffffff1a" } }}>
                      <TableCell align="left"><ContentFeedItem item={row} sx={{ minWidth: 290 }} /></TableCell>
                      <TableCell align="right" sx={{ verticalAlign: 'top', pt: 3 }}>{Formatter.formatNumber(row.impressions)}</TableCell>
                      <TableCell align="right" sx={{ verticalAlign: 'top', pt: 3 }}>{Formatter.formatNumber(row.reactionCount)}</TableCell>
                      <TableCell align="right" sx={{ verticalAlign: 'top', pt: 3 }}>{Formatter.formatNumber(row.commentCount)}</TableCell>
                      <TableCell align="right" sx={{ verticalAlign: 'top', pt: 3 }}>{Formatter.formatNumber(row.reboltCount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && performanceFeeds.length === 0 && <Typography sx={{ color: '#ffffff80', fontSize: 14, height: { xs: 30, sm: 70 }, mt: 2, textAlign: "center" }}>No data</Typography>}
          </FlexCol>
        </FlexCol>
        <FlexCol sx={{ gap: 2, flex: 1 }}>
          <FlexCol component={Paper} sx={{ p: { xs: 1.5, sm: 3 }, pt: { xs: 1, sm: 2 }, width: "100%" }}>
            <Typography sx={{ color: "#A3AED0" }}>Reactions</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600, mb: 2.5 }}>{Formatter.formatNumber(totalReactions)}</Typography>
            <Bar options={chartOptions} data={chartData(statistics.reactions, "#F2C94C", "Reactions")} height={50} />
          </FlexCol>
          <FlexCol component={Paper} sx={{ p: { xs: 1.5, sm: 3 }, pt: { xs: 1, sm: 2 }, width: "100%" }}>
            <Typography sx={{ color: "#A3AED0" }}>Replies</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600, mb: 2.5 }}>{Formatter.formatNumber(totalReplies)}</Typography>
            <Bar options={chartOptions} data={chartData(statistics.comments, "#219653", "Replies")} height={50} />
          </FlexCol>
          <FlexCol component={Paper} sx={{ p: { xs: 1.5, sm: 3 }, pt: { xs: 1, sm: 2 }, width: "100%" }}>
            <Typography sx={{ color: "#A3AED0" }}>Rebolts</Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600, mb: 2.5 }}>{Formatter.formatNumber(totalRebolts)}</Typography>
            <Bar options={chartOptions} data={chartData(statistics.rebolts, "#F2994A", "Rebolts")} height={50} />
          </FlexCol>
        </FlexCol>
      </FlexRow>
    </Box >
  );
};

export default AnalyticsPost;
