import React from "react";
import { useParams } from "react-router-dom";

import { FlexCol } from "../../components";
import { useUserSettings } from "../../layouts";
import { EmailSettingsPage } from "./EmailSettingsPage";
import { NotificationSettings } from "./NotificationSettings";
import { AnalyticsSettings } from "./AnalyticsSetting";

export const SettingsPage = () => {
  const { category = "home" } = useParams();
  const { data, updateSettings } = useUserSettings();

  return (
    <FlexCol>
      {category === "home" && <EmailSettingsPage settings={data} updateSettings={updateSettings} />}
      {category === "email" && <EmailSettingsPage settings={data} updateSettings={updateSettings} />}
      {category === "notifications" && <NotificationSettings />}
      {category === "analytics" && <AnalyticsSettings />}
    </FlexCol>
  );
};
