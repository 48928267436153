import { PropsWithChildren } from "react";
import { SxProps } from "@mui/material";

import { StepScrollContainerContextProvider } from "./StepScrollContainerContext";
import { StepScrollContainerUi } from "./StepScrollContainerUi";

type StepScrollContainerProps = PropsWithChildren<{
  containerSx?: SxProps;
}>;

export const StepScrollContainer = ({ children, containerSx = {} }: StepScrollContainerProps) => {
  return (
    <StepScrollContainerContextProvider>
      <StepScrollContainerUi containerSx={containerSx}>{children}</StepScrollContainerUi>
    </StepScrollContainerContextProvider>
  );
};
