import { useMemo } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import { LiveChannels } from "./LiveChannels";
import { Collections } from "./Collections";
import { useStreamsContext } from "./StreamsContext";
import { LiveNow } from "./LiveNow";
import { Rewinds } from "./Rewinds";
import { Users } from "./Users";

export const StreamsUi = () => {
  const { channels, isLoadingChannels, categories, isLoadingCategories, liveNows, rewinds, users, isLoadingProfiles } =
    useStreamsContext();

  const searchedLiveChannels = useMemo(() => {
    return !isLoadingChannels && channels.data.length > 0;
  }, [isLoadingChannels, channels.data.length]);

  const searchedCollections = useMemo(() => {
    return !isLoadingCategories && categories.data.length > 0;
  }, [isLoadingCategories, categories.data.length]);

  const searchedLiveNows = useMemo(() => {
    return !isLoadingProfiles && liveNows.length > 0;
  }, [isLoadingProfiles, liveNows.length]);

  const searchedRewinds = useMemo(() => {
    return !isLoadingProfiles && rewinds.length > 0;
  }, [isLoadingProfiles, rewinds.length]);

  const searchedUsers = useMemo(() => {
    return !isLoadingProfiles && users.length > 0;
  }, [isLoadingProfiles, users.length]);

  const isLoading = useMemo(() => {
    return isLoadingChannels || isLoadingCategories || isLoadingProfiles;
  }, [isLoadingChannels, isLoadingCategories]);

  const notFound = useMemo(() => {
    return (
      !isLoading &&
      channels.data.length === 0 &&
      categories.data.length === 0 &&
      liveNows.length === 0 &&
      rewinds.length === 0 &&
      users.length === 0
    );
  }, [isLoading, channels.data.length, categories.data.length && liveNows.length && rewinds.length && users.length]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
      {searchedLiveChannels && <LiveChannels />}

      {searchedCollections && <Collections />}

      {searchedLiveNows && <LiveNow />}

      {searchedRewinds && <Rewinds />}

      {searchedUsers && <Users />}

      {isLoading && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 180 }}>
          <CircularProgress />
        </Box>
      )}

      {notFound && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 180 }}>
          <Typography color="text.secondary">No streams found</Typography>
        </Box>
      )}
    </Box>
  );
};
