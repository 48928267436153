import { useCallback, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";

import { SliderItem, SliderItemProps } from "./SliderItem";

export type SliderProps = {
  title?: string;
  items?: SliderItemProps[];
};

export const Slider = ({ title = "", items = [] }: SliderProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [AutoScroll({ speed: 1, startDelay: 100, stopOnFocusIn: false, stopOnInteraction: false })]
  );

  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    if (isPlaying) {
      autoScroll.play();
    } else {
      autoScroll.stop();
    }
  }, [emblaApi, isPlaying]);

  const isXs = useMediaQuery("(max-width:600px)");

  const onMouseEnter = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const _items = items.length > (isXs ? 1 : 4) && items.length < (isXs ? 4 : 8) ? [...items, ...items] : items || [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: { xs: 0.5, md: 1 }, width: "100%", pb: 2 }}>
      <Box sx={{ px: { xs: 2, md: 4 } }}>
        <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>{title}</Typography>
      </Box>

      <Box sx={{ position: "relative", width: "100%" }}>
        <Box
          ref={emblaRef}
          sx={{ overflow: "hidden", py: 1, px: 0.5, maxWidth: "100%" }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, pl: 2 }}>
            {_items.map((item, index) => (
              <Box key={`discover-slider-item-${index}`}>
                <SliderItem {...item} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
