import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchEntityAction } from "../../store/entity/actions";
import { setUserProfileAction } from "../../store/profile/actions";
import { entitySelector } from "../../store/entity/selector";
import { usePrivateLayoutContext } from "../private-layout";
import { LoadingView, PageNotFound, GeoBlocked } from "../../components";
import { PrivateStreamerPage } from "../../pages";

type EntityLayoutContextType = {
  boltId: string;
  //
  entity: any;
  //
  updateContext: (payload: Partial<Omit<EntityLayoutContextType, "updateContext">>) => void;
};

const defaultEntityLayoutContextValue: EntityLayoutContextType = {
  boltId: "",
  //
  entity: null,
  //
  updateContext: () => {},
};

export const EntityLayoutContext = createContext<EntityLayoutContextType>(defaultEntityLayoutContextValue);

type EntityLayoutContextProviderProps = PropsWithChildren<{}>;

export const EntityLayoutContextProvider = ({ children }: EntityLayoutContextProviderProps) => {
  const [contextValue, setContextValue] = useState<EntityLayoutContextType>(defaultEntityLayoutContextValue);

  const updateContext: EntityLayoutContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const dispatch = useDispatch();

  const { boltId = "" } = useParams();

  const { loading, entity, error } = useSelector(entitySelector);

  const { navbar__height, leftMenubar__width } = usePrivateLayoutContext();

  useEffect(() => {
    if (boltId) {
      dispatch(fetchEntityAction(boltId));
    }
  }, [boltId]);

  useEffect(() => {
    if (entity) {
      dispatch(setUserProfileAction(entity?.data));
    }
  }, [entity, dispatch]);

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      //
      boltId,
      //
      entity,
      //
      updateContext,
    };
  }, [contextValue, boltId, entity]);

  if (loading && !entity) {
    return <LoadingView visible sx={{ top: navbar__height, left: leftMenubar__width }} />;
  }

  if (!loading && error) {
    if (error.geoBlocked) {
      return <GeoBlocked sx={{ top: navbar__height, left: leftMenubar__width }} message={error.message} />;
    }
    return <PageNotFound sx={{ top: navbar__height, left: leftMenubar__width }} />;
  }

  if (entity?.type === "user" && !entity?.data?.streamProfile) {
    return <PrivateStreamerPage />;
  }

  return <EntityLayoutContext.Provider value={memoizedValue}>{children}</EntityLayoutContext.Provider>;
};

export const useEntityLayoutContext = () => {
  const context = useContext(EntityLayoutContext);

  return context;
};
