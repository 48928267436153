import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  create,
  ErrorType,
  isPlayerSupported,
  MediaPlayer,
  PlayerError,
  PlayerEventType,
  PlayerState,
} from "amazon-ivs-player";

type Props = {
  style?: any;
  height?: number | string;
  width?: number | string;
  playsinline?: boolean;
  controls?: boolean;
  url: string;
  muted?: boolean;
  isLowLatency?: boolean;
  playing: boolean;
  volume?: number;
  quality?: string;
  enablePip?: boolean;
  position?: number;
  enableFullScreen?: boolean;
  onReady?: () => void;
  onPlay?: () => void;
  onEnd?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onError?: (e: PlayerError) => void;
  onProgress?: ({ playedSeconds, loadedSeconds }: { playedSeconds?: number; loadedSeconds?: number }) => void;
  onDisablePIP?: () => void;
  onGetQualities?: (qualities: string[]) => void;
  onGetDuration?: (duration: number) => void;
  onDisableFullScreen?: () => void;
};

const WASM_WORKER = "https://cdn.boltplus.tv/libs/amazon/amazon-ivs-wasmworker.min.js";
const WASM_BINARY = "https://cdn.boltplus.tv/libs/amazon/amazon-ivs-wasmworker.min.wasm";

const IvsPlayer = forwardRef<HTMLVideoElement, Props>(
  (
    {
      style,
      height,
      width,
      playsinline,
      controls,
      url,
      muted = true,
      isLowLatency = false,
      playing,
      volume,
      quality = "Auto",
      enablePip = false,
      position,
      enableFullScreen = false,
      onReady,
      onPlay,
      onEnd,
      onBuffer,
      onBufferEnd,
      onError,
      onProgress,
      onDisablePIP,
      onGetQualities,
      onGetDuration,
      onDisableFullScreen,
    },
    ref
  ) => {
    const [player, setPlayer] = useState<MediaPlayer>();
    const playerRef = useRef<HTMLVideoElement>(null);

    const loadAndPlay = () => {
      player?.setAutoplay(true);
      player?.setLiveLowLatencyEnabled(isLowLatency);
      if (volume) {
        player?.setVolume(volume);
      }
      player?.load(url);
    };

    const attachListeners = () => {
      player?.addEventListener(PlayerState.READY, () => {
        if (onReady) {
          onReady();
        }
      });

      player?.addEventListener(PlayerState.BUFFERING, () => {
        if (onBuffer) {
          onBuffer();
        }
      });

      player?.addEventListener(PlayerState.PLAYING, () => {
        if (onBufferEnd) {
          onBufferEnd();
        }
        if (onPlay) {
          onPlay();
          if (onGetQualities) {
            const tempQualities = player.getQualities();
            const filteredQualities = tempQualities.filter(
              (value, index, self) => index === self.findIndex((t) => t.name === value.name)
            );
            const qualities = filteredQualities.sort((a, b) => b.height - a.height).map((item) => item.name);
            onGetQualities(["Auto", ...qualities]);
          }
        }
      });

      player?.addEventListener(PlayerState.ENDED, () => {
        if (onBufferEnd) {
          onBufferEnd();
        }
        if (onEnd) {
          onEnd();
        }
      });

      player?.addEventListener(PlayerEventType.INITIALIZED, () => {
        loadAndPlay();
      });

      player?.addEventListener(PlayerEventType.ERROR, (error: PlayerError) => {
        const statusTooManyRequests = 429;
        if (error.type === ErrorType.NOT_AVAILABLE && error.code === statusTooManyRequests) {
          console.error("Concurrent-viewer limit reached", error);
        } else {
          console.error("ERROR", error);
        }
        if (onError) {
          onError(error);
        }
      });

      player?.addEventListener(PlayerEventType.DURATION_CHANGED, (duration: number) => {
        if (onGetDuration) {
          duration === Infinity ? onGetDuration(0) : onGetDuration(duration);
        }
      });

      player?.addEventListener(PlayerEventType.TIME_UPDATE, (playedSeconds: number) => {
        if (onProgress) {
          onProgress({ playedSeconds });
        }
      });

      player?.addEventListener(PlayerEventType.SEEK_COMPLETED, (playedSeconds: number) => {
        if (onProgress) {
          onProgress({ playedSeconds });
        }
      });

      player?.addEventListener(PlayerEventType.BUFFER_UPDATE, () => {
        if (onProgress) {
          onProgress({ loadedSeconds: player.getBuffered().end });
        }
      });

      playerRef.current?.addEventListener("leavepictureinpicture", () => {
        if (onDisablePIP) {
          onDisablePIP();
        }
      });

      playerRef.current?.addEventListener("fullscreenchange", () => {
        if (onDisableFullScreen && !enableFullScreen) {
          onDisableFullScreen();
        }
      });
    };

    useEffect(() => {
      if (!isPlayerSupported) {
        throw new Error("IVS Player is not supported in this browser");
      }

      const createAbsolutePath = (assetPath: string) => {
        return new URL(assetPath, document.URL).toString();
      };
      const player = create({
        wasmWorker: createAbsolutePath(WASM_WORKER),
        wasmBinary: createAbsolutePath(WASM_BINARY),
      });

      player.attachHTMLVideoElement(playerRef.current!);

      setPlayer(player);
    }, []);

    useEffect(() => {
      if (player) {
        attachListeners();
      }
    }, [player]);

    useEffect(() => {
      if (player && volume) {
        player.setVolume(volume);
      }
    }, [volume]);

    useEffect(() => {
      if (player && quality) {
        if (quality === "Auto") {
          player.setAutoQualityMode(true);
        } else {
          const qualities = player.getQualities();
          const selectedQuality = qualities.find((item) => item.name === quality);
          if (selectedQuality) {
            player.setQuality(selectedQuality);
          }
        }
      }
    }, [quality]);

    useEffect(() => {
      if (player) {
        playing ? player.play() : player.pause();
      }
    }, [playing]);

    useEffect(() => {
      if (playerRef) {
        if (enablePip) playerRef?.current?.requestPictureInPicture();
      }
    }, [enablePip]);

    useEffect(() => {
      if (player && position) {
        player.seekTo(position);
      }
    }, [position]);

    useEffect(() => {
      if (player && enableFullScreen) {
        playerRef.current?.requestFullscreen();
      }
    }, [enableFullScreen]);

    useEffect(() => {
      if (player && url) {
        loadAndPlay();
      }
    }, [url]);

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        ref={playerRef}
        style={style}
        playsInline={playsinline}
        height={height}
        width={width}
        controls={controls}
        muted={muted}
      />
    );
  }
);

export default IvsPlayer;
