import React from "react";
import { Button, Dialog, DialogActions, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface Props {
  title: string;
  submitFn?: () => void;
  cancelFn?: () => void;
  modalVisible: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  posting?: boolean;
}

export const ConfirmationModal = ({
  title,
  submitFn,
  cancelFn,
  submitLabel,
  cancelLabel,
  modalVisible,
  posting,
}: Props) => {
  return (
    <Dialog
      open={modalVisible}
      TransitionComponent={Transition}
      onClose={cancelFn}
      PaperProps={{
        sx: {
          borderRadius: "25px",
          padding: 3,
          minWidth: 300,
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          bgcolor: "#13162C",
          maxWidth: "revert",
          backdropFilter: "blur(25px)",
          boxShadow: "-5px 14px 19px rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "Poppins",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "27px",
          display: "flex",
          alignItems: "center",
          color: "#A3AED0",
        }}
      >
        {title}
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          sx={{
            background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
            px: 2,
            borderRadius: "15px",
            color: "#fff",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={submitFn}
          disabled={posting}
        >
          {submitLabel}
        </Button>
        <Button
          onClick={cancelFn}
          sx={{
            color: "#EB5757",
            textDecoration: "underline",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
