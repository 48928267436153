import { PropsWithChildren, ReactNode } from "react";
import { ButtonBase, Typography } from "@mui/material";

import { NavItemContainer } from "./NavItemContainer";

type BottomNavItemProps = PropsWithChildren<{
  icon?: ReactNode;
  label?: string;
  onClick?: () => void;
}>;

export const BottomNavItem = ({ children, icon = null, label = "", onClick }: BottomNavItemProps) => {
  return (
    <NavItemContainer>
      {children !== undefined ? (
        children
      ) : (
        <ButtonBase
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.5,
            width: "100%",
            height: "100%",
          }}
          onClick={onClick}
        >
          {icon}

          <Typography sx={{ fontSize: 11 }}>{label}</Typography>
        </ButtonBase>
      )}
    </NavItemContainer>
  );
};
