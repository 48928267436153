import { Box } from "@mui/material";
import React from "react";

interface Props {
  size?: number;
  thick?: number;
  percent?: number;
}

export const PulseChart = ({ size = 270, thick = 16, percent = 0 }: Props) => {
  const color = React.useMemo(() => {
    if (percent > 75) {
      return "#00FF6C";
    }
    if (percent > 50) {
      return "#FAFF00";
    }
    if (percent > 25) {
      return "#FF8A00";
    }
    return "#FF0000";
  }, [percent]);
  return (
    <Box
      className="pulse_chart"
      sx={{
        width: `${size}px`,
        height: `${size / 2}px`,
        position: "relative",
        fontSize: "22px",
        fontWeight: 600,
        overflow: "hidden",
        color: "green",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        boxSizing: "border-box",
        "&:after": {
          content: '""',
          width: `${size}px`,
          height: `${size}px`,
          border: `${thick}px solid`,
          borderColor: `rgba(0,0,0,0.15) rgba(0,0,0,0.15) ${color} ${color}`,
          position: "absolute",
          borderRadius: "50%",
          left: 0,
          top: 0,
          boxSizing: "border-box",
          transform: `rotate( calc( 1deg * ( -45 + ${percent} * 1.8 ) ) )`,
          animation: "fillAnimation 1s ease-in",
        },
      }}
    />
  );
};
