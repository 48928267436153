import { DiscoverPageProvider } from "./DiscoverPageContext";
import { DiscoverPageUi } from "./DiscoverPageUi";

export const DiscoverPage = () => {
  return (
    <DiscoverPageProvider>
      <DiscoverPageUi />
    </DiscoverPageProvider>
  );
};
