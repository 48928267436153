export enum NotificationType {
  USER_FOLLOW = "user_follow",
  STREAM_FEED_MENTION = "stream_feed_mention",
  STREAM_FEED_REACTION = "stream_feed_reaction",
  STREAM_FEED_COMMENT = "stream_feed_comment",
  STREAM_FEED_COMMENT_MENTION = "stream_feed_comment_mention",
  STREAM_FEED_COMMENT_REACTION = "stream_feed_comment_reaction",
  NFT_FEED_MENTION = "nft_feed_mention",
  NFT_FEED_REACTION = "nft_feed_reaction",
  NFT_FEED_COMMENT_MENTION = "nft_feed_comment_mention",
  NFT_FEED_COMMENT_REACTION = "nft_feed_comment_reaction",
  CHANNEL_MESSAGE_MENTION = "channel_message_mention",
  CHANNEL_FOLLOW = "channel_follow",
  NFT_MESSAGE_MENTION = "nft_message_mention",
  NFT_PROFILE_FOLLOW = "nft_profile_follow",
  RADIO_STATION_FOLLOW = "radio_station_follow",
  STREAM_MESSAGE_MENTION = "stream_message_mention",
  STREAM_LIVE = "stream_live",
  CHANNEL_LIVE = "channel_live",
}

export enum EntityType {
  // user notifications
  USER = "user",
  CHANNEL = "channel",
  // stream feed notifications
  STREAM_FEED = "stream_feed",
  STREAM_FEED_REACTION = "stream_feed_reaction",
  STREAM_FEED_COMMENT = "stream_feed_comment",
  STREAM_FEED_COMMENT_REACTION = "stream_feed_comment_reaction",
  // nft feed notifications
  NFT_FEED = "nft_feed",
  NFT_FEED_REACTION = "nft_feed_reaction",
  NFT_FEED_COMMENT = "nft_feed_comment",
  NFT_FEED_COMMENT_REACTION = "nft_feed_comment_reaction",
  // live chat message
  CHANNEL_MESSAGE = "channel_message",
  STREAM_MESSAGE = "stream_message",
  NFT_MESSAGE = "nft_message",
  // channel follow
  CHANNEL_FOLLOW = "channel_follow",
  NFT_PROFILE_FOLLOW = "nft_profile_follow",
  RADIO_STATION_FOLLOW = "radio_station_follow",
}

export enum NotifierType {
  USER = "user",
  CHANNEL = "channel",
  NFT_COMMNUITY = "nft_profile",
  SYSTEM = "system",
}
