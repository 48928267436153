import React from "react";
import { Tab } from "@mui/material";
import { styled } from '@mui/material/styles';

interface StyledTabProps {
  label: string;
};

export const CustomTab = styled((props: StyledTabProps) => <Tab disableRipple={false} {...props} />)(({ theme }) => ({
  textTransform: 'capitalize',
  fontWeight: 500,
  marginRight: 0,
  color: '#FFFFFF80',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: { minWidth: 0 },
  '&:hover': { color: '#FFFFFF', opacity: 1 },
  '&.Mui-selected': { color: '#FFFFFF', borderBottom: '2px solid #e8e8e8' },
  '&.Mui-focusVisible': { backgroundColor: '#d1eaff' }
}));
