import { Box, Typography, useTheme } from "@mui/material";

type OrderChipProps = {
  order: number;
  isSelected?: boolean;
};

export const OrderChip = ({ order, isSelected = false }: OrderChipProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 40,
        height: 40,
        border: `1px solid #fff`,
        bgcolor: isSelected ? theme.palette.primary.main : "#fff",
        color: isSelected ? "#fff" : "#000",
        px: 0.8,
        transition: theme.transitions.create(["all", "transform"]),
      }}
    >
      <Typography sx={{ fontFamily: "NFUltra-Regular", fontSize: 20, color: "inherit", fontWeight: 900 }}>
        #{order + 1}
      </Typography>
    </Box>
  );
};
