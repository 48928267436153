import React, { LegacyRef } from "react";
import { Typography } from "@mui/material";
import { FlexCol } from "./FlexCol";

type Props = {
  videoPermissions: boolean;
  canvasRef: LegacyRef<HTMLCanvasElement>;
};

export const StreamPreview = ({ videoPermissions, canvasRef }: Props) => {
  return (
    <FlexCol sx={{ width: "100%", height: "100%", mt: 2, display: "flex", overflow: "hidden" }}>
      <FlexCol sx={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
        <canvas
          ref={canvasRef}
          key="STREAM_PREVIEW_VIDEO"
          id="cam-video-preview"
          style={{ overflow: "hidden", height: "100%", width: "100%", position: "relative" }}
        />
        {!videoPermissions && (
          <Typography
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
          >
            To start streaming, allow access to your camera and microphone
          </Typography>
        )}
      </FlexCol>
    </FlexCol>
  );
};
