import { createAction } from "redux-actions";
import { START } from "../common";

export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";
export const JOIN_CHANNEL = "JOIN_CHANNEL";
export const LEAVE_CHANNEL = "LEAVE_CHANNEL";
export const RECEIVED_CHANNEL_MESSAGE = "RECEIVED_CHANNEL_MESSAGE";
export const RECEIVED_NOTIFICATION = "RECEIVED_NOTIFICATION";
export const FETCH_CHANNEL_MESSAGE_HISTORY = "FETCH_CHANNEL_MESSAGE_HISTORY";
export const SEND_CHANNEL_MESSAGE = "SEND_CHANNEL_MESSAGE";
export const SEND_TYPING_STATUS = "SEND_TYPING_STATUS";
export const SET_TYPERS = "SET_TYPERS";

export const connectSocket = createAction(CONNECT_SOCKET + START);
export const disonnectSocket = createAction(DISCONNECT_SOCKET);
export const joinChannel = createAction(JOIN_CHANNEL);
export const leaveChannel = createAction(LEAVE_CHANNEL);
export const fetchHistoryChannelComunity = createAction(FETCH_CHANNEL_MESSAGE_HISTORY + START);
export const sendMessageChannelCommunity = createAction(SEND_CHANNEL_MESSAGE + START);
export const sendTypingStatus = createAction(SEND_TYPING_STATUS);
export const setTypers = createAction(SET_TYPERS);
