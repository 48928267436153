import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { debounce } from "lodash";

import { apiClient } from "../../config";
import { CreateUserDto } from "../../dtos";
import { USER_GENDER } from "../../types";
import { FlexCol } from "../../components";
import { autoFillTextFieldSxProps, textSXProps, textFieldInputProps, SIGNUP_STEP } from "./authComps";

interface Props {
  setErrorMessage: (msg: string) => void;
  setStep: (flag: number) => void;
  setSubmitData: (data: CreateUserDto) => void;
}

const resolver = classValidatorResolver(CreateUserDto);

export const EmailSignupForm = (props: Props) => {
  const { setErrorMessage, setStep, setSubmitData } = props;

  const [isDuplicatedUsername, setIsDuplicatedUsername] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateUserDto>({
    resolver,
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      gender: USER_GENDER.MALE,
      subscribeEmail: true,
      allowUserTracking: false,
    },
  });

  const onSubmit = async (data: CreateUserDto) => {
    if (isDuplicatedUsername || emailExist) return;
    if (data.password === data.confirmPassword) {
      setErrorMessage("");
      setStep(SIGNUP_STEP.tos);
      setSubmitData(data);
    }
  };

  const checkUserName = useMemo(
    () =>
      debounce(async (username: string) => {
        try {
          const { data } = await apiClient.post("/auth/userNameCheck", { username: username?.trim() });
          setIsDuplicatedUsername(!!data.exists);
        } catch (error) {
          setIsDuplicatedUsername(false);
        }
      }, 500),
    []
  );

  const checkEmail = useMemo(
    () =>
      debounce(async (email: string) => {
        try {
          const { data } = await apiClient.post("/auth/emailExistCheck", { email: email?.trim() });
          setEmailExist(!!data.exists);
        } catch (error) {
          setEmailExist(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    const username = watch("username");
    if (username?.length > 5) {
      checkUserName(username);
    }
  }, [watch("username")]);

  useEffect(() => {
    const email = watch("email");
    if (email?.length > 2) {
      checkEmail(email);
    }
  }, [watch("email")]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ width: "100%" }}>
      <TextField
        required
        fullWidth
        size="small"
        placeholder="First Name *"
        autoFocus
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        {...register("firstName")}
        InputProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />

      <TextField
        required
        fullWidth
        size="small"
        placeholder="Last Name *"
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        {...register("lastName")}
        InputProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />

      <TextField
        required
        fullWidth
        type="email"
        size="small"
        placeholder="Email Address *"
        autoComplete="email"
        error={!!errors.email || emailExist}
        helperText={!!errors.email || emailExist ? errors.email?.message || "Email already exists." : ""}
        {...register("email")}
        InputProps={textFieldInputProps}
        InputLabelProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps, textTransform: "lowercase" }}
      />

      <TextField
        required
        fullWidth
        size="small"
        placeholder="Username *"
        error={!!errors.username || isDuplicatedUsername}
        helperText={
          !!errors.username || isDuplicatedUsername ? errors.username?.message || "Username already exists." : ""
        }
        {...register("username")}
        InputProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />

      <TextField
        required
        fullWidth
        size="small"
        placeholder="Password *"
        type="password"
        autoComplete="current-password"
        error={!!errors.password}
        helperText={errors.password?.message}
        {...register("password")}
        InputProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />

      <TextField
        required
        fullWidth
        size="small"
        placeholder="Re-enter Password *"
        type="password"
        error={
          (getValues().confirmPassword && getValues().password !== getValues().confirmPassword) ||
          !!errors.confirmPassword
        }
        helperText={
          getValues().password && getValues().password !== getValues().confirmPassword
            ? "Password doesn't match"
            : errors.confirmPassword?.message
        }
        {...register("confirmPassword")}
        InputProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />

      <FormControl fullWidth sx={{ mt: 2, display: "none" }} size="small">
        <InputLabel id="genderLabel">Gender</InputLabel>
        <Select
          label="Gender"
          labelId="genderLabel"
          id="gender"
          defaultValue={getValues().gender}
          {...register("gender")}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="non-binary">Non Binary</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        sx={{ mt: 2 }}
        componentsProps={{ typography: { variant: "tiny", sx: { mt: 2 } } }}
        label="Keep me in the loop! I wish to receive marketing and promotional materials from Bolt and its service providers."
        control={
          <Checkbox
            id="subscribeEmail"
            size="small"
            defaultChecked={getValues().subscribeEmail}
            {...register("subscribeEmail")}
          />
        }
      />
      <FormControlLabel
        componentsProps={{ typography: { variant: "tiny", sx: { mt: 2 } } }}
        sx={{ display: "none" }}
        label="Share my registration data with BOLT Global’s content providers for marketing purposes"
        control={<Checkbox size="small" id="allowUserTracking" {...register("allowUserTracking")} />}
      />
      <FlexCol sx={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 1, mb: 2, width: 120, height: 30, textTransform: "none", borderRadius: 4 }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Sign Up</Typography>
        </Button>
      </FlexCol>
    </Box>
  );
};
