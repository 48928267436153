import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useMotionValueEvent, useScroll } from "framer-motion";

import { useStepScrollContainerContext } from "../../../components";
import { useCollectionPageContext } from "../context";

type RowAnimationProps = PropsWithChildren<{
  debug?: boolean;
}>;

export const RowAnimation = ({ children, debug }: RowAnimationProps) => {
  const { scrollRef } = useStepScrollContainerContext();

  const { itemHeight, bottomOffset } = useCollectionPageContext();

  const rowRef = useRef<HTMLDivElement>(null);

  const snapRef = useRef<HTMLDivElement>(null);

  const [start, setStart] = useState(-1);

  const [opacity, setOpacity] = useState(1);

  const { scrollY } = useScroll({
    container: scrollRef,
  });

  useEffect(() => {
    const options = {
      root: scrollRef.current,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries: any) => {
      const [entry] = entries;

      if (entry.isIntersecting && start === -1 && itemHeight !== 0) {
        setStart(scrollRef.current?.scrollTop !== undefined ? scrollRef.current?.scrollTop : -1);
      }
    }, options);

    if (snapRef.current) observer.observe(snapRef.current);

    return () => {
      if (snapRef.current) observer.unobserve(snapRef.current);
    };
  }, [debug, start, itemHeight]);

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (start > -1) {
      setOpacity(1 - (latest - start) / ((rowRef.current?.clientHeight || 0) / 2));
    }
  });

  return (
    <Box ref={rowRef} sx={{ opacity, pointerEvents: opacity <= 0.5 ? "none" : "inherit" }}>
      {children}

      <Box
        ref={snapRef}
        sx={{
          scrollSnapAlign: "end",
          scrollSnapStop: "always",
          height: bottomOffset,
          mb: `-${bottomOffset}px`,
        }}
      />
    </Box>
  );
};
