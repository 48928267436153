import { HomePageContextProvider } from "./HomePageContext";
import { HomePageUi } from "./HomePageUi";

export const HomePage = () => {
  return (
    <HomePageContextProvider>
      <HomePageUi />
    </HomePageContextProvider>
  );
};
