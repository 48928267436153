import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import { isMobile } from "react-device-detect";

import { FlexCol } from "../FlexCol";
import { FeaturedItem } from "../../types";
import BoltPlayer from "../BoltPlayer";
import LiveStreamPlayer from "../LiveStreamPlayer";

interface Props extends StackedCarouselSlideProps {
  onClickCenterItem: (boltId: string) => void;
  slideHeight?: number;
}

const Slider = (props: Props) => {
  const { data, dataIndex, isCenterSlide, swipeTo, slideIndex } = props;
  const loaded = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (isCenterSlide) {
      setTimeout(() => {
        loaded.current = true;
      }, 1000);
    } else {
      setTimeout(() => {
        loaded.current = false;
      }, 1000);
    }
  }, [isCenterSlide]);

  const { thumbnail, boltId, title, url, description, avatar, type, startedAt }: FeaturedItem = data[dataIndex];

  const defaultHeight = isMobile ? 198 : 210;

  const height = props.slideHeight || defaultHeight;

  return (
    <Paper
      sx={{
        transition: "all 300ms ease",
        cursor: "pointer",
        width: "100%",
        height,
        position: "relative",
        ":hover": { transform: !isCenterSlide ? "scale(1.03)" : "none" },
        overflow: "hidden",
        borderRadius: 2.5,
        my: { xs: 1, sm: 2 },
      }}
      className="container"
      component="div"
      draggable={false}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: isCenterSlide ? 1 : 0.5,
          transition: "opacity 300ms ease",
        }}
      >
        <img
          style={{
            position: "absolute",
            objectFit: "cover",
            height: "100%",
            width: "100%",
            borderRadius: 10,
            zIndex: 0,
          }}
          src={thumbnail}
          alt="cover"
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
            bottom: "10%",
            ".live-counter": { top: "10%" },
          }}
        >
          {isCenterSlide && type === "channel" && <BoltPlayer url={url} isLive />}
          {isCenterSlide && type === "streamer" && <LiveStreamPlayer url={url} isLive title="" startDate={startedAt} />}
        </Box>
      </Box>
      <Box
        sx={{
          userSelect: "none",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          zIndex: 2,
          position: "absolute",
          opacity: isCenterSlide ? 1 : 0.9,
          transition: "opacity 300ms ease",
        }}
        onClick={() => {
          if (isCenterSlide) {
            if (loaded.current) {
              props?.onClickCenterItem(boltId);
            }
          } else {
            swipeTo(slideIndex);
          }
        }}
      >
        <Box>{/* <Typography>Live</Typography> */}</Box>
        <Paper sx={{ p: 1.5, display: "flex", borderRadius: 0 }}>
          <Avatar sx={{ height: 37, width: 37, mr: 1 }} src={avatar} />
          <FlexCol>
            <Box
              sx={{
                mt: -0.4,
                display: "-webkit-box",
                WebkitLineClamp: 1,
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: "600" }}>
                {title}
              </Typography>
            </Box>
            <Typography variant="tiny">{boltId}</Typography>
          </FlexCol>
        </Paper>
      </Box>
    </Paper>
  );
};

export default React.memo(Slider);
