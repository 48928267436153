import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios, { CancelTokenSource } from "axios";

import { apiClient } from "../../../config";
import { usePrivateNavbarContext } from "../../navbar/private-navbar";

type ChannelCategory = {
  _id: string;
  name: string;
  description: string;
  status: string;
  icon: string;
};

type Channel = {
  title: string;
  description: string;
  thumbnail: string;
  url: string;
  channelId: string;
  categories: ChannelCategory[];
  id: string;
};

type Category = {
  name: string;
  slug: string;
  thumbnails: {
    landscape: string;
    portrait: string;
  };
  icon: string;
  tags: string[];
  id: string;
  partner: {
    name: string;
    slug: string;
  };
};

type Profile = {
  username?: string;
  firstName?: string;
  lastName?: string;
  photoUrl?: string;
  id: string;
  isLive?: boolean;
  streamThumbnail?: string;
  streamUrl?: string;
};

type StreamsContextType = {
  channels: {
    data: Channel[];
    page: number;
    limit: number;
    total: number;
    type: string;
  };
  isLoadingChannels: boolean;
  //
  categories: {
    data: Category[];
    page: number;
    limit: number;
    total: number;
    type: string;
  };
  isLoadingCategories: boolean;
  //
  profiles: {
    data: Profile[];
    page: number;
    limit: number;
    total: number;
    type: string;
  };
  isLoadingProfiles: boolean;
  //
  updateContext: (payload: Partial<Omit<StreamsContextType, "updateContext">>) => void;
};

const defaultStreamsContextValue: StreamsContextType = {
  channels: {
    data: [],
    page: 1,
    limit: 0,
    total: 0,
    type: "",
  },
  isLoadingChannels: true,
  //
  categories: {
    data: [],
    page: 1,
    limit: 0,
    total: 0,
    type: "",
  },
  isLoadingCategories: true,
  //
  profiles: {
    data: [],
    page: 1,
    limit: 0,
    total: 0,
    type: "",
  },
  isLoadingProfiles: true,
  //
  updateContext: () => {},
};

export const StreamsContext = createContext<StreamsContextType>(defaultStreamsContextValue);

type StreamsContextProviderProps = PropsWithChildren<{}>;

let channelCancelToken: CancelTokenSource;
let categoryCancelToken: CancelTokenSource;
let profileCancelToken: CancelTokenSource;

export const StreamsContextProvider = ({ children }: StreamsContextProviderProps) => {
  const [contextValue, setContextValue] = useState<StreamsContextType>(defaultStreamsContextValue);

  const { searchKeyword } = usePrivateNavbarContext();

  const updateContext: StreamsContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const fetchChannels = useCallback(async () => {
    try {
      updateContext({ isLoadingChannels: true });

      if (typeof channelCancelToken !== typeof undefined) {
        channelCancelToken.cancel("Operation canceled due to new request.");
      }
      channelCancelToken = axios.CancelToken.source();
      const { data } = await apiClient.get(`/search?query=${searchKeyword}&type=channel`, {
        cancelToken: channelCancelToken.token,
      });

      updateContext({ channels: data });
    } catch (err) {
      // console.error("Error at fetchCategories: ", err);
    } finally {
      updateContext({ isLoadingChannels: false });
    }
  }, [searchKeyword]);

  const fetchCategories = useCallback(async () => {
    try {
      updateContext({ isLoadingCategories: true });

      if (typeof categoryCancelToken !== typeof undefined) {
        categoryCancelToken.cancel("Operation canceled due to new request.");
      }
      categoryCancelToken = axios.CancelToken.source();
      const { data } = await apiClient.get(`/search?query=${searchKeyword}&type=category`, {
        cancelToken: categoryCancelToken.token,
      });

      updateContext({ categories: data });
    } catch (err) {
      // console.error("Error at fetchCategories: ", err);
    } finally {
      updateContext({ isLoadingCategories: false });
    }
  }, [searchKeyword]);

  const fetchProfiles = useCallback(async () => {
    try {
      updateContext({ isLoadingProfiles: true });

      if (typeof profileCancelToken !== typeof undefined) {
        profileCancelToken.cancel("Operation canceled due to new request.");
      }
      profileCancelToken = axios.CancelToken.source();
      const { data } = await apiClient.get(`/search?query=${searchKeyword}&type=user`, {
        cancelToken: profileCancelToken.token,
      });

      updateContext({ profiles: data });
    } catch (err) {
      // console.error("Error at fetchProfiles: ", err);
    } finally {
      updateContext({ isLoadingProfiles: false });
    }
  }, [searchKeyword]);

  useEffect(() => {
    fetchChannels();
    fetchCategories();
    fetchProfiles();
  }, [fetchChannels, fetchCategories, fetchProfiles]);

  const memoizedValue = useMemo(() => {
    return { ...contextValue, updateContext };
  }, [contextValue]);

  return <StreamsContext.Provider value={memoizedValue}>{children}</StreamsContext.Provider>;
};

export const useStreamsContext = () => {
  const context = useContext(StreamsContext);

  const profiles = useMemo(() => {
    const liveNows = context.profiles.data.filter((item) => item.isLive === true);

    const rewinds = context.profiles.data.filter((item) => item.isLive === false);

    const users = context.profiles.data.filter((item) => item.isLive === undefined);

    return {
      liveNows,
      rewinds,
      users,
    };
  }, [context.profiles]);

  return { ...context, ...profiles };
};
