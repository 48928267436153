import { all, call, take, fork, put } from "redux-saga/effects";
import * as actions from "./actions";
import { fetchRates } from "../../services/freeCurrency";
import { FAIL, START, SUCCESS } from "../common";
import { apiClient } from "../../config";
import { createBinancePayOrder, createStripePayOrder } from "../../services";

function* fetchExchangeRates() {
  while (true) {
    yield take(actions.FETCH_EXCHANGE_RATE + START);
    const { data } = yield fetchRates();
    yield put({
      type: actions.FETCH_EXCHANGE_RATE + SUCCESS,
      payload: data,
    });
  }
}

function* createBinanceOrder() {
  while (true) {
    const { payload } = yield take(actions.CREATE_BINANCE_PAY_ORDER + START);
    try {
      const { data } = yield createBinancePayOrder(payload);
      yield put({
        type: actions.CREATE_BINANCE_PAY_ORDER + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.CREATE_BINANCE_PAY_ORDER + FAIL,
        payload: "Error: Failed to create an order",
      });
    }
  }
}

function* createBinancePayoutRequest() {
  while (true) {
    const { payload } = yield take(actions.CREATE_BINANCE_PAYOUT_REQUEST + START);
    const { community, id, requestData } = payload;
    try {
      const { data } = yield apiClient.post(`/${community}/dashboard/${id}/payout/binance`, requestData);
      yield put({
        type: actions.CREATE_BINANCE_PAYOUT_REQUEST + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.CREATE_BINANCE_PAYOUT_REQUEST + FAIL,
        payload: "Error: Failed to create an order",
      });
    }
  }
}

function* getBinanceAvailableBalance() {
  while (true) {
    const { payload } = yield take(actions.FETCH_BINANCE_AVAILABLE_BALANCE + START);
    const { community, id } = payload;
    try {
      const { data } = yield apiClient.get(`/${community}/dashboard/${id}/balance`);
      yield put({
        type: actions.FETCH_BINANCE_AVAILABLE_BALANCE + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_BINANCE_AVAILABLE_BALANCE + FAIL,
        payload: "Error: Failed to create an order",
      });
    }
  }
}

function* createStripeOrder() {
  while (true) {
    const { payload } = yield take(actions.CREATE_STRIPE_PAY_ORDER + START);
    try {
      const {
        data: { client_secret: clientSecret },
      } = yield createStripePayOrder(payload);

      yield put({
        type: actions.CREATE_STRIPE_PAY_ORDER + SUCCESS,
        payload: clientSecret,
      });
    } catch (error) {
      yield put({
        type: actions.CREATE_STRIPE_PAY_ORDER + FAIL,
        payload: "Error: Payment failed",
      });
    }
  }
}

export default function* paymentSaga() {
  yield all([
    fork(fetchExchangeRates),
    fork(createBinanceOrder),
    fork(createStripeOrder),
    fork(createBinancePayoutRequest),
    fork(getBinanceAvailableBalance),
  ]);
}
