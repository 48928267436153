import React, { PropsWithChildren, createContext, useMemo, useState } from "react";

import { PrivateLayoutContextType } from "./types";
import { defaultPrivateLayoutContextValue } from "./consts";
import { PrivateLayoutContext } from "./PrivateLayoutContext";

type PrivateLayoutProviderProps = PropsWithChildren<{}>;

export const PrivateLayoutProvider = ({ children }: PrivateLayoutProviderProps) => {
  const [contextValue, setContextValue] = useState<PrivateLayoutContextType>(defaultPrivateLayoutContextValue);

  const updatePrivateLayout: PrivateLayoutContextType["updatePrivateLayout"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      updatePrivateLayout,
    };
  }, [contextValue]);

  return <PrivateLayoutContext.Provider value={memoizedValue}>{children}</PrivateLayoutContext.Provider>;
};
