import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const getRadioStaionState = ({ radioStation }: RootState) => radioStation;

export const radioStationSelector = createSelector([getRadioStaionState], (radioStation) => {
  // quick fix for presentation
  const sortOrder = [
    "France Culture",
    "SoulSide Radio - Club",
    "Trace FM",
    "BBC Radio 5 Live",
    "MSNBC",
    "Kiss FM",
    "Hits Radio",
    "Bloomberg Radio",
    "Hot Hits",
    "Fox News",
    "Talk Sport",
    "ESPN Radio",
    "NBC Sports Radio",
    "Radio Jazz & Soul",
    "Coca Cola Radio",
    "KCRW",
    "Heart 104.9FM",
  ];

  const notInclude = radioStation.data
    .filter((item: any) => !sortOrder.includes(item.title))
    .sort((a: any, b: any) => {
      const titleA = a.title.toString();
      const titleB = b.title.toString();

      return titleA.localeCompare(titleB);
    });

  const includeAndSorted = radioStation.data
    .filter((item: any) => sortOrder.includes(item.title))
    .sort((a: any, b: any) => {
      const titleA = a.title;
      const titleB = b.title;

      return sortOrder.indexOf(titleA) - sortOrder.indexOf(titleB);
    });

  return {
    ...radioStation,
    data: [...includeAndSorted, ...notInclude],
  };
});

export const selectedRadioSelector = createSelector([getRadioStaionState], ({ selected }) => selected);
