import { Container, Link, Typography } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { useSelector } from "react-redux";

import { FlexCol } from "../../../components";
import { streamerSelector } from "../../../store/streamer/selector";
import { usePrivateLayoutContext } from "../../../layouts";

export const PrivateStreamerPage = () => {
  const {
    streamer: { data },
  } = useSelector(streamerSelector);

  const { navbar__height } = usePrivateLayoutContext();

  return (
    <Container>
      <FlexCol
        sx={{
          minHeight: `calc(100vh - ${navbar__height}px)`,
          justifyContent: "center",
          alignItems: "center",
          py: 6,
        }}
      >
        <LockPersonIcon sx={{ fontSize: 80, mb: 4 }} />

        <Typography variant="h6">This is a private access stream.</Typography>

        <Typography sx={{ mt: 2 }} variant="caption">
          Head{" "}
          <Link
            component="a"
            rel="noreferrer nofollow"
            className="mention"
            href={data?.subscriptionUrl || "https://boltplus.tv"}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            here
          </Link>{" "}
          to purchase access.
        </Typography>
      </FlexCol>
    </Container>
  );
};
