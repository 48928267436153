import * as React from "react";
import { SVGProps } from "react";

export const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 9.33337V16C3.5 16.4421 3.67559 16.866 3.98816 17.1786C4.30072 17.4911 4.72464 17.6667 5.16667 17.6667H15.1667C15.6087 17.6667 16.0326 17.4911 16.3452 17.1786C16.6577 16.866 16.8333 16.4421 16.8333 16V9.33337"
      stroke={props?.color || "#010101"}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4997 5.33333L10.1663 2M10.1663 2L6.83301 5.33333M10.1663 2V12.8333"
      stroke={props.color || "#010101"}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
