import React, { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Grid, Typography } from "@mui/material";

import { fetchChannelTweets } from "../../store/channel/actions";
import { channelTweetsSelector } from "../../store/channel/selector";
import { fetchStreamerTweetsAction } from '../../store/streamer/actions';
import { streamerTweetsSelector } from '../../store/streamer/selector';
import { EmptySkeleton } from "../../pages/home/EmptySkeleton";
import { FlexRow } from "../FlexRow";
import { PostFilter } from "../PostFilter";
import { TweetCard } from "./TweetCard";

type Props = {
  id: string;
  advertisement?: any;
  isUser?: boolean;
};

export const TweetsTab = ({ id, advertisement, isUser = false }: Props) => {
  const dispatch = useDispatch();
  const { loading: cLoading = false, data: cData = [], includes: cInclude, meta: cMeta } = useSelector(channelTweetsSelector(id));
  const { loading: uLoading = false, data: uData = [], includes: uInclude, meta: uMeta } = useSelector(streamerTweetsSelector(id));

  const loadChannelTweets = useCallback((data: any) => dispatch(fetchChannelTweets(data)), [dispatch]);
  const loadUserTweets = useCallback((data: any) => dispatch(fetchStreamerTweetsAction(data)), [dispatch]);

  const loading = isUser ? uLoading : cLoading;
  const data = isUser ? uData : cData;
  const includes = isUser ? uInclude : cInclude;
  const meta = isUser ? uMeta : cMeta;

  useEffect(() => {
    if (id) {
      isUser ? loadUserTweets({ twitterId: id }) : loadChannelTweets({ channelId: id });
    }
  }, [id]);

  const loadMore = () => {
    isUser ?
      loadUserTweets({ twitterId: id, nextPageToken: uMeta?.next_token })
      : loadChannelTweets({ channelId: id, nextPageToken: meta?.next_token });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 6, flexDirection: "column", display: "flex", minHeight: data.length === 0 ? 50 : 400 }} disableGutters>
      <FlexRow sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <FlexRow sx={{ justifyContent: "space-between", alignItems: "center", mb: 1.5, display: isUser ? "none" : "flex" }}>
            <Typography variant="subtitle1">Tweets</Typography>
            <PostFilter />
          </FlexRow>
          {!data?.length && !loading && <Typography variant="subtitle2" sx={{ textAlign: "center", mt: 1, color: "#FFFFFF80" }}>No posts</Typography>}
          <InfiniteScroll pageStart={1} loadMore={() => !loading && loadMore()} hasMore={!!meta?.next_token} useWindow>
            {data.map((item, index) => <TweetCard key={index} item={item} user={includes?.users ? includes?.users[0] : undefined} />)}
          </InfiniteScroll>
          {loading && <EmptySkeleton />}
        </Box>
        {advertisement?.link && (
          <Grid item md={2} lg={2} sx={{ mt: 6, ml: 2 }} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
            <a href={advertisement.link} target="_blank" rel="noreferrer">
              <img width="300px" src={advertisement.assetUrl} alt="Advertisement" />
            </a>
          </Grid>
        )}
      </FlexRow>
    </Container>
  );
};
