import { Typography, TypographyProps } from "@mui/material";
import React from "react";

type Props = {} & TypographyProps;

const TimeFromNow = ({ children, sx }: Props) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{ fontSize: 11, fontWeight: 300, lineHeight: "132.5%", color: "rgba(255, 255, 255, 0.5)", ...sx }}
    >
      {children}
    </Typography>
  );
};

export default TimeFromNow;
