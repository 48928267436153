import { Dispatch } from "redux";
import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import profileSaga from "./profile/saga";
import templateSaga from "./template/saga";
import paymentSaga from "./payment/saga";
import uiSaga from "./ui/saga";
import walletSaga from "./wallet/saga";
import nftCreatorSaga from "./nftCreator/saga";
import socketState from "./socket/saga";
import radioStationsSaga from "./radio/saga";
import feedSaga from "./feed/saga";
import feedDetailsSaga from "./feedDetails/saga";
import channelSage from "./channel/saga";
import streamerSaga from "./streamer/saga";
import searchSaga from "./search/saga";
import userSettingsSaga from "./settings/saga";
import notificationSaga from "./notification/saga";
import userStreamSaga from "./users/saga";
import entitySaga from "./entity/saga";
import categorySaga from "./categories/saga";

export default function* rootSaga(dispatch: Dispatch) {
  yield all([
    fork(templateSaga),
    fork(authSaga, dispatch),
    fork(profileSaga, dispatch),
    fork(paymentSaga),
    fork(uiSaga),
    fork(walletSaga, dispatch),
    fork(nftCreatorSaga),
    fork(socketState, dispatch),
    fork(radioStationsSaga),
    fork(feedSaga, dispatch),
    fork(feedDetailsSaga, dispatch),
    fork(channelSage),
    fork(streamerSaga, dispatch),
    fork(searchSaga),
    fork(userSettingsSaga),
    fork(notificationSaga),
    fork(userStreamSaga),
    fork(entitySaga),
    fork(categorySaga),
  ]);
}
// Note
