import { apiClient } from "../config";
import { ANALYTICS_TRACK_TYPE } from "../types";

export class BoltAnalytics {
  public static async watchRewind(rewindId?: string) {
    if (!rewindId) {
      return;
    }
    try {
      await apiClient.post(`/users/analytics/watchRewind/${rewindId}`);
    } catch (error) {
      console.error(error);
    }
  }

  public static async trackViews(body: {
    entityId: string;
    type: ANALYTICS_TRACK_TYPE;
    userId?: string;
    enabled?: boolean;
  }) {
    try {
      const { entityId, type, enabled } = body;
      if (!entityId || !type || !enabled) {
        return;
      }
      await apiClient.post(`/analytics/views`, body);
    } catch (error) {
      console.error(error);
    }
  }
}
