import { useState } from "react";
import { Link, Typography } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import useExternalLinkStopPropagation from "../hooks/useExternalLinkStopPropagation";

interface Props extends BoxProps {
  maxLine?: number;
  active?: boolean;
  backgroundColor?: string;
}

export const ReadMore = ({ children, active = false, maxLine = 10, backgroundColor, sx, ...rest }: Props) => {
  const [isHidden, setIsHidden] = useState(true);

  useExternalLinkStopPropagation();

  return (
    <Box {...rest} sx={{ position: "relative", backgroundColor, ...sx }}>
      <Box
        sx={
          isHidden && active
            ? { display: "-webkit-box", WebkitLineClamp: maxLine, overflow: "hidden", WebkitBoxOrient: "vertical" }
            : null
        }
      >
        {children}
      </Box>
      {active && (
        <Typography
          component={Link}
          sx={{
            color: "#1d9bf0",
            backgroundColor: backgroundColor || "background.paper",
            cursor: "pointer",
            textDecoration: "none",
          }}
          onClick={(e: any) => {
            e.stopPropagation();
            setIsHidden(!isHidden);
          }}
        >
          {isHidden ? "see more" : "see less"}
        </Typography>
      )}
    </Box>
  );
};
