import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, MenuProps, SxProps } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { PeriodOption, PeriodFilterType } from "../../types";

type Props = {
  sx?: SxProps;
  mode: PeriodOption,
  setMode: (mode: PeriodOption) => void,
  hasFullOption?: boolean
};

export const periodFullFilters: PeriodOption[] = [
  { label: "All", value: PeriodFilterType.all },
  { label: "Today", value: PeriodFilterType.today },
  { label: "Last week", value: PeriodFilterType.last_week },
  { label: "Last month", value: PeriodFilterType.last_month },
  { label: "This year", value: PeriodFilterType.this_year },
  { label: "Last year", value: PeriodFilterType.last_year }
];

export const periodPostFilters: PeriodOption[] = [
  { label: "Last week", value: PeriodFilterType.last_week },
  { label: "Last month", value: PeriodFilterType.last_month },
  { label: "This year", value: PeriodFilterType.this_year },
  { label: "Last year", value: PeriodFilterType.last_year }
];

export const PeriodSelect = ({ sx, mode, setMode, hasFullOption = true }: Props) => {

  const [isOpen, setIsOpen] = useState(false);

  const options = hasFullOption ? periodFullFilters : periodPostFilters;

  useEffect(() => {
    const handler = () => setIsOpen(false);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const handleFilterChange = (event: SelectChangeEvent) => {
    const temp = options.find((one) => one.value === event.target.value);
    setMode(temp!);
  };

  const menuProps: Partial<MenuProps> = {
    variant: 'menu',
    disableScrollLock: true,
  };

  return (
    <FormControl
      sx={{
        height: 40,
        minWidth: 140,
        borderRadius: 2,
        backgroundColor: '#282C46',
        marginBottom: { xs: 1 },
        alignSelf: { xs: "flex-start", sm: "flex-start", md: "center" },
        "& fieldset": { borderWidth: 0 },
        '& .MuiOutlinedInput-root': {
          '& .MuiSelect-select': { textAlign: 'start', fontSize: 14 },
          '&.Mui-focused fieldset': { borderWidth: 0 }
        },
        ...sx
      }}
      size='small' color='secondary'>
      <Select
        value={mode.value}
        open={isOpen}
        onChange={handleFilterChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        displayEmpty={false}
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={menuProps}
      >
        {options.map(({ label, value }, index) => <MenuItem key={index} value={value} sx={{ fontSize: 14 }}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  )
};
