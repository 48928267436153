import { useState } from "react";
import { Drawer, useMediaQuery } from "@mui/material";

import { ImmersivePanel } from "../../components";
import { usePrivateLayoutContext } from "../private-layout";
import { useImmersivePanelLayoutContext } from "./ImmersivePanelLayoutContext";

export const ImmersivePanelDrawer = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const {
    chatProps,
    immersivePanel__open,
    immersivePanel__variant,
    immersivePanel__width,
    updateContext: updateImmersivePanelLayout,
  } = useImmersivePanelLayoutContext();

  const [flexDirection, setFlexDirection] = useState("column-reverse");
  const isXs = useMediaQuery("(max-width:400px)");
  const isSm = useMediaQuery("(max-width:768px)");

  return (
    <Drawer
      anchor={isXs ? "bottom" : "right"}
      open={immersivePanel__open}
      onClose={() => {
        updateImmersivePanelLayout({ immersivePanel__open: false });
      }}
      variant={isXs ? "temporary" : "persistent"}
      hideBackdrop
      sx={{
        width: "100%",
        maxWidth: isXs || (isSm && !immersivePanel__open) ? 0 : immersivePanel__width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: { xs: "100%", sm: `${immersivePanel__width}px` },
          boxSizing: "border-box",
          pt: isXs ? "0" : `${navbar__height}px`,
          height: isXs ? "calc(100% - 256px)" : "100%",
          flexDirection,
        },
      }}
      PaperProps={{
        id: chatProps.containerId,
      }}
    >
      <ImmersivePanel
        chatProps={chatProps}
        onChangeTab={(value) => {
          setFlexDirection(value === 0 ? "column-reverse" : "column");
        }}
      />
    </Drawer>
  );
};
