import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userSelector } from "../../store/auth/selector";
import { BottomNavItem } from "./BottomNavItem";
import { ProfileSvg } from "./ProfileSvg";

export const ProfileNavItem = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const user = useSelector(userSelector);

  const isActive = useMemo(() => {
    return pathname === `/${user?.username}`;
  }, [pathname]);

  const onClick = () => {
    navigate(`/${user?.username}`);
  };

  return (
    <BottomNavItem
      icon={<ProfileSvg style={{ fontSize: 22, color: isActive ? theme.palette.primary.main : "white" }} />}
      label="Profile"
      onClick={onClick}
    />
  );
};
