// actions suffix

export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";
export const CANCEL = "_CANCEL";

export const GOOGLE = "_GOOGLE";
export const APPLE = "_APPLE";
export const EMAIL = "_EMAIL";
export const WALLET = "_WALLET";
