import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const MARK_AS_READ_NOTIFICATION = "MARK_AS_READ_NOTIFICATION";
export const MARK_ALL_READ_NOTIFICATION = "MARK_ALL_READ_NOTIFICATION";
export const FETCH_NOTIFICATION_UNREAD_COUNT = 'FETCH_NOTIFICATION_UNREAD_COUNT'

export const fetchNotificationsAction = createAction(FETCH_NOTIFICATIONS + START);
export const markAsReadNotification = createAction(MARK_AS_READ_NOTIFICATION + START);
export const markAllReadNotificationAction = createAction(MARK_ALL_READ_NOTIFICATION + START);
export const fetchNotificationUnreadCountAction = createAction(FETCH_NOTIFICATION_UNREAD_COUNT + START);