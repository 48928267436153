import { Dispatch } from "redux";
import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import {
  FeedCommentAddAction,
  FeedCommentAddReplyAction,
  FeedCommentLikeAction,
  FeedCommentsActionType,
  FeedCommentsReplyActionType,
  FeedDetailsActionType,
  FeedDetailsLikeAction,
  FEED_COMMENT_ADD,
  FEED_COMMENT_ADD_REPLY,
  FEED_COMMENT_LIKE,
  FEED_DETAILS_LIKE,
  FETCH_FEED_COMMENT,
  FETCH_FEED_COMMENT_REPLY,
  FETCH_FEED_DETAILS,
  STREAM_COMMENT_REACTION,
  STREAM_COMMENT_REACTION_REMOVE,
  STREAM_INDIVIDUAL_FEED_REACTION,
  STREAM_INDIVIDUAL_FEED_REACTION_REMOVE,
  STREAM_REPLY_REACTION,
  STREAM_REPLY_REACTION_REMOVE,
} from "./actions";

function* fetchFeedDetails() {
  while (true) {
    const { payload = {} } = yield take(FETCH_FEED_DETAILS + START);
    const { streamFeed = "" } = payload as FeedDetailsActionType;
    try {
      const { data } = yield apiClient.get(`/v2/feed/stream/detail/${streamFeed}`);
      yield put({
        type: FETCH_FEED_DETAILS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: FETCH_FEED_DETAILS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchFeedComments() {
  while (true) {
    const { payload = {} } = yield take(FETCH_FEED_COMMENT + START);
    const { streamFeed = "", limit = 3, initialCommentsLoading } = payload as FeedCommentsActionType;
    try {
      const { data } = yield apiClient.get(`/v2/feed/stream/comment/${streamFeed}?limit=${limit}`);
      yield put({
        type: FETCH_FEED_COMMENT + SUCCESS,
        payload: { data, initialCommentsLoading },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: FETCH_FEED_COMMENT + FAIL,
        payload: { error, initialCommentsLoading },
      });
    }
  }
}

function* fetchFeedCommentReply() {
  while (true) {
    const { payload = {} } = yield take(FETCH_FEED_COMMENT_REPLY + START);
    const { streamFeed = "", streamFeedComment = "", limit = 3, index } = payload as FeedCommentsReplyActionType;
    try {
      const { data } = yield apiClient.get(
        `/v2/feed/stream/comment/${streamFeed}/reply/${streamFeedComment}?limit=${limit}`
      );
      yield put({
        type: FETCH_FEED_COMMENT_REPLY + SUCCESS,
        payload: { data, index },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: FETCH_FEED_COMMENT_REPLY + FAIL,
        payload: { error, index },
      });
    }
  }
}

function* addStreamIndividualFeedReaction() {
  while (true) {
    const { payload } = yield take(STREAM_INDIVIDUAL_FEED_REACTION + START);
    const { streamFeedId, reaction, noApiCall } = payload;
    if (noApiCall) continue;
    try {
      yield apiClient.post(`/v2/feed/stream/${streamFeedId}/reaction`, { type: reaction });
      yield put({
        type: STREAM_INDIVIDUAL_FEED_REACTION + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_INDIVIDUAL_FEED_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamIndividualFeedReaction() {
  while (true) {
    const { payload } = yield take(STREAM_INDIVIDUAL_FEED_REACTION_REMOVE + START);
    const { streamFeedId, reaction, noApiCall } = payload;
    if (noApiCall) continue;
    try {
      yield apiClient.delete(`/v2/feed/stream/${streamFeedId}/reaction`, { data: { type: reaction } });
      yield put({
        type: STREAM_INDIVIDUAL_FEED_REACTION_REMOVE + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_INDIVIDUAL_FEED_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* addStreamCommentReaction() {
  while (true) {
    const { payload } = yield take(STREAM_COMMENT_REACTION + START);
    const { streamFeedCommentId, reaction } = payload;
    try {
      yield apiClient.post(`/v2/feed/stream/comment/${streamFeedCommentId}/reaction`, { type: reaction });
      yield put({
        type: STREAM_COMMENT_REACTION + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_COMMENT_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamCommentReaction() {
  while (true) {
    const { payload } = yield take(STREAM_COMMENT_REACTION_REMOVE + START);
    const { streamFeedCommentId, reaction } = payload;
    try {
      yield apiClient.delete(`/v2/feed/stream/comment/${streamFeedCommentId}/reaction`, { data: { type: reaction } });
      yield put({
        type: STREAM_COMMENT_REACTION_REMOVE + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_COMMENT_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* addStreamReplyReaction() {
  while (true) {
    const { payload } = yield take(STREAM_REPLY_REACTION + START);
    const { replyId, reaction } = payload;
    try {
      yield apiClient.post(`/v2/feed/stream/comment/${replyId}/reaction`, { type: reaction });
      yield put({
        type: STREAM_REPLY_REACTION + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_REPLY_REACTION + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* removeStreamReplyReaction() {
  while (true) {
    const { payload } = yield take(STREAM_REPLY_REACTION_REMOVE + START);
    const { replyId, reaction } = payload;
    try {
      yield apiClient.delete(`/v2/feed/stream/comment/${replyId}/reaction`, { data: { type: reaction } });
      yield put({
        type: STREAM_REPLY_REACTION_REMOVE + SUCCESS,
      });
    } catch (error) {
      yield put({
        type: STREAM_REPLY_REACTION_REMOVE + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* feedCommentAdd() {
  while (true) {
    const { payload } = yield take(FEED_COMMENT_ADD + START);
    const { feedId, commentText, draftContent, giphy, limit = 3 } = payload as FeedCommentAddAction;
    try {
      const { data } = yield apiClient.post(
        `/v2/feed/stream/comment/${feedId}/`,
        { text: commentText, draftContent, giphy },
        {}
      );
      yield put({
        type: FETCH_FEED_COMMENT + START,
        payload: { streamFeed: feedId, limit } as FeedCommentsActionType,
      });
      yield put({
        type: FEED_COMMENT_ADD + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: FEED_COMMENT_ADD + FAIL,
        payload: { request: payload, error },
      });
    }
  }
}

function* feedCommentAddReply() {
  while (true) {
    const { payload } = yield take(FEED_COMMENT_ADD_REPLY + START);
    const { feedId, commentId, replyText, draftContent, limit = 3, index } = payload as FeedCommentAddReplyAction;
    try {
      const params = new URLSearchParams();
      params.append("text", replyText);
      const { data } = yield apiClient.post(`/v2/feed/stream/comment/${feedId}/reply/${commentId}`, {
        text: replyText,
        draftContent,
      });
      yield put({
        type: FETCH_FEED_COMMENT_REPLY + START,
        payload: { streamFeed: feedId, streamFeedComment: commentId, limit, index } as FeedCommentsReplyActionType,
      });
      yield put({
        type: FEED_COMMENT_ADD_REPLY + SUCCESS,
        payload: { index, data },
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      // yield put({
      //   type: FEED_DETAILS_LIKE + FAIL,
      //   payload: { request: payload, error },
      // });
    }
  }
}

export default function* commentsSaga(dispatch: Dispatch) {
  yield all([
    fork(fetchFeedDetails),
    fork(fetchFeedComments),
    fork(fetchFeedCommentReply),
    fork(addStreamIndividualFeedReaction),
    fork(removeStreamIndividualFeedReaction),
    fork(addStreamCommentReaction),
    fork(removeStreamCommentReaction),
    fork(addStreamReplyReaction),
    fork(removeStreamReplyReaction),
    fork(feedCommentAdd),
    fork(feedCommentAddReply),
  ]);
}
