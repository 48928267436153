import React, { useEffect, useMemo, useState } from "react";
import { Button, Chip, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { ReactComponent as ActionActiveIcon } from "../../assets/svgs/studio/actionActive.svg";
import { ReactComponent as ActionInactiveIcon } from "../../assets/svgs/studio/actionInactive.svg";
import { ReactComponent as ActionControlIcon } from "../../assets/svgs/studio/actionControl.svg";
import { ReactComponent as ActionControlWhiteIcon } from "../../assets/svgs/studio/actionControlWhite.svg";
import { ReactComponent as CameraIcon } from "../../assets/svgs/studio/camera.svg";
import { ReactComponent as CameraWhiteIcon } from "../../assets/svgs/studio/cameraWhite.svg";
import { ReactComponent as ChatIcon } from "../../assets/svgs/studio/chat.svg";
import { ReactComponent as ChatWhiteIcon } from "../../assets/svgs/chatIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/svgs/studio/clock.svg";
import { ReactComponent as ClockWhiteIcon } from "../../assets/svgs/studio/clickWhite.svg";
import { ReactComponent as PlayColorIcon } from "../../assets/svgs/studio/play.svg";
import { ReactComponent as PencilIcon } from "../../assets/svgs/studio/pencil.svg";
import { ReactComponent as SaveIcon } from "../../assets/svgs/studio/save.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/studio/settings.svg";
import { ReactComponent as PopoutIcon } from "../../assets/svgs/popout-icon.svg";

import { FlexCol, FlexRow, HContainer, LayoutFilters, LayoutSelect, VContainer, Window } from "../../components";
import { NAV_BAR_HEIGHT } from "../../constants";
import { Formatter } from "../../utils";
import { StudioActivity } from "./StudioActivity";
import { StudioActions } from "./StudioActions";
import { ActionControls } from "./ActionControls";
import { StudioStream } from "./StudioStream";
import { BoltPlayerState, Channel } from "../../types";
import { StudioChat } from "./StudioChat";


export const Studio = () => {

  const [layout, setLayout] = useState(LayoutFilters[0]);
  const [showActivity, setShowActivity] = useState(true);
  const [showStream, setShowStream] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [showAction, setShowAction] = useState(true);
  const [showControl, setShowControl] = useState(true);
  const [muted, setMuted] = useState(false);
  const [isPlay, setPlay] = useState(true);
  const [time, setTime] = useState(0);
  const [playerState, setPlayerState] = useState<BoltPlayerState>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const open = Boolean(anchorEl)

  const videoData = {
    "_id": "6406f56faa9c51c78e7c3291",
    "user": "62da382b8fe74e1cf33e8686",
    "url": "https://d38ousqojhgkl4.cloudfront.net/streams/live_hkjL3btEJ0jskCzHD8DKLO9tzm6nTSeaC4IjqO7C-2023-3-7_8-27-26-317_adaptive.m3u8",
    "title": "Smile",
    "thumbnail": "https://d38ousqojhgkl4.cloudfront.net/previews/live_hkjL3btEJ0jskCzHD8DKLO9tzm6nTSeaC4IjqO7C-2023-3-7_8-27-26-317_finished.png",
    "views": 20,
    "duration": 3642,
    "protocol": "HLS",
    "createdAt": "2023-03-07T08:27:27.882Z",
    "updatedAt": "2023-03-07T08:27:27.882Z",
    "__v": 0,
    "id": "6406f56faa9c51c78e7c3291"
  };

  useEffect(() => {
    const handler = () => setAnchorEl(null);
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  useEffect(() => {
    setMuted(!showStream);
    setPlay(showStream);
  }, [showStream]);

  const streamTime = useMemo(() => {
    if (playerState?.playedSeconds) setTime(playerState?.playedSeconds);
    const temp = Formatter.getDuration(playerState?.playedSeconds || time);
    return <Chip size="small" sx={{ borderRadius: 1.5, color: 'white', textTransform: 'uppercase', fontSize: 12, fontWeight: 600 }} label={temp} />
  }, [playerState]);

  const checkActiveWin = () => { }

  const onPlay = () => { }

  const openSettingsMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const popupChat = () => {
    setAnchorEl(null);
  }

  return (
    <FlexCol
      sx={{
        minWidth: { xs: '100%', sm: '100%', md: `calc(100% - 65px)` },
        height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
        position: "absolute",
        left: { xs: 0, md: NAV_BAR_HEIGHT },
        p: 1.5,
        pt: 0.5,
        pb: { xs: 10, sm: 9.5, md: 1.5 },
      }}>
      <FlexRow sx={{ width: '100%', justifyContent: 'space-between', px: 1 }}>
        <FlexRow sx={{ alignItems: 'center' }}>
          <PlayColorIcon width={20} height={20} />
          <Typography sx={{ mx: 1, color: "#A3AED0", fontWeight: 600, }}>Stream Manager</Typography>
          <IconButton onClick={() => setShowActivity(!showActivity)}>{showActivity ? <ClockWhiteIcon /> : <ClockIcon />}</IconButton>
          <IconButton onClick={() => setShowStream(!showStream)}>{showStream ? <CameraWhiteIcon /> : <CameraIcon />}</IconButton>
          <IconButton onClick={() => setShowAction(!showAction)}>{showAction ? <ActionActiveIcon /> : <ActionInactiveIcon />}</IconButton>
          <IconButton onClick={() => setShowControl(!showControl)}>{showControl ? <ActionControlWhiteIcon /> : <ActionControlIcon />}</IconButton>
          <IconButton onClick={() => setShowChat(!showChat)}>{showChat ? <ChatWhiteIcon /> : <ChatIcon />}</IconButton>
        </FlexRow>
        <FlexRow sx={{ alignItems: 'center' }}>
          <IconButton><PencilIcon /></IconButton>
          <IconButton><SaveIcon /></IconButton>
          <LayoutSelect sx={{ ml: 1 }} mode={layout} setMode={setLayout} />
        </FlexRow>
      </FlexRow>
      <FlexRow sx={{ mt: 1, width: "100%", height: "100%", gap: 1.5 }}>
        <VContainer hasItem={showActivity || showStream || showAction || showControl}>
          <HContainer visible={showActivity || showStream}>
            <Window title="Activity" Icon={ClockWhiteIcon} link="home" visible={showActivity} onClose={() => setShowActivity(false)} sx={{ maxWidth: `${showStream ? "40%" : "100%"}`, minWidth: 300 }}>
              <StudioActivity />
            </Window>
            <Window
              title="Stream"
              Icon={CameraWhiteIcon}
              visible={showStream}
              onClose={() => setShowStream(false)}
              endItem={
                <>
                  {streamTime}
                  <Chip size="small" sx={{ ml: 1, borderRadius: 1.5, color: 'white', textTransform: 'uppercase', fontSize: 12, fontWeight: 600 }} label="Live" color="error" />
                </>
              }
            >
              <StudioStream channel={videoData as unknown as Channel} onPlay={onPlay} setPlayerState={setPlayerState} muted={muted} isPlay={isPlay} />
            </Window>
          </HContainer>

          <HContainer visible={showAction || showControl} sx={{ maxHeight: `${(showActivity || showStream) ? "35" : "100"}%` }}>
            <Window title="Actions" Icon={ActionActiveIcon} link="home" visible={showAction} onClose={() => setShowAction(false)} sx={{ maxWidth: `${showControl ? "300px" : "100%"}` }}>
              <StudioActions />
            </Window>
            <Window title="Action Controls" Icon={ActionControlWhiteIcon} visible={showControl} onClose={() => setShowControl(false)}>
              <ActionControls />
            </Window>
          </HContainer>
        </VContainer>
        <Window
          title="Chat"
          Icon={ChatWhiteIcon}
          visible={showChat}
          onClose={() => setShowChat(false)}
          sx={{ display: { xs: "none", sm: "none", md: showChat ? "block" : "none" }, width: "35%", maxWidth: "500px" }}
          endItem={<SettingsIcon id="setting-button" style={{ width: 16, height: 16 }} onClick={openSettingsMenu} />}
        >
          <StudioChat channelId="630bb3b2afdca98dcf0d64f0" />
        </Window>
      </FlexRow>
      <Menu
        anchorOrigin={{ vertical: 25, horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="setting-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': 'setting-button' }}
        disableScrollLock
      >
        <MenuItem onClick={popupChat}>
          <Button disableRipple sx={{ color: "white", p: 0 }} endIcon={<PopoutIcon />}>Popout Chat</Button>
        </MenuItem>
      </Menu>
    </FlexCol>
  )
}