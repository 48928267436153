import React from "react";
import { Typography, Button, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FlexCol, FlexRow } from "../../components";
import { AppLogo } from "./index";

interface Props {
  onBack?: () => void;
  onAgree: () => void;
}

export const PolicyForm = ({ onBack, onAgree }: Props) => {
  return (
    <FlexCol sx={{ width: "100%", height: "100%", pt: 5, alignItems: "center", justifyContent: "center" }}>
      <FlexRow sx={{ width: "90%" }}>
        <IconButton aria-label="back" onClick={onBack}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <AppLogo style={{ marginRight: '40px', flex: 1 }} />
      </FlexRow>
      <FlexCol sx={{ flexGrow: 1, alignItems: "center" }} height='400px'>
        <Typography sx={{ fontSize: 20, fontWeight: 600, mt: 2, mb: 1 }}>Privacy Policy</Typography>
        <Typography variant="caption">Date last revised: <strong>18 July 2022</strong></Typography>
        <FlexCol className="-webkit-chatbox-scrollbar chatbox-scrollbar" sx={{ mt: 1.5, px: { xs: 4, sm: 6 }, pb: 2, flexGrow: 1, overflowY: 'auto', minHeight: 0 }}>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ mr: 2 }}>1.</Typography>
            Introduction
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.1</Typography>
            <strong>BOLT GLOBAL MEDIA UK LTD</strong> and its affiliates (“we”, “us” or “BOLT Global) are technology-based companies that builds web3-enabled platforms and services for the next generation of the internet. BOLT Global operates three core businesses across web3 media and entertainment, self-custody non-fungible tokens (“NFT”) and digital asset wallet management, and a web3-commerce marketplace, which are known as Bolt+, BoltX, and BoltZap respectively.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>1.2</Typography>
            We are pleased to provide you with our Services which are accessible through our Platforms.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            “Platforms” 	means any and all platforms offered by BOLT Global, these include but are not limited to: Bolt+, BoltX, Bolt Zap and boltplus.tv. Any subsequent website, mobile application or software published by BOLT Global will also fall within this definition. Please note that this is not an exhaustive summary and the Platforms may be added or suspended at the discretion of BOLT Global.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            “Services”	means the services offered to you by BOLT Global on our Platforms. This includes Content, web3 media and entertainment, self-custody NFT and digital asset wallet management, and a web3-commerce marketplace, media campaigns, competitions, quizzes and any other services published by BOLT Global including those by third parties. Please note that this is not an exhaustive list and the Services may be added or suspended at the discretion of BOLT Global.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            “Content” 	means all audio, audio-visual, videos, entertainment or informational programming, music, games, chats, texts, posts, comments, messages, articles, photographs, images, graphics, creative works and all other content, information, and materials available on or through the Services, whether posted, uploaded, transmitted, shared, sent or otherwise made available by us, our licensors, vendors, and/or service providers, or by you, and/or other users or third parties, including any such content uploaded manually or bookmarked by you and/or other users. Some Content may be free or paid, depending on the Service options selected or subscribed by you. The term “User-Generated Content” shall refer to the Content posted, submitted, uploaded, transmitted, shared, communicated, sent or otherwise made available by you on our Platforms.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            This Privacy Policy applies to information obtained by us through your use of our Services (via our website, apps and other means and media made available by us) on our Platforms.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            This Privacy Policy does not apply to websites, apps, destinations, or other offerings that we do not own or control, including those linked to or from the Services. These include any and all payment getaways, cryptoasset services and partners which are listed on our Platforms.  If you submit Personal Information to any of those websites, your information is governed by their privacy policies.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            By registering for our Services or otherwise accessing, visiting or using the Services or any part thereof, you consent and agree to be bound by the terms of this Privacy Policy.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            For the purposes of the applicable data protection laws (the General Data Protection Regulation (Regulation (EU) 2016/679) (GDPR), together with any implementing legislation, in particular, the Data Protection Act 2018), BOLT GLOBAL MEDIA UK LTD is the ‘data controller’ of your personal information so collected by us or provided by you.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            This Privacy Policy explains what information we collect about you and why, what we do with that information, and how we handle that information.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.</Typography>
            Collection of your Personal Information
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.1</Typography>
            We have collected and may be collecting the following information:-

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Information that you could have provided about yourself such as your first and last name, postal address, email address, telephone number, user ID, and payment processing information (if relevant), gender, nationality and country of origin;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Information that you upload or contribute as a user on our Services including your User-Generated Content. Such User-Generated Content may contain your personal information, which we may collect and use for the purposes described herein;

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Information that are automatically collected by using cookies and other types of technology when you access our Services such as your browser type, IP address, device ID, domain names, cookie information, pages and links you visited, geo-location data, website or app activity data and preferences;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Information that we obtain from third parties and sources such as social media networks (when you access our Services through these platforms), where information such as your preferences of content viewed/uploaded on our Services may be collected;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Some of the payment information you provide through our platform will be saved for analytics and regulatory compliance. Any cryptoasset payment information may also be saved in order to comply with regulatory KYC/AML requirements; and

          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            Any and all KYC you provide to us will be retained for regulatory purposes.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            All such information shall be collectively referred to as “Personal Information”
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>2.2</Typography>
            If you have reason to believe that a child under the age of 18 or the legal age of majority has provided personal data to us through our Services, please contact us and we will endeavour to delete that information from our database. Otherwise, all personal data provided by a user shall be deemed to have been submitted by a user above 18 or of the legal age of majority or has obtained the relevant permission from their parent/guardian.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3.</Typography>
            How we collect your Personal Information
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>3.1</Typography>
            Your Personal Information are collected in several ways, including:-
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            when you register for our Services, create a profile or account with us;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            when you access and use our Services including the features and functionality thereof;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            when you participate in the activities on our Services;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            when you complete KYC procedures; and
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            when you contact us.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.</Typography>
            Use of your Personal Information
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.1</Typography>
            The Personal Information and other information which you provide or have provided for the use of the Services including any additional information you may subsequently provide, may be used and processed by us for the following purposes:-
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to assess and process your requests for and use of our Services;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to operate our Services and conduct the activities therein;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to communicate with you about our Services as well as products and services of our business partners;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to respond to your inquiries and requests for assistance, including account verification support, send our Services information and updates;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            o process or verify your payment transactions, if relevant;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            for the conduct of internal activities including any investigations, compliance or security purposes such as fraud,  market manipulation/cheating, crime detection and prevention;
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to comply with any regulatory, statutory or legal obligation imposed on us by any relevant authority (including for KYC/AML regulations);
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            For market research or analytics, so that we can improve our services to you; and
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            for such other purposes as set out in our Terms of Service.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>4.2</Typography>
            We also use Personal Information in particular, those automatically collected ones to analyse and use the information so that we can better understand how our users use or interact with our Services, to maintain and improve our Services, source potential customer leads and disclose aggregated statistics, analysis and trends regarding our Services. Such use includes:
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to remember your information for your future visits to or access to our services;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to monitor the effectiveness of advertising and marketing campaigns so that we can endeavour to provide you with more relevant and customised content, advertising and information;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to track your activities on our Services; and
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to update our apps on your system automatically.
          </Typography>

          <Typography variant="caption" sx={{ mt: 1 }}>
            (collectively "Purposes")
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.</Typography>
            Retention Period of Personal Information

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>5.1</Typography>
            Unless a longer retention period is permitted or required by law, we will only hold your Personal Information in our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy, or until you request that the information be deleted in accordance with your right of erasure.

          </Typography>


          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.</Typography>
            Personal Information Access And Disclosure
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.1</Typography>
            We may share your Personal Information within our group of companies and advisers, such as lawyers and accountants for the Purposes set out in Clause 4 above.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.2</Typography>
            We may also disclose your Personal Information:-
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to third parties for the Purposes set out in Clause 4 above such as our business partners and/or service providers, vendors and advisers (collectively “Service Providers”) to assist us in our business or operating needs. Such needs include hosting and maintenance of our website and app, communication with users, delivery/transmission of our Services, processing of payments (if any), customer support services and other advertising systems, tracking and analytics needs. These Service Providers may only access, process or store your Personal Information pursuant to our instructions and to perform their duties to us, and in accordance with applicable laws and regulations;

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to prospective investors or buyers of any part of our business or assets for the purposes of due diligence exercises (but we will not sell nor trade your Personal Information); and

          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>-</Typography>
            to government and law enforcement authorities if required by law or for health, safety and public interest reasons.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.3</Typography>
            Further, please be informed that if required for any of the foregoing Purposes, your Personal Information may be transferred to locations outside the United Kingdom or disclosed to our group of companies, Service Providers and other permitted persons, who may be located within or outside the United Kingdom.
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>6.4</Typography>
            We will employ appropriate safeguards and procedures with the third parties we share your Personal Information, to ensure that your Personal Information is treated by those third parties in a way that is consistent with data protection laws.
          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.</Typography>
            Data Security & Integrity
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.1</Typography>
            We are committed to taking reasonable and appropriate steps to protect the Personal Information that we hold from misuse, loss, or unauthorised access. We do this by using technical and organisational measures. These measures may vary based on the sensitivity of your Personal Information.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.2</Typography>
            Personal Information that we collect from you will be stored and backed up securely in an environmentally-controlled location. Only authorized people with user ID and password from BOLT Global have access to the information.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.3</Typography>
            You must remember to log off after accessing and/or using our Services and/or close the browser window after use so that no unauthorised party can have access. You must not, at any time, divulge your Personal Information (in particular your password and private keys) to any third party.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>7.4</Typography>
            However, while we do our best to protect your Personal Information, we cannot guarantee the security of information you provide to us over the internet or similar connection as the internet is not a secure medium, and communications over the internet may be subject to third-party interception. We also cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our technical or organisational safeguards and measures.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.</Typography>
            Your Rights

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.1</Typography>
            How To Update Your Personal Information. If you have registered or created an account on any part of our Services, you may be able to access, review, update, rectify, delete/erase your information or preferences, disable your account, or otherwise limit our use of information under the Settings page, if available. If no such mechanism is available, please contact us at contact@bolt.global. You will be required to provide your name, address and telephone number and specify the relevant changes you wish to make. Before making any changes, we may need to verify your identity before granting you access or making corrections to your information. Please note that where you have requested for deletion of any of the Personal Information, you may no longer be able to access or use our Services or certain features and functionality thereof.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.2</Typography>
            How to Opt-Out of Marketing Messages from Us. If you do not wish to receive future marketing materials from us, simply follow the unsubscribe instructions contained within the message you received, select your preferences under the Settings page, if available or contact us at the Contact details below. However, please note that you may continue to receive certain communications from us that are necessary for the Services, such as technical notices, updates, security alerts, and support and administrative service bulletins.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>8.3</Typography>
            If you are a past user who has registered for our Services or any part thereof, please be informed that we will continue to process your Personal Information in accordance with this Privacy Policy unless we hear otherwise from you. You may exercise your rights in respect of your Personal Information in the manner described above.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.</Typography>
            Use of Cookies
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.1</Typography>
            We may use analytical/performance cookies such as Google analytics. They allow us to recognise and count the number of users and to see how users move around our website and app when they are using it. Our websites and apps may contain third-party tracking and data collection and analytics tools from our Service Providers who may use cookies and other software in our Services to enable them to collect and analyse user and device-related data and information on our behalf. Such third parties may have access to and collect data and information about you, such as your device identifier, geo-location information, IP address, application usage, access and session times, and your viewing of and activity with advertising for the purpose of performing their services.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.2</Typography>
            If you want to check or change what types of cookies you accept, this can usually be done within your browser settings. Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.

          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>9.3</Typography>
            If you do not wish to receive cookies, you can reject them by opting out or modifying your browser settings.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.</Typography>
            Changes to this Policy
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>10.1</Typography>
            We may change or update this Privacy Policy from time to time, to comply with applicable laws, or to reflect users’ feedback. We encourage you to periodically review this Privacy Policy to be informed of how we are protecting your Personal Information.

          </Typography>

          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>11.</Typography>
            Contact
          </Typography>
          <Typography variant="caption" sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ mr: 2 }}>11.1</Typography>
            If you have any queries and complaints regarding this Privacy Policy or your data protection rights, please contact us at contact@bolt.global, or write to us at BOLT GLOBAL MEDIA UK LTD at SOHO Works 2, Television Centre 101 Wood Lane White City W12 7FR.
          </Typography>
        </FlexCol>
      </FlexCol>
      <FlexRow sx={{ px: 3, alignItems: "center", width: "100%", height: "80px", backgroundColor: '#171B3A', boxShadow: '0px -10px 24px 5px #11142D' }}>
        <Typography sx={{ fontSize: 12, fontWeight: 300, mr: 1, flex: 1 }}>I have read and hereby agree to BOLT's Privacy Policy.</Typography>
        <Button variant="contained" sx={{ height: 30, background: '#6535E9', px: 5, borderRadius: 4 }} onClick={onAgree}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: 14 }}>Accept</Typography>
        </Button>
      </FlexRow>
    </FlexCol>
  );
};
