import React from 'react';
import { Button, Typography } from '@mui/material';
import { EarningHistory } from '../../../../types';

type HistoryTabButtonProps = {
  title: EarningHistory;
  Icon: React.ElementType;
  isActive: boolean;
  isMobile?: boolean;
  onClick: () => void;
};

export const HistoryTabButton = ({ title, Icon, isActive, isMobile = false, onClick }: HistoryTabButtonProps) => {
  return (
    <Button
      variant={isActive ? "contained" : "text"}
      sx={[
        {
          justifyContent: 'space-between',
          borderRadius: { xs: "8px", md: "4px" },
          borderWidth: 0,
          mr: { xs: 0.5, sm: 1.3 },
          "&:hover": { bgcolor: "#FFFFFF1A" },
        },
        isMobile && { "&.MuiButton-textPrimary": { backgroundColor: { xs: "rgba(28, 32, 57, 0.5)", md: "transparent" } } },
        !isMobile && { bgcolor: isActive ? "#FFFFFF1A" : "transparent", px: 2 },
      ]}
      onClick={onClick}
    >
      {isMobile && <Icon width={20} height={20} color={isActive ? "white" : "#A3AED0"} />}
      {!isMobile && <Icon color={isActive ? "white" : "#A3AED0"} />}
      <Typography sx={{ ml: { xs: 0.6, md: 1 }, fontWeight: 600, fontSize: { xs: 12, md: 16 }, color: isActive ? "white" : "#A3AED0" }}>
        {title}
      </Typography>
    </Button>
  );
};