import React from "react";
import { Button, Stack, StackProps, Typography } from "@mui/material";
import { ReactComponent as RewindIcon } from "../assets/svgs/rewind.svg";

interface Props extends StackProps {}

export const RewindLabel = (props: Props) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{ alignItems: "center", bgcolor: "success.500", borderRadius: 1, px: 1, ...props.sx }}
    >
      <RewindIcon />
      <Typography sx={{ color: "white", fontSize: 8, fontWeight: 600, lineHeight: "17px" }}>
        REWIND
      </Typography>
    </Stack>
  );
};
