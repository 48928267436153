import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { MenuOpen, WebAsset } from "@mui/icons-material";
import { useEffect } from "react";

import { Popup } from "../../utils";
import { useImmersivePanelLayoutContext } from "./ImmersivePanelLayoutContext";

export const DrawerControls = () => {
  const theme = useTheme();
  const isSm = useMediaQuery("(max-width:768px)");
  const {
    immersivePanel__open,
    chatProps,
    updateContext: updateImmersivePanelLayout,
  } = useImmersivePanelLayoutContext();

  useEffect(() => {
    if (isSm) {
      updateImmersivePanelLayout({
        immersivePanel__open: true,
      });
    }
  }, [isSm]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 180,
        right: 0,
        zIndex: theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px 0 0 8px",
        overflow: "hidden",
      }}
    >
      {/* {immersivePanel__open && isSm ? null : ( */}
      <Button
        variant="contained"
        sx={{
          minWidth: 0,
          p: 0.8,
          borderRadius: 0,
        }}
        onClick={() => {
          updateImmersivePanelLayout({
            immersivePanel__open: !immersivePanel__open,
          });
        }}
      >
        <MenuOpen />
      </Button>
      {/* )} */}

      {immersivePanel__open && (
        <Button
          variant="contained"
          sx={{
            display: { xs: "none", md: "inline-flex" },
            minWidth: 0,
            p: 0.8,
            borderRadius: 0,
          }}
          onClick={() => {
            updateImmersivePanelLayout({
              immersivePanel__open: false,
            });

            Popup.handleChatPopup(chatProps);
          }}
        >
          <WebAsset />
        </Button>
      )}
    </Box>
  );
};
