import { handleActions } from "redux-actions";
import produce from "immer";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { RadioStation } from "../../types";

export interface RadioStationState {
  loading: boolean;
  success: boolean;
  error: any;
  data: Array<RadioStation>;
  selected?: RadioStation;
}

const initialState: RadioStationState = {
  loading: false,
  success: false,
  error: null,
  data: [],
};

const reducer = handleActions<RadioStationState, any>(
  {
    [actions.FETCH_RADIO_STATIONS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [actions.FETCH_RADIO_STATIONS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.data = payload;
      }),
    [actions.FETCH_RADIO_STATIONS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [actions.SET_SELECTED_STATION]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [actions.PLAY_NEXT_RADIO_STATION]: (state, { payload }) =>
      produce(state, (draft) => {
        const index = state.data.findIndex(({ id }) => id === payload);
        if (index !== -1 && state.data[index + 1]) {
          draft.selected = state.data[index + 1];
        }
      }),

    [actions.FETCH_TRENDING_RADIO_STATIONS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [actions.FETCH_TRENDING_RADIO_STATIONS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.data = payload;
      }),
    [actions.FETCH_TRENDING_RADIO_STATIONS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
  },
  initialState
);

export default reducer;
