import { Avatar, Box, Paper, LinearProgress, Typography, Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { EditorState } from "draft-js";

import { getVideoCover } from "../utils";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";
import { ReactComponent as ImageIcon } from "../assets/svgs/image.svg";
import { ReactComponent as BoltIcon } from "../assets/svgs/boltIcon.svg";
import { ToastService } from "../services";
import { uploadProgressSelector } from "../store/ui/selector";
import { DefaultAvatar } from "./DefaultAvatar";
import BoltMentionInput from "./BoltMentionInput";
import { GiphyObject } from "../types";
import { GiphyIcon } from "../assets/icons/GiphyIcon";
import { GiphyModal } from "./modals/GiphyModal";

type Props = {
  placeHolder?: string;
  loading?: boolean;
  avatarUrl?: string;
  showAvatar: boolean;
  resetCreator?: number;
  onSubmit?: (data: {
    files?: File[];
    text?: string;
    draftContent?: string;
    giphy?: GiphyObject;
    uploadId?: number;
  }) => void;
  disabled?: boolean;
};

export const PostBar = ({
  placeHolder = "Write something",
  loading = false,
  disabled = false,
  showAvatar,
  avatarUrl,
  resetCreator = 0,
  onSubmit = () => {},
}: Props) => {
  const [text, setText] = useState("");
  const [draftContent, setDraftContent] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [focused, setFocused] = useState(false);
  const [dragMode, setDragMode] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileUris, setFileUris] = useState<string[] | undefined>();
  const [uploadId, setUploadId] = useState<number | undefined>();
  const [showGiphyModal, setShowGiphyModal] = useState(false);
  const inputRef = useRef<DropzoneRef>(null);

  const uploadProgress = useSelector(uploadProgressSelector);

  useEffect(() => {
    setText("");
    setFiles([]);
    setDragMode(false);
    setUploadId(undefined);
  }, [resetCreator]);

  // const ffmpeg = createFFmpeg({
  //   log: true,
  //   corePath: "/ffmpeg/ffmpeg-core.js",
  // });

  // const convertVideo = async (url: string) => {
  //   await ffmpeg.load();
  //   ffmpeg.FS("writeFile", "test.avi", await fetchFile(url));
  //   await ffmpeg.run(
  //     "-i",
  //     "test.avi",
  //     "-codec:v",
  //     "libx264",
  //     "-pix_fmt",
  //     "yuv420p",
  //     "-b:v",
  //     "6000k",
  //     "-bufsize",
  //     "6000k",
  //     "-preset",
  //     "ultrafast",
  //     "test.mp4"
  //   );
  //   const data = ffmpeg.FS("readFile", "test.mp4");
  //   return blobToFile(new Blob([data.buffer], { type: "video/mp4" }), "test.mp4");
  // };

  const getThumbnails = useCallback(async (data: File[]) => {
    const uriArray: string[] = [];
    const promiseArray: Promise<unknown>[] = [];
    data.forEach((file) => {
      if (!file.type.includes("image")) {
        promiseArray.push(getVideoCover(file, 1));
      }
    });
    const results = await Promise.all(promiseArray);
    let videoIndex = results.length ? 0 : -1;
    data.forEach(async (file) => {
      let fileUrl = "";
      if (file.type.includes("image")) {
        fileUrl = URL.createObjectURL(file);
      } else if (videoIndex !== -1) {
        const blob = results[videoIndex];
        fileUrl = URL.createObjectURL(blob as Blob);
        videoIndex += 1;
      }
      if (fileUrl) {
        uriArray.push(fileUrl);
      }
    });
    setFileUris(uriArray);
  }, []);

  useEffect(() => {
    if (files.length) {
      getThumbnails(files);
    } else {
      setFileUris(undefined);
    }
  }, [files, getThumbnails]);

  const handlePaste = React.useCallback((files: Blob[]) => {
    if (files?.length) {
      const blob = files[0];
      setFiles((prev) => [...prev, blob as File]);
      return "handled";
    }
    return "not-handled";
  }, []);

  const handleSubmit = useCallback(async () => {
    if ((!text.trim() && !files.length) || loading) {
      return;
    }
    const problemIndex = files.findIndex((file) => {
      if (file?.size >= 100000000) {
        return true;
      }
      return false;
    });
    if (problemIndex !== -1) {
      ToastService.showErrorMessage(
        "One or more file(s) is too large and cannot be uploaded. Please reduce the size for the file and try again."
      );
      return;
    }
    const uploadTempId = Date.now();
    if (files.length) {
      setUploadId(uploadTempId);
    }
    onSubmit({
      files,
      text: text === placeHolder ? "" : text,
      draftContent,
      uploadId: files.length ? uploadTempId : undefined,
    });
  }, [files, text, draftContent]);

  const verticalMode = focused || dragMode;

  // const processFile = async (data: File) => {
  //   if (data.type.includes("image")) {
  //     setFile(data);
  //   } else {
  //     const newFile = await convertVideo(URL.createObjectURL(data));
  //     getThumbnail(newFile);
  //     setFile(newFile);
  //   }
  // };

  return (
    <>
      <Dropzone
        ref={inputRef}
        multiple
        maxSize={1024 * 1024 * 100} // 100MB limit
        accept={{ "image/*": [".mov", ".mp4", ".avi"] }}
        onDrop={(items) => {
          if (items.length + files.length <= 4) {
            setFiles((prevItems) => [...prevItems, ...items]);
          } else {
            ToastService.showErrorMessage("Max 4 files can be selected!");
          }
        }}
        noClick
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => (
          <Box
            component={Paper}
            sx={{
              p: 2,
              borderRadius: 4,
              borderStyle: isDragActive ? "dashed" : "none",
              borderWidth: 2,
              borderColor: "primary.main",
              position: "relative",
              backgroundColor: "#282C46",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FlexRow sx={{ flex: 1, flexDirection: verticalMode || isDragActive || !!files.length ? "column" : "row" }}>
              <FlexRow sx={{ flex: 1, alignItems: "flex-start", zIndex: 1 }}>
                {showAvatar && (
                  <FlexRow sx={{ alignItems: "center", mr: 2, mt: 0.4 }}>
                    <Avatar
                      variant="circular"
                      src={avatarUrl}
                      alt=""
                      sx={{ width: 32, height: 32, borderColor: "primary.main", borderWidth: 1, borderStyle: "solid" }}
                    >
                      <DefaultAvatar />
                    </Avatar>
                  </FlexRow>
                )}
                <FlexRow sx={{ flex: 1, alignItems: "center" }} className="post-bar-input">
                  <BoltMentionInput
                    placeholder={placeHolder}
                    resetCreator={resetCreator}
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      // setTimeout(() => {
                      //   if (!text.trim()) {
                      //     setText("");
                      //   }
                      //   setFocused(false);
                      // }, 300);
                    }}
                    handlePastedFiles={handlePaste}
                    onChangeText={(value: string) => setText(value)}
                    onChangeDraftContent={setDraftContent}
                    onChangeEditorState={setEditorState}
                  />
                </FlexRow>
              </FlexRow>
              <FlexRow
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  ml: verticalMode || isDragActive || !!files.length ? 6 : 2,
                  mt: verticalMode || isDragActive || !!files.length ? 2 : 0,
                }}
              >
                <FlexRow
                  sx={{
                    cursor: "pointer",
                    p: dragMode ? 2 : 0,
                    borderRadius: 4,
                    borderStyle: dragMode ? "dashed" : "none",
                    borderWidth: 2,
                    borderColor: "primary.main",
                    alignItems: "center",
                    flex: 1,
                  }}
                  onClick={() => {
                    if (!disabled) {
                      if (dragMode) {
                        open();
                      } else {
                        setDragMode(true);
                      }
                    }
                  }}
                >
                  {!files.length && (
                    <>
                      <ImageIcon width="20" height="20" />
                      {dragMode && (
                        <FlexCol sx={{ pl: 2 }}>
                          <Typography variant="subtitle2" fontWeight="600">
                            Click to add media
                          </Typography>
                          <Typography variant="caption" fontSize={10}>
                            or Drag & Drop
                          </Typography>
                        </FlexCol>
                      )}
                    </>
                  )}
                  {!!files.length && (
                    <FlexRow sx={{ gap: 1 }}>
                      {fileUris?.map((fileUri, index) => (
                        <Box key={fileUri} sx={{ position: "relative" }}>
                          <Avatar variant="rounded" src={fileUri} />
                          <FlexRow
                            sx={{
                              position: "absolute",
                              top: -8,
                              right: -8,
                              p: 0,
                              bgcolor: "primary.dark",
                              width: 20,
                              height: 20,
                              borderRadius: 20,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={(e) => {
                              if (!disabled) {
                                e.preventDefault();
                                e.stopPropagation();
                                setFiles((prevFiles) => {
                                  const newFiles = [...prevFiles];
                                  newFiles.splice(index, 1);
                                  return newFiles;
                                });
                              }
                            }}
                          >
                            <CloseIcon sx={{ width: 16, height: 16 }} />
                          </FlexRow>
                        </Box>
                      ))}
                    </FlexRow>
                  )}
                </FlexRow>
                {verticalMode && !files.length && (
                  <GiphyIcon
                    height={40}
                    style={{ cursor: "pointer", marginLeft: 8 }}
                    onClick={(e) => {
                      setShowGiphyModal(true);
                    }}
                  />
                )}
                <FlexCol>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 26, px: 2, ml: 2, borderRadius: 13 }}
                    onClick={handleSubmit}
                    disabled={disabled}
                  >
                    <Typography variant="caption" sx={{ fontWeight: "600", mr: 0.5 }}>
                      Bolt it
                    </Typography>
                    <BoltIcon width={13} height={13} />
                  </Button>
                </FlexCol>
              </FlexRow>
            </FlexRow>
            {loading && (
              <FlexCol
                sx={{
                  position: "absolute",
                  inset: 0,
                  bgcolor: "#00000080",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 10,
                }}
              >
                {uploadProgress && uploadId && uploadProgress[uploadId] && (
                  <>
                    <LinearProgress
                      variant="determinate"
                      color="warning"
                      value={uploadProgress[uploadId] || 0}
                      sx={{ width: "80%", borderRadius: 4 }}
                    />
                    <Typography sx={{ mt: 1 }}>Uploading file...</Typography>
                  </>
                )}
              </FlexCol>
            )}
          </Box>
        )}
      </Dropzone>
      <GiphyModal
        open={showGiphyModal}
        onClose={() => setShowGiphyModal(false)}
        initialEditorState={editorState}
        onSelectItem={(data) => {
          setShowGiphyModal(false);
          onSubmit({ ...data });
        }}
      />
    </>
  );
};
