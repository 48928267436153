import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SxProps } from "@mui/material";

import { apiClient } from "../../config";
import { flashbacks } from "../../assets/pngs/flashback/carousels";

type IFlashBackItem = {
  image: string;
  isStreamer: boolean;
  id: string;
  sxProps?: SxProps;
};

type FlashbackContextProviderValues = {
  loading: boolean;
  data: any;
};

const defaultValues: FlashbackContextProviderValues = {
  loading: false,
  data: undefined,
};

export interface FlashbackContextType {
  flashbacks: IFlashBackItem[];
  values: FlashbackContextProviderValues;
  setValues: (payload: Partial<FlashbackContextProviderValues>) => void;
  getCardInfo: (id: string) => { value: string; data?: any[] };
}

const defaultFlashbackContextValue: FlashbackContextType = {
  flashbacks,
  values: defaultValues,
  setValues: () => {},
  getCardInfo: () => ({ value: "" }),
};

export const FlashbackContext = React.createContext(defaultFlashbackContextValue);

interface FlashbackContextProviderInterface {}

const formatCountValue = (value: number = 0) => value?.toLocaleString();
const formatMinutesValue = (value: number = 0) => Math.round(value / 60)?.toLocaleString();
const formatHoursValue = (value: number = 0) => (Math.round(value / 60 / 60) || 1)?.toLocaleString();
const formatDaysValue = (value: number = 0) => {
  if (value < 60 * 60 * 24) {
    const hours = Math.round(value / 60 / 60);
    return `${hours?.toLocaleString()} Hour${hours > 1 ? "s" : ""}`;
  }
  const days = Math.round(value / 60 / 60 / 24);
  return `${days?.toLocaleString()} Day${days > 1 ? "s" : ""}`;
};

export const FlashbackContextProvider = ({ children }: React.PropsWithChildren<FlashbackContextProviderInterface>) => {
  const [values, setValues] = React.useState<FlashbackContextProviderValues>(defaultValues);
  const { username } = useParams();
  const navigate = useNavigate();

  const updateValues = React.useCallback(
    (payload: Partial<FlashbackContextProviderValues>) => setValues((prev) => ({ ...prev, ...payload })),
    []
  );

  const getFlashback = React.useCallback(async (username: string) => {
    try {
      updateValues({ loading: true });
      const { data } = await apiClient.get(`/flashback/${username}`);
      updateValues({ data, loading: false });
    } catch (error) {
      navigate("/home");
      updateValues({ loading: false });
    }
  }, []);

  const filteredFlashbacks = React.useMemo(() => {
    const { data } = values;
    const isStreamer = data?.streams > 0;
    return flashbacks?.filter((item) => (isStreamer ? true : !item.isStreamer));
  }, [values.data, flashbacks]);

  const getCardInfo = React.useCallback(
    (flashbackId: string) => {
      if (flashbackId === "total_mins") {
        return { value: formatMinutesValue(values?.data?.duration) };
      }
      if (flashbackId === "total_days") {
        return { value: formatDaysValue(values?.data?.duration) };
      }
      if (flashbackId === "most_views") {
        return { value: formatCountValue(values?.data?.maxViews) };
      }
      if (flashbackId === "total_chats") {
        return { value: formatCountValue(values?.data?.liveChats) };
      }
      if (flashbackId === "total_stream_impressions") {
        return { value: formatCountValue(values?.data?.views) };
      }
      if (flashbackId === "total_unique_visitors") {
        return { value: formatCountValue(values?.data?.uniqueMembers) };
      }
      if (flashbackId === "top_users") {
        return { value: "", data: values?.data?.topMembers || [] };
      }
      if (flashbackId === "max_duration") {
        return { value: formatHoursValue(values?.data?.maxDuration) };
      }
      if (flashbackId === "earning") {
        return { value: formatCountValue(values?.data?.bolt) };
      }
      if (flashbackId === "pulse_score") {
        return { value: formatCountValue(values?.data?.pulseScore) };
      }
      if (flashbackId === "final_share_info") {
        return { value: values?.data?.shareLink };
      }

      return { value: "" };
    },
    [filteredFlashbacks, values.data]
  );

  React.useEffect(() => {
    if (username) {
      getFlashback(username);
    }
  }, [username]);

  const value = React.useMemo(
    () => ({
      values,
      flashbacks: filteredFlashbacks,
      setValues: updateValues,
      getCardInfo,
    }),
    [values]
  );

  return <FlashbackContext.Provider value={value}>{children}</FlashbackContext.Provider>;
};

export const useFlashback = () => {
  return React.useContext(FlashbackContext);
};
