import { PropsWithChildren } from "react";
import { Box, SxProps } from "@mui/material";

import { useStepScrollContainerContext } from "./StepScrollContainerContext";

type StepScrollContainerUiProps = PropsWithChildren<{
  containerSx?: SxProps;
}>;

export const StepScrollContainerUi = ({ children, containerSx = {} }: StepScrollContainerUiProps) => {
  const { isMounted, scrollRef } = useStepScrollContainerContext();

  return (
    <Box
      ref={scrollRef}
      sx={{
        height: "100vh",
        lineHeight: 0,
        position: "relative",
        overflow: "auto",
        scrollSnapType: "y mandatory",
        ...containerSx,
      }}
    >
      {isMounted ? children : null}
    </Box>
  );
};
