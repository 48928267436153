import React from "react";
import { Button, ModalProps, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";
import { BaseWithdrawModal } from "./BaseWithdrawModal";
import { FlexCol } from "../../FlexCol";

interface Props extends Partial<ModalProps> {}

export const WithdrawFailureModal = ({ ...rest }: Props) => {
  const { values, resetContext, setValues } = useWithdraw();

  return (
    <BaseWithdrawModal
      {...rest}
      showTitle={false}
      open={values.modal === WithdrawModalType.WITHDRAW_FAIL}
      onClose={resetContext}
    >
      <FlexCol alignItems="center" sx={{ pb: 2, mt: -2 }}>
        <FlexCol sx={{ backgroundColor: "#EB5757", borderRadius: "100%" }}>
          <CloseRoundedIcon sx={{ color: "white", borderRadius: "100%", width: 40, height: 40 }} />
        </FlexCol>
        <Typography sx={{ color: "#A3AED0", fontWeight: 600, mt: 1 }}>Withdrawal Failed</Typography>
        <Typography variant="tiny" color="error" sx={{ mt: 1 }}>
          {values?.errors?.message || ""}
        </Typography>
        <FlexCol alignItems="center" sx={{ mt: 3 }}>
          <FlexCol>
            <Button
              variant="contained"
              sx={{ borderRadius: 40, width: "50%", height: 24 }}
              onClick={() => setValues({ modal: WithdrawModalType.CODE_VERIFY })}
            >
              Retry
            </Button>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </BaseWithdrawModal>
  );
};
