import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  WithdrawConfirmModal,
  WithdrawFailureModal,
  WithdrawFormModal,
  WithdrawMFABackupCodeModal,
  WithdrawMFACodeModal,
  WithdrawMFASetupModal,
  WithdrawMFASetupSuccessModal,
  WithdrawProcessingModal,
  WithdrawSuccessModal,
} from "../components/modals";
import { apiClient } from "../config";
import { BoltError, ToastService } from "../services";
import { syncUserAction } from "../store/auth/actions";
import { userSelector } from "../store/auth/selector";
import { earningSelector } from "../store/users/selector";
import { BinanceDestination, WithdrawContextProviderValues, WithdrawModalType, WithdrawType } from "../types";

const defaultValues: WithdrawContextProviderValues = {
  secureWithdraw: false,
  withdrawToken: "",
  verifyCode: "",
  backupCode: [],
  qrCode: undefined,
  type: WithdrawType.BINANCE,
  modal: undefined,
  withdrawAmount: 2,
  withdrawCurrency: "USDT",
  convertedWithdrawAmount: 0,
  balance: {
    binance: 0,
    stripe: 0,
  },
  binanceInfo: {
    destination: BinanceDestination.BINANCE_ID,
    value: "",
    quoteId: "",
  },
  errors: {
    invalidCode: false,
    message: "",
  },
  loadings: {
    mfaSettingUp: false,
    mfaVerifying: false,
    processing: false,
    quoting: false,
  },
};

type WithdrawContextType = {
  values: WithdrawContextProviderValues;
  setValues: (payload: Partial<WithdrawContextProviderValues>) => void;
  openForm: () => void;
  handleWithdraw: () => void;
  confirmWithdraw: () => void;
  verifySetupCode: () => Promise<void>;
  verifyCode: () => Promise<void>;
  resetContext: () => void;
  quoteBinanceAmount: (payload: { currency: string; amount: number }) => Promise<void>;
};

const defaultWithdrawContextValue: WithdrawContextType = {
  values: defaultValues,
  setValues: () => {},
  openForm: () => {},
  handleWithdraw: () => {},
  confirmWithdraw: () => {},
  verifySetupCode: async () => {},
  verifyCode: async () => {},
  resetContext: () => {},
  quoteBinanceAmount: async () => {},
};

export const WithdrawContext = React.createContext(defaultWithdrawContextValue);

interface WithdrawContextProviderProps {}

export const WithdrawContextProvider = ({ children }: React.PropsWithChildren<WithdrawContextProviderProps>) => {
  const user = useSelector(userSelector);
  const { totalEarnings, totalWithdraw } = useSelector(earningSelector);
  const [values, setValues] = React.useState<WithdrawContextProviderValues>(defaultValues);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(syncUserAction());
  }, [dispatch]);

  React.useEffect(() => {
    setValues((prev) => ({
      ...prev,
      balance: {
        stripe: (totalEarnings?.stripe || 0) - (totalWithdraw?.stripe || 0),
        binance: (totalEarnings?.binance || 0) - (totalWithdraw?.binance || 0),
      },
    }));
  }, [totalEarnings, totalWithdraw]);

  React.useEffect(() => {
    setValues((prev) => ({ ...prev, secureWithdraw: !!user?.secureWithdraw }));
  }, [user?.secureWithdraw]);

  const openForm = React.useCallback(() => {
    setValues((prev) => ({
      ...defaultValues,
      secureWithdraw: prev.secureWithdraw,
      qrCode: prev.qrCode,
      type: prev.type,
      balance: prev.balance,
      modal: WithdrawModalType.FORM,
    }));
  }, []);

  const handleWithdraw = React.useCallback(async () => {
    if (!values?.secureWithdraw) {
      try {
        if (!values?.qrCode) {
          setValues((prev) => ({ ...prev, loadings: { ...prev.loadings, mfaSettingUp: true } }));
          const { data } = await apiClient.post("/withdraw/generateAuth");
          setValues((prev) => ({
            ...prev,
            modal: WithdrawModalType.CODE_SETUP,
            qrCode: data.qrcode,
            loadings: { ...prev.loadings, mfaSettingUp: false },
          }));
        } else {
          setValues((prev) => ({ ...prev, modal: WithdrawModalType.CODE_SETUP }));
        }
      } catch (error) {
        ToastService.showErrorMessage(BoltError(error).message);
      }
    } else {
      setValues((prev) => ({ ...prev, modal: WithdrawModalType.CODE_VERIFY }));
    }
  }, [values]);

  const verifySetupCode = React.useCallback(async () => {
    if (values.verifyCode?.length !== 6) {
      return;
    }
    try {
      setValues((prev) => ({
        ...prev,
        errors: { ...prev.errors, invalidCode: false },
        loadings: { ...prev.loadings, mfaVerifying: true },
      }));
      const { data } = await apiClient.post("/withdraw/validateAuth", { token: values.verifyCode });
      setValues((prev) => ({
        ...prev,
        modal: WithdrawModalType.CODE_SETUP_SUCCESS,
        backupCode: data?.backupCode,
        withdrawToken: data ? data["x-withdraw"] : "",
        loadings: { ...prev.loadings, mfaVerifying: false },
      }));
      dispatch(syncUserAction());
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        errors: { ...prev.errors, invalidCode: true },
        loadings: { ...prev.loadings, mfaVerifying: false },
      }));
    }
  }, [values, dispatch]);

  const verifyCode = React.useCallback(async () => {
    if (values.verifyCode?.length !== 6) {
      return;
    }
    try {
      setValues((prev) => ({
        ...prev,
        errors: { ...prev.errors, invalidCode: false },
        loadings: { ...prev.loadings, mfaVerifying: true },
      }));
      const { data } = await apiClient.post("/withdraw/verifyToken", { token: values.verifyCode });
      setValues((prev) => ({
        ...prev,
        modal: WithdrawModalType.CODE_VERIFY_SUCCESS,
        withdrawToken: data ? data["x-withdraw"] : "",
        loadings: { ...prev.loadings, mfaVerifying: false },
      }));
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        errors: { ...prev.errors, invalidCode: true },
        loadings: { ...prev.loadings, mfaVerifying: false },
      }));
    }
  }, [values, dispatch]);

  const confirmWithdraw = React.useCallback(async () => {
    try {
      setValues((prev) => ({
        ...prev,
        modal: WithdrawModalType.WITHDRAW_PROCESSING,
        errors: defaultValues.errors,
        loadings: { ...prev.loadings, processing: true },
      }));
      await apiClient.post(
        `/withdraw/${values.type}`,
        {
          transferAmount: values.withdrawAmount,
          receiveType: values.binanceInfo.destination,
          receiver: values.binanceInfo.value,
          remark: "Payout",
          currency: values.withdrawCurrency,
          quoteId: values.binanceInfo?.quoteId,
        },
        { headers: { "x-withdraw": values.withdrawToken } }
      );
      setValues((prev) => ({
        ...prev,
        modal: WithdrawModalType.WITHDRAW_SUCCESS,
        loadings: { ...prev.loadings, processing: false },
      }));

      // TODO: refresh withdraw transaction list after success
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        modal: WithdrawModalType.WITHDRAW_FAIL,
        errors: { ...prev.errors, message: BoltError(error).message },
        loadings: { ...prev.loadings, processing: false },
      }));
    }
  }, [values, dispatch]);

  const quoteBinanceAmount = React.useCallback(
    async ({ currency, amount }: { currency: string; amount: number }) => {
      try {
        setValues((prev) => ({
          ...prev,
          errors: defaultValues.errors,
          loadings: { ...prev.loadings, quoting: true },
        }));
        const { data } = await apiClient.post(
          `/withdraw/binance/getQuote`,
          {
            currency,
            amount,
          },
          { headers: { "x-withdraw": values.withdrawToken } }
        );
        setValues((prev) => ({
          ...prev,
          loadings: { ...prev.loadings, quoting: false },
          binanceInfo: { ...prev.binanceInfo, ...data },
          convertedWithdrawAmount: data?.toAmount || 0,
        }));
      } catch (error) {
        setValues((prev) => ({
          ...prev,
          errors: { ...prev.errors, message: BoltError(error).message },
          convertedWithdrawAmount: 0,
          loadings: { ...prev.loadings, quoting: false },
        }));
      }
    },
    [values, dispatch]
  );

  const updateValues = React.useCallback(
    (payload: Partial<WithdrawContextProviderValues>) => setValues((prev) => ({ ...prev, ...payload })),
    []
  );

  const resetContext = React.useCallback(() => {
    setValues((prev) => ({
      ...defaultValues,
      secureWithdraw: prev.secureWithdraw,
      qrCode: prev.qrCode,
      type: prev.type,
      balance: prev.balance,
    }));
  }, [values]);

  const value = React.useMemo(
    () => ({
      values,
      setValues: updateValues,
      openForm,
      handleWithdraw,
      confirmWithdraw,
      verifySetupCode,
      verifyCode,
      resetContext,
      quoteBinanceAmount,
    }),
    [values]
  );

  return (
    <WithdrawContext.Provider value={value}>
      {children}
      <WithdrawFormModal />
      <WithdrawMFASetupModal />
      <WithdrawMFASetupSuccessModal />
      <WithdrawMFABackupCodeModal />
      <WithdrawMFACodeModal />
      <WithdrawConfirmModal />
      <WithdrawProcessingModal />
      <WithdrawFailureModal />
      <WithdrawSuccessModal />
    </WithdrawContext.Provider>
  );
};

export const useWithdraw = () => {
  return React.useContext(WithdrawContext);
};
