import * as Device from "react-device-detect";
import { DeviceUUID } from "device-uuid";
import { FilterType } from "../types";

const deviceUUID = new DeviceUUID().get();

export const userNameRegex = /^(?=.{3,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/;

export const getDuration = (number: number) => {
  const date = new Date(0);
  date.setSeconds(number);
  return date.toISOString().substr(number > 3600 ? 11 : 14, number > 3600 ? 8 : 5);
};

export const nArray = (length: number) => Array.from({ length }, (v, k) => k + 1);

export const formatCount = (n: number) => {
  if (n < 1e3) {
    return n;
  }
  if (n >= 1e3 && n < 1e6) {
    return `${+(n / 1e3).toFixed(1)}K`;
  }
  if (n >= 1e6 && n < 1e9) {
    return `${+(n / 1e6).toFixed(1)}M`;
  }
  if (n >= 1e9 && n < 1e12) {
    return `${+(n / 1e9).toFixed(1)}B`;
  }
  if (n >= 1e12) {
    return `${+(n / 1e12).toFixed(1)}T`;
  }
  return 0;
};

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const mentionRegex = /\B@\w+/g;
  const tagRegex = /\B#\w+/g;
  const mentionReplaced = text.replace(mentionRegex, (mention: string) => {
    return `<span class="mention">${mention}</span>`;
  });
  const tagReplaced = mentionReplaced.replace(tagRegex, (mention: string) => {
    return `<span class="mention">${mention}</span>`;
  });
  const final = tagReplaced.replace(urlRegex, (url: string) => {
    return `<a href="${url}" target="_blank" class="external-link" rel="noopener noreferrer nofollow">${url}</a>`;
  });
  return final;
};

export const parseUrls = (text: string) => {
  const urls: string[] = text.match(/\bhttps?::\/\/\S+/gi) || text.match(/\bhttps?:\/\/\S+/gi) || [];
  return urls;
};

export const enumFromStringValue = <T>(enm: { [s: string]: T }, value: string): T | undefined => {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
};

export const getDateFormatType = (chartIndex: FilterType) => {
  let format = "DD MMM, YYYY";
  if (chartIndex === FilterType.DAILY) {
    format = "DD/MM/YYYY";
  } else if (chartIndex === FilterType.MONTHLY) {
    format = "MMM, YYYY";
  }

  return format;
};

export const formatNumber = (value?: number) => {
  return (value || 0).toLocaleString("en-US");
};

export const createOtteraChannelTrackUrl = (url: string, options?: { channelId?: string; channelName?: string }) => {
  const newUrl = new URL(url);
  newUrl.searchParams.append("site_page", window.location.href);
  newUrl.searchParams.append("site_name", "Boltplus");
  newUrl.searchParams.append("app_store_url", "https://boltplus.tv");
  newUrl.searchParams.append("app_bundle", "boltplus.web");
  newUrl.searchParams.append("device_width", `${window.screen.width}`);
  newUrl.searchParams.append("device_height", `${window.screen.height}`);
  newUrl.searchParams.append("width", `${window.screen.width}`);
  newUrl.searchParams.append("height", `${window.screen.height}`);
  newUrl.searchParams.append("device_language", navigator.language);
  newUrl.searchParams.append("device_id", deviceUUID);
  newUrl.searchParams.append("did", deviceUUID);
  newUrl.searchParams.append("us_privacy", "1---");
  newUrl.searchParams.append("app_name", "Boltplus");
  newUrl.searchParams.append("ifa_type", "web");
  newUrl.searchParams.append("dnt", "0");
  newUrl.searchParams.append("device_model", Device.parseUserAgent(navigator.userAgent)?.device?.model || "browser");

  if (options?.channelName) {
    newUrl.searchParams.append("content_channel", options?.channelName);
  }

  // custom params: reference https://support.ottera.tv/support/solutions/articles/67000268643
  if (options?.channelId) {
    newUrl.searchParams.append("PT_bolt_channel_id", options?.channelId);
  }

  return newUrl.href;
};
