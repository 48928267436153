import { Avatar, Box, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";

import { usePrivateLayoutContext } from "../../../../layouts";
import { useBloombergEpisodesPageContext } from "../episodes-context";
import { OrderChip } from "./OrderChip";
import { TvPgSvg } from "./TvPgSvg";

export const Content = () => {
  const { navbar__height } = usePrivateLayoutContext();

  const { data } = useBloombergEpisodesPageContext();

  return (
    <Box
      sx={{
        position: { xs: "relative", lg: "sticky" },
        top: 0,
        left: 0,
        height: `calc(100vh - ${navbar__height}px)`,
        display: "flex",
        alignItems: "center",
        scrollSnapAlign: "start",
        scrollSnapStop: { xs: "normal", lg: "always" },
        margin: { xs: "auto", md: "unset" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "start" },
          rowGap: { xs: 2, md: 3 },
          px: { xs: 4, md: 7.5, lg: 4 },
          py: 4,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 4 }}>
          <Avatar
            src={data?.icon}
            sx={{
              height: { xs: 80, md: 100 },
              width: "auto",
              objectFit: "contain",
              borderRadius: 1,
            }}
          >
            <Image />
          </Avatar>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
          <OrderChip order={1} />

          <Typography sx={{ color: "#8F9095" }}>in Popular on Bolt+</Typography>
        </Box>

        <Box sx={{ maxWidth: { md: 600, lg: 428 } }}>
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: "28px",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {data?.description || ""}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2.5 }}>
            <Typography color="text.secondary">{(data?.channels || []).length} Episodes</Typography>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
              <TvPgSvg />

              <Typography>Age 12+</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
