import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { apiClient } from "../../../config";
import { CollectionPageContextType, GetDataFuncPayload } from "./types";
import { defaultCollectionPageContextValue } from "./consts";
import { CollectionPageContext } from "./CollectionPageContext";

type CollectionPageContextProviderProps = PropsWithChildren<{}>;

export const CollectionPageContextProvider = ({ children }: CollectionPageContextProviderProps) => {
  const [contextValue, setContextValue] = useState<CollectionPageContextType>(defaultCollectionPageContextValue);

  const updateContext: CollectionPageContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const isXs = useMediaQuery("(max-width:600px)");

  const isMd = useMediaQuery("(max-width:900px)");

  const isLg = useMediaQuery("(max-width:1200px)");

  const bottomOffset = isXs ? 28 : isMd ? 64 : isLg ? 80 : 100;

  const { collectionSlug } = useParams();

  const getCollectionInfo = useCallback(async () => {
    const url = `collections/${collectionSlug}`;

    try {
      updateContext({
        isLoadingMainInfo: true,
      });

      const { data } = await apiClient.get(url);

      updateContext({
        mainInfo: data,
      });
    } catch (err) {
      console.error("Error at getCollectionInfo: ", err);
    } finally {
      updateContext({
        isLoadingMainInfo: false,
      });
    }
  }, [collectionSlug]);

  const getCollectionChannels = useCallback(
    async (payload?: GetDataFuncPayload) => {
      const url = `collections/${collectionSlug}/channels?page=${payload?.page || 1}&limit=${payload?.limit || 12}`;

      try {
        updateContext({
          isLoadingChannels: true,
        });

        const { data } = await apiClient.get(url);

        updateContext({
          channels: {
            data: payload?.moreData ? [...contextValue.channels.data, ...data.data] : data.data,
            page: data.page,
            limit: data.limit,
            total: data.total,
          },
        });
      } catch (err) {
        console.error("Error at getCollectionChannels: ", err);
      } finally {
        updateContext({
          isLoadingChannels: false,
        });
      }
    },
    [collectionSlug, contextValue.channels.data]
  );

  const getCollectionStreamers = useCallback(
    async (payload?: GetDataFuncPayload) => {
      const url = `collections/${collectionSlug}/streamers?page=${payload?.page || 1}&limit=${payload?.limit || 12}`;

      try {
        updateContext({
          isLoadingStreamers: true,
        });

        const { data } = await apiClient.get(url);

        updateContext({
          streamers: {
            data: payload?.moreData ? [...contextValue.streamers.data, ...data.data] : data.data,
            page: data.page,
            limit: data.limit,
            total: data.total,
          },
        });
      } catch (err) {
        console.error("Error at getCollectionStreamers: ", err);
      } finally {
        updateContext({
          isLoadingStreamers: false,
        });
      }
    },
    [collectionSlug, contextValue.streamers.data]
  );

  const getCollectionRewinds = useCallback(
    async (payload?: GetDataFuncPayload) => {
      const url = `collections/${collectionSlug}/rewinds?page=${payload?.page || 1}&limit=${payload?.limit || 12}`;

      try {
        updateContext({
          isLoadingRewinds: true,
        });

        const { data } = await apiClient.get(url);

        updateContext({
          rewinds: {
            data: payload?.moreData ? [...contextValue.rewinds.data, ...data.data] : data.data,
            page: data.page,
            limit: data.limit,
            total: data.total,
          },
        });
      } catch (err) {
        console.error("Error at getCollectionRewinds: ", err);
      } finally {
        updateContext({
          isLoadingRewinds: false,
        });
      }
    },
    [collectionSlug, contextValue.rewinds.data]
  );

  useEffect(() => {
    updateContext({
      isPageLoading: defaultCollectionPageContextValue.isPageLoading,
      mainInfo: defaultCollectionPageContextValue.mainInfo,
      isLoadingMainInfo: defaultCollectionPageContextValue.isLoadingMainInfo,
      channels: defaultCollectionPageContextValue.channels,
      isLoadingChannels: defaultCollectionPageContextValue.isLoadingChannels,
      streamers: defaultCollectionPageContextValue.streamers,
      isLoadingStreamers: defaultCollectionPageContextValue.isLoadingStreamers,
      rewinds: defaultCollectionPageContextValue.rewinds,
      isLoadingRewinds: defaultCollectionPageContextValue.isLoadingRewinds,
      noData: defaultCollectionPageContextValue.noData,
      bgVideo: defaultCollectionPageContextValue.bgVideo,
    });

    getCollectionInfo();
    getCollectionChannels();
    getCollectionStreamers();
    getCollectionRewinds();
  }, [collectionSlug]);

  useEffect(() => {
    if (
      contextValue.isLoadingMainInfo &&
      contextValue.isLoadingChannels &&
      contextValue.isLoadingStreamers &&
      contextValue.isLoadingRewinds
    ) {
      updateContext({ isPageLoading: true });
    }

    if (
      !contextValue.isLoadingMainInfo &&
      !contextValue.isLoadingChannels &&
      !contextValue.isLoadingStreamers &&
      !contextValue.isLoadingRewinds
    ) {
      updateContext({ isPageLoading: false });
    }
  }, [
    contextValue.isLoadingMainInfo,
    contextValue.isLoadingChannels,
    contextValue.isLoadingStreamers,
    contextValue.isLoadingRewinds,
  ]);

  const { noData, bgVideo } = useMemo(() => {
    const noData =
      contextValue.channels.data.length === 0 &&
      contextValue.streamers.data.length === 0 &&
      contextValue.rewinds.data.length === 0;

    const bgVideo = noData
      ? ""
      : contextValue.channels.data[0]?.url ||
        contextValue.streamers.data[0]?.stream_profile.url ||
        contextValue.rewinds.data[0]?.url ||
        "";

    return { noData, bgVideo };
  }, [contextValue.channels.data, contextValue.streamers.data, contextValue.rewinds.data]);

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      //
      bottomOffset,
      //
      noData,
      bgVideo,
      //
      updateContext,
      getCollectionChannels,
      getCollectionStreamers,
      getCollectionRewinds,
    };
  }, [
    contextValue,
    bottomOffset,
    noData,
    bgVideo,
    getCollectionChannels,
    getCollectionStreamers,
    getCollectionRewinds,
  ]);

  return <CollectionPageContext.Provider value={memoizedValue}>{children}</CollectionPageContext.Provider>;
};
