import { Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexCol, LoadingView } from "../../components";
import { apiClient } from "../../config";
import { useQuery } from "../../hooks/useQuery";
import { initAuthAction } from "../../store/auth/actions";

export const EmailVerifyPage = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const token = query.get("token");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const verifyEmail = async () => {
      try {
        await apiClient.post("/auth/verifyEmail", { token });
        setLoading(false);
        dispatch(initAuthAction());
        setError("");
      } catch (err: any) {
        setLoading(false);
        setError(err.response?.data?.message);
      }
    };
    if (token) {
      setLoading(true);
      verifyEmail();
    } else {
      navigate("/");
    }
  }, [query]);

  return (
    <FlexCol sx={{ margin: "auto", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      {!loading && !error && (
        <FlexCol>
          <Typography variant="h6" color="primary.contrastText" sx={{ my: 3, textAlign: "center" }}>
            Your email has been verified
          </Typography>
          <Typography variant="subtitle2" color="primary.light">
            You can now sign in with your new account
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, px: 8, textTransform: "none", borderRadius: 50 }}
            onClick={() => navigate("/login")}
          >
            <Typography variant="subtitle1" fontWeight="600">
              Log in
            </Typography>
          </Button>
        </FlexCol>
      )}
      {!loading && error && (
        <FlexCol>
          <Typography variant="subtitle2" color="error">
            {error || "The verification link doesn't exist or expired."}
          </Typography>
        </FlexCol>
      )}
      <LoadingView visible={loading} />
    </FlexCol>
  );
};
