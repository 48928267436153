import React from "react";

import { FlexCol } from "../../components";
import { StreamFeedList } from "./StreamFeedList";
import { NFTFeedList } from "./NFTFeedList";

type Props = {
  tabIndex: number;
  postAds: any[];
  postAdIndex: number;
};

export const FeedContainer = ({ tabIndex, postAds, postAdIndex }: Props) => {
  return (
    <FlexCol sx={{ pb: 2, mb: { xs: 18, sm: 0 } }}>
      {tabIndex === 0 && <StreamFeedList postAds={postAds} postAdIndex={postAdIndex} />}
      {tabIndex === 1 && <NFTFeedList />}
    </FlexCol>
  );
};
