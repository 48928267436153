import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { BottomNavItem } from "./BottomNavItem";
import { DiscoverSvg } from "./DiscoverSvg";

export const DiscoverNavItem = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    return pathname === "/discover";
  }, [pathname]);

  const onClick = () => {
    navigate("/discover");
  };

  return (
    <BottomNavItem
      icon={<DiscoverSvg style={{ fontSize: 22, color: isActive ? theme.palette.primary.main : "white" }} />}
      label="Discover"
      onClick={onClick}
    />
  );
};
