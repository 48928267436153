import { Box, Button, Paper, Skeleton, Stack } from "@mui/material";
import React from "react";
import { FlexRow } from "../../components";

type Props = {};

const CommentSkeleton = (props: Props) => {
  return (
    <Stack
      sx={{
        background: "#1C2039",
        borderRadius: 2.5,
        overflow: "hidden",
        cursor: "default",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
        mb: "10px",
      }}
    >
      <Box
        component={Paper}
        elevation={0}
        sx={{
          zIndex: 10,
          borderRadius: 2.5,
          pl: 2.25,
          pr: 3.5,
          paddingBottom: 1.375,
          backgroundColor: "#1C2039",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.35)",
        }}
      >
        <FlexRow sx={{ gap: 1.25, alignItems: "stretch" }}>
          <Stack sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Stack sx={{ alignItems: "center", justifyContent: "flex-start" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textDecoration: "none",
                  pt: 2,
                }}
              >
                <Skeleton
                  variant="circular"
                  animation="wave"
                  sx={{
                    height: 30,
                    width: 30,
                  }}
                />
              </Box>
            </Stack>
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <FlexRow sx={{ alignItems: "center", paddingTop: 2.25, mb: 0.4 }}>
              <Box>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: 10, width: 120, lineHeight: "18px" }} />
              </Box>
              {/* <VerifiedIcon style={{ marginLeft: theme.spacing(1) }} /> */}
              <Skeleton variant="text" animation="wave" sx={{ ml: 1.25, fontSize: 8, width: 50, lineHeight: "12px" }} />
            </FlexRow>
            {/* <Skeleton variant="text" animation="wave" style={{ fontSize: 12 }} /> */}
            <Skeleton variant="text" animation="wave" style={{ fontSize: 12 }} />
            <Skeleton variant="text" animation="wave" style={{ fontSize: 12, width: 230 }} />
            <FlexRow
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1.5,
                pt: 1,
              }}
            >
              {/* <Skeleton variant="circular" animation="wave" style={{ height: 16, width: 16 }} /> */}
              {/* {["", "", ""].map((item, index) => {
                return (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    animation="wave"
                    style={{ height: 16, width: 30, borderRadius: 42 }}
                  />
                );
              })} */}
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{
                  height: 16,
                  width: 58,
                  borderRadius: "74px",
                }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{
                  height: 16,
                  width: 58,
                  borderRadius: "74px",
                }}
              />
            </FlexRow>
          </Stack>
        </FlexRow>
      </Box>
    </Stack>
  );
};

export default CommentSkeleton;
