import { PropsWithChildren } from "react";

import {
  ImmersivePanelLayoutContextProvider,
  ImmersivePanelLayoutContextProviderProps,
} from "./ImmersivePanelLayoutContext";
import { ImmersivePanelLayoutUi } from "./ImmersivePanelLayoutUi";

type ImmersivePanelLayoutProps = ImmersivePanelLayoutContextProviderProps;

export const ImmersivePanelLayout = ({
  children,
  immersivePanel__hidden,
}: PropsWithChildren<ImmersivePanelLayoutProps>) => {
  return (
    <ImmersivePanelLayoutContextProvider immersivePanel__hidden={immersivePanel__hidden}>
      <ImmersivePanelLayoutUi>{children}</ImmersivePanelLayoutUi>
    </ImmersivePanelLayoutContextProvider>
  );
};
