import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Avatar, Box, BoxProps, Button, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Image404 from "../../../assets/404.webp";
import { ReactComponent as CursorIcon } from "../../../assets/svgs/cursor.svg";
import { ReactComponent as EyeIcon } from "../../../assets/svgs/eye.svg";
import { ReactComponent as PeopleIcon } from "../../../assets/svgs/people.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/svgs/verified.svg";

import { ReactComponent as CameraLightIcon } from "../../../assets/svgs/users/camera_light.svg";
import { ReactComponent as FaceLightIcon } from "../../../assets/svgs/users/face_light.svg";
import { ReactComponent as LinkIcon } from "../../../assets/svgs/users/link.svg";
import { ReactComponent as MessageLightIcon } from "../../../assets/svgs/users/message_light.svg";
import { ReactComponent as StarLightIcon } from "../../../assets/svgs/users/star_light.svg";
import { ReactComponent as TextSnippetIcon } from "../../../assets/svgs/users/textSnippet.svg";
import { ReactComponent as UserCheckLightIcon } from "../../../assets/svgs/users/user_check_light.svg";

import { AnalyticsFeedCard, FlexCol, FlexRow, PeriodSelect, periodFullFilters } from "../../../components";
import { fetchAnalyticsOverviewAction, fetchAnalyticsOverviewTopFeedsAction } from "../../../store/users/actions";
import { userStreamSelector } from "../../../store/users/selector";
import { authSelector } from "../../../store/auth/selector";

import { OverviewSectionInfo, StatusBox } from "./components";
import { Formatter } from "../../../utils";
import { DefaultAvatar } from "../../../components/DefaultAvatar";
import { PeriodFilterType, StreamFeed } from "../../../types";
import { ConfirmationModal } from "../../../components/modals";
import { followUserAction } from "../../../store/profile/actions";

type ChangeFollowing = "fan" | "follower" | null;

const screenMaxWidth = "1500px";

const AnalyticsOverview = ({ sx, ...other }: BoxProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);

  const [period, setPeriod] = useState(periodFullFilters[0]);
  const [userChangeFollowing, setUserChangeFollowing] = useState<ChangeFollowing>(null);
  const [unfollowModalVisible, setUnfollowModalVisible] = useState(false);

  const {
    loading,
    overview: { impressions, impressionsRate, clicks, followers, followsIncrease },
    topFeeds: { impressionFeed, reactionFeed, commentFeed, topStream, topFollower, topFan },
  } = useSelector(userStreamSelector);

  const getOverviewData = useCallback((data: any) => dispatch(fetchAnalyticsOverviewAction(data)), [dispatch]);
  const getOverviewTopFeed = useCallback(
    (data: any) => dispatch(fetchAnalyticsOverviewTopFeedsAction(data)),
    [dispatch]
  );

  const loadOverviewData = (isOnlyTopFeed: boolean = false) => {
    const parmas = {
      startDate: 0,
      endDate: Date.now(),
    };

    const date = new Date();
    switch (period.value) {
      case PeriodFilterType.today:
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        parmas.startDate = date.getTime();
        break;
      case PeriodFilterType.last_week:
        parmas.startDate = Date.now() - 7 * 24 * 60 * 60 * 1000;
        break;
      case PeriodFilterType.last_month:
        parmas.startDate = Date.now() - 30 * 24 * 60 * 60 * 1000;
        break;
      case PeriodFilterType.this_year:
        date.setMonth(0);
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        parmas.startDate = date.getTime();
        break;
      case PeriodFilterType.last_year:
        parmas.startDate = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
        parmas.endDate = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
        break;
      case PeriodFilterType.all:
        parmas.endDate = 0;
        break;
      default:
        break;
    }

    getOverviewTopFeed(parmas);
    if (!isOnlyTopFeed) getOverviewData(parmas);
  };

  useEffect(() => {
    loadOverviewData();
  }, [period]);

  const postFollow = () => {
    const newStatus = userChangeFollowing === "fan" ? !topFan?.followed : !topFollower?.followed;
    const userId = userChangeFollowing === "fan" ? topFan?.id : topFollower?.id;
    dispatch(followUserAction({ follow: newStatus, userId }));

    setTimeout(() => {
      setUnfollowModalVisible(false);
    }, 300);
    loadOverviewData(true);
  };

  const handleClickFollow = (type: ChangeFollowing) => {
    setUserChangeFollowing(type);
    const isFollowing = type === "fan" ? topFan?.followed : topFollower?.followed;
    isFollowing ? setUnfollowModalVisible(true) : postFollow();
  };

  return (
    <Box sx={{ pt: 3, pb: { xs: 12, sm: 3 }, ...sx }} {...other}>
      <FlexRow
        sx={{
          flexWrap: "wrap",
          display: { xs: "block", sm: "flex" },
          justifyContent: { xs: "center", sm: "start" },
          ml: { xs: 2, sm: 0 },
        }}
      >
        <PeriodSelect sx={{ mr: 4.5 }} mode={period} setMode={setPeriod} />
        <StatusBox Icon={EyeIcon} label="Impressions" value={impressions} rate={impressionsRate} />
        <StatusBox Icon={CursorIcon} label="Clicks" value={clicks} />
        <StatusBox Icon={PeopleIcon} label="Followers" value={followers} increase={followsIncrease} />
      </FlexRow>

      <Box sx={{ mt: 4, px: { xs: 1.5, sm: 0 }, width: "100%" }} maxWidth={screenMaxWidth}>
        <Typography sx={{ color: "#FFFFFF80" }}>
          <b style={{ color: "white" }}>{moment(new Date()).format("MMMM, YYYY")}</b> Highlights
        </Typography>

        <FlexRow
          component="section"
          sx={{ mb: { sm: 6, md: 1 }, flexDirection: { xs: "column", sm: "column", md: "row" } }}
        >
          {loading && (
            <FlexRow sx={{ maxWidth: screenMaxWidth, width: screenMaxWidth }}>
              <FlexCol sx={{ flex: 1, mt: 2 }}>
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%" }} />
                <Skeleton variant="rectangular" animation="wave" height="180px" width="100%" sx={{ opacity: 0.4 }} />
              </FlexCol>
              <FlexCol sx={{ flex: 1, mt: 2, ml: { sm: 0, md: 2 } }}>
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%" }} />
                <Skeleton variant="rectangular" animation="wave" height="180px" width="100%" sx={{ opacity: 0.4 }} />
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%", mt: 2 }} />
                <Skeleton variant="rectangular" animation="wave" height="180px" width="100%" sx={{ opacity: 0.4 }} />
              </FlexCol>
              <FlexCol sx={{ flex: 1, mt: 2, ml: { sm: 0, md: 2 } }}>
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%" }} />
                <Skeleton variant="rectangular" animation="wave" height="180px" width="100%" sx={{ opacity: 0.4 }} />
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%", mt: 2 }} />
                <Skeleton variant="rectangular" animation="wave" height="80px" width="100%" sx={{ opacity: 0.4 }} />
                <Skeleton variant="text" sx={{ fontSize: "20px", width: "50%", mt: 2 }} />
                <Skeleton variant="rectangular" animation="wave" height="80px" width="100%" sx={{ opacity: 0.4 }} />
              </FlexCol>
            </FlexRow>
          )}

          {!loading && (
            <>
              {impressionFeed && (
                <FlexCol sx={{ flex: 1, mt: 2, maxWidth: 500 }}>
                  <FlexRow sx={{ alignItems: "center", mb: 1 }}>
                    <OverviewSectionInfo Icon={TextSnippetIcon} label="Top Post" />
                    <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                      earned {Formatter.formatCount(impressionFeed.impressions || 0)} Impressions
                    </Typography>
                  </FlexRow>
                  <AnalyticsFeedCard component={Paper} item={impressionFeed as StreamFeed} />
                </FlexCol>
              )}

              <FlexCol
                sx={{
                  flex: 1,
                  mt: 2,
                  maxWidth: 500,
                  ml: { sm: 0, md: impressionFeed ? 2 : 0 },
                  display: reactionFeed || commentFeed ? "flex" : "none",
                }}
              >
                {reactionFeed && (
                  <>
                    <FlexRow sx={{ alignItems: "center", mb: 1 }}>
                      <OverviewSectionInfo Icon={FaceLightIcon} label="Top Reacted" />
                      <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                        earned{" "}
                        {Formatter.formatCount(
                          reactionFeed.reboltFeed?.reactionCount || reactionFeed.reactionCount || 0
                        )}{" "}
                        Reactions
                      </Typography>
                    </FlexRow>
                    <AnalyticsFeedCard component={Paper} item={reactionFeed as StreamFeed} />
                  </>
                )}

                {commentFeed && (
                  <>
                    <FlexRow sx={{ alignItems: "center", mb: 1, mt: 2 }}>
                      <OverviewSectionInfo Icon={MessageLightIcon} label="Top Replied" />
                      <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                        earned {Formatter.formatCount(commentFeed?.commentCount || 0)} Replies
                      </Typography>
                    </FlexRow>
                    <AnalyticsFeedCard component={Paper} item={commentFeed as StreamFeed} />
                  </>
                )}
              </FlexCol>

              <FlexCol
                sx={{
                  flex: 1,
                  mt: 2,
                  ml: { sm: 0, md: impressionFeed || reactionFeed || commentFeed ? 2 : 0 },
                  maxWidth: 500,
                  display: topStream || topFollower || topFan ? "flex" : "none",
                }}
              >
                {topStream && (
                  <>
                    <FlexRow sx={{ alignItems: "center", mb: 1 }}>
                      <OverviewSectionInfo Icon={CameraLightIcon} label="Top Stream" />
                      <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                        earned {Formatter.formatCount(topStream.views || 0)} Engagements
                      </Typography>
                    </FlexRow>

                    <FlexCol component={Paper} sx={{ borderRadius: 2 }}>
                      <FlexCol sx={{ borderRadius: 1, backgroundColor: "black", position: "relative", pb: "50%" }}>
                        <img
                          src={topStream.thumbnail}
                          // width="100%"
                          // height="auto"
                          alt=""
                          style={{
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            position: "absolute",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = Image404;
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            bgcolor: "#282C4680",
                            py: 0.3,
                            px: 0.8,
                            right: 5,
                            bottom: 5,
                            position: "absolute",
                            borderRadius: 0.5,
                          }}
                        >
                          {moment
                            .utc((topStream.duration || 0) * 1000)
                            .format(topStream.duration >= 3600 ? "HH:mm:ss" : "mm:ss")}
                        </Typography>
                      </FlexCol>
                      <FlexRow
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          px: 2,
                          pt: 1,
                          pb: 1.5,
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{topStream.title}</Typography>
                          <Typography sx={{ fontSize: 12, color: "#FFFFFF80" }}>
                            <b style={{ color: "white" }}>{user?.username}</b> • {topStream.views} Views
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ lineHeight: 0.8, marginBottom: 0 }}
                          >
                            {moment(topStream.createdAt).fromNow(false)}
                          </Typography>
                        </Box>
                        <IconButton
                          aria-label="open"
                          size="medium"
                          onClick={() => navigate(`/${user?.username}`)}
                          sx={{ mr: { sm: 1, md: 0 } }}
                        >
                          <LinkIcon />
                        </IconButton>
                      </FlexRow>
                    </FlexCol>
                  </>
                )}

                {topFollower && (
                  <>
                    <FlexRow sx={{ alignItems: "center", mb: 1, mt: 2 }}>
                      <OverviewSectionInfo Icon={UserCheckLightIcon} label="Top Followers" />
                      <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                        followed by {Formatter.formatCount(user?.followers || 0)} Followers
                      </Typography>
                    </FlexRow>
                    <FlexRow component={Paper} sx={{ py: 1, px: 2, borderRadius: 2 }}>
                      <Avatar
                        src={topFollower.photoUrl}
                        alt={topFollower.username}
                        sx={{
                          borderColor: "Purple",
                          borderWidth: 1.5,
                          borderStyle: "solid",
                          height: 54,
                          width: 54,
                          alignSelf: "center",
                        }}
                      >
                        <DefaultAvatar />
                      </Avatar>
                      <FlexCol sx={{ ml: 1, flex: 1 }}>
                        <FlexRow sx={{ alignItems: "center" }}>
                          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 600 }}>
                            {topFollower.username}
                          </Typography>
                          <VerifiedIcon style={{ marginLeft: 5 }} />
                        </FlexRow>
                        <Typography variant="caption" sx={{ fontSize: 12 }}>
                          @{topFollower.username}
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: 12, color: "#FFFFFF80" }}>
                          <b style={{ color: "white" }}>{Formatter.formatNumber(topFollower.followers)}</b> Followers
                        </Typography>
                      </FlexCol>
                      <FlexCol
                        sx={{
                          mt: { xs: 0, sm: 0, md: 1 },
                          flexDirection: { xs: "column", sm: "row-reverse", md: "column" },
                          alignItems: { sm: "center", xs: "center", md: "end" },
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            height: 24,
                            borderRadius: 24 / 2,
                            background: topFollower.followed
                              ? "#1F2234"
                              : "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                          }}
                          endIcon={topFollower.followed ? null : <AddOutlinedIcon style={{ marginLeft: -8 }} />}
                          onClick={() => handleClickFollow("follower")}
                        >
                          {topFollower.followed ? "Followed" : "Follow"}
                        </Button>
                        <IconButton
                          aria-label="open"
                          size="medium"
                          onClick={() => navigate(`/${topFollower.username}`)}
                          sx={{ mr: { sm: 1, md: 0 }, mt: { md: 1, sm: 0, xs: 1 } }}
                        >
                          <LinkIcon style={{}} />
                        </IconButton>
                      </FlexCol>
                    </FlexRow>
                  </>
                )}

                {topFan && (
                  <>
                    <FlexRow sx={{ alignItems: "center", mb: 1, mt: 2 }}>
                      <OverviewSectionInfo Icon={StarLightIcon} label="Top Fan" />
                      <Typography sx={{ fontSize: 12, color: "#A3AED0", ml: 1 }}>
                        contributed {Formatter.formatCount(topFan.impressions || 0)} Engagements
                      </Typography>
                    </FlexRow>
                    <FlexRow component={Paper} sx={{ py: 1, px: 2, borderRadius: 2 }}>
                      <Avatar
                        src={topFan.photoUrl}
                        alt={topFan.username}
                        sx={{
                          borderColor: "Purple",
                          borderWidth: 1.5,
                          borderStyle: "solid",
                          height: 54,
                          width: 54,
                          alignSelf: "center",
                        }}
                      >
                        <DefaultAvatar />
                      </Avatar>
                      <FlexCol sx={{ ml: 1, flex: 1 }}>
                        <FlexRow sx={{ alignItems: "center" }}>
                          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{topFan.username}</Typography>
                          <VerifiedIcon style={{ marginLeft: 5 }} />
                        </FlexRow>
                        <Typography sx={{ fontSize: 12 }}>@{topFan.username}</Typography>
                        <Typography sx={{ fontSize: 12, color: "#FFFFFF80" }}>
                          <b style={{ color: "white" }}>{Formatter.formatNumber(topFan.followers)}</b> Followers
                        </Typography>
                      </FlexCol>
                      <FlexCol
                        sx={{
                          mt: { xs: 0, sm: 0, md: 1 },
                          flexDirection: { xs: "column", sm: "row-reverse", md: "column" },
                          alignItems: { sm: "center", xs: "center", md: "end" },
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            height: 24,
                            borderRadius: 24 / 2,
                            background: topFan.followed
                              ? "#1F2234"
                              : "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                          }}
                          endIcon={topFan.followed ? null : <AddOutlinedIcon style={{ marginLeft: -8 }} />}
                          onClick={() => handleClickFollow("fan")}
                        >
                          {topFan.followed ? "Followed" : "Follow"}
                        </Button>
                        <IconButton
                          aria-label="open"
                          size="medium"
                          onClick={() => navigate(`/${topFan.username}`)}
                          sx={{ mr: { sm: 1, md: 0 }, mt: { md: 1, sm: 0, xs: 1 } }}
                        >
                          <LinkIcon style={{}} />
                        </IconButton>
                      </FlexCol>
                    </FlexRow>
                  </>
                )}

                {unfollowModalVisible && (
                  <ConfirmationModal
                    modalVisible={unfollowModalVisible}
                    title={`Unfollow ${userChangeFollowing === "fan" ? topFan?.username : topFollower?.username}?`}
                    submitFn={postFollow}
                    cancelFn={() => {
                      setUnfollowModalVisible(false);
                      setUserChangeFollowing(null);
                    }}
                    submitLabel="Unfollow"
                    cancelLabel="Discard"
                  />
                )}
              </FlexCol>
            </>
          )}
        </FlexRow>
      </Box>
    </Box>
  );
};

export default AnalyticsOverview;
