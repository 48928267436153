import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { TRANSACTION_STATUS } from '../../../../types';

const StatusChip = ({ sx, ...other }: ChipProps) => {
  return <Chip size="small" sx={{ color: 'white', textTransform: 'uppercase', borderRadius: 1.5, fontSize: 12, ...sx }} {...other} />;
};

type Props = {
  status?: string
} & ChipProps;

export const StatusLabel = ({ status = "", sx }: Props) => {
  let color: "default" | "success" | "primary" | "secondary" | "error" | "info" | "warning";

  if (status.toLowerCase() === TRANSACTION_STATUS.INITIALIZED) color = "default";
  else if (status.toLowerCase() === TRANSACTION_STATUS.SUCCEEDED) color = "success";
  else if (status.toLowerCase() === TRANSACTION_STATUS.PENDING) color = "warning";
  else if (status.toLowerCase() === TRANSACTION_STATUS.FAILED) color = "primary";
  else if (status.toLowerCase() === TRANSACTION_STATUS.CANCELED) color = "error";
  else if (status.toLowerCase() === TRANSACTION_STATUS.BLOCKED) color = "info";
  else color = "default";

  return <StatusChip label={status} color={color} sx={sx} />;
};