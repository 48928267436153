import { Box, Paper } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { FlexCol } from "../../components";
import CommentContainer from "../../components/CommentContainer";
import FeedContent from "../../components/FeedContent";
import { feedCommentAdd } from "../../store/feedDetails/actions";
import { GiphyObject, NFTFeed, StreamFeed } from "../../types";
import { FeedDetailsType } from "../../types/feedDetails";
import CommentInputBox from "./CommentInputBox";

type Props = {
  feedDetails?: FeedDetailsType;
  item?: StreamFeed | NFTFeed;
  excludeMedia?: boolean;
  disableExpandView?: boolean;
};

export const FeedContentContainer = ({ feedDetails, item, excludeMedia, disableExpandView = false }: Props) => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [showReplyBox] = useState(true);

  const handleSend = useCallback(
    ({ text, draftContent, giphy }: { text: string; draftContent?: string; giphy?: GiphyObject }) => {
      if (!text?.trim() && !giphy) {
        return;
      }
      let limit = 1;
      if (feedDetails?.reboltFeed && feedDetails.reboltFeed.comments) {
        limit = feedDetails.reboltFeed.comments.length + 1;
      }
      if (feedDetails?.comments) {
        limit = feedDetails.comments.length + 1;
      }
      if (item?.reboltFeed && item.reboltFeed.comments) {
        limit = item.reboltFeed.comments.length + 1;
      }
      if (item?.comments) {
        limit = item.comments.length + 1;
      }
      let selectedId = feedDetails?.id || item!.id;
      if (feedDetails?.reboltFeed) {
        selectedId = feedDetails.reboltFeed.id;
      }
      if (item?.reboltFeed) {
        selectedId = item.reboltFeed.id;
      }
      dispatch(
        feedCommentAdd({
          commentText: text,
          draftContent,
          giphy,
          feedId: selectedId,
          limit,
        })
      );
      setInputValue("");
    },
    [feedDetails, item, dispatch]
  );

  return (
    <FlexCol
      component={Paper}
      sx={{ borderRadius: { xs: 0, sm: 2.5 }, mb: { xs: 20, sm: 0 }, height: "100%", overflow: "visible" }}
    >
      <FeedContent {...{ item: feedDetails || item!, excludeMedia, disableExpandView }} />

      <Box sx={{ px: 2.125, py: 0.625, zIndex: 11 }} onClick={(e) => e.stopPropagation()}>
        <CommentInputBox
          {...{
            inputValue,
            setInputValue,
            showReplyBox,
            mainCommentReply: true,
            onSend: handleSend,
          }}
        />
      </Box>

      <CommentContainer
        {...{
          item: feedDetails || item!,
          initialLoad: feedDetails?.initialCommentsLoading,
          loadingComments: feedDetails?.loadingComments,
          loadingCommentIndex: feedDetails?.loadingCommentIndex,
        }}
      />
    </FlexCol>
  );
};
