import { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { BloombergSubscription } from "./bloomberg-subscription";

export type ShopProps = {
  containerSx?: SxProps;
};

export const Shop = ({ containerSx = {} }: ShopProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ ...containerSx }}>
      <BloombergSubscription />
    </Box>
  );
};
