import React, { useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";

import { FlexCol } from ".";
import { NFTFeed, StreamFeed } from "../types";
import FeedContent from "./FeedContent";
import { createFeedImpression } from "../store/feed/actions";
import { userSelector } from "../store/auth/selector";

interface Props {
  item: StreamFeed | NFTFeed;
  disabled?: boolean;
  streamerId?: string;
}

export const FeedCard = ({ item, disabled = false, streamerId }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const timeout = useRef<any>();

  const { ref, inView, entry } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      timeout.current = setTimeout(async () => {
        dispatch(
          createFeedImpression({
            type: "stream_feed", // or "nft_feed"
            item: item.reboltFeed?.id || item.id,
          })
        );
      }, 2000);
    } else {
      timeout.current = undefined;
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [inView, item]);

  return (
    <FlexCol
      ref={ref}
      component={Paper}
      sx={{
        borderRadius: { xs: 0, sm: 4 },
        textDecoration: "none",
        cursor: user?.username !== "guest" ? "pointer" : "initial",
      }}
      onClick={() => (user?.username !== "guest" ? navigate(`/feed/${item.id}`) : null)}
    >
      <FeedContent {...{ item, disabled, home: true, streamerId }} />
    </FlexCol>
  );
};
