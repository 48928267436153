import React from "react";
import { BoxProps, Typography } from "@mui/material";

import { FlexCol, FlexRow } from "../../../../components";
import { Formatter } from "../../../../utils";
import { RoundIcon } from "./RoundIcon";

type StatusBoxProps = {
  Icon: React.ElementType,
  label: string,
  value: number,
  increase?: number,
  decrease?: number,
  rate?: number
} & BoxProps;

type LabelProps = {
  value: number,
  symbol?: string,
}

const StatusLabel = ({ value, symbol }: LabelProps) => {
  return (
    <Typography component="span" sx={{ fontSize: 14, ml: 1.5, color: value > 0 ? "#27AE60" : "#EB5757" }}>
      {value > 0 ? "↑" : "↓"}
      {Number.isInteger(value) ? value : value.toFixed(2)}
      {symbol}
    </Typography>
  );
}

export const StatusBox = ({ Icon, label, value, increase = 0, decrease = 0, rate = 0, sx }: StatusBoxProps) => {
  return (
    <FlexRow sx={{ justifyContent: { xs: '-webkit-box', sm: 'start' }, marginRight: { xs: 0, sm: 4 }, minWidth: 200, ...sx }}>
      <RoundIcon Icon={Icon} />
      <FlexCol sx={{ ml: 2, justifyContent: 'center' }}>
        <Typography sx={{ color: '#A3AED0', fontSize: 14 }}>{label}</Typography>
        <FlexRow sx={{ alignItems: 'center' }}>
          <Typography sx={{ color: '#FFFFFF', fontSize: 24, fontWeight: 600 }}>{Formatter.formatNumber(value)}</Typography>
          {increase !== 0 && <StatusLabel value={increase} />}
          {decrease !== 0 && <StatusLabel value={decrease} />}
          {rate !== 0 && <StatusLabel value={rate} symbol="%" />}
        </FlexRow>
      </FlexCol>
    </FlexRow>
  );
};