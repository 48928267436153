import { Avatar, Box, CircularProgress, Popover, Typography } from "@mui/material";
import React from "react";
import { reactionToRender } from "../helpers";
import { DefaultAvatar } from "./DefaultAvatar";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";
import CustomCircularProgress from "./CustomCircularProgress";
import { BasePost } from "../types/feed";

type Props = {
  boltsReceivedAnchor: HTMLButtonElement | null;
  setBoltsReceivedAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  bolts?: BasePost["bolts"];
  loading?: boolean;
  onClose?: () => void;
};

const BoltsReceivedPopover = ({ boltsReceivedAnchor, setBoltsReceivedAnchor, bolts = [], loading, onClose }: Props) => {
  const open = Boolean(boltsReceivedAnchor);
  return (
    <Popover
      id="boltsReceived"
      open={open}
      anchorEl={boltsReceivedAnchor}
      anchorOrigin={{
        vertical: 30,
        horizontal: 0,
      }}
      PaperProps={{
        sx: { background: "#282C46" },
        onClick: (e) => e.stopPropagation(),
      }}
      onClose={(e: any) => {
        e.stopPropagation();
        setBoltsReceivedAnchor(null);
        if (onClose) onClose();
      }}
    >
      <FlexCol sx={{ py: 1.5, px: 1.875, gap: 1.25, minWidth: 130 }}>
        <FlexRow sx={{ justifyContent: "space-between", gap: 2, position: "relative", overflow: "hidden" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 12, lineHeight: "18px", color: "white", marginRight: "30px" }}>
            Gifts Received
          </Typography>
          {loading && <CustomCircularProgress sx={{ position: "absolute", top: -15, right: -15 }} />}
        </FlexRow>
        {bolts.map((data) => {
          return (
            <FlexRow key={data.sender.username} sx={{ alignItems: "center", justifyContent: "space-between", gap: 1 }}>
              <FlexRow sx={{ alignItems: "center" }}>
                {/* <Avatar
                  src={data.sender.photoUrl}
                  alt={data.sender.username}
                  sx={{
                    borderColor: "primary.main",
                    borderWidth: 1,
                    borderStyle: "solid",
                    height: 14,
                    width: 14,
                    marginRight: 1.25,
                  }}
                >
                  <DefaultAvatar />
                </Avatar> */}
                <Typography
                  key={data.sender.username}
                  sx={{ fontWeight: 400, fontSize: 10, lineHeight: "100%", color: "white" }}
                >
                  {data.sender.username}
                </Typography>
              </FlexRow>
              <Box
                sx={{
                  px: 0.575,
                  py: 0.4375,
                  background: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "2.6px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 600, fontSize: 9, lineHeight: "100%", color: "white" }}>
                  ${data.count}
                </Typography>
              </Box>
            </FlexRow>
          );
        })}
      </FlexCol>
    </Popover>
  );
};

export default BoltsReceivedPopover;
