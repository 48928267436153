import { all, fork, put, take } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { FollowedChannel } from "../../types";

function* fetchChannel() {
  while (true) {
    const { payload } = yield take(actions.FETCH_CHANNEL + START);
    try {
      const { data } = yield apiClient.get(`/channels/${payload}`);
      yield put({ type: actions.FETCH_CHANNEL + SUCCESS, payload: data });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.FETCH_CHANNEL + FAIL, payload: BoltError(error) });
    }
  }
}

function* fetchHomeCarousel() {
  while (true) {
    yield take(actions.FETCH_HOME_CAROUSEL + START);
    try {
      const { data } = yield apiClient.get("/carousels");
      yield put({
        type: actions.FETCH_HOME_CAROUSEL + SUCCESS,
        payload: data.data || [],
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_HOME_CAROUSEL + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchHomeChannels() {
  while (true) {
    yield take(actions.FETCH_HOME_CHANNELS + START);
    try {
      const { data } = yield apiClient.get(`/channels/getHomeChannels`);
      yield put({
        type: actions.FETCH_HOME_CHANNELS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_HOME_CHANNELS + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchChannelsByCategoryId() {
  while (true) {
    const { payload } = yield take(actions.FETCH_CHANNELS_BY_CAGETORY + START);
    try {
      const { data } = yield apiClient.get(`/channels/byCategory/${payload}`);
      yield put({
        type: actions.FETCH_CHANNELS_BY_CAGETORY + SUCCESS,
        payload: data,
      });
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_CHANNELS_BY_CAGETORY + FAIL,
        payload: error,
      });
    }
  }
}

function* fetchChannelTweets() {
  while (true) {
    const { payload } = yield take(actions.FETCH_CHANNEL_TWEETS + START);
    const { channelId, nextPageToken = "" } = payload;
    try {
      const { data } = yield apiClient.get(`/feed/stream/${channelId}/tweets?limit=10&pageToken=${nextPageToken}`);
      yield put({
        type: actions.FETCH_CHANNEL_TWEETS + SUCCESS,
        payload: { ...data, channelId },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_CHANNEL_TWEETS + FAIL,
        payload: { ...BoltError(error), channelId },
      });
    }
  }
}

function* checkChannelFollow() {
  while (true) {
    const { payload } = yield take(actions.CHECK_CHANNEL_FOLLOW + START);
    const { channelId } = payload;
    try {
      const { data } = yield apiClient.get(`/channels/follow/`);
      const foundChannel: FollowedChannel | undefined = data.find((item: any) => item.channel.channelId === channelId);
      yield put({
        type: actions.CHECK_CHANNEL_FOLLOW + SUCCESS,
        payload: foundChannel || false,
      });
    } catch (error) {
      yield put({
        type: actions.CHECK_CHANNEL_FOLLOW + FAIL,
        payload: { ...BoltError(error), channelId },
      });
    }
  }
}

function* changeChannelFollow() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_CHANNEL_FOLLOW + START);
    const { channelId, follow } = payload;
    try {
      const { data } = yield apiClient.post(`/channels/follow/${channelId}`, { follow });
      yield put({
        type: actions.CHANGE_CHANNEL_FOLLOW + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.CHANGE_CHANNEL_FOLLOW + FAIL,
        payload: { ...BoltError(error), channelId },
      });
    }
  }
}

export default function* channelSaga() {
  yield all([
    fork(fetchChannel),
    fork(fetchHomeCarousel),
    fork(fetchHomeChannels),
    fork(fetchChannelsByCategoryId),
    fork(fetchChannelTweets),
    fork(checkChannelFollow),
    fork(changeChannelFollow),
  ]);
}
