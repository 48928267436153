import React, { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import { useNavigate } from "react-router-dom";
import { Chip, IconButton, Menu, MenuItem, Slider, Tooltip, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import moment from "moment";

import PlayIcon from "@mui/icons-material/PlayArrowSharp";
import PauseIcon from "@mui/icons-material/PauseSharp";
import PipIcon from "@mui/icons-material/FeaturedVideo";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

import { TitleBar } from "./TitleBar";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { BoltPlayerState } from "../../types";
import { Formatter } from "../../utils";
import { ReactComponent as VolumeMuteIcon } from "../../assets/svgs/volume-mute.svg";
import { ReactComponent as VolumeMinIcon } from "../../assets/svgs/volume-min.svg";
import { ReactComponent as VolumeHalfIcon } from "../../assets/svgs/volume-half.svg";
import { ReactComponent as VolumeMaxIcon } from "../../assets/svgs/volume-max.svg";
import { ReactComponent as VideoPopoutIcon } from "../../assets/svgs/pipIcon.svg";
import { LiveDuration } from "../LiveDuration";
import { CaptionControl, LevelControl } from "../BoltPlayerV2/components";

const spin = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

type ResoultionMenuItemProps = {
  onClick: () => void;
  label: string;
  index: number;
  selectedIndex: number;
};

const ResoultionMenuItem = ({ onClick, label, index, selectedIndex }: ResoultionMenuItemProps) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        fontWeight: 500,
        fontSize: { xs: 9, sm: 12 },
        minHeight: 12,
        color: index === selectedIndex ? "#ffffff" : "#ffffff4d",
      }}
    >
      {label}
    </MenuItem>
  );
};

type Props = {
  title: string;
  description: string;
  playing: boolean;
  muted: boolean;
  volume: number;
  isPopup: boolean;
  isFloatingVideoPlayer: boolean;
  player: ReactPlayer;
  state: BoltPlayerState;
  type: "live" | "normal";
  isChatOpen?: boolean;
  setPlaying: (play: boolean) => void;
  togglePip?: () => void;
  toggleMute?: () => void;
  setMuted?: (value: any) => void;
  onChangeVolume?: (value: number) => void;
  handlePopout?: () => void;
  handleChatClose?: () => void;
  closeFloatingPlayer?: () => void;
  backToProfile?: () => void;
  playerValue?: any;
};

const PlayerControls = (props: Props) => {
  const {
    title,
    description,
    playing = true,
    muted = false,
    volume = 0.5,
    isPopup,
    isFloatingVideoPlayer,
    player,
    state,
    type,
    isChatOpen,
    setPlaying,
    togglePip,
    toggleMute,
    setMuted,
    onChangeVolume,
    handlePopout,
    handleChatClose,
    closeFloatingPlayer,
    backToProfile,
    playerValue,
  } = props;

  const navigate = useNavigate();
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState("Auto");
  const [anchorSettingEl, setAnchorSettingEl] = useState<null | HTMLElement>(null);
  const [reversedIndex, setReversedIndex] = useState(-1);
  const openSettingMenu = Boolean(anchorSettingEl);
  const hlsPlayer = player?.getInternalPlayer("hls");
  const dashPlayer = player?.getInternalPlayer("dash");

  const dashBitrates = hlsPlayer ? [] : dashPlayer?.getBitrateInfoListFor("video") || [];

  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSettingEl(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorSettingEl(null);
  };

  useEffect(() => {
    if (hlsPlayer) {
      const selectedLevel = reversedIndex === -1 ? -1 : hlsPlayer.levels.length - 1 - reversedIndex;
      if (!isFloatingVideoPlayer) {
        hlsPlayer.currentLevel = selectedLevel;
      }
      setSelectedResolution(selectedLevel === -1 ? "Auto" : `${hlsPlayer.levels[selectedLevel].height}p`);
    }

    if (dashBitrates.length > 0) {
      const selectedLevel = reversedIndex === -1 ? -1 : dashBitrates.length - 1 - reversedIndex;
      dashPlayer.setQualityFor("video", selectedLevel);
      const selectedItem = dashBitrates[selectedLevel];
      setSelectedResolution(
        selectedLevel === -1 ? "Auto" : `${selectedItem.height}p${selectedItem.bitrate % 100 === 0 ? "" : " (Source)"}`
      );
    }

    handleCloseSetting();
  }, [reversedIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseSetting);
    return () => {
      window.removeEventListener("scroll", handleCloseSetting);
    };
  }, []);

  const isActivieControls = playing || openSettingMenu;

  const handleSeekSliderChange = (event: Event, newValue: number | number[]) => {
    player?.seekTo(newValue as number);
  };

  const addedResolution = (index: number) => {
    if (hlsPlayer?.levels.length === 0 || hlsPlayer?.levels.length === index + 1) return false;
    const reversedLevels = [...hlsPlayer.levels].reverse();

    const findIndex = reversedLevels.findIndex(
      (item) => item.height === reversedLevels[index].height && item !== reversedLevels[index]
    );

    if (findIndex === -1) return false;

    return reversedLevels[index].height < reversedLevels[index + 1].height;
  };

  console.log("playerValue====", playerValue);

  return (
    <>
      <FlexCol
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          opacity: !playing || openSettingMenu ? 1 : 0,
          ":hover": { animation: isActivieControls ? `${spin} 0.5s ease-in-out` : "", opacity: 1 },
        }}
        // onDoubleClick={() => setPlaying(!playing)}
      >
        <FlexRow alignItems="center" justifyContent="space-between" sx={{ px: { xs: 0, sm: 1 } }}>
          <TitleBar title={title} description={description} isFloatingPlayer={isFloatingVideoPlayer} />
          {isFloatingVideoPlayer && (
            <IconButton
              sx={{
                p: 1,
                borderRadius: 6,
                position: "absolute",
                top: { xs: -1, sm: 8 },
                right: { xs: 0, sm: 10 },
                zIndex: 10,
              }}
              onClick={closeFloatingPlayer}
            >
              <CloseIcon />
            </IconButton>
          )}
        </FlexRow>
        {isFloatingVideoPlayer && (
          <FlexRow justifyContent="center" sx={{ mt: 2 }}>
            <IconButton style={{ borderRadius: 4, padding: 6, marginRight: 30 }} onClick={backToProfile}>
              <OpenInFullIcon />
            </IconButton>
            <IconButton style={{ borderRadius: 4, padding: 6 }} onClick={() => setPlaying(!playing)}>
              {playing ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
          </FlexRow>
        )}
        {!isPopup && !isChatOpen && !isFloatingVideoPlayer && (
          <IconButton
            disableRipple
            sx={{
              color: "white",
              mr: 1,
              alignSelf: "flex-end",
              transition: "all 0.3s ease-out",
              mb: -10,
              zIndex: 5,
              "&:hover": { transform: "scale(1.3) translateX(-10px)" },
            }}
            onClick={handleChatClose}
          >
            <KeyboardArrowLeftIcon />
            <MarkChatUnreadIcon />
          </IconButton>
        )}
        <FlexCol sx={{ zIndex: isFloatingVideoPlayer ? -1 : 2 }}>
          <FlexCol
            sx={{
              width: "100%",
              height: { xs: 80, md: 150, lg: 200 },
              px: { xs: 1, sm: 2 },
              pb: { xs: 0, sm: 2 },
              background: "linear-gradient(180deg, rgba(10, 12, 31, 0) 51.93%, #00000080)",
              alignItems: "end",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <FlexRow sx={{ width: "100%", height: "100%", flex: 1, alignItems: "end" }}>
              <FlexRow alignItems="center">
                <IconButton
                  onClick={() => setPlaying(!playing)}
                  style={{ borderRadius: 4.5, marginRight: 10, width: 35, height: 35 }}
                >
                  {!player.props.playing && <PlayIcon />}
                  {player.props.playing && type === "normal" && <PauseIcon />}
                  {player.props.playing && type === "live" && <PauseIcon />}
                </IconButton>
                <FlexRow sx={{ position: "relative" }} className="relative bolt-player">
                  <IconButton
                    style={{ borderRadius: 4.5 }}
                    onClick={toggleMute}
                    onMouseOver={() => setShowVolumeSlider(true)}
                    onMouseLeave={() => setShowVolumeSlider(false)}
                  >
                    {muted && <VolumeMuteIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume < 0.4 && <VolumeMinIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume >= 0.4 && volume < 0.9 && <VolumeHalfIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume >= 0.9 && <VolumeMaxIcon style={{ width: 18, height: 18 }} />}
                  </IconButton>
                  <Slider
                    orientation="vertical"
                    value={muted ? 0 : volume * 100}
                    min={0}
                    max={100}
                    sx={{
                      height: { xs: 80, sm: 100 },
                      position: "absolute",
                      bottom: "115%",
                      left: { xs: 0, sm: "4%" },
                      opacity: showVolumeSlider ? 1 : 0,
                      "& .MuiSlider-thumb": {
                        height: 14,
                        width: 14,
                        backgroundColor: "#fff",
                        border: "2px solid currentColor",
                        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": { boxShadow: "inherit" },
                        "&:before": { display: "none" },
                        "&:after": { height: 12 },
                      },
                      "& .MuiSlider-track": { width: 3 },
                    }}
                    onChange={(_, value) => {
                      if (onChangeVolume) onChangeVolume((value as number) / 100);
                      if (setMuted) setMuted(volume === 0);
                    }}
                    onMouseOver={() => setShowVolumeSlider(true)}
                    onMouseLeave={() => setShowVolumeSlider(false)}
                  />
                </FlexRow>
              </FlexRow>
              {type === "normal" && (
                <FlexRow sx={{ my: 0.5, ml: 0.5 }}>
                  <Typography className="text-white select-none text-xs w-8">
                    {Formatter.getDuration(state.playedSeconds)}
                  </Typography>
                  <Typography className="text-xs leading-tight px-px select-none">/</Typography>
                  <Typography className="text-white select-none text-xs w-8">
                    {Formatter.getDuration(player.getDuration() || state.loadedSeconds)}
                  </Typography>
                </FlexRow>
              )}

              <FlexCol sx={{ flex: 1, mx: 2 }}>
                <Slider
                  value={state.playedSeconds}
                  min={0}
                  max={player.getDuration() || 0}
                  aria-label="Default"
                  // valueLabelFormat={(value: number) => moment.utc(value * 1000).format(value >= 3600 ? "HH:mm:ss" : "mm:ss")}
                  // valueLabelDisplay="auto"
                  sx={{
                    display: type === "live" ? "none" : { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
                    "& .MuiSlider-thumb": {
                      height: 14,
                      width: 14,
                      backgroundColor: "#fff",
                      border: "2px solid currentColor",
                    },
                    "& .MuiSlider-track": { width: 3 },
                  }}
                  onChange={(_, value) => player.seekTo(value as number)}
                />
              </FlexCol>
              <FlexRow sx={{ alignItems: "center", height: 30 }}>
                <Typography sx={{ color: "#ffffff4d", mr: 0.5, fontWeight: 600, fontSize: { xs: 12, sm: 14 } }}>
                  {selectedResolution}
                </Typography>
                <Tooltip title="Video Resolution" placement="top">
                  <IconButton
                    style={{ borderRadius: 4.5 }}
                    onClick={handleClickSetting}
                    sx={{ mr: 1, backgroundColor: openSettingMenu ? "#ffffff1a" : "transparent" }}
                    id="player_setting_button"
                    aria-controls={openSettingMenu ? "player_setting_menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSettingMenu ? "true" : undefined}
                  >
                    <SettingsIcon style={{ width: 18, height: 18 }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="player_setting_menu"
                  aria-labelledby="player_setting_button"
                  anchorEl={anchorSettingEl}
                  open={openSettingMenu}
                  onClose={handleCloseSetting}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                  anchorOrigin={{ vertical: -2, horizontal: "right" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                  disableScrollLock
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: 80,
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mb: { xs: 0, sm: 2 },
                    },
                  }}
                >
                  <ResoultionMenuItem
                    onClick={() => setReversedIndex(-1)}
                    index={-1}
                    selectedIndex={reversedIndex}
                    label="Auto"
                  />
                  {hlsPlayer &&
                    hlsPlayer.levels.length > 0 &&
                    [...hlsPlayer.levels].reverse().map((item: any, index: number) => {
                      if (item.height === 0 || addedResolution(index)) return null;
                      return (
                        <ResoultionMenuItem
                          key={index}
                          onClick={() => setReversedIndex(index)}
                          index={index}
                          selectedIndex={reversedIndex}
                          label={`${item.height}p`}
                        />
                      );
                    })}
                  {dashPlayer &&
                    dashBitrates.length > 0 &&
                    [...dashBitrates].reverse().map((item: any, index: number) => {
                      if (item.height === 0) return null;
                      return (
                        <ResoultionMenuItem
                          key={index}
                          onClick={() => setReversedIndex(index)}
                          index={index}
                          selectedIndex={reversedIndex}
                          label={`${item.height}p${item.bitrate % 100 === 0 ? "" : " (Source)"}`}
                        />
                      );
                    })}
                </Menu>

                {/* <LevelControl player={player!} /> */}
                <CaptionControl player={playerValue!} />
                {!isPopup && (
                  <>
                    <Tooltip title="Pip Mode" placement="top">
                      <IconButton style={{ borderRadius: 4.5 }} onClick={togglePip} sx={{ mr: 1 }}>
                        <PipIcon sx={{ width: 18, height: 18 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Popout Video" placement="top">
                      <IconButton
                        style={{ borderRadius: 4.5 }}
                        onClick={() => {
                          if (handlePopout) handlePopout();
                          setPlaying(false);
                        }}
                        sx={{ mr: 1, display: { xs: "none", md: "inline-flex" } }}
                      >
                        <VideoPopoutIcon style={{ width: 18, height: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Full Screen" placement="top">
                  <IconButton
                    style={{ borderRadius: 4.5 }}
                    onClick={() => {
                      // @ts-ignore
                      screenfull.request(player.getInternalPlayer());
                    }}
                  >
                    <FullScreenIcon sx={{ width: 22, height: 22 }} />
                  </IconButton>
                </Tooltip>
              </FlexRow>
            </FlexRow>
            {type === "normal" && (
              <Slider
                value={state.playedSeconds}
                min={0}
                max={player.getDuration() || 0}
                sx={{
                  mx: "auto",
                  width: { xs: "90%", sm: "95%" },
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
                  "& .MuiSlider-thumb": {
                    height: 14,
                    width: 14,
                    backgroundColor: "#fff",
                    border: "2px solid currentColor",
                    "&:after": { height: 12 },
                  },
                  "& .MuiSlider-track": { width: 3 },
                }}
                onChange={handleSeekSliderChange}
              />
            )}
          </FlexCol>
        </FlexCol>
      </FlexCol>
      {type === "live" && !isFloatingVideoPlayer && (
        <FlexCol
          className="live-counter"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            alignItems: "flex-end",
            px: { xs: 0, sm: 1 },
            pt: { xs: 1, sm: 2 },
            pointerEvents: "none",
          }}
        >
          <FlexRow sx={{ mr: 2, zIndex: 5, alignItems: "center" }}>
            {/*
            <LiveDuration startDate={moment().utc().startOf("D").toDate()} />
            <Chip color="error" label="LIVE" size="small" sx={{ fontWeight: "600", px: 0.5 }} />
            */}
          </FlexRow>
        </FlexCol>
      )}
    </>
  );
};

export default PlayerControls;
