import { Avatar, CircularProgress, Popover, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { reactionToRender } from "../helpers";
import { DefaultAvatar } from "./DefaultAvatar";
import { FlexCol } from "./FlexCol";
import { FlexRow } from "./FlexRow";
import { ReactionUsersObjectType } from "./ReactionButtons";
import CustomCircularProgress from "./CustomCircularProgress";

type Props = {
  reactedAnchor: HTMLButtonElement | null;
  setReactedAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  reactionUsers: ReactionUsersObjectType;
  loading?: boolean;
  onClose?: () => void;
};

const ReactionUsersPopover = ({ reactedAnchor, setReactedAnchor, reactionUsers, loading, onClose }: Props) => {
  const reactedOpen = Boolean(reactedAnchor);

  useEffect(() => {
    const handler = () => setReactedAnchor(null);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <Popover
      id="reactions"
      open={reactedOpen}
      anchorEl={reactedAnchor}
      disableScrollLock
      anchorOrigin={{
        vertical: 30,
        horizontal: 0,
      }}
      PaperProps={{
        sx: { background: "#282C46" },
        onClick: (e) => e.stopPropagation(),
      }}
      onClose={(e: any) => {
        e.stopPropagation();
        setReactedAnchor(null);
        if (onClose) onClose();
      }}
    >
      <FlexCol sx={{ py: 1.5, px: 1.875, gap: 1.25, minWidth: 130 }}>
        <FlexRow sx={{ justifyContent: "space-between", gap: 2, position: "relative", overflow: "hidden" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 12, lineHeight: "18px", color: "white" }}>Reactions</Typography>
          {loading && <CustomCircularProgress sx={{ position: "absolute", top: -15, right: -15 }} />}
        </FlexRow>
        {reactionUsers.map((data) => {
          return (
            <FlexRow key={data.username} sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <FlexRow sx={{ alignItems: "center" }}>
                {/* <Avatar
                  src={data.photoUrl}
                  alt={data.username}
                  sx={{
                    borderColor: "primary.main",
                    borderWidth: 1,
                    borderStyle: "solid",
                    height: 14,
                    width: 14,
                    marginRight: 1.25,
                  }}
                >
                  <DefaultAvatar />
                </Avatar> */}
                <Typography
                  key={data.username}
                  sx={{ fontWeight: 400, fontSize: 10, lineHeight: "100%", color: "white" }}
                >
                  {data.fullName}
                </Typography>
              </FlexRow>
              <Typography sx={{ fontWeight: 300, fontSize: 12, lineHeight: "100%" }}>
                {reactionToRender(data.type)}
              </Typography>
            </FlexRow>
          );
        })}
      </FlexCol>
    </Popover>
  );
};

export default ReactionUsersPopover;
