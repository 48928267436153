import { Container, Typography } from "@mui/material";
import React from "react";
import { OnboardingForm } from "../../components";

interface Props {}

export const OnboardingPage = (props: Props) => {
  return (
    <Container maxWidth="md" disableGutters sx={{ margin: "auto" }}>
      <OnboardingForm />
    </Container>
  );
};
