import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";

import { EpisodeItem } from "./EpisodeItem";
import { useEpisodeItems } from "./useEpisodeItems";
import { useCurrentEpisode } from "./useCurrentEpisode";

export const EpisodesCarousel = () => {
  const { episodeItems } = useEpisodeItems();

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [AutoScroll({ speed: 1, startDelay: 100, stopOnFocusIn: false })]
  );
  const [isPlaying, setIsPlaying] = useState(true);

  const { currentEpisode } = useCurrentEpisode();

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    if (isPlaying) {
      autoScroll.play();
    } else {
      autoScroll.stop();
    }
  }, [emblaApi, isPlaying]);

  const onMouseEnter = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsPlaying(true);
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Box ref={emblaRef} sx={{ overflow: "hidden" }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Box sx={{ display: "flex" }}>
          {episodeItems.map((item, index) => (
            <Box key={`browse-item-${index}`}>
              <EpisodeItem {...item} active={item.id === currentEpisode.id} />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          zIndex: 10,
          height: "100%",
          width: "10%",
          background: "linear-gradient(90deg, rgba(15,16,39,1) 0%, rgba(15,16,39,0.5) 39%, rgba(255,255,255,0) 100%)",
          pointerEvents: "none",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 10,
          height: "100%",
          width: "10%",
          background: "linear-gradient(270deg, rgba(15,16,39,1) 0%, rgba(15,16,39,0.5) 39%, rgba(255,255,255,0) 100%)",
          pointerEvents: "none",
        }}
      />
    </Box>
  );
};
