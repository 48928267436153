import { Avatar, Button, IconButton, ModalProps, TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../store/auth/selector";
import { DefaultAvatar } from "../DefaultAvatar";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { BaseModal } from "./BaseModal";
import { ReactComponent as Rebolt } from "../../assets/svgs/repost.svg";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import FeedContentTop from "../FeedContentTop";
import { NFTFeed, StreamFeed } from "../../types";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { fetchHomeStreamFeed } from "../../store/feed/actions";

interface Props extends Partial<ModalProps> {
  item: StreamFeed | NFTFeed;
}

export const ReboltModal = ({ item, open, onClose, ...rest }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [input, setInput] = useState("");

  const rebolt = useCallback(async () => {
    const url = `/v2/feed/stream/rebolt/${item.reboltFeed ? item.reboltFeed.id : item.id}`;
    try {
      await apiClient.post(url, {
        text: input,
      });
      ToastService.showSuccessMessage("Rebolted!");
      dispatch(fetchHomeStreamFeed());
      if (onClose) onClose({}, "escapeKeyDown");
    } catch (error) {
      ToastService.showErrorMessage(BoltError(error).message);
    }
  }, [item, input, dispatch]);

  return (
    <BaseModal {...rest} open={!!open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <FlexCol
          sx={{
            px: { xs: 1.75, sm: 2.875 },
            py: 2.5,
            backgroundColor: "#13162C",
            borderRadius: "10px",
            width: { xs: "calc(100% - 20px)", sm: 435 },
            maxHeight: "80%",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <FlexRow sx={{ gap: "15.48px", alignItems: "center", mb: 1.75 }}>
            <Avatar
              src={user?.photoUrl}
              alt="user profile"
              sx={{
                borderColor: "primary.main",
                borderWidth: 1,
                borderStyle: "solid",
                height: 28.52,
                width: 28.52,
              }}
            >
              <DefaultAvatar />
            </Avatar>
            <FlexRow sx={{ justifyContent: "space-between", alignItems: "flex-start", flex: 1 }}>
              <FlexCol>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "white",
                    letterSpacing: "-0.004em",
                  }}
                >
                  {user?.username}
                </Typography>
                <FlexRow sx={{ alignItems: "center", gap: "5px" }}>
                  <Rebolt />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "rgba(255,255,255,0.25)",
                      letterSpacing: "-0.004em",
                    }}
                  >
                    rebolt this
                  </Typography>
                </FlexRow>
              </FlexCol>
              <IconButton sx={{ marginRight: "-8px" }} onClick={(e) => !!onClose && onClose(e, "backdropClick")}>
                <Close />
              </IconButton>
            </FlexRow>
          </FlexRow>
          <FlexRow sx={{ mb: 1.25 }}>
            <TextareaAutosize
              autoFocus
              placeholder="Add your thoughts.."
              style={{
                backgroundColor: "inherit",
                border: "none",
                flex: 1,
                fontFamily: "inherit",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "21px",
                color: "white",
                outline: "none",
                marginBottom: 2.375,
              }}
              className="placeholder-color"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </FlexRow>
          <Box
            className="-webkit-chatbox-scrollbar"
            sx={{ border: "1px solid rgba(255,255,255,0.1)", borderRadius: "5px", mb: 2.5, overflow: "auto" }}
          >
            <FeedContentTop {...{ item, rebolt: true }} />
          </Box>
          <FlexRow>
            <Button
              onClick={rebolt}
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "100%",
                color: "white",
                padding: "10px 15px",
                backgroundColor: "#6535E9",
                borderRadius: "22px",
                ":hover": {
                  backgroundColor: "#6535E999",
                },
              }}
            >
              <Rebolt style={{ marginRight: 7 }} />
              Rebolt
            </Button>
          </FlexRow>
        </FlexCol>
      </Box>
    </BaseModal>
  );
};
