import React from "react";
import { Fab, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomOption } from "../../types";

type Props = {};

const defaultFilters: CustomOption[] = [
  { label: "New", value: "new" },
  { label: "Hot", value: "hot" },
  { label: "Explore", value: "explore" },
];

export const NFTFeedFilter = (props: Props) => {
  const theme = useTheme();
  const [selected, setSelected] = React.useState(defaultFilters[0]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item: CustomOption) => {
    setSelected(item);
    setAnchorEl(undefined);
  };
  return (
    <>
      <Fab
        sx={{
          textTransform: "none",
          height: 24,
          pr: 1,
          bgcolor: "background.paper",
          zIndex: 0,
        }}
        color="secondary"
        variant="extended"
        onClick={handleClick}
      >
        <Typography variant="caption" sx={{ px: 1, color: theme.palette.mode === "dark" ? "white" : "black" }}>
          {selected.label}
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
      </Fab>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose(selected)}>
        {defaultFilters.map(({ label, value }) => (
          <MenuItem selected={selected.value === value} key={value} onClick={() => handleClose({ label, value })}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
