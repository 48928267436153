import { useMediaQuery, useTheme } from "@mui/material";

import { NAV_BAR_HEIGHT } from "../../constants";
import { StepScrollContainer } from "../../components";
import { useDiscoverPageContext } from "./DiscoverPageContext";
import { Background } from "./Background";
import { PageLoader } from "./PageLoader";
import { Overlay } from "./Overlay";
import { Content } from "./Content";
import { Sliders } from "./Sliders";

export const DiscoverPageUi = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { isLoadingCollections } = useDiscoverPageContext();

  if (isLoadingCollections) {
    return <PageLoader />;
  }

  return (
    <>
      <Background />

      <Overlay />

      <StepScrollContainer
        containerSx={{
          height: `calc(100vh - ${NAV_BAR_HEIGHT}px - ${isMobile ? "65px" : "0px"})`,
        }}
      >
        <Content />

        <Sliders />
      </StepScrollContainer>
    </>
  );
};
