import React, { createContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useAccount, useSignMessage, useDisconnect } from "wagmi";
import { initWalletAction } from "../store/wallet/actions";
import { fetchLoginWalletAction } from "../store/auth/actions";
import { apiClient } from "../config";
import { userSelector } from "../store/auth/selector";
import { BoltWallet } from "../types";
import { BoltError, ToastService } from "../services";

interface Props {
  isLogin?: boolean;
  children: React.ReactNode;
}

interface ContextInterface {
  disconnectAll: () => void;
}

export const ConnectWalletContext = createContext<ContextInterface | undefined>(undefined);

const ConnectWalletWrapper = ({ children, isLogin = false }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { disconnect } = useDisconnect();

  const { address, isConnected, connector, status } = useAccount();
  const { signMessageAsync, isError } = useSignMessage();

  useEffect(() => {
    const initialize = async () => {
      if (["connecting", "connected"].includes(status)) {
        if (connector?.name === "MetaMask" && isConnected) {
          try {
            if (isLogin) {
              const { data } = await apiClient.get(`/auth/wallet/${address}/nonce/${BoltWallet.METAMASK}`);
              const signature = await signMessageAsync({ message: data.msg });
              dispatch(fetchLoginWalletAction({ walletAddress: address, signature, wallet: BoltWallet.METAMASK }));
            } else {
              dispatch(initWalletAction({ walletAddress: address, connectedTo: BoltWallet.METAMASK }));
            }
          } catch (error) {
            disconnect();
            ToastService.showErrorMessage(BoltError(error).message);
          }
        }

        if (connector?.name === "WalletConnect" && isConnected) {
          try {
            if (isLogin) {
              const { data } = await apiClient.get(`/auth/wallet/${address}/nonce/${BoltWallet.WALLET_CONNECT}`);
              const signature = await signMessageAsync({ message: data.msg });
              dispatch(
                fetchLoginWalletAction({ walletAddress: address, signature, wallet: BoltWallet.WALLET_CONNECT })
              );
            } else {
              dispatch(initWalletAction({ walletAddress: address, connectedTo: BoltWallet.WALLET_CONNECT }));
            }
          } catch (error) {
            disconnect();
            ToastService.showErrorMessage(BoltError(error).message);
          }
        }

        if (connector?.name === "OKX Wallet" && isConnected) {
          try {
            if (isLogin) {
              const { data } = await apiClient.get(`/auth/wallet/${address}/nonce/${BoltWallet.OKX_WALLET}`);
              const signature = await signMessageAsync({ message: data.msg });
              dispatch(fetchLoginWalletAction({ walletAddress: address, signature, wallet: BoltWallet.OKX_WALLET }));
            } else {
              dispatch(initWalletAction({ walletAddress: address, connectedTo: BoltWallet.OKX_WALLET }));
            }
          } catch (error) {
            disconnect();
            ToastService.showErrorMessage(BoltError(error).message);
          }
        }
      }
    };

    initialize();

    if (isError) {
      ToastService.showErrorMessage("User denied message signature");
    }
  }, [status, isConnected, isError]);

  const value = React.useMemo(
    () => ({
      disconnectAll: () => {
        useDisconnect();
        dispatch(
          initWalletAction({
            accounts: [],
            connectedTo: BoltWallet.METAMASK,
          })
        );
      },
    }),
    []
  );

  return <ConnectWalletContext.Provider value={value}>{children}</ConnectWalletContext.Provider>;
};

export default ConnectWalletWrapper;
