import React from "react";

import { Box, Modal, ModalProps } from "@mui/material";

export const BaseModal = ({ children, sx, ...rest }: ModalProps) => {
  return (
    <Modal
      {...rest}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: "blur(7px)", ...sx }}
    >
      {children}
    </Modal>
  );
};
