import React from "react";
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import { FlexRow } from "..";
import { CustomSelector, Option } from "../CustomSelector";
import { ReactComponent as BinanceUSDIcon } from "../../assets/svgs/binanceUSD.svg";
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert.svg";
import { updateBinancePayoutRequest } from "../../store/payment/actions";
import { useWithdraw } from "../../hooks/WithdrawContext";
import { BinanceDestination } from "../../types";

const tokenList: Option[] = [
  { icon: <BinanceUSDIcon />, label: "Binance USD", value: "binanceUSD" },
  { icon: <BinanceUSDIcon />, label: "BNB", value: "bnb" },
];

const destination: Option[] = [
  // { label: "Pay ID", value: BinanceDestination.PAY_ID }, // disable for launch
  { label: "Binance ID", value: BinanceDestination.BINANCE_ID },
  { label: "Email Address", value: BinanceDestination.EMAIL },
];

export const WithdrawTabBinance = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    values: {
      balance: { binance: binanceAvialbleBalance },
      withdrawAmount: amount,
      binanceInfo,
    },
    setValues: setWithdrawValues,
    handleWithdraw,
  } = useWithdraw();

  const binanceHelp = "https://www.binance.com/en-NG/support/faq/e23f61cafb5a4307bfb32506bd39f89d";

  const onChangeAmount = (e: any) => {
    setWithdrawValues({ withdrawAmount: Number(e.target.value) });
    dispatch(updateBinancePayoutRequest({ transferAmount: Number(e.target.value) }));
  };

  const onChangeDestination = (v: string) => {
    setWithdrawValues({ binanceInfo: { ...binanceInfo, destination: v as BinanceDestination } });
    dispatch(updateBinancePayoutRequest({ receiveType: v }));
  };

  const onChangeReceiver = (e: any) => {
    setWithdrawValues({ binanceInfo: { ...binanceInfo, value: e.target.value } });
    dispatch(updateBinancePayoutRequest({ receiver: e.target.value }));
  };

  const renderDestinationType = () => {
    if (binanceInfo.destination === BinanceDestination.PAY_ID) {
      return (
        <Grid container spacing={0} direction="column">
          <Grid item>
            <Typography
              sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mb: "6px", mt: "10px" }}
            >
              Pay ID
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              name="payId"
              placeholder="Enter your Pay ID"
              inputProps={{
                style: {
                  backgroundColor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
                  border: "0.5px solid rgba(255, 255, 255, 0.15)",
                  fontWeight: 300,
                  fontSize: 16,
                  lineHeight: "30px",
                  height: "24px",
                  padding: "8px",
                  paddingLeft: "17px",
                  paddingRight: "17px",
                  borderRadius: "6px",
                },
              }}
              value={binanceInfo.value}
              onChange={onChangeReceiver}
              fullWidth
            />
          </Grid>
        </Grid>
      );
    }

    if (binanceInfo.destination === BinanceDestination.BINANCE_ID) {
      return (
        <Grid container spacing={0} direction="column">
          <Grid item>
            <FlexRow sx={{ mb: "6px", mt: "10px" }}>
              <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
                Binance ID
              </Typography>
              <IconButton
                aria-label="Find out Binance ID"
                sx={{ height: "15px", width: "15px", ml: "5px" }}
                onClick={() => window.open(binanceHelp)}
              >
                <HelpIcon sx={{ fontSize: 12 }} />
              </IconButton>
            </FlexRow>
          </Grid>
          <Grid item>
            <TextField
              name="binanceId"
              placeholder="Binance ID"
              inputProps={{
                style: {
                  backgroundColor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
                  border: "0.5px solid rgba(255, 255, 255, 0.15)",
                  fontWeight: 300,
                  fontSize: 16,
                  lineHeight: "30px",
                  height: "24px",
                  padding: "8px",
                  paddingLeft: "17px",
                  paddingRight: "17px",
                  borderRadius: "6px",
                },
              }}
              value={binanceInfo.value}
              onChange={onChangeReceiver}
              fullWidth
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={0} direction="column">
        <Grid item>
          <Typography
            sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mb: "6px", mt: "10px" }}
          >
            Email
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            name="email"
            placeholder="Email Address"
            inputProps={{
              style: {
                backgroundColor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
                border: "0.5px solid rgba(255, 255, 255, 0.15)",
                fontWeight: 300,
                fontSize: 16,
                lineHeight: "30px",
                height: "24px",
                padding: "8px",
                paddingLeft: "17px",
                paddingRight: "17px",
                borderRadius: "6px",
              },
            }}
            value={binanceInfo.value}
            onChange={onChangeReceiver}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container rowSpacing={1}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container spacing={0} direction="column">
          <Grid item>
            <Typography
              sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mb: "6px", mt: "10px" }}
            >
              Destination
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelector options={destination} onClick={(val) => onChangeDestination(val.value)} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        {renderDestinationType()}
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        <Grid container spacing={0} direction="column">
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mb: "6px", mt: "10px" }}
                >
                  Amount to Withdraw
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px" }}>
                      Balance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontWeight: 600, fontSize: 10, lineHeight: "15px", ml: "3px" }}>
                      ${binanceAvialbleBalance}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              name="amount"
              type="number"
              placeholder="$0.00"
              inputProps={{
                min: 2,
                style: {
                  backgroundColor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
                  border: "0.5px solid rgba(255, 255, 255, 0.15)",
                  fontWeight: 300,
                  fontSize: 20,
                  lineHeight: "30px",
                  height: "24px",
                  padding: "8px",
                  paddingLeft: "17px",
                  paddingRight: "17px",
                  borderRadius: "6px",
                },
              }}
              value={amount}
              error={amount > binanceAvialbleBalance || amount < 2}
              onChange={onChangeAmount}
              fullWidth
            />
          </Grid>

          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <AlertIcon />
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#A3AED0", fontWeight: 300, fontSize: 8, lineHeight: "15px", ml: "5px" }}>
                      Minimum amount to withdraw is $2
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 600, fontSize: 10, lineHeight: "15px" }}>$1 ⁓ 0.96 BUSD</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="center" sx={{ my: 2 }}>
        <Button
          variant="contained"
          onClick={handleWithdraw}
          disabled={amount > binanceAvialbleBalance || !binanceInfo.value}
          sx={{ textTransform: "none", height: 34, borderRadius: "25px", px: "44px" }}
        >
          <Typography variant="subtitle1">Withdraw</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
