import React, { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  textColor?: string;
  showText?: boolean;
}

export const GiphyIcon = ({ textColor = "white", showText = true, ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      // @ts-ignore
      // enableBackground: "new 0 0 2794.67 1847.88",
    }}
    viewBox={showText ? "0 0 2794.67 1847.88" : "0 0 947.88 1847.88"}
    {...props}
  >
    <path
      d="M208.8 717.97h305.14v411.94H208.8V717.97z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
    <path
      d="M147.77 702.71h61.03v442.46h-61.03V702.71z"
      style={{
        fill: "#04ff8e",
      }}
    />
    <path
      d="M513.94 824.77h61.03v320.4h-61.03v-320.4z"
      style={{
        fill: "#8e2eff",
      }}
    />
    <path
      d="M147.77 1129.91h427.2v61.03h-427.2v-61.03z"
      style={{
        fill: "#00c5ff",
      }}
    />
    <path
      d="M147.77 656.94h244.11v61.03H147.77v-61.03z"
      style={{
        fill: "#fff152",
      }}
    />
    <path
      d="M513.94 779v-61.03h-61.03v-61.03h-61.03v183.09h183.09V779"
      style={{
        fill: "#ff5b5b",
      }}
    />
    <path
      d="M513.94 901.06v-61.03h61.03"
      style={{
        fill: "#551c99",
      }}
    />
    <path
      d="M391.89 656.94v61.03h-61.03"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#999131",
      }}
    />
    {showText && (
      <path
        style={{
          fill: textColor,
        }}
        d="M1049.47 840.03c-30.51-28.99-67.13-36.62-94.59-36.62-67.13 0-111.38 39.67-111.38 122.06 0 53.4 27.46 119.01 111.38 119.01 21.36 0 56.45-4.58 79.34-21.36v-53.4H928.94v-91.54h202.92v184.61c-25.94 53.4-97.65 80.86-178.51 80.86-163.25 0-225.81-109.85-225.81-218.18s71.71-219.7 227.33-219.7c57.98 0 108.33 12.21 163.25 67.13l-68.65 67.13zm138.84 292.94v-415h115.95v414.99h-115.95zm306.67-112.91v111.38H1377.5V717.97h201.39c111.38 0 166.3 70.18 166.3 151.05 0 85.44-54.93 151.05-166.3 151.05h-83.91zm0-99.17h83.91c32.04 0 48.82-24.41 48.82-50.35 0-27.46-16.78-51.87-48.82-51.87h-83.91v102.22zm559.94 212.08V975.82H1905.4v157.15h-117.48v-415h117.48v157.15h149.52V717.97h115.95v414.99h-115.95zm369.22-273.11 90.02-141.89h132.74v4.58l-164.78 244.11v164.78h-117.48V966.66l-157.15-244.11v-4.58h132.74l83.91 141.89z"
      />
    )}
  </svg>
);
