import { useCallback, useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import { CollectionItemProps, CollectionItems } from "./collection-items";
import { useCollectionPageContext } from "./context";

export const LiveChannels = () => {
  const theme = useTheme();

  const { items, isLoadingMore, hideShowMore, onShowMore } = useLiveChannelsItems();

  return (
    <CollectionItems
      title={
        <Box sx={{ height: 40 }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
            <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Live Channels</Typography>

            <Box sx={{ width: 10, height: 10, bgcolor: theme.palette.error.main, borderRadius: "50%" }} />
          </Box>
        </Box>
      }
      itemBadge={
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 12,
            bgcolor: theme.palette.error.main,
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            columnGap: 0.5,
            px: 0.6,
            py: 0.2,
          }}
        >
          <Box sx={{ width: 5, height: 5, bgcolor: "#fff", borderRadius: 5 }} />

          <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>LIVE</Typography>
        </Box>
      }
      items={items}
      isLoadingMore={isLoadingMore}
      hideShowMore={hideShowMore}
      onShowMore={onShowMore}
    />
  );
};

const useLiveChannelsItems = () => {
  const { channels, isLoadingChannels, getCollectionChannels } = useCollectionPageContext();

  const items: CollectionItemProps[] = channels.data.map((item) => {
    return {
      thumbnail: item.thumbnail,
      avatar: item.logo,
      title: item.title,
      description: item.description,
      slug: item.channelId,
    };
  });

  const hideShowMore = useMemo(() => {
    return channels.data.length === channels.total;
  }, [channels.data.length, channels.total]);

  const onShowMore = useCallback(() => {
    getCollectionChannels({ page: channels.page + 1, moreData: true });
  }, [getCollectionChannels, channels.page]);

  return { items, isLoadingMore: isLoadingChannels, hideShowMore, onShowMore };
};
