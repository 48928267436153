import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { orderBy, unionBy } from "lodash";

import { apiClient } from "../../config";
import { ChannelCollection, ChannelCollectionItem, Collection, SelectedItem } from "./types";

type DiscoverPageContextType = {
  collections: Collection[];
  isLoadingCollections: boolean;
  selectedItem?: SelectedItem;
  bottomOffset: number;
  //
  updateContext: (payload: Partial<Omit<DiscoverPageContextType, "updateContext">>) => void;
};

const defaultDiscoverPageContextValue: DiscoverPageContextType = {
  collections: [],
  isLoadingCollections: true,
  selectedItem: undefined,
  bottomOffset: 34,
  updateContext: () => {},
};

export const DiscoverPageContext = createContext<DiscoverPageContextType>(defaultDiscoverPageContextValue);

type DiscoverPageProviderProps = PropsWithChildren<{}>;

export const DiscoverPageProvider = ({ children }: DiscoverPageProviderProps) => {
  const [contextValue, setContextValue] = useState<DiscoverPageContextType>(defaultDiscoverPageContextValue);

  const updateContext: DiscoverPageContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  const fetchHomeChannels = useCallback(async () => {
    try {
      updateContext({ isLoadingCollections: true });

      const { data } = await apiClient.get(`/channels/getHomeChannelsV2`);

      const popularOnBoltCollectionItems = orderBy(
        unionBy(
          Object.values(data).reduce((a: ChannelCollectionItem[], b: any) => {
            if (b.type === "channel") {
              return a.concat(b.items);
            }

            return a;
          }, []),
          "id"
        ),
        ["order", "createdAt", "title"],
        ["asc", "desc", "asc"]
      );

      let collections: Collection[] = [];
      // [
      //   {
      //     title: "Popular on Bolt+",
      //     order: 0,
      //     type: "channel",
      //     items: popularOnBoltCollectionItems,
      //   } as ChannelCollection,
      // ];

      collections = orderBy(
        Object.values(data).reduce((prev: Collection[], curr: any) => {
          return [...prev, curr];
        }, collections),
        ["order"],
        ["asc"]
      );

      updateContext({
        collections,
        selectedItem: {
          row: 0,
          column: 0,
          logo: popularOnBoltCollectionItems[0].logo,
          collectionName: collections[0].title,
          shortDescription: popularOnBoltCollectionItems[0].shortDescription,
          description: popularOnBoltCollectionItems[0].description,
          slug: popularOnBoltCollectionItems[0].channelId,
          video: popularOnBoltCollectionItems[0].url,
        },
      });
    } catch (err) {
      console.error("Error at fetchHomeChannels: ", err);
    } finally {
      updateContext({ isLoadingCollections: false });
    }
  }, []);

  useEffect(() => {
    fetchHomeChannels();
  }, [fetchHomeChannels]);

  const memoizedValue = useMemo(
    () => ({
      ...contextValue,
      updateContext,
    }),
    [contextValue]
  );

  return <DiscoverPageContext.Provider value={memoizedValue}>{children}</DiscoverPageContext.Provider>;
};

export const useDiscoverPageContext = () => {
  const context = useContext(DiscoverPageContext);

  return context as Omit<DiscoverPageContextType, "selectedChannel"> & {
    selectedItem: SelectedItem;
  };
};
