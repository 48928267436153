import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CHANNEL_TYPE, Message, RECEIVER_TYPE } from "../../../types";
import { socketSelector } from "../../../store/socket/selector";
import { fetchHistoryChannelComunity, joinChannel, leaveChannel } from "../../../store/socket/actions";

type ChatContextType = {
  containerId?: string;
  channelId: string;
  channelType: CHANNEL_TYPE;
  receiverName: string;
  receiverType: RECEIVER_TYPE;
  //
  messages: Message[];
  isLoadingMessages: boolean;
  //
  loadMore: (list: any) => void;
  updateContext: (payload: Partial<Omit<ChatContextType, "updateContext" | "loadMore">>) => void;
};

const defaultChatContextValue: ChatContextType = {
  channelId: "",
  channelType: CHANNEL_TYPE.CHANNEL,
  receiverName: "",
  receiverType: RECEIVER_TYPE.CHANNEL,
  //
  messages: [],
  isLoadingMessages: true,
  //
  loadMore: () => {},
  updateContext: () => {},
};

export const ChatContext = createContext<ChatContextType>(defaultChatContextValue);

export type ChatContextProviderProps = Pick<
  ChatContextType,
  "containerId" | "channelId" | "channelType" | "receiverName" | "receiverType"
>;

export const ChatContextProvider = ({
  children,
  containerId,
  channelId,
  channelType,
  receiverName,
  receiverType,
}: PropsWithChildren<ChatContextProviderProps>) => {
  const [contextValue, setContextValue] = useState<ChatContextType>(defaultChatContextValue);

  const updateContext: ChatContextType["updateContext"] = (payload) => {
    setContextValue((prev) => ({ ...prev, ...payload }));
  };

  const dispatch = useDispatch();

  const { socketConnected, messages, loading, hasMore } = useSelector(socketSelector);

  const loadMore = useCallback(
    (list: any) => {
      if (list?.[0]?.isIntersecting && !loading && hasMore) {
        dispatch(
          fetchHistoryChannelComunity({
            channelId,
            channelType,
            lastMessageId: messages[messages.length - 1]?.id,
          })
        );
      }
    },
    [messages, channelId, channelType, loading, hasMore]
  );

  useEffect(() => {
    if (channelId && socketConnected) {
      dispatch(fetchHistoryChannelComunity({ channelId, channelType }));
      dispatch(joinChannel({ channelId, channelType }));
    }

    return () => {
      if (socketConnected) {
        dispatch(leaveChannel());
      }
    };
  }, [channelId, channelType, socketConnected]);

  const memoizedValue = useMemo(() => {
    return {
      ...contextValue,
      containerId,
      channelId,
      channelType,
      receiverName,
      receiverType,
      messages,
      isLoadingMessages: loading,
      loadMore,
      updateContext,
    };
  }, [contextValue, channelId, channelType, receiverName, receiverType, messages, loading, loadMore, updateContext]);

  return <ChatContext.Provider value={memoizedValue}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
  const context = useContext(ChatContext);

  return context;
};
