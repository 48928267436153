import React from "react";
import { Button, Divider, ModalProps, Typography } from "@mui/material";

import { BaseMFAModal } from "../BaseMFAModal";
import { FlexCol } from "../../FlexCol";
import { MFACodeInput } from "../../MFACodeInput";
import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";

interface Props extends Partial<ModalProps> {}

export const WithdrawMFASetupModal = ({ ...rest }: Props) => {
  const { values, setValues, verifySetupCode } = useWithdraw();

  return (
    <BaseMFAModal
      {...rest}
      open={values.modal === WithdrawModalType.CODE_SETUP}
      loading={values.loadings.mfaVerifying}
      subtitle="To continue the withdrawal, Please set up and enable the two factor authentication."
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <FlexCol sx={{ mt: 2 }}>
        <Typography variant="tiny" component="span" color="#FFFFFF80">
          You will need{" "}
          <Typography variant="inherit" color="#2F80ED" fontWeight={600} component="span">
            Google Authenticator
          </Typography>{" "}
          app to complete this process. Using the Authenticator, Please scan the QR code below and enter the{" "}
          <Typography variant="inherit" color="white" fontWeight={600} component="span">
            6-digit verification code
          </Typography>{" "}
          generated by the app.
        </Typography>
        <FlexCol sx={{ mt: 2 }}>
          <Typography textAlign="center" variant="body2" fontWeight="600">
            Scan the QR code to enable 2FA
          </Typography>
        </FlexCol>
        <FlexCol sx={{ mt: 2, alignItems: "center" }}>
          <FlexCol sx={{ borderRadius: 2, overflow: "hidden", width: 150, height: 150 }}>
            <img src={values.qrCode} alt="loading" />
          </FlexCol>
        </FlexCol>
        <Divider sx={{ mx: -4, mt: 3, mb: 2 }} />
        <FlexCol>
          <MFACodeInput
            error={values.errors.invalidCode}
            onChange={(code) => setValues({ ...values, verifyCode: code })}
          />
        </FlexCol>
        <FlexCol alignItems="center" sx={{ mt: 3 }}>
          <Button variant="contained" sx={{ borderRadius: 40, width: "50%" }} onClick={verifySetupCode}>
            Enable 2FA
          </Button>
        </FlexCol>
      </FlexCol>
    </BaseMFAModal>
  );
};
