import { SubCategory } from "./category";
import { StreamCategory, StreamSubCategory } from "./streamCategory";

export interface Channel {
  id: string;
  _id: string;
  channelId?: string;
  title: string;
  subtitle: string;
  description: string;
  shortDescription?: string;
  languages: string[];
  countries: string[];
  logo: string;
  thumbnail?: string;
  url: string;
  hlsUrl: string;
  slug: string;
  twitterId: string;
  discordId: string;
  instagramId: string;
  website: string;
  isLive: boolean;
  isPremium: string;
  isOnTV: boolean;
  isOnWeb: boolean;
  protocol: string;
  sourceType: string;
  enableChat: boolean;
  enableTip: boolean;
  categories: string[];
  tags: string[];
  status: string;
  streamKey: string;
  tvBackgroundColor: string;
  restrictedCountries: string[];
  availableCountries: string[];
  advertisement?: any;
  followers?: number;
  createdAt: string;
  updatedAt: string;
  isSuitableForKids?: boolean;
  country?: string;
  vods?: [];
}

export enum PROFILE_MENU_TYPE {
  Channel = "Channel",
  User = "User",
}

export enum PROFILE_MENU_ITEM {
  about = "about",
  dashboard = "dashboard",
  posts = "posts",
  tweets = "tweets",
  videos = "videos",
}

export enum CHANNEL_STREAM_PROTOCOL {
  HLS = "HLS",
  DASH = "DASH",
  WEBRTC = "WEBRTC",
  RTMP = "RTMP",
}

export enum CHANNEL_SOURCE_TYPE {
  HLS = "HLS",
  FILE = "FILE",
  RTMP = "RTMP",
}

interface ChannelGiftType {
  count: number;
  gift: {
    createdAt: string;
    image: string;
    name: string;
    price: number;
    status: string;
    updatedAt: string;
    __v: number;
    _id: string;
  };
}

export interface ChannelGiftsType {
  total: number;
  gifts: ChannelGiftType[];
}

export interface ChannelTransactionType {
  _id: string;
  amount: number;
  currency: string;
  gift?: {
    _id: string;
    name: string;
    image: string;
    price: number;
    status: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  receiverType: string;
  receiver: string;
  status: string;
  sender: {
    createdAt: string;
    firstName: string;
    interests: string[];
    language: string;
    lastName: string;
    updatedAt: string;
    user: string;
    username: string;
    __v: number;
    _id: string;
  };
  transaction: {
    _id: string;
    prepayId: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  paymentMethod: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export type SumAndDate = {
  sum: number;
  date: string;
};

export interface ChannelTransactionStatisticsType {
  tipTransactoins: {
    days: SumAndDate[];
    months: SumAndDate[];
    years: SumAndDate[];
  }[];
  giftTransactions: {
    days: SumAndDate[];
    months: SumAndDate[];
    years: SumAndDate[];
  }[];
}

export interface FollowedChannel {
  channel: {
    _id: string;
    title: string;
    subtitle: string;
    description: string;
    languages: string[];
    countries: string[];
    logo: string;
    url: string;
    twitterId: string;
    isPremium: boolean;
    isOnTV: boolean;
    isOnWeb: boolean;
    protocol: string;
    sourceType: string;
    enableChat: boolean;
    categories: string[];
    tags: string[];
    status: string;
    tvBackgroundColor: string;
    restrictedCountries: string[];
    availableCountries: string[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    orderInCategory: number;
    channelId: string;
    enableTip: boolean;
    isOnMobile: boolean;
    thumbnail: string;
  };
}

export interface FollowedRadioStation {
  _id: string;
  title: string;
  description: string;
  languages: string[];
  logo: string;
  categories: string[];
  tags: string[];
  status: string;
  tvBackgroundColor: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  restrictedCountries: string[];
  availableCountries: string[];
}

export interface FollowedRadio {
  radioStation: FollowedRadioStation;
}

export interface AxiosResponseFollowedChannels {
  data: FollowedChannel[];
}

export interface AxiosResponseFollowedRadioStations {
  data: FollowedRadio[];
}

type AnalyticsItem = {
  _id: string;
  total: number;
};

export interface ChannelAnalyticsData {
  followers: [
    {
      days: AnalyticsItem[];
      months: AnalyticsItem[];
      years: AnalyticsItem[];
    }
  ];
  impressions: [
    {
      days: AnalyticsItem[];
      months: AnalyticsItem[];
      years: AnalyticsItem[];
    }
  ];
}

export interface StreamVideo {
  user: string;
  url: string;
  title: string;
  description?: string;
  thumbnail: string;
  thumbnails?: string[];
  language?: string;
  private?: boolean;
  duration: number;
  protocol: string;
  views: number;
  categories?: SubCategory[];
  createdAt: Date;
  updatedAt: Date;
  id: string;
  _id: string;
}
