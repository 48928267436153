import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

type NavItemContainerProps = PropsWithChildren<{}>;

export const NavItemContainer = ({ children }: NavItemContainerProps) => {
  return (
    <Box sx={{ width: "20%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {children}
    </Box>
  );
};
