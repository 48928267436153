import { Dispatch } from "redux";
import { io, Socket } from "socket.io-client";
import { all, fork, take, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { apiClient } from "../../config";
import { FAIL, START, SUCCESS } from "../common";
import * as socketActions from "./actions";
import * as paymentActions from "../payment/actions";
import { MESSAGE_TYPE, NOTIFICATION_TYPE, RootState } from "../../types";

let socket: Socket;

function* connectSocket(dispatch: Dispatch) {
  while (true) {
    yield take(socketActions.CONNECT_SOCKET + START);
    try {
      const state: RootState = yield select();
      const token = state.auth?.token;
      const user = state.auth?.user;
      socket = io(`${apiClient.defaults.baseURL}`, {
        auth: { token },
        transports: ["websocket"],
        withCredentials: true,
      });
      // eslint-disable-next-line no-loop-func
      socket.on("connect", () => {
        dispatch({
          type: socketActions.CONNECT_SOCKET + SUCCESS,
        });
        socket.on(NOTIFICATION_TYPE.MESSAGE, (data) => {
          switch (data.type) {
            case MESSAGE_TYPE.TEXT:
            case MESSAGE_TYPE.GIPHY:
            case MESSAGE_TYPE.TIP:
            case MESSAGE_TYPE.GIFT:
            case MESSAGE_TYPE.ASSIGN_MODERATOR:
            case MESSAGE_TYPE.REMOVE_MODERATOR:
              dispatch({
                type: socketActions.RECEIVED_CHANNEL_MESSAGE,
                payload: data,
              });
              break;
            case MESSAGE_TYPE.VIDEO:
            case MESSAGE_TYPE.AUDIO:
            case MESSAGE_TYPE.IMAGE:
              // eslint-disable-next-line no-console
              break;
            case MESSAGE_TYPE.NOTICE:
              // handle notification for all events
              // check data.socketNotifyType: SOCKET_NOTIFY_TYPE
              break;
            default:
              break;
          }
        });
        socket.on(NOTIFICATION_TYPE.POLL, (data) => {
          // handle live vote result
        });
        socket.on(NOTIFICATION_TYPE.TIP, ({ sender, status }) => {
          if (user?.id === sender) {
            dispatch({
              type: paymentActions.UPDATE_PAY_STATUS,
              payload: status,
            });
          }
        });
        socket.on(NOTIFICATION_TYPE.GIFT, ({ sender, status }) => {
          if (user?.id === sender) {
            dispatch({
              type: paymentActions.UPDATE_PAY_STATUS,
              payload: status,
            });
          }
        });
        socket.on(NOTIFICATION_TYPE.STREAM, (data) => {
          dispatch({
            type: socketActions.RECEIVED_NOTIFICATION,
            payload: data,
          });
        });
        socket.on(NOTIFICATION_TYPE.USER_TYPING, (data) => {
          dispatch({
            type: socketActions.SET_TYPERS,
            payload: data,
          });
        });
        socket.on(NOTIFICATION_TYPE.VIEWER, ({ viewers }) => {});
        socket.on(NOTIFICATION_TYPE.NOTICE, (data) => {
          // handle notification for all events
          // check data.socketNotifyType: SOCKET_NOTIFY_TYPE
        });
      });
      socket.on("disconnect", () => {
        dispatch({
          type: socketActions.DISCONNECT_SOCKET,
        });
      });
    } catch (error: any) {
      yield put({
        type: socketActions.CONNECT_SOCKET + FAIL,
        payload: "Failed to connect socket.",
      });
    }
  }
}

function* disconnectSocket() {
  while (true) {
    yield take(socketActions.DISCONNECT_SOCKET);
    try {
      socket.disconnect();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Socket Disconnect Error:", error);
    }
  }
}

function* joinRoom() {
  while (true) {
    const { payload } = yield take(socketActions.JOIN_CHANNEL);
    try {
      socket.emit("join", payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Join room failed: ", error);
    }
  }
}

function* leaveRoom() {
  while (true) {
    yield take(socketActions.LEAVE_CHANNEL);
    try {
      socket.emit("leave");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Leave room failed: ", error);
    }
  }
}

let cancelSource = axios.CancelToken.source();

function* fetchChannelMessageHistory({ payload }: any) {
  const { channelId, lastMessageId, channelType } = payload;
  if (typeof cancelSource !== typeof undefined) {
    cancelSource.cancel("Operation canceled due to new request.");
  }
  cancelSource = axios.CancelToken.source();
  try {
    const { data } = yield apiClient.post(
      `messages/${channelType}/${channelId}`,
      {
        lastMessageId,
      },
      { cancelToken: cancelSource.token }
    );
    yield put({
      type: socketActions.FETCH_CHANNEL_MESSAGE_HISTORY + SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: socketActions.FETCH_CHANNEL_MESSAGE_HISTORY + FAIL,
      payload: "Failed to fetch history.",
    });
  }
}

function* sendChannelMessage() {
  while (true) {
    const { payload } = yield take(socketActions.SEND_CHANNEL_MESSAGE + START);
    const { channelId, message, type, giphy, channelType, draftContent = "" } = payload;
    try {
      socket.emit(`sendMessage`, { message, type, draftContent, giphy });
    } catch (error) {
      yield put({
        type: socketActions.SEND_CHANNEL_MESSAGE + FAIL,
        payload: "Failed to send message.",
      });
    }
  }
}

function* sendTypingStatus() {
  while (true) {
    const { payload } = yield take(socketActions.SEND_TYPING_STATUS);
    const { channelId, channelType, isTyping } = payload;
    try {
      socket.emit(NOTIFICATION_TYPE.USER_TYPING, { channelId, channelType, isTyping });
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

export default function* messageSaga(dispatch: Dispatch) {
  yield all([
    fork(connectSocket, dispatch),
    fork(disconnectSocket),
    fork(joinRoom),
    fork(leaveRoom),
    takeLatest(socketActions.FETCH_CHANNEL_MESSAGE_HISTORY + START, fetchChannelMessageHistory),
    fork(sendChannelMessage),
    fork(sendTypingStatus),
  ]);
}
