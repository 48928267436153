import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FlexCol, LoadingView } from "../../components";
import { apiClient } from "../../config";
import { useQuery } from "../../hooks/useQuery";

export const UnsubscribeEmailPage = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const token = query.get("token");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const unsubscribeEmail = async () => {
      try {
        await apiClient.get(`/unsubscribe/emailNotification?token=${token}`);
        setLoading(false);
        setError("");
      } catch (err: any) {
        setLoading(false);
        setError(err.response?.data?.message);
      }
    };
    if (token) {
      setLoading(true);
      unsubscribeEmail();
    } else {
      navigate("/");
    }
  }, [query]);

  return (
    <FlexCol sx={{ margin: "auto", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      {!loading && !error && (
        <FlexCol sx={{ alignItems: "center" }}>
          <Typography variant="h6" color="primary.contrastText" sx={{ my: 3, textAlign: "center" }}>
            Unsubscribe successful.
          </Typography>
          <Typography variant="subtitle2" color="primary.light">
            You have been successfully unsubscribed from this type of email. If you've done this by mistake you can
            always resubscribe in your account settings.
          </Typography>
        </FlexCol>
      )}
      {!loading && error && (
        <FlexCol>
          <Typography variant="subtitle2" color="error">
            {error || "The link doesn't exist or expired."}
          </Typography>
        </FlexCol>
      )}
      <LoadingView visible={loading} />
    </FlexCol>
  );
};
