import { useEffect, useRef } from "react";
import { Box, ButtonBase } from "@mui/material";

import { usePrivateNavbarContext } from "../PrivateNavbarContext";
import { SearchInput } from "./SearchInput";

export const SearchInputDesktop = () => {
  const { activeDrawer, updateContext: updateNavbarContext } = usePrivateNavbarContext();

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (activeDrawer === "search-result") {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [activeDrawer]);

  return (
    <ButtonBase
      component="div"
      onClick={
        activeDrawer === "search-result"
          ? undefined
          : () => {
              updateNavbarContext({ activeDrawer: "search-result" });
            }
      }
      sx={{ borderRadius: "10px" }}
      disableRipple
    >
      <Box sx={activeDrawer === "search-result" ? {} : { pointerEvents: "none" }}>
        <SearchInput
          ref={inputRef}
          containerSx={{ flex: 1, width: { sm: 240, lg: 340 } }}
          focusedSx={{ width: { sm: 360, lg: 340, xl: 480 } }}
        />
      </Box>
    </ButtonBase>
  );
};
