import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_RADIO_STATIONS = "FETCH_RADIO_STATIONS";
export const SET_SELECTED_STATION = "SET_SELECTED_STATION";
export const PLAY_NEXT_RADIO_STATION = "PLAY_NEXT_RADIO_STATION";
export const FETCH_TRENDING_RADIO_STATIONS = "FETCH_TRENDING_RADIO_STATIONS";

export const fetchRadioStationsAction = createAction(FETCH_RADIO_STATIONS + START);
export const setSelectedRadioStation = createAction(SET_SELECTED_STATION);
export const playNextRadioStation = createAction(PLAY_NEXT_RADIO_STATION);
export const fetchTrendingRadioStationsAction = createAction(FETCH_TRENDING_RADIO_STATIONS + START);
