import React from "react";
import { Button, Grid, IconButton, ModalProps, Paper, Typography } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { BaseMFAModal } from "../BaseMFAModal";
import { FlexCol } from "../../FlexCol";
import { useWithdraw } from "../../../hooks/WithdrawContext";
import { downloadTextFile } from "../../../utils";
import { ToastService } from "../../../services";
import { WithdrawModalType } from "../../../types";

interface Props extends Partial<ModalProps> {}

export const WithdrawMFABackupCodeModal = ({ ...rest }: Props) => {
  const { values, setValues } = useWithdraw();

  return (
    <BaseMFAModal
      {...rest}
      open={values.modal === WithdrawModalType.BACKUP_CODE}
      subtitle="Save this emergency backup code and store it somewhere safe. If you lose your phone, you can use backup codes to sign in."
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <FlexCol>
        <FlexCol alignItems="center" sx={{ mt: 3 }}>
          <Paper sx={{ backgroundColor: "white", borderRadius: 2.5, width: "70%", p: 0.5 }}>
            <Grid container sx={{ border: "solid 0.5px lightgray", borderRadius: 2 }}>
              <Grid container item sx={{ py: 1 }}>
                {values?.backupCode?.map((code, index) => (
                  <Grid xs={4} key={index} alignItems="center" justifyContent="center" display="flex" sx={{ my: 0.3 }}>
                    <Typography variant="tiny" fontFamily="monospace" fontWeight="600" textAlign="center" color="black">
                      {code}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container item sx={{ border: "solid 0 lightgray", borderTopWidth: 0.5 }}>
                <Grid item xs={6} textAlign="center" sx={{ border: "solid 0 lightgray", borderRightWidth: 0.5 }}>
                  <IconButton onClick={() => downloadTextFile("bolt_mfa.txt", values.backupCode?.join(" ")!)}>
                    <SaveAltIcon fontSize="small" sx={{ color: "#457cf6" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={6} textAlign="center">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(values.backupCode?.join(" ")!);
                      ToastService.showInfoMessage("Copied the code to clipboard!");
                    }}
                  >
                    <ContentCopyIcon fontSize="small" sx={{ color: "#457cf6" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </FlexCol>
        <FlexCol alignItems="center" sx={{ mt: 3 }}>
          <Button
            variant="contained"
            sx={{ borderRadius: 40, width: "50%" }}
            onClick={() => setValues({ ...values, modal: WithdrawModalType.WITHDRAW_CONFIRM })}
          >
            Done
          </Button>
        </FlexCol>
      </FlexCol>
    </BaseMFAModal>
  );
};
