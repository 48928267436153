import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { Channel, RadioStation, SearchType, User } from "../../types";
import { FAIL, START, SUCCESS, CANCEL } from "../common";

export interface SearchState {
  searchInput: string;
  selectedType: SearchType;
  channel: {
    items: Channel[];
    loading: boolean;
    hasMore: boolean;
    loadingMore: boolean;
    page: number;
    total: number;
  };
  radio: {
    items: RadioStation[];
    loading: boolean;
    hasMore: boolean;
    loadingMore: boolean;
    page: number;
    total: number;
  };
  user: {
    items: User[];
    loading: boolean;
    hasMore: boolean;
    loadingMore: boolean;
    page: number;
    total: number;
  };
}

const initialState: SearchState = {
  searchInput: "",
  selectedType: SearchType.channel,
  channel: {
    items: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    page: 1,
    total: 0,
  },
  radio: {
    items: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    page: 1,
    total: 0,
  },
  user: {
    items: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    page: 1,
    total: 0,
  },
};

const reducer = handleActions<SearchState, any>(
  {
    [actions.SET_SEARCH_INPUT]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searchInput = payload;
      }),
    [actions.UPDATE_SEARCH_TYPE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selectedType = payload;
      }),
    [actions.INIT_SEARCH]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searchInput = "";
      }),
    [actions.FETCH_SEARCH + CANCEL]: (state, { payload }) => ({ ...initialState, selectedType: state.selectedType }),
    [actions.FETCH_SEARCH + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft[state.selectedType].loading = true;
        if (payload.page === 1) {
          draft[state.selectedType].items = [];
        }
      }),
    [actions.FETCH_SEARCH + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const type: SearchType = payload?.type;
        draft[type].loading = false;
        draft[type].items = payload.page === 1 ? payload.data : [...state[type].items, ...payload.data];
        draft[type].page = payload.page;
        draft[type].total = payload.total;
        draft[type].hasMore = draft[type].items.length < payload.total;
      }),
    [actions.FETCH_SEARCH + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft[state.selectedType].loading = false;
      }),
  },
  initialState
);

export default reducer;
