import { useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface Promotion {
  background: string;
  logo: string;
  tags: string[];
}

interface Props {
  promotions: Promotion[];
}

export default function LoginPageBackground({ promotions }: Props) {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const loginBackgroundRef = useRef<HTMLDivElement>(null);
  const [selectedPromotion, setSelectedPromotion] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [images, setImages] = useState<HTMLImageElement[]>([]);
  const [logos, setLogos] = useState<HTMLDivElement[]>([]);
  const isMd = useMediaQuery("(max-width:1100px)");
  const isLg = useMediaQuery("(max-width:1400px)");

  useEffect(() => {
    let nextPromotion = 0;

    if (selectedPromotion === promotions.length - 1) {
      nextPromotion = 0;
    } else {
      nextPromotion = selectedPromotion + 1;
    }

    const timer = setTimeout(() => setSelectedPromotion(nextPromotion), 8000);
    return () => clearTimeout(timer);
  }, [selectedPromotion, promotions.length]);

  useEffect(() => {
    // handle backgrounds
    const tempBackgrounds = promotions.map((promotion) => {
      const image = new Image();
      image.src = promotion.background;
      image.style.position = "absolute";
      image.style.objectFit = "cover";
      image.style.width = "100%";
      image.style.height = "100%";
      return image;
    });

    Promise.all(
      tempBackgrounds.map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) resolve(img);
            else img.onload = () => resolve(img);
          })
      )
    ).then(() => {
      setImages(tempBackgrounds);

      if (backgroundRef.current) {
        const image = tempBackgrounds[0];
        backgroundRef.current.appendChild(image);
        (backgroundRef.current.firstChild as HTMLImageElement).classList.add("fade-zoom-out");

        if (tempBackgrounds.length > 1) {
          const image1 = tempBackgrounds[1];
          backgroundRef.current.insertBefore(image1, backgroundRef.current.firstChild);
        }
      }
    });

    // handle logos
    const tempLogos = promotions.map((promotion) => {
      const wrapper = document.createElement("div");
      wrapper.style.position = "absolute";
      wrapper.style.left = "80px";
      wrapper.style.bottom = "-400px";
      wrapper.style.zIndex = "4";
      wrapper.classList.add("bottom-in-top-out");

      const logo = new Image();
      logo.style.width = isLg ? "400px" : "600px";
      logo.style.height = "200px";
      logo.style.objectFit = "contain";
      logo.style.objectPosition = "left";
      logo.src = promotion.logo;
      if (!isMd) {
        wrapper.appendChild(logo);
      }

      const tagDiv = document.createElement("div");
      tagDiv.style.display = "flex";
      tagDiv.style.alignItems = "center";
      tagDiv.style.marginTop = "20px";
      tagDiv.style.marginBottom = "20px";
      tagDiv.style.flexWrap = "wrap";
      tagDiv.style.width = isLg ? "400px" : "600px";
      tagDiv.style.gap = "16px";

      promotion.tags.forEach((tag) => {
        const p = document.createElement("p");
        p.style.color = "#FFFFFFD9";
        p.style.fontWeight = "600";
        p.style.fontSize = "20px";
        p.style.backgroundColor = "#FFFFFF7D";
        p.style.paddingTop = "8px";
        p.style.paddingBottom = "8px";
        p.style.paddingLeft = "20px";
        p.style.paddingRight = "20px";
        // p.style.marginRight = "16px";
        p.style.borderRadius = "100px";
        p.textContent = `#${tag}`;
        tagDiv.appendChild(p);
      });
      if (!isMd) {
        wrapper.appendChild(tagDiv);
      }
      const innerDiv = document.createElement("div");
      innerDiv.style.display = "flex";
      innerDiv.style.alignItems = "center";

      const liveNow = document.createElement("p");
      liveNow.style.color = "#FFFFFFD9";
      liveNow.style.fontWeight = "600";
      liveNow.style.fontSize = "40px";
      liveNow.style.marginRight = "12px";
      liveNow.textContent = "Live now";
      if (!isMd) {
        innerDiv.appendChild(liveNow);
      }
      const redDot = new Image();
      redDot.style.width = "20px";
      redDot.style.height = "20px";
      redDot.src = "https://cdn.boltplus.tv/boltplus-global/assets/image/red_dot.png";
      if (!isMd) {
        innerDiv.appendChild(redDot);

        wrapper.appendChild(innerDiv);
      }
      return wrapper;
    });

    setLogos(tempLogos);

    if (wrapperRef.current) {
      wrapperRef.current.appendChild(tempLogos[0]);
    }
  }, [promotions, isMd, isLg]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    // handle logo
    if (wrapperRef.current && wrapperRef.current.lastElementChild && !isMd) {
      wrapperRef.current.removeChild(wrapperRef.current.lastElementChild);
      wrapperRef.current.appendChild(logos[selectedPromotion]);
    }

    // handle background
    if (backgroundRef.current && backgroundRef.current.firstChild) {
      (backgroundRef.current.firstChild as HTMLImageElement).classList.add("fade-zoom-out");
    }

    if (backgroundRef.current && images.length > 0) {
      let image;
      if (selectedPromotion + 1 === images.length) {
        image = images[0];
      } else {
        image = images[selectedPromotion + 1];
      }
      backgroundRef.current.insertBefore(image, backgroundRef.current.firstChild);
    }

    // handle login background
    if (loginBackgroundRef.current) {
      const newBackgroundImage = `url("${promotions[selectedPromotion % promotions.length].background}")`;
      if (loginBackgroundRef.current.style.backgroundImage !== newBackgroundImage) {
        loginBackgroundRef.current.style.backgroundImage = newBackgroundImage;
        loginBackgroundRef.current.classList.remove("fade-in");
        // Trigger reflow
        (() => loginBackgroundRef.current.offsetWidth)();
        loginBackgroundRef.current.classList.add("fade-in");
      }
    }

    setTimeout(() => {
      if (backgroundRef.current && backgroundRef.current.lastElementChild) {
        (backgroundRef.current.lastElementChild as HTMLImageElement).classList.remove("fade-zoom-out");
        backgroundRef.current.removeChild(backgroundRef.current.lastElementChild);
      }
    }, 4000);
  }, [selectedPromotion, images, logos, firstLoad, promotions, isMd]);

  return (
    <div ref={wrapperRef} className="relative w-full h-full min-h-screen">
      {/* Black Overlay */}
      <div className="absolute inset-0 bg-black/50" style={{ zIndex: "2" }}></div>
      <div ref={backgroundRef} className="absolute inset-0 w-full h-full"></div>
      <div
        ref={loginBackgroundRef}
        className="login-background absolute inset-0 w-full h-full bg-cover bg-center transition-all duration-500 ease-in-out"
        style={{
          backgroundImage: `url("${promotions[0].background}")`,
          opacity: 1,
        }}
      />
    </div>
  );
}
