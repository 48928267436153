import React, { useEffect, useState } from "react";
import { BoxProps } from "@mui/material";
import { FlexRow } from "../FlexRow";

type HContainerProps = {
  visible?: boolean;
} & BoxProps

export const HContainer = ({ visible = true, sx, children }: HContainerProps) => {
  const delayTime = 100;
  const [display, setDisplay] = useState<"flex" | "none">("flex");

  useEffect(() => {
    if (visible) {
      setTimeout(() => setDisplay("flex"), 10);
    } else {
      setTimeout(() => setDisplay("none"), 10);
    }
  }, [visible]);

  return (
    // <FlexRow sx={{
    //   gap: 1.5,
    //   width: "100%",
    //   // display: hasItem ? "flex" : "none",
    //   display,
    //   visibility: visible ? "visible" : "hidden",
    //   opacity: visible ? 1 : 0,
    //   height: display === "flex" ? "100%" : "0%",
    //   transition: `all ${delayTime}ms ease-in-out`,
    //   ...sx
    // }}>
    <FlexRow sx={{ display: visible ? "flex" : "none", gap: 1.5, flex: 1, ...sx }}>
      {children}
    </FlexRow>
  )
}