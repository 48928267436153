import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { AnalyticsData, EearningData, EearningTotal, StreamFeed, StreamVideo, User } from "../../types";

export interface UserSteamState {
  loading: boolean;

  overview: {
    impressions: number;
    impressionsRate: number;
    clicks: number;
    followers: number;
    followsIncrease: number;
  };

  topFeeds: {
    impressionFeed: StreamFeed | null;
    reactionFeed: StreamFeed | null;
    commentFeed: StreamFeed | null;
    topStream: StreamVideo | null;
    topFollower: User | null;
    topFan: User | null;
  }

  followers: {
    totalFollowers: number;
    newFollowers: number;
    statistics: AnalyticsData[]
  };

  post: {
    performanceFeeds: StreamFeed[];
    statistics: {
      reactions: AnalyticsData[],
      impressions: AnalyticsData[],
      comments: AnalyticsData[],
      rebolts: AnalyticsData[]
    };
    page: number;
    limit: number;
    total: number;

    totalPosts: number;
    totalReactions: number;
    totalReplies: number;
    totalRebolts: number;
    impressions: number;
    totalBolts: number;
  };

  livestream: {
    statistics: AnalyticsData[];
    totalBolts: number;
    totalStreams: number;
    totalViews: number;
    totalMinutes: number;
    totalChats: number;
    totalImpressions: number;
    totalRecords: number;
  }

  action: {
    loading: boolean;
  };

  earning: {
    loading: boolean;
    error: any;
    page: number;
    limit: number;
    hasMore: boolean;
    total: number;
    totalBalance: number;
    totalEarnings: EearningTotal;
    totalWithdraw: EearningTotal;
    data: EearningData[];
  }
}

const initialState: UserSteamState = {
  loading: false,

  overview: {
    impressions: 0,
    impressionsRate: 0,
    clicks: 0,
    followers: 0,
    followsIncrease: 0,
  },

  topFeeds: {
    impressionFeed: null,
    reactionFeed: null,
    commentFeed: null,
    topStream: null,
    topFollower: null,
    topFan: null,
  },

  followers: {
    totalFollowers: 0,
    newFollowers: 0,
    statistics: []
  },

  post: {
    performanceFeeds: [],
    statistics: {
      reactions: [],
      impressions: [],
      comments: [],
      rebolts: []
    },
    page: 0,
    limit: 0,
    total: 0,

    totalPosts: 0,
    totalReactions: 0,
    totalReplies: 0,
    totalRebolts: 0,
    impressions: 0,
    totalBolts: 0,
  },

  livestream: {
    statistics: [],
    totalBolts: 0,
    totalStreams: 0,
    totalViews: 0,
    totalMinutes: 0,
    totalChats: 0,
    totalImpressions: 0,
    totalRecords: 0,
  },

  action: {
    loading: false,
  },

  earning: {
    loading: false,
    error: null,
    page: 1,
    limit: 0,
    hasMore: false,
    total: 0,
    totalBalance: 0,
    totalEarnings: {
      binance: 0,
      stripe: 0,
      boltx: 0,
      telenor: 0
    },
    totalWithdraw: {
      binance: 0,
      stripe: 0,
      boltx: 0,
      telenor: 0
    },
    data: [],
  }
};

const reducer = handleActions<UserSteamState, any>(
  {
    [actions.FETCH_ANALYTICS_OVERVIEW + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_OVERVIEW + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.overview = {
        impressions: payload.impressions,
        impressionsRate: payload.impressions_rate,
        clicks: payload.clicks,
        followers: payload.followers,
        followsIncrease: payload.follows_increase,
      };
    }),
    [actions.FETCH_ANALYTICS_OVERVIEW + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.overview = {
        impressions: 0,
        impressionsRate: 0,
        clicks: 0,
        followers: 0,
        followsIncrease: 0,
      };
    }),

    [actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.topFeeds = {
        impressionFeed: payload.impressionFeed,
        reactionFeed: payload.reactionFeed,
        commentFeed: payload.commentFeed,
        topStream: payload.topStream,
        topFollower: payload.topFollower,
        topFan: payload.topFan,
      };
    }),
    [actions.FETCH_ANALYTICS_OVERVIEW_TOP_FEEDS + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.topFeeds = {
        impressionFeed: null,
        reactionFeed: null,
        commentFeed: null,
        topStream: null,
        topFollower: null,
        topFan: null,
      };
    }),


    [actions.FETCH_ANALYTICS_FOLLOWERS + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_FOLLOWERS + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.followers.totalFollowers = payload.totalFollowers;
      draft.followers.newFollowers = payload.newFollowers;
    }),
    [actions.FETCH_ANALYTICS_FOLLOWERS + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),

    [actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.followers.statistics = payload;

    }),
    [actions.FETCH_ANALYTICS_FOLLOWERS_STATISTICS + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),

    [actions.FETCH_ANALYTICS_POSTS + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_POSTS + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.post.totalPosts = payload.totalPosts;
      draft.post.totalReactions = payload.totalReactions;
      draft.post.totalReplies = payload.totalReplies;
      draft.post.totalRebolts = payload.totalRebolts;
      draft.post.impressions = payload.impressions;
      draft.post.totalBolts = payload.totalBolts;

    }),
    [actions.FETCH_ANALYTICS_POSTS + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),

    [actions.FETCH_ANALYTICS_POSTS_CONTENT + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_POSTS_CONTENT + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.post.performanceFeeds = payload.data;
      draft.post.page = payload.page;
      draft.post.limit = payload.limit;
      draft.post.total = payload.total;
    }),
    [actions.FETCH_ANALYTICS_POSTS_CONTENT + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),

    [actions.FETCH_ANALYTICS_POSTS_STATISTICS + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_POSTS_STATISTICS + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.post.statistics = payload;
    }),
    [actions.FETCH_ANALYTICS_POSTS_STATISTICS + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),

    [actions.FETCH_ANALYTICS_LIVESTREAM + START]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = true;
    }),
    [actions.FETCH_ANALYTICS_LIVESTREAM + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
      draft.livestream.totalStreams = payload.totalStreams;
      draft.livestream.totalViews = payload.totalViews;
      draft.livestream.totalMinutes = payload.totalMinutes;
      draft.livestream.totalChats = payload.totalChats;
      draft.livestream.totalImpressions = payload.totalImpressions;
      draft.livestream.totalRecords = payload.totalRecords;
      draft.livestream.totalBolts = payload.totalBolts;
    }),
    [actions.FETCH_ANALYTICS_LIVESTREAM + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.loading = false;
    }),


    [actions.FETCH_EARNING_TOTAL + START]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = true;
      draft.earning.error = null;
    }),
    [actions.FETCH_EARNING_TOTAL + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.totalEarnings = payload;
    }),
    [actions.FETCH_EARNING_TOTAL + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.error = payload;
    }),

    [actions.FETCH_EARNING_LIST + START]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = true;
      draft.earning.error = null;
      if (payload.page === 1) draft.earning.data = [];
    }),
    [actions.FETCH_EARNING_LIST + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.data = payload.page === 1 ? payload.data : [...state.earning.data, ...payload.data];
      draft.earning.page = payload.page;
      draft.earning.limit = payload.limit;
      draft.earning.total = payload.total;
      draft.earning.hasMore = draft.earning.data.length < payload.total;
    }),
    [actions.FETCH_EARNING_LIST + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.error = payload;
    }),

    [actions.FETCH_EARNING_WITHDRAW_TOTAL + START]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = true;
      draft.earning.error = null;
    }),
    [actions.FETCH_EARNING_WITHDRAW_TOTAL + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.totalWithdraw = payload;
    }),
    [actions.FETCH_EARNING_WITHDRAW_TOTAL + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.error = payload;
    }),

    [actions.FETCH_EARNING_WITHDRAW_LIST + START]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = true;
      draft.earning.error = null;
      if (payload.page === 1) draft.earning.data = [];
    }),
    [actions.FETCH_EARNING_WITHDRAW_LIST + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.data = payload.page === 1 ? payload.data : [...state.earning.data, ...payload.data];
      draft.earning.page = payload.page;
      draft.earning.limit = payload.limit;
      draft.earning.total = payload.total;
      draft.earning.hasMore = draft.earning.data.length < payload.total;
    }),
    [actions.FETCH_EARNING_WITHDRAW_LIST + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.error = payload;
    }),

    [actions.FETCH_EARNING_BALANCE_TOTAL + START]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = true;
      draft.earning.error = null;
    }),
    [actions.FETCH_EARNING_BALANCE_TOTAL + SUCCESS]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.totalBalance = payload.total;
    }),
    [actions.FETCH_EARNING_BALANCE_TOTAL + FAIL]: (state, { payload }) => produce(state, (draft) => {
      draft.earning.loading = false;
      draft.earning.error = payload;
    }),
  },
  initialState
);

export default reducer;
