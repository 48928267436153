import { Divider, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { FeedCard, FlexCol, FlexRow } from "../../components";
import { fetchHomeNFTFeed, likeNFTFeed } from "../../store/feed/actions";
import { nftFeedSelector } from "../../store/feed/selector";
import { EmptySkeleton } from "./EmptySkeleton";
import { NFTFeedFilter } from "./NFTFeedFilter";

export const NFTFeedList = () => {
  const dispatch = useDispatch();
  const { items, loading, hasMore, page } = useSelector(nftFeedSelector);

  React.useEffect(() => {
    dispatch(fetchHomeNFTFeed());
  }, [dispatch]);

  return (
    <FlexCol>
      <FlexRow sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle1">Posts</Typography>
        <NFTFeedFilter />
      </FlexRow>
      <Divider sx={{ width: "40%" }} />
      <InfiniteScroll
        pageStart={1}
        loadMore={() => !loading && dispatch(fetchHomeNFTFeed({ page: page + 1 }))}
        hasMore={hasMore}
        useWindow
      >
        {items.map((item, index) => (
          <FeedCard key={item.id} item={item} />
        ))}
      </InfiniteScroll>
      {loading && (
        <>
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
          <EmptySkeleton />
        </>
      )}
    </FlexCol>
  );
};
