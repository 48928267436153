import { styled, Switch } from "@mui/material";

export const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20.5,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(0px)",
    "&.Mui-checked": {
      // color: "#fff",
      transform: "translateX(16px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 14 14"><rect width="14" height="14" rx="7" fill="${encodeURIComponent(
          "#FBBB00"
        )}"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#2D9CDB",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#2D9CDB",
    boxShadow: "none",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
        "#263238"
      )}" d="M0.96875 8C0.96875 11.8813 4.11875 15.0312 8 15.0312C11.8363 15.0279 15.0312 11.6562 15.0312 8.28125C13.9062 8.84375 10.5312 9.96875 8.28125 7.71875C6.03125 5.46875 7.15625 2.09375 7.71875 0.96875C4.34375 0.96875 0.96875 4.16375 0.96875 8Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
    borderRadius: 20 / 2,
  },
}));
