import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, IconButton, BoxProps, Typography } from "@mui/material";
import moment from "moment";

import { ReactComponent as LinkIcon } from "../../assets/svgs/users/link.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/svgs/verified.svg";

import { NFTFeed, StreamFeed } from "../../types";
import { Formatter } from "../../utils";
import { DefaultAvatar } from "../DefaultAvatar";
import { FeedContentTextBody } from "../FeedContentTextBody";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { LinkPreview } from "../LinkPreview";
import { ReadMore } from "../ReadMore";
import FeedVideo from "../FeedVideo";
import TimeFromNow from "../TimeFromNow";
import ZoomedViewModal from "../../pages/feed/ZoomedViewModal";
import ReactionButtons from "../ReactionButtons";
import BoltsReceived from "../BoltsReceived";

type Props = {
  item: StreamFeed | NFTFeed;
  rebolt?: boolean;
} & BoxProps;

export const AnalyticsFeedCard = ({ item, rebolt, component, sx }: Props) => {
  const navigate = useNavigate();

  const [zoomedViewOpen, setZoomedViewOpen] = useState(false);
  const [contentIndex, setContentIndex] = useState<number>(0);

  const handleMediaClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>, content: number) => {
      e.stopPropagation();
      setZoomedViewOpen(true);
      setContentIndex(content);
    },
    []
  );

  const reboltFeed = item?.reboltFeed;

  let includedUrls = item?.text ? Formatter.parseUrls(item?.text) : [];

  if (rebolt && reboltFeed?.text) {
    includedUrls = Formatter.parseUrls(reboltFeed.text);
  }

  const shouldRenderUrlMeta = includedUrls.length > 0 && ((rebolt && !reboltFeed?.items.length) || !item?.items.length);

  const mediaItem = useCallback(
    (index: number) => {
      if (item) {
        let selectedMediaArray = item.items;
        if (item.reboltFeed?.items) {
          selectedMediaArray = item.reboltFeed.items;
        }

        if (selectedMediaArray.length > 1) {
          const videoItemIndex = selectedMediaArray.findIndex((e) => e.type === "video");
          if (videoItemIndex > 0) {
            const firstItem = selectedMediaArray[0];
            const videoItem = selectedMediaArray[videoItemIndex];
            selectedMediaArray[0] = videoItem;
            selectedMediaArray[videoItemIndex] = firstItem;
          }
        }

        if (selectedMediaArray.length && index < selectedMediaArray.length) {
          if (selectedMediaArray[index].type === "image") {
            return (
              <img
                src={selectedMediaArray[index].url}
                alt="post"
                style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "cover" }}
              />
            );
          }
          return (
            <FeedVideo
              url={selectedMediaArray[index].url}
              onClick={(e: any) => handleMediaClick(e, index)}
              zoomedViewOpen={zoomedViewOpen}
            />
          );
        }
      }
      return null;
    },
    [item]
  );

  const gotoFeedDetailpage = () => {
    navigate(`/feed/${item.id}`);
  };

  return (
    <Box component={component} sx={{ borderRadius: 2, ...sx }}>
      <FlexRow
        sx={{
          alignItems: "center",
          pt: 1.5,
          pl: 2,
          pr: 1.25,
          mb: (rebolt && item?.reboltFeed?.text) || item?.text ? undefined : 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textDecoration: "none" }}>
          <Avatar
            src={(rebolt && item?.reboltFeed?.creator?.photoUrl) || item?.creator?.photoUrl}
            alt={(rebolt && item?.reboltFeed?.creator?.firstName) || item?.creator?.firstName}
            sx={{
              borderColor: "primary.main",
              borderWidth: 2,
              borderStyle: "solid",
              height: 34,
              width: 34,
            }}
            imgProps={{ referrerPolicy: "no-referrer" }}
          >
            <DefaultAvatar />
          </Avatar>
          <Typography variant="subtitle1" color="primary.contrastText" sx={{ ml: 1, fontWeight: 600 }}>
            {(rebolt && item?.reboltFeed?.creator?.username) || item?.creator?.username}
          </Typography>
          <VerifiedIcon style={{ marginLeft: 5 }} />
        </Box>
        <TimeFromNow sx={{ ml: "auto" }}>
          {moment((rebolt && item?.reboltFeed?.createdAt) || item?.createdAt).fromNow()}
        </TimeFromNow>
        <IconButton aria-label="open" size="medium" onClick={gotoFeedDetailpage} sx={{ ml: 0.5 }}>
          <LinkIcon />
        </IconButton>
      </FlexRow>
      {((rebolt && !!item?.reboltFeed?.text?.trim()) || !!item?.text?.trim()) && (
        <FlexCol sx={{ my: 2, px: 3 }}>
          <ReadMore
            maxLine={5}
            active={
              (rebolt && item?.reboltFeed?.text?.length && item?.reboltFeed?.text?.length > 250) ||
              (!!item?.text?.length && item?.text?.length > 250)
            }
          >
            <FeedContentTextBody
              draftContent={rebolt ? item.reboltFeed?.draftContent : item.draftContent}
              text={rebolt ? item.reboltFeed?.text : item.text}
            />
          </ReadMore>
        </FlexCol>
      )}
      {((rebolt && !!item?.reboltFeed?.items?.length) || !!item?.items?.length) && (
        <FlexRow
          sx={{
            gap: "3px",
            width: "100%",
            bgcolor: "background.paper",
            flexWrap: "wrap",
            height:
              (item.reboltFeed?.items.length && item.reboltFeed?.items.length > 1) || item.items.length > 1 ? 187 : 377,
          }}
        >
          <Box
            sx={{ flex: 1, backgroundColor: "black", height: "100%", width: "calc(100% - 1.5px)" }}
            onClick={(e) => handleMediaClick(e, 0)}
          >
            {mediaItem(0)}
          </Box>
          {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 1) || item.items.length > 1) && (
            <FlexCol sx={{ height: "100%", width: "calc(50% - 1.5px)", gap: "3px" }}>
              <Box sx={{ flex: 1, height: "calc(50% - 1.5px)", width: "100%" }} onClick={(e) => handleMediaClick(e, 1)}>
                {mediaItem(1)}
              </Box>
              {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 2) || item.items.length > 2) && (
                <FlexRow sx={{ height: "calc(50% - 1.5px)", gap: "3px" }}>
                  <Box
                    sx={{ flex: 1, height: "100%", width: "calc(50% - 1.5px)" }}
                    onClick={(e) => handleMediaClick(e, 2)}
                  >
                    {mediaItem(2)}
                  </Box>
                  {((item.reboltFeed?.items.length && item.reboltFeed?.items.length > 3) || item.items.length > 3) && (
                    <Box sx={{ height: "100%", width: "calc(50% - 1.5px)" }} onClick={(e) => handleMediaClick(e, 3)}>
                      {mediaItem(3)}
                    </Box>
                  )}
                </FlexRow>
              )}
            </FlexCol>
          )}
        </FlexRow>
      )}

      {shouldRenderUrlMeta && (
        <FlexCol sx={{ overflow: "hidden", width: "100%", bgcolor: "background.paper" }}>
          <LinkPreview url={includedUrls[0]} />
        </FlexCol>
      )}
      {zoomedViewOpen && (
        <ZoomedViewModal
          item={item}
          home
          contentIndex={contentIndex}
          setContentIndex={setContentIndex}
          onClose={() => setZoomedViewOpen(false)}
        />
      )}

      <FlexRow sx={{ pl: 1.875, pr: 1, py: 1.25, justifyContent: "space-between" }}>
        <ReactionButtons
          {...{
            reactions: item.reboltFeed?.reactions || item.reactions,
            reactionCount: item.reboltFeed?.reactionCount || item.reactionCount,
            streamFeedId: item.reboltFeed?.id || item.id,
            replyCount: item.reboltFeed?.commentCount || item.commentCount,
            reboltCount: item.reboltFeed?.reboltCount || item.reboltCount,
          }}
        />
        <BoltsReceived
          boltCount={item?.reboltFeed?.boltCount || item.boltCount}
          bolts={item?.reboltFeed?.bolts || item.bolts}
        />
      </FlexRow>
    </Box>
  );
};
