import { Typography } from "@mui/material";

export type TitleProps = {
  title: string;
};

export const Title = ({ title }: TitleProps) => {
  return (
    <Typography
      sx={{
        fontSize: 12,
        color: "rgba(255, 255, 255, 0.25)",
      }}
    >
      {title}
    </Typography>
  );
};
