import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Fab, IconButton, Typography } from "@mui/material";
import FlyIcon from "@mui/icons-material/SendRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BoltMentionInput from "../../components/BoltMentionInput";
import { FlexCol, FlexRow } from "../../components";
import {
  fetchHistoryChannelComunity,
  joinChannel,
  leaveChannel,
  sendMessageChannelCommunity,
} from "../../store/socket/actions";
import { socketSelector } from "../../store/socket/selector";
import { CHANNEL_TYPE, MESSAGE_TYPE, Message } from "../../types";
import { ReactComponent as VoteIcon } from "../../assets/svgs/studio/vote.svg";
import { DisplayChatMessage } from "../../components/immersive-panel/chat/DisplayChatMessage";

type Props = {
  channelId: string;
};

export const StudioChat = ({ channelId }: Props) => {
  const dispatch = useDispatch();

  const channelType = CHANNEL_TYPE.STREAM;

  const { socketConnected, messages } = useSelector(socketSelector);
  const [message, setMessage] = useState("");
  const [draftContent, setDraftContent] = useState("");
  const [resetCreator, setResetCreator] = useState(0);
  const [isActiveWelcomeMSG, setActiveWelcomeMSG] = useState(true);
  const [isShowVote, setShowVote] = useState(true);
  const [voteStatus, setVoteStatus] = useState<"none" | "hide" | "show">("show");

  useEffect(() => {
    setTimeout(() => setActiveWelcomeMSG(false), 1000 * 5);
  }, []);

  useEffect(() => {
    if (channelId && socketConnected) {
      dispatch(fetchHistoryChannelComunity({ channelId, channelType }));
      dispatch(joinChannel({ channelId, channelType }));
    }
    return () => {
      if (socketConnected) {
        dispatch(leaveChannel());
      }
    };
  }, [channelId, socketConnected]);

  const handleScroll = (e: any) => {
    if (e.target.scrollHeight + e.target.scrollTop === e.target.clientHeight) {
      dispatch(
        fetchHistoryChannelComunity({
          channelId,
          channelType,
          lastMessageId: messages[messages.length - 1]?.id,
        })
      );
    }
  };

  const sendMessage = () => {
    if (message && message.trim()) {
      dispatch(
        sendMessageChannelCommunity({
          channelId,
          message,
          draftContent,
          channelType,
          type: MESSAGE_TYPE.TEXT,
        })
      );
      setMessage("");
      setResetCreator(Date.now());
    }
  };

  const handleSendClick = (e: any) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <FlexCol
      sx={{
        width: "100%",
        height: "calc(100% - 40px)",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        overflow: "hidden",
      }}
    >
      <FlexCol
        className="-webkit-chatbox-scrollbar chatbox-scrollbar"
        sx={{
          height: 50,
          flexGrow: 1,
          my: 0.5,
          pt: 1.5,
          px: 1,
          overflowY: "auto",
          flexDirection: "column-reverse",
          wordWrap: "break-word",
          position: "relative",
        }}
        onScroll={handleScroll}
      >
        <Button
          variant="contained"
          onClick={() => setActiveWelcomeMSG(false)}
          sx={{
            "&.MuiButton-root:hover": { bgcolor: "secondary.light" },
            position: "absolute",
            left: 8,
            zIndex: 10000,
            py: 0.5,
            px: 1.5,
            mb: 0.5,
            borderRadius: 1,
            bgcolor: "secondary.light",
            fontWeight: 600,
            color: "white",
            transform: isActiveWelcomeMSG ? "scaleY(1)" : "scaleY(0)",
            transition: "transform 400ms ease 0ms",
            // transition: "opacity 0.6s linear",
            // opacity: isActiveWelcomeMSG ? 1 : 0,
          }}
        >
          Welcome to Live Chat!
        </Button>
        {messages.map((item: Message, index) => (
          <DisplayChatMessage item={item} key={index} />
        ))}
      </FlexCol>
      <FlexCol sx={{ bgcolor: "#282C46" }}>
        {voteStatus !== "none" && (
          <FlexCol sx={{ py: 1.2, px: 2, mb: voteStatus === "show" ? -3 : 0 }}>
            <FlexRow sx={{ justifyContent: "space-between" }}>
              <FlexRow>
                <VoteIcon style={{ width: 18, height: 18 }} />
                <Typography sx={{ ml: 1, color: "#A3AED0", fontSize: 14, fontWeight: 600 }}>Vet Now</Typography>
              </FlexRow>
              <IconButton
                sx={{ bgcolor: "#3C436F", width: 22, height: 22 }}
                onClick={() => setVoteStatus((preVal) => (preVal === "show" ? "hide" : "show"))}
              >
                {voteStatus === "show" ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </FlexRow>
            {voteStatus === "show" && (
              <>
                <Typography sx={{ fontSize: 10, color: "#FFFFFF80", letterSpacing: 3, mt: 2 }}>QUESTION #1</Typography>
                <Typography sx={{ fontSize: 14, color: "white", fontWeight: 600 }}>
                  Do you wanna see more of this BAYC Bolties?
                </Typography>

                <FlexRow sx={{ mt: 1.5 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol
                      sx={{
                        background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                        width: "70%",
                        height: 27,
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}
                    >
                      YESSSSS!!! 😍
                    </Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>70%</Typography>
                </FlexRow>

                <FlexRow sx={{ mt: 0.8 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol sx={{ bgcolor: "#343648", width: "20%", height: 27 }} />
                    <Typography
                      sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}
                    >
                      Yes 👍
                    </Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>20%</Typography>
                </FlexRow>

                <FlexRow sx={{ mt: 0.8 }}>
                  <FlexCol sx={{ flex: 1, mr: 1, borderRadius: 2, overflow: "hidden", position: "relative" }}>
                    <FlexCol sx={{ bgcolor: "#343648", width: "10%", height: 27 }} />
                    <Typography
                      sx={{ fontSize: 12, color: "white", fontWeight: 600, position: "absolute", left: 8, top: 4.5 }}
                    >
                      No 👎
                    </Typography>
                  </FlexCol>
                  <Typography sx={{ fontSize: 12, color: "white", fontWeight: 600 }}>10%</Typography>
                </FlexRow>
              </>
            )}
          </FlexCol>
        )}

        <FlexRow
          className="chat-input"
          sx={{
            alignItems: "flex-end",
            p: 1,
            height: voteStatus === "show" ? 94 : 60,
            background:
              voteStatus === "none" ? "#282C46" : "linear-gradient(180deg, rgba(19, 22, 44, 0) 0%, #13162C 61.26%)",
          }}
        >
          <BoltMentionInput
            placeholder="Type something..."
            containerStyle={{ backgroundColor: voteStatus !== "none" ? "#282C46" : "#FFFFFF26", height: 44 }}
            resetCreator={resetCreator}
            onChangeText={(value: string) => setMessage(value)}
            onChangeDraftContent={setDraftContent}
            onEnterKey={sendMessage}
          />
          <Fab
            variant="circular"
            color="primary"
            sx={{
              width: 44,
              height: 44,
              flexShrink: 0,
              px: 2.3,
              background: "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
              borderRadius: 2,
              ml: 1,
              zIndex: 1,
            }}
            onClick={handleSendClick}
          >
            <FlyIcon sx={{ width: 18, height: 18, color: "white" }} />
          </Fab>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};
