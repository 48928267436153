import { useContext } from "react";
import { PrivateLayoutContext } from "./PrivateLayoutContext";

export const usePrivateLayoutContext = () => {
  const context = useContext(PrivateLayoutContext);

  if (!context) {
    throw new Error("usePrivateLayoutContext must be use inside PrivateLayoutProvider");
  }

  return context;
};
