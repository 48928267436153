import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StreamCreateModal } from "./modals/StreamCreateModal";
import { StreamDirectlyCameraMicModal } from "./modals/StreamDirectlyCameraMicModal";
import { userSelector } from "../store/auth/selector";
import { setIsStreamingAction } from "../store/profile/actions";

interface Props {
  onClose: () => void;
}

export const StreamDirectly = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = React.useState(true);
  const user = useSelector(userSelector);

  return isCreating ? (
    <StreamCreateModal
      open
      onClose={onClose}
      onNext={() => {
        setIsCreating(false);
      }}
    />
  ) : (
    <StreamDirectlyCameraMicModal
      open
      onBack={() => setIsCreating(true)}
      onNext={() => {
        onClose();
        setIsCreating(true);
        dispatch(setIsStreamingAction(true));
      }}
    />
  );
};
