import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import {
  BINANCE_PAYOUT_STATUS,
  BINANCE_TRANSACTION_STATUS,
  PAYMENT_STATUS,
  STRIPE_TRANSACTION_STATUS,
} from "../../types";

export interface PaymentState {
  rates: any;
  binancePayOrder: any;
  binancePayStatus: string;
  binancePayoutRequest: {
    transferAmount: number;
    receiveType: string;
    receiver: string;
    remark: string;
  };
  binancePayoutStatus: string;
  binanceAvialbleBalance: number;
  binanceTotalEarnings: number;
  stripePayOrder: any;
  stripePayStatus: string;
  error: any;
  loading: boolean;
  paymentStatus: string;
}

const initialState: PaymentState = {
  rates: null,
  binancePayOrder: null,
  binancePayStatus: BINANCE_TRANSACTION_STATUS.NOT_INITILIZED,
  binancePayoutRequest: {
    transferAmount: 2,
    receiveType: "BINANCE_ID",
    receiver: "",
    remark: "Payout",
  },
  binancePayoutStatus: BINANCE_PAYOUT_STATUS.NOT_INITILIZED,
  error: null,
  loading: false,
  binanceAvialbleBalance: 0,
  binanceTotalEarnings: 0,
  stripePayOrder: null,
  stripePayStatus: STRIPE_TRANSACTION_STATUS.NOT_INITILIZED,
  paymentStatus: PAYMENT_STATUS.NOT_INITILIZED,
};

const reducer = handleActions<PaymentState, any>(
  {
    [actions.FETCH_EXCHANGE_RATE + START]: (state) =>
      produce(state, (draft) => {
        draft.rates = null;
        draft.loading = true;
      }),
    [actions.FETCH_EXCHANGE_RATE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.rates = payload;
        draft.loading = false;
      }),
    [actions.CREATE_BINANCE_PAY_ORDER + START]: (state) =>
      produce(state, (draft) => {
        draft.binancePayOrder = null;
        draft.binancePayStatus = BINANCE_TRANSACTION_STATUS.NOT_INITILIZED;
        draft.paymentStatus = PAYMENT_STATUS.NOT_INITILIZED;
        draft.error = null;
        draft.loading = true;
      }),
    [actions.CREATE_BINANCE_PAY_ORDER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binancePayOrder = payload;
        draft.binancePayStatus = BINANCE_TRANSACTION_STATUS.INITIALIZED;
        draft.paymentStatus = PAYMENT_STATUS.INITIALIZED;
        draft.loading = false;
      }),
    [actions.CREATE_BINANCE_PAY_ORDER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.UPDATE_BINANCE_PAYOUT_REQUEST]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binancePayoutRequest = { ...state.binancePayoutRequest, ...payload };
        draft.error = null;
        draft.loading = true;
      }),
    [actions.CREATE_BINANCE_PAYOUT_REQUEST + START]: (state) =>
      produce(state, (draft) => {
        draft.binancePayoutStatus = BINANCE_PAYOUT_STATUS.NOT_INITILIZED;
        draft.paymentStatus = PAYMENT_STATUS.NOT_INITILIZED;
        draft.error = null;
        draft.loading = true;
      }),
    [actions.CREATE_BINANCE_PAYOUT_REQUEST + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binancePayoutStatus = BINANCE_PAYOUT_STATUS.SUCCESS;
        draft.binancePayoutRequest = {
          transferAmount: 2,
          receiveType: "BINANCE_ID",
          receiver: "",
          remark: "Payout",
        };
        draft.loading = false;
      }),
    [actions.CREATE_BINANCE_PAYOUT_REQUEST + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binancePayoutStatus = BINANCE_PAYOUT_STATUS.FAILED;
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.UPDATE_BINANCE_PAY_STATUS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binancePayStatus = payload;
        if (payload === BINANCE_TRANSACTION_STATUS.NOT_INITILIZED) {
          draft.binancePayOrder = null;
        }
      }),
    [actions.UPDATE_PAY_STATUS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.paymentStatus = payload;
        if (payload === PAYMENT_STATUS.NOT_INITILIZED) {
          draft.binancePayOrder = null;
          draft.stripePayOrder = null;
        }
      }),
    [actions.FETCH_BINANCE_AVAILABLE_BALANCE + START]: (state) =>
      produce(state, (draft) => {
        draft.error = null;
        draft.loading = true;
      }),
    [actions.FETCH_BINANCE_AVAILABLE_BALANCE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.binanceAvialbleBalance = payload.balance;
        draft.loading = false;
      }),
    [actions.FETCH_BINANCE_AVAILABLE_BALANCE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.CREATE_STRIPE_PAY_ORDER + START]: (state) =>
      produce(state, (draft) => {
        draft.stripePayOrder = null;
        draft.stripePayStatus = STRIPE_TRANSACTION_STATUS.NOT_INITILIZED;
        draft.paymentStatus = PAYMENT_STATUS.NOT_INITILIZED;
        draft.error = null;
        draft.loading = true;
      }),
    [actions.CREATE_STRIPE_PAY_ORDER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stripePayOrder = payload;
        draft.stripePayStatus = STRIPE_TRANSACTION_STATUS.INITIALIZED;
        draft.paymentStatus = PAYMENT_STATUS.INITIALIZED;
        draft.loading = false;
      }),
    [actions.CREATE_STRIPE_PAY_ORDER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.UPDATE_STRIPE_PAY_STATUS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stripePayStatus = payload;
        if (payload === STRIPE_TRANSACTION_STATUS.NOT_INITILIZED) {
          draft.stripePayOrder = null;
        }
      }),
  },
  initialState
);

export default reducer;
