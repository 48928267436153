import { Avatar, AvatarProps, Box, ButtonBase, Typography, alpha, useTheme } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useNavigate } from "react-router-dom";

export type LiveItemProps = {
  id: string;
  imageProps?: AvatarProps;
};

export const LiveItem = ({ id, imageProps = {} }: LiveItemProps) => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <ButtonBase
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        "&:hover": {
          outline: `3px solid ${alpha("#fff", 0.2)}`,
        },
      }}
      onClick={() => {
        navigate(`/${id}`);
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Avatar
          sx={{
            width: { xs: 160, md: 320 },
            height: { xs: 120, md: 180 },
            borderRadius: "10px",
            bgcolor: "rgb(35, 37, 51)",
            ".MuiAvatar-img": {
              scale: "1",
              transition: theme.transitions.create(["scale", "transform"]),
            },
            "&:hover": {
              ".MuiAvatar-img": {
                scale: "1.1",
              },
            },
          }}
          {...imageProps}
        >
          <VideocamIcon sx={{ fontSize: 60, color: "#666" }} />
        </Avatar>

        <Box
          sx={{
            position: "absolute",
            top: 12,
            right: 16,
            bgcolor: theme.palette.error.main,
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            columnGap: 0.5,
            px: 0.6,
            py: 0.2,
          }}
        >
          <Box sx={{ width: 5, height: 5, bgcolor: "#fff", borderRadius: 5 }} />

          <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: 10 }}>LIVE</Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};
