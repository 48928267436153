import produce from "immer";
import { handleActions } from "redux-actions";
import { CommentType } from "../../types";
import { FeedDetailsType } from "../../types/feedDetails";
import { FAIL, START, SUCCESS } from "../common";
import {
  FeedCommentsReplyActionType,
  FEED_COMMENT_ADD,
  FEED_COMMENT_ADD_REPLY,
  FETCH_FEED_COMMENT,
  FETCH_FEED_COMMENT_REPLY,
  FETCH_FEED_DETAILS,
  STREAM_COMMENT_REACTION,
  STREAM_COMMENT_REACTION_REMOVE,
  STREAM_INDIVIDUAL_FEED_REACTION,
  STREAM_INDIVIDUAL_FEED_REACTION_REMOVE,
} from "./actions";

const initialState: FeedDetailsType = {
  id: "",
  bolts: [],
  boltCount: 0,
  reboltCount: 0,
  community: {},
  reactionCount: 0,
  creator: {
    username: "",
    firstName: "",
    lastName: "",
    photoUrl: "",
    fullName: "",
    id: "",
  },
  text: "",
  image: "",
  video: "",
  items: [],
  processing: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  comments: [],
  reactions: [],
  commentCount: 0,
  loading: false,
  initialCommentsLoading: false,
  loadingComments: false,
  loadingCommentIndex: -1,
};

const reducer = handleActions<FeedDetailsType, any>(
  {
    // feed details
    [FETCH_FEED_DETAILS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
      }),
    [FETCH_FEED_DETAILS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.id = payload.id;
        draft.creator = payload.creator;
        draft.text = payload.text;
        draft.giphy = payload.giphy;
        draft.image = payload.image;
        draft.video = payload.video;
        draft.items = payload.items;
        draft.processing = payload.processing;
        draft.createdAt = payload.createdAt;
        draft.updatedAt = payload.updatedAt;
        draft.comments = payload.comments;
        draft.reactions = payload.reactions;
        draft.commentCount = payload.commentCount;
        draft.reactionCount = payload.reactionCount;
        draft.bolts = payload.bolts;
        draft.boltCount = payload.boltCount;
        draft.community = payload.community;
        draft.reboltFeed = payload.reboltFeed;
        draft.reboltCount = payload.reboltCount;
        draft.draftContent = payload.draftContent;
        draft.rewind = payload.rewind;
        draft.loading = false;
      }),
    [FETCH_FEED_DETAILS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
      }),
    // feed comments
    [FETCH_FEED_COMMENT + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (payload.initialCommentsLoading) {
          draft.initialCommentsLoading = true;
        }
        draft.loadingComments = true;
      }),
    [FETCH_FEED_COMMENT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { data, initialCommentsLoading } = payload;
        if (initialCommentsLoading) {
          draft.initialCommentsLoading = false;
        }
        draft.loadingComments = false;
        const preparedComments: CommentType[] = [];
        const { reboltFeed } = draft;
        if (reboltFeed) {
          data.forEach((item: any) => {
            // this operation preserves replies between network call
            const prevCommentData = reboltFeed.comments?.find((comment) => comment.id === item.id);
            preparedComments.push({
              ...item,
              replies: prevCommentData?.replies || item.replies,
            });
          });
          reboltFeed.comments = preparedComments;
        } else {
          data.forEach((item: any) => {
            // this operation preserves replies between network call
            const prevCommentData = draft.comments?.find((comment) => comment.id === item.id);
            preparedComments.push({
              ...item,
              replies: prevCommentData?.replies || item.replies,
            });
          });
          draft.comments = preparedComments;
        }
      }),
    [FETCH_FEED_COMMENT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        const { error, initialCommentsLoading } = payload;
        if (initialCommentsLoading) {
          draft.initialCommentsLoading = false;
        }
        draft.loadingComments = false;
      }),
    // feed comment replies
    [FETCH_FEED_COMMENT_REPLY + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const { index } = payload as FeedCommentsReplyActionType;
        draft.loadingCommentIndex = index;
      }),
    [FETCH_FEED_COMMENT_REPLY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loadingCommentIndex = -1;
        const { index, data } = payload;
        if (draft.comments) {
          draft.comments[index].replies = data;
        }
      }),
    [FETCH_FEED_COMMENT_REPLY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loadingCommentIndex = -1;
        // const { index } = payload as FeedCommentsReplyActionType;
      }),
    // feed add comment
    [FEED_COMMENT_ADD + START]: (state, { payload }) => produce(state, (draft) => {}),
    [FEED_COMMENT_ADD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.reboltFeed) {
          draft.reboltFeed.commentCount += 1;
        } else {
          draft.commentCount += 1;
        }
      }),
    [FEED_COMMENT_ADD + FAIL]: (state, { payload }) => produce(state, (draft) => {}),
    // feed add reply
    [FEED_COMMENT_ADD_REPLY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { index } = payload;
        if (draft.comments) {
          draft.comments[index].repliesCount += 1;
        }
      }),
    [STREAM_INDIVIDUAL_FEED_REACTION + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const { reaction, rebolt } = payload;
        if (!rebolt) {
          draft.reactionCount += 1;
          const reactionIndex = draft.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
          if (reactionIndex !== -1) {
            draft.reactions[reactionIndex].count += 1;
            draft.reactions[reactionIndex].reacted = true;
          } else {
            draft.reactions = [
              ...draft.reactions,
              {
                type: reaction,
                count: 1,
                reacted: true,
              },
            ];
          }
        }
        if (rebolt && draft.reboltFeed) {
          draft.reboltFeed.reactionCount += 1;
          const reactionIndex = draft.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
          if (reactionIndex !== -1) {
            draft.reboltFeed.reactions[reactionIndex].count += 1;
            draft.reboltFeed.reactions[reactionIndex].reacted = true;
          } else {
            draft.reboltFeed.reactions = [
              ...draft.reboltFeed.reactions,
              {
                type: reaction,
                count: 1,
                reacted: true,
              },
            ];
          }
        }
      }),
    [STREAM_INDIVIDUAL_FEED_REACTION_REMOVE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const { reaction, rebolt } = payload;
        if (!rebolt) {
          const reactionIndex = draft.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
          if (reactionIndex !== -1) {
            draft.reactionCount -= 1;
            draft.reactions[reactionIndex].count -= 1;
            draft.reactions[reactionIndex].reacted = false;
          }
        }
        if (rebolt && draft.reboltFeed) {
          const reactionIndex = draft.reboltFeed.reactions.findIndex((reactionItem) => reactionItem.type === reaction);
          if (reactionIndex !== -1) {
            draft.reboltFeed.reactionCount -= 1;
            draft.reboltFeed.reactions[reactionIndex].count -= 1;
            draft.reboltFeed.reactions[reactionIndex].reacted = false;
          }
        }
      }),
    [STREAM_COMMENT_REACTION + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.comments && draft.comments) {
          const { streamFeedCommentId, reaction, rebolt } = payload;
          if (!rebolt) {
            const commentIndex = state.comments.findIndex(({ id }) => id === streamFeedCommentId);
            const reactionIndex = state.comments[commentIndex].reactions.findIndex((item) => item.type === reaction);
            draft.comments[commentIndex].reactionCount += 1;
            if (reactionIndex !== -1) {
              draft.comments[commentIndex].reactions[reactionIndex].count += 1;
              draft.comments[commentIndex].reactions[reactionIndex].reacted = true;
            } else {
              draft.comments[commentIndex].reactions = [
                ...draft.comments[commentIndex].reactions,
                {
                  type: reaction,
                  count: 1,
                  reacted: true,
                },
              ];
            }
          }
          if (rebolt && draft.reboltFeed?.comments) {
            const commentIndex = draft.reboltFeed.comments.findIndex(({ id }) => id === streamFeedCommentId);
            const reactionIndex = draft.reboltFeed.comments[commentIndex].reactions.findIndex(
              (item) => item.type === reaction
            );
            draft.reboltFeed.comments[commentIndex].reactionCount += 1;
            if (reactionIndex !== -1) {
              draft.reboltFeed.comments[commentIndex].reactions[reactionIndex].count += 1;
              draft.reboltFeed.comments[commentIndex].reactions[reactionIndex].reacted = true;
            } else {
              draft.reboltFeed.comments[commentIndex].reactions = [
                ...draft.reboltFeed.comments[commentIndex].reactions,
                {
                  type: reaction,
                  count: 1,
                  reacted: true,
                },
              ];
            }
          }
        }
      }),
    [STREAM_COMMENT_REACTION_REMOVE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.comments && draft.comments) {
          const { streamFeedCommentId, reaction, rebolt } = payload;
          if (!rebolt) {
            const commentIndex = state.comments.findIndex(({ id }) => id === streamFeedCommentId);
            const reactionIndex = state.comments[commentIndex].reactions.findIndex((item) => item.type === reaction);
            draft.comments[commentIndex].reactionCount -= 1;
            if (reactionIndex !== -1) {
              draft.comments[commentIndex].reactions[reactionIndex].count -= 1;
              draft.comments[commentIndex].reactions[reactionIndex].reacted = false;
            }
          }
          if (rebolt && draft.reboltFeed?.comments) {
            const commentIndex = draft.reboltFeed.comments.findIndex(({ id }) => id === streamFeedCommentId);
            const reactionIndex = draft.reboltFeed.comments[commentIndex].reactions.findIndex(
              (item) => item.type === reaction
            );
            draft.reboltFeed.comments[commentIndex].reactionCount -= 1;
            if (reactionIndex !== -1) {
              draft.reboltFeed.comments[commentIndex].reactions[reactionIndex].count -= 1;
              draft.reboltFeed.comments[commentIndex].reactions[reactionIndex].reacted = false;
            }
          }
        }
      }),
    // [STREAM_REPLY_REACTION + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedCommentId, replyId, reaction } = payload;
    //     const commentIndex = state.comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const replyIndex = state.comments[commentIndex].replies.findIndex(({ id }) => id === replyId);
    //     const reactionIndex = state.comments[commentIndex].replies[replyIndex].reactions.findIndex(
    //       ({ type }) => type === reaction
    //     );
    //     if (reactionIndex !== -1) {
    //       draft.comments[commentIndex].replies[replyIndex].reactions[reactionIndex].count += 1;
    //       draft.comments[commentIndex].replies[replyIndex].reactions[reactionIndex].reacted = true;
    //     } else {
    //       draft.comments[commentIndex].replies[replyIndex].reactions = [
    //         ...draft.comments[commentIndex].replies[replyIndex].reactions,
    //         { count: 1, reacted: true, type: reaction },
    //       ];
    //     }
    //   }),
    // [STREAM_REPLY_REACTION_REMOVE + START]: (state, { payload }) =>
    //   produce(state, (draft) => {
    //     const { streamFeedCommentId, replyId, reaction } = payload;
    //     const commentIndex = state.comments.findIndex(({ id }) => id === streamFeedCommentId);
    //     const replyIndex = state.comments[commentIndex].replies.findIndex(({ id }) => id === replyId);
    //     const reactionIndex = state.comments[commentIndex].replies[replyIndex].reactions.findIndex(
    //       ({ type }) => type === reaction
    //     );
    //     if (reactionIndex !== -1) {
    //       draft.comments[commentIndex].replies[replyIndex].reactions[reactionIndex].count -= 1;
    //       draft.comments[commentIndex].replies[replyIndex].reactions[reactionIndex].reacted = false;
    //     }
    //   }),
  },
  initialState
);

export default reducer;
