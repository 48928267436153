import { all, fork, take, put } from "redux-saga/effects";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* getUserSettings() {
  while (true) {
    const { payload } = yield take(actions.GET_USER_SETTINGS + START);
    try {
      const { data } = yield apiClient.get("/users/settings");
      yield put({ type: actions.GET_USER_SETTINGS + SUCCESS, payload: data });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.GET_USER_SETTINGS + FAIL, payload: BoltError(error) });
    }
  }
}

function* updateUserSettings() {
  while (true) {
    const { payload } = yield take(actions.SET_USER_SETTINGS + START);
    try {
      const { data } = yield apiClient.put("/users/settings", payload);
      yield put({ type: actions.SET_USER_SETTINGS + SUCCESS, payload: data });
      ToastService.showSuccessMessage("Successfully updated settings.");
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.SET_USER_SETTINGS + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

export default function* userSettingsSaga() {
  yield all([
    fork(getUserSettings),
    fork(updateUserSettings),
  ]);
}
