import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";
import { UserSettings } from "../../types";

export interface UserSettingsState {
  loading: boolean;
  success: boolean;
  error: any;
  data?: UserSettings;
}

const initialState: UserSettingsState = {
  loading: false,
  success: false,
  error: null,
  data: undefined
}

const reducer = handleActions<UserSettingsState, any>(
  {
    [actions.GET_USER_SETTINGS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [actions.GET_USER_SETTINGS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.data = payload;
      }),
    [actions.SET_USER_SETTINGS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [actions.SET_USER_SETTINGS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.data = payload;
      }),
    [actions.SET_USER_SETTINGS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
        draft.data = undefined;
      })
  },

  initialState
);

export default reducer;
