import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_STREAMER_TWEETS = "FETCH_STREAMER_TWEETS";
export const RESET_STREAMER = "RESET_STREAMER";
export const GET_STREAMER = "GET_STREAMER";
export const FOLLOW_STREAMER = "FOLLOW_STREAMER";
export const FETCH_STREAMER_RECORD = "FETCH_STREAMER_RECORD";
export const UPDATE_STREAMER_RECORD = "UPDATE_STREAMER_RECORD";
export const DELETE_STREAMER_RECORD = "DELETE_STREAMER_RECORD";
export const FETCH_LIVE_STREAMERS = "FETCH_LIVE_STREAMERS";
export const SET_STREAM_DATA = "SET_STREAM_DATA";

export const resetStreamerAction = createAction(RESET_STREAMER);
export const getStreamerAction = createAction(GET_STREAMER + START);
export const fetchStreamerTweetsAction = createAction(FETCH_STREAMER_TWEETS + START);
export const followStreamerAction = createAction(FOLLOW_STREAMER + START);
export const fetchStreamerRecordAction = createAction(FETCH_STREAMER_RECORD + START);
export const updateStreamerRecordAction = createAction(UPDATE_STREAMER_RECORD + START);
export const deleteStreamerRecordAction = createAction(DELETE_STREAMER_RECORD + START);
export const fetchLiveStreamersAction = createAction(FETCH_LIVE_STREAMERS + START);
export const setStreamDataAction = createAction(SET_STREAM_DATA);
