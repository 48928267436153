import React, { useCallback, useEffect, useMemo, useState } from "react";
import OpenPlayerJS from "openplayerjs";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ClosedCaptionOffIcon from "@mui/icons-material/ClosedCaptionOff";
import Hls from "hls.js";

import { FlexRow } from "../../FlexRow";

type OptionMenuItemProps = {
  onClick: () => void;
  label: string;
  selected: boolean;
};

const OptionMenuItem = ({ onClick, label, selected }: OptionMenuItemProps) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        fontWeight: 500,
        fontSize: { xs: 9, sm: 12 },
        minHeight: 12,
        color: selected ? "#ffffff" : "#ffffff4d",
      }}
    >
      {label}
    </MenuItem>
  );
};

interface Props {
  player: any;
}

export const CaptionControl = ({ player }: Props) => {
  // const [tracks, setTracks] = useState<any>([]);
  const [selected, setSelected] = useState(-1);

  const [anchorSettingEl, setAnchorSettingEl] = useState<null | HTMLElement>(null);
  const openSettingMenu = Boolean(anchorSettingEl);

  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSettingEl(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorSettingEl(null);
  };

  useEffect(() => {
    handleCloseSetting();
  }, [selected]);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseSetting);
    return () => {
      window.removeEventListener("scroll", handleCloseSetting);
    };
  }, []);

  // useEffect(() => {
  //   if (!player) {
  //     return;
  //   }
  //   setTracks(player?.subtitleTrackController?.tracks || []);
  // }, [player]);

  // const selectedLabel = useMemo(() => {
  //   if (selected < 0) {
  //     return "Off";
  //   }
  //   const selectedLevel: any = tracks?.[selected];
  //   if (selectedLevel?.name) {
  //     return selectedLevel?.name;
  //   }
  //   return "Off";
  // }, [tracks, selected]);

  const onClickLevel = useCallback(
    (id: number) => {
      if (!player) {
        return;
      }
      setSelected(id);
      if (id === -1) {
        // Turn off captions
        // @ts-ignore
        const videoElement = document.getElementById("player") as HTMLVideoElement;
        if (videoElement && videoElement.textTracks) {
          for (let i = 0; i < videoElement.textTracks.length; i += 1) {
            videoElement.textTracks[i].mode = "disabled"; // Disable the subtitle track
          }
        }

        // player.getOptions().subtitleDisplay = false;
        // @ts-ignore
        // player.getOptions().subtitleTrack = -1;
      } else {
        const videoElement = document.getElementById("player") as HTMLVideoElement;
        if (videoElement && videoElement.textTracks) {
          for (let i = 0; i < videoElement.textTracks.length; i += 1) {
            if (id === i) {
              videoElement.textTracks[id].mode = "showing"; // Disable the subtitle track
            } else {
              videoElement.textTracks[i].mode = "disabled";
            }
          }
        }
        // Enable selected caption
        // @ts-ignore
        // player.getOptions().subtitleDisplay = true;
        // @ts-ignore
        // player.getOptions().subtitleTrack = id;
      }
    },
    [player]
  );

  if (!player?.subtitleTrackController?.tracks?.length) {
    return null;
  }

  // const playerValue = player?.getInternalPlayer("hls");

  return (
    <FlexRow sx={{ alignItems: "center" }}>
      <Tooltip title="Subtitles" placement="top">
        <IconButton
          style={{ borderRadius: 4.5 }}
          onClick={handleClickSetting}
          sx={{ mr: 1, backgroundColor: openSettingMenu ? "#ffffff1a" : "transparent" }}
          id="player_setting_button"
          aria-controls={openSettingMenu ? "player_setting_menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openSettingMenu ? "true" : undefined}
        >
          <ClosedCaptionOffIcon style={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="player_setting_menu"
        aria-labelledby="player_setting_button"
        anchorEl={anchorSettingEl}
        open={openSettingMenu}
        onClose={handleCloseSetting}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        anchorOrigin={{ vertical: -2, horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 80,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mb: { xs: 0, sm: 2 },
          },
        }}
      >
        {player?.subtitleTrackController?.tracks?.map((item: any, index: number) => (
          <OptionMenuItem
            key={index}
            onClick={() => {
              onClickLevel(index);
            }}
            selected={selected === index}
            label={`${item.name}`}
          />
        ))}
        <OptionMenuItem
          onClick={() => {
            onClickLevel(-1);
          }}
          selected={selected === -1}
          label="Off"
        />
      </Menu>
    </FlexRow>
  );
};
