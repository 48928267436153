import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { BoltPlayer, PageLoader } from "../../../../components";
import { defaultChatContainerId } from "../../../../constants";
import { CHANNEL_TYPE, RECEIVER_TYPE } from "../../../../types";
import { useImmersivePanelLayoutContext } from "../../../../layouts";
import "./styles.css";
import { EpisodesCarousel } from "./EpisodesCarousel";
import { useCurrentEpisode } from "./useCurrentEpisode";
import { BackToEpisodes } from "./BackToEpisodes";
import { PrevBtn } from "./PrevBtn";
import { NextBtn } from "./NextBtn";

export const OneFootballEpisodePageUi = () => {
  const navigate = useNavigate();

  const { updateContext: updateImmersivePanelLayout } = useImmersivePanelLayoutContext();

  const { currentEpisode, isLoadingData, categorySlug } = useCurrentEpisode();

  useEffect(() => {
    if (!isLoadingData) {
      if (!currentEpisode) {
        navigate(`/p/bloomberg/${categorySlug}`);
      } else {
        updateImmersivePanelLayout({
          chatProps: {
            containerId: defaultChatContainerId,
            channelId: currentEpisode.id,
            channelType: CHANNEL_TYPE.CHANNEL,
            receiverName: currentEpisode.title,
            receiverType: RECEIVER_TYPE.CHANNEL,
          },
        });
      }
    }
  }, [isLoadingData, currentEpisode]);

  if (isLoadingData) {
    return <PageLoader />;
  }

  return (
    <>
      <Box>
        <BoltPlayer
          url={currentEpisode?.media.url}
          title={currentEpisode?.title}
          channelId=""
          isSideChatBoxOpen
          className="bloomberg-episode-player"
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, py: 3 }}>
        <Box sx={{ px: 3, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
            <BackToEpisodes />

            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 2, ml: "auto" }}>
              <PrevBtn />

              <NextBtn />
            </Box>
          </Box>

          <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{currentEpisode.title}</Typography>

          <Typography
            color="text.secondary"
            sx={{
              fontSize: 14,
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            }}
          >
            {currentEpisode.description}
          </Typography>
        </Box>

        <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "space-between", px: 3 }}>
          <PrevBtn />

          <NextBtn />
        </Box>

        <EpisodesCarousel />
      </Box>
    </>
  );
};
