import { apiClient } from "../config";

export const fetchNftCommunityFeed = (nftCreatorId: string) => {
  return apiClient.get(`/feed/nftCommunity/${nftCreatorId}`);
};

export const createNftCommunityPost = (nftCreatorId: string, data: any) => {
  return apiClient.post(`/feed/nftCommunity/${nftCreatorId}`, data);
};

// export const uploadFileNftComunityPost = (contractAddress: string, data: any) => {
//   return apiClient.post(`/nftcreators/${contractAddress}/feeds/upload`, data)
// }
