import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { NAV_BAR_HEIGHT } from "../constants";
import { streamDataSelector } from "../store/streamer/selector";
import LiveStreamPlayer from "./LiveStreamPlayer";
import { setStreamDataAction } from "../store/streamer/actions";
import { Popup } from "../utils";
import BoltPlayer from "./BoltPlayer";

export const FloatingVideoPlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { url, isLive, boltId, title, isChannel } = useSelector(streamDataSelector);

  const closeFloatingPlayer = () => {
    dispatch(setStreamDataAction({ url: undefined }));
  };

  const backToProfile = () => {
    navigate(`/${boltId}`);
  };

  const handlePopout = () => {
    Popup.handleVideoPopup(boltId);
  };

  if (!isLive || !url) return null;

  return (
    <Box
      sx={{
        zIndex: 9000,
        position: "fixed",
        bottom: { xs: NAV_BAR_HEIGHT + 5, sm: 9 },
        right: { xs: 8, sm: 16 },
        height: { xs: 120, sm: 180 },
        width: { xs: 210, sm: 320 },
      }}
    >
      {isChannel ? (
        <BoltPlayer
          url={url}
          title={title}
          isLive={isLive}
          isFloatingPlayer
          closeFloatingPlayer={closeFloatingPlayer}
          backToProfile={backToProfile}
          handlePopout={handlePopout}
        />
      ) : (
        <LiveStreamPlayer
          url={url}
          isLive={isLive}
          title={title}
          isFloatingPlayer
          closeFloatingPlayer={closeFloatingPlayer}
          backToProfile={backToProfile}
          handlePopout={handlePopout}
        />
      )}
    </Box>
  );
};
