import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { userSelector } from "../../../../store/auth/selector";
import { DefaultAvatar } from "../../../DefaultAvatar";
import { usePrivateNavbarContext } from "../PrivateNavbarContext";

export const ProfileButton = () => {
  const theme = useTheme();
  const user = useSelector(userSelector);
  const { updateContext: updateNavbarContext } = usePrivateNavbarContext();

  return (
    <Button
      variant="contained"
      startIcon={
        <Avatar
          alt={user?.username}
          src={user?.photoUrl || ""}
          sx={{
            height: 25,
            width: 25,
            borderRadius: "7px",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <DefaultAvatar />
        </Avatar>
      }
      endIcon={<ExpandMoreIcon />}
      onClick={() => {
        updateNavbarContext({
          activeDrawer: "profile",
        });
      }}
      sx={{
        height: 36,
        py: 0,
        pl: 1.5,
        pr: 1,
        borderRadius: 2,
        bgcolor: theme.palette.secondary.dark,
        color: theme.palette.common.white,

        "&:hover": {
          bgcolor: theme.palette.secondary.light,
          color: theme.palette.common.white,
        },
      }}
    >
      <span className="main-user-name">{user?.username}</span>
    </Button>
  );
};
