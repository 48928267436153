import { all, fork, put, select, take } from "redux-saga/effects";
import { Action, Dispatch } from "redux";

import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { UpdateStreamRecordDto } from "../../dtos";
import { uploadCommonFile } from "../../services/s3";
import { FILE_UPLOAD_PROGRESS } from "../ui/actions";
import { RootState } from "../../types";

function* getStreamer(): any {
  while (true) {
    const { payload } = yield take(actions.GET_STREAMER + START);
    try {
      const { data } = yield apiClient.get(`/users/${payload}`);
      yield put({
        type: actions.GET_STREAMER + SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      yield put({
        type: actions.GET_STREAMER + FAIL,
        payload: error.response.data,
      });
    }
  }
}

function* fetchStreamerTweets() {
  while (true) {
    const { payload } = yield take(actions.FETCH_STREAMER_TWEETS + START);
    const { twitterId, nextPageToken = "" } = payload;
    try {
      const { data } = yield apiClient.get(`/twitter/${twitterId}/tweets?limit=10&pageToken=${nextPageToken}`);
      yield put({
        type: actions.FETCH_STREAMER_TWEETS + SUCCESS,
        payload: { ...data, twitterId },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_STREAMER_TWEETS + FAIL,
        payload: { ...BoltError(error), twitterId },
      });
    }
  }
}

function* fetchStreamerRecord() {
  while (true) {
    const { payload } = yield take(actions.FETCH_STREAMER_RECORD + START);
    const { page = 1, userId, sort } = payload;

    try {
      const { data } = yield apiClient.get(
        `/v2/users/${userId}/records?limit=10&page=${page}${sort !== "" ? "&query=-1" : ""}`
      );
      yield put({
        type: actions.FETCH_STREAMER_RECORD + SUCCESS,
        payload: { ...data, userId },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_STREAMER_RECORD + FAIL,
        payload: error,
      });
    }
  }
}

interface UpdateStreamerRecordPayload {
  payload: {
    id: string;
    data: UpdateStreamRecordDto;
    file?: File;
    onSuccess?: () => void;
    onFail?: (error: any) => void;
  };
}

function* updateStreamerRecord(dispatch: Dispatch<Action<UpdateStreamerRecordPayload>>) {
  while (true) {
    const { payload }: UpdateStreamerRecordPayload = yield take(actions.UPDATE_STREAMER_RECORD + START);
    const { id, file, data, onSuccess = () => {}, onFail = () => {} } = payload;

    let newThumbnail = data.thumbnail;
    try {
      if (file) {
        const { auth }: RootState = yield select();
        const uploadId = Date.now();
        newThumbnail = yield uploadCommonFile({
          folder: `stream/thumbnails/${auth.user?.id}`,
          file,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          uploadId,
        });
        yield put({
          type: FILE_UPLOAD_PROGRESS,
          payload: {
            uploadId,
            progress: 0.01,
          },
        });
      }
      const { data: newData } = yield apiClient.put(`/streamRecords/${id}`, { ...data, thumbnail: newThumbnail });
      yield put({
        type: actions.UPDATE_STREAMER_RECORD + SUCCESS,
        payload: { id, data: newData },
      });
      onSuccess();
      ToastService.showSuccessMessage("Successfully updated video records.");
    } catch (error) {
      yield put({
        type: actions.UPDATE_STREAMER_RECORD + FAIL,
        payload: error,
      });
      onFail(error);
      ToastService.showErrorMessage("Something went wrong");
    }
  }
}

function* deleteStreamerRecord() {
  while (true) {
    const { payload } = yield take(actions.DELETE_STREAMER_RECORD + START);
    const { id } = payload;

    try {
      yield apiClient.delete(`/streamRecords/${id}`);
      yield put({
        type: actions.DELETE_STREAMER_RECORD + SUCCESS,
        payload: { id },
      });
      ToastService.showSuccessMessage("Successfully deleted video.");
    } catch (error) {
      yield put({
        type: actions.DELETE_STREAMER_RECORD + FAIL,
        payload: error,
      });
      ToastService.showErrorMessage("Something went wrong");
    }
  }
}

function* fetchLiveStreamer() {
  while (true) {
    yield take(actions.FETCH_LIVE_STREAMERS + START);
    try {
      const { data } = yield apiClient.get("/live");
      yield put({
        type: actions.FETCH_LIVE_STREAMERS + SUCCESS,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_LIVE_STREAMERS + FAIL,
        payload: error,
      });
    }
  }
}

export default function* streamerSaga(dispatch: Dispatch) {
  yield all([
    fork(getStreamer),
    fork(fetchStreamerTweets),
    fork(fetchStreamerRecord),
    fork(updateStreamerRecord, dispatch),
    fork(deleteStreamerRecord),
    fork(fetchLiveStreamer),
  ]);
}
