import { Dispatch } from "redux";
import { all, fork, take, put } from "redux-saga/effects";
import IVSBroadcastClient from "amazon-ivs-web-broadcast";
import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { FETCH_USER } from "../auth/actions";
import { uploadProfileImage } from "../../services/s3";
import { FILE_UPLOAD_PROGRESS } from "../ui/actions";
import { SubCategory } from "../../types";

function* InitStream() {
  while (true) {
    const { payload } = yield take(actions.INIT_IVS_BROADCAST_CLIENT + START);
    try {
      const ivsBroadcastClient = IVSBroadcastClient.create(payload);
      yield put({ type: actions.INIT_IVS_BROADCAST_CLIENT + SUCCESS, payload: ivsBroadcastClient });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.INIT_IVS_BROADCAST_CLIENT + FAIL, payload: BoltError(error) });
    }
  }
}

function* GetUserProfile() {
  while (true) {
    const { payload } = yield take(actions.GET_USER_PROFILE + START);
    try {
      const { data } = yield apiClient.get(`/users/${payload}`);
      yield put({ type: actions.GET_USER_PROFILE + SUCCESS, payload: data });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.GET_USER_PROFILE + FAIL, payload: BoltError(error) });
    }
  }
}

function* FollowUser() {
  while (true) {
    const { payload } = yield take(actions.FOLLOW_USER + START);
    const { userId, follow = true } = payload;
    try {
      yield apiClient.post(`/users/follow/${userId}`, { follow });
      yield put({ type: actions.FOLLOW_USER + SUCCESS, payload: { userId, follow } });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.FOLLOW_USER + FAIL, payload: { ...BoltError(error), userId, follow } });
    }
  }
}

function* UpdateProfile(dispatch: Dispatch) {
  while (true) {
    const { payload } = yield take(actions.CREATE_STREAM_PROFILE + START);
    try {
      if (typeof payload.photoUrl === "object") {
        const timestamp = new Date().getTime();
        const splitedByDotName = payload.photoUrl.name.split(".");
        const fileType = splitedByDotName[splitedByDotName.length - 1];
        const fileName = payload.photoUrl.name.split(".")[0].toLowerCase().replace(/\s/g, "");
        const newFileName = `${timestamp}_${fileName}.${fileType}`;
        const mediaUrl: string | undefined = yield uploadProfileImage({
          folder: "avatar",
          file: payload.photoUrl,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          newFileName,
        });

        payload.photoUrl = mediaUrl;
      }
      if (typeof payload.coverImage === "object") {
        const timestamp = new Date().getTime();
        const splitedByDotName = payload.coverImage.name.split(".");
        const fileType = splitedByDotName[splitedByDotName - 1];
        const fileName = payload.coverImage.name.split(".")[0].toLowerCase().replace(/\s/g, "");
        const newFileName = `${timestamp}_${fileName}.${fileType}`;
        const mediaUrl: string | undefined = yield uploadProfileImage({
          folder: "cover",
          file: payload.coverImage,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          newFileName,
        });

        payload.coverImage = mediaUrl;
      }
      const { data } = yield apiClient.post("/users/profile", payload);
      yield apiClient.post("/users/stream");
      yield dispatch({ type: FETCH_USER + SUCCESS, payload: { data } });
      yield put({
        type: actions.CREATE_STREAM_PROFILE + SUCCESS,
      });
    } catch (error: any) {
      yield put({
        type: actions.CREATE_STREAM_PROFILE + FAIL,
        payload: BoltError(error),
      });
    }
  }
}

function* UpdateStreamProfile(dispatch: Dispatch) {
  while (true) {
    const { payload } = yield take(actions.UPDATE_STREAM_PROFILE + START);
    try {
      if (typeof payload.thumbnail === "object") {
        const timestamp = new Date().getTime();
        const splitedByDotName = payload.thumbnail.name.split(".");
        const fileType = splitedByDotName[splitedByDotName.length - 1];
        const fileName = payload.thumbnail.name.split(".")[0].toLowerCase().replace(/\s/g, "");
        const newFileName = `${timestamp}_${fileName}.${fileType}`;
        const mediaUrl: string | undefined = yield uploadProfileImage({
          folder: "thumbnail",
          file: payload.thumbnail,
          dispatch,
          reduxAction: FILE_UPLOAD_PROGRESS,
          newFileName,
        });

        payload.thumbnail = mediaUrl;
      }
      yield put(actions.setStreamProfileAction(payload));
      yield apiClient.put("/users/stream", {
        ...payload,
        categories: payload?.categories?.map((item: SubCategory) => item.id) || [],
      });
      if (payload?.onFinish) {
        payload?.onFinish();
      }
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.UPDATE_STREAM_PROFILE + FAIL,
        payload: BoltError(error),
      });
      if (payload?.onFinish) {
        payload?.onFinish();
      }
    }
  }
}

function* GetTrendingTags(dispatch: Dispatch) {
  while (true) {
    yield take(actions.GET_STREAMING_TAGS + START);
    try {
      const { data } = yield apiClient.get("trending-tags");
      const tags = data.map((item: any) => ({ id: item.id, title: item.tag.name }));

      yield dispatch({ type: actions.GET_STREAMING_TAGS + SUCCESS, payload: { tags } });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
    }
  }
}

function* JoinWorldCupCampaign() {
  while (true) {
    yield take(actions.JOIN_WORLD_CUP_CAMPAIGN + START);
    try {
      const { data } = yield apiClient.post("/users/activities", { event: "637914b9754e4f081c61c910" });
      yield put({ type: actions.JOIN_WORLD_CUP_CAMPAIGN + SUCCESS, payload: { data } });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({ type: actions.JOIN_WORLD_CUP_CAMPAIGN + FAIL, payload: { ...BoltError(error) } });
    }
  }
}

export default function* profileSaga(dispatch: Dispatch) {
  yield all([
    fork(GetUserProfile),
    fork(FollowUser),
    fork(UpdateProfile, dispatch),
    fork(UpdateStreamProfile, dispatch),
    fork(GetTrendingTags, dispatch),
    fork(JoinWorldCupCampaign),
    fork(InitStream),
  ]);
}
