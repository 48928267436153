import { Link as ReactRouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

import { useCurrentEpisode } from "./useCurrentEpisode";

export const BackToEpisodes = () => {
  const { categorySlug } = useCurrentEpisode();

  return (
    <Link
      component={ReactRouterLink}
      to={`/p/bloomberg/${categorySlug}`}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
        textDecoration: "none",
      }}
    >
      <ArrowBackIos sx={{ width: 14, height: 14 }} />
      Back
    </Link>
  );
};
