import { useSearchLandingContext } from "../search-landing";
import { BrowseItemProps } from "./BrowseItem";

export const useBrowseItems = () => {
  const { categories } = useSearchLandingContext();

  const browseItems: BrowseItemProps[] = categories.map((item) => {
    return {
      id: item.id,
      imageProps: {
        src: item.icon,
      },
      title: item.name,
    };
  });

  return { browseItems };
};
