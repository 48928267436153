import { handleActions } from "redux-actions";
import produce from "immer";
import { Message } from "../../types";

import * as actions from "./actions";
import { FAIL, START, SUCCESS } from "../common";

export interface SocketState {
  socketConnected: boolean;
  loading: boolean;
  error: any;
  messages: Message[];
  hasMore?: boolean;
  notification: any;
  typers: {
    items: {
      id: string;
      username: string;
      photoUrl: string;
      fullName: string;
    }[];
    total: number;
  };
}

const initialState: SocketState = {
  socketConnected: false,
  loading: false,
  error: null,
  messages: [],
  hasMore: false,
  notification: null,
  typers: {
    items: [],
    total: 0,
  },
};

const reducer = handleActions<SocketState, any>(
  {
    [actions.CONNECT_SOCKET + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
        draft.messages = [];
        draft.notification = null;
      }),
    [actions.CONNECT_SOCKET + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.socketConnected = true;
        draft.loading = false;
        draft.error = null;
      }),
    [actions.CONNECT_SOCKET + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.DISCONNECT_SOCKET]: (state) =>
      produce(state, (draft) => {
        draft.socketConnected = false;
        draft.messages = [];
        draft.notification = null;
      }),
    [actions.JOIN_CHANNEL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages = [];
      }),
    [actions.LEAVE_CHANNEL]: (state) =>
      produce(state, (draft) => {
        // draft.messages = [];
      }),
    [actions.SEND_CHANNEL_MESSAGE + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
      }),
    [actions.SEND_CHANNEL_MESSAGE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [actions.RECEIVED_CHANNEL_MESSAGE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages = [payload, ...state.messages];
      }),
    [actions.RECEIVED_NOTIFICATION]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.notification = payload;
      }),
    [actions.FETCH_CHANNEL_MESSAGE_HISTORY + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.hasMore = false;
        draft.error = payload;
      }),
    [actions.FETCH_CHANNEL_MESSAGE_HISTORY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
        draft.hasMore = payload?.length > 0;
        draft.messages = [...state.messages, ...payload];
      }),
    [actions.FETCH_CHANNEL_MESSAGE_HISTORY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.hasMore = false;
        draft.error = payload;
      }),
    [actions.SET_TYPERS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.typers = payload;
      }),
  },

  initialState
);

export default reducer;
