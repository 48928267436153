import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import {
  ModalProps,
  Typography,
  Paper,
  IconButton,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TextareaAutosize,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

import { FlexRow } from "../FlexRow";
import { FlexCol } from "../FlexCol";
import { BaseModal } from "./BaseModal";
import { REPORT_TYPE } from "../../types";
import { ReportDto } from "../../dtos";
import { apiClient } from "../../config";
import { ToastService } from "../../services";

const resolver = classValidatorResolver(ReportDto);

interface Props extends Partial<ModalProps> {
  reportType: REPORT_TYPE;
  entityId?: string;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const ReportModal = ({ reportType, entityId, onConfirm = () => {}, onClose = () => {}, ...rest }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<ReportDto>({ resolver, defaultValues: { entityType: reportType, entityId, reason: "", content: "" } });

  React.useEffect(() => {
    reset({ entityType: reportType, entityId, reason: "", content: "" });
  }, [rest?.open, reportType, entityId]);

  const onSubmit = async (data: ReportDto) => {
    try {
      await apiClient.post("/report", data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    ToastService.showInfoMessage(
      "Thank you for reporting. Your feedback is important in helping us keep the Bolt+ community safe."
    );
    onClose();
  };

  return (
    <BaseModal
      {...rest}
      open={!!rest.open}
      onClose={onClose}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <Box
          component={Paper}
          sx={{
            px: 4,
            py: 2,
            borderRadius: 4,
            position: "relative",
            flexDirection: "column",
            width: 420,
          }}
        >
          <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
          <FlexCol>
            <Typography fontWeight="600">Report Post</Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>
              Why are you reporthing this post?
            </Typography>
            <FormControl>
              <Controller
                rules={{ required: true }}
                control={control}
                name="reason"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel value="offensive" control={<Radio />} label="Offensive" />
                    <FormControlLabel value="spam" control={<Radio />} label="Spam" />
                    <FormControlLabel value="violence" control={<Radio />} label="Violence" />
                    <FormControlLabel value="harassment" control={<Radio />} label="Harassment" />
                    <FormControlLabel value="nudity" control={<Radio />} label="Nudity" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                )}
              />
              <FormHelperText error={!!errors.reason}>{errors?.reason?.message}</FormHelperText>
            </FormControl>
            <input type="hidden" id="entityId" {...register("entityId")} />
            <>
              <Typography variant="caption" color="info" sx={{ my: 1 }}>
                Your comment(optional)
              </Typography>
              <TextareaAutosize
                required
                maxRows={4}
                minRows={4}
                id="content"
                style={{
                  width: "100%",
                  outline: "none",
                  fontFamily: "Poppins",
                  fontSize: 14,
                  backgroundColor: "#ffffff10",
                  color: "white",
                  borderRadius: 8,
                  padding: 8,
                  fontWeight: 500,
                  borderWidth: 0,
                  resize: "none",
                }}
                // error={!!errors.content}
                // helperText={errors.content?.message}
                {...register("content")}
              />
              <FormHelperText error={!!errors.content}>{errors?.content?.message}</FormHelperText>
            </>
          </FlexCol>
          <FlexRow sx={{ justifyContent: "flex-end", mt: 2 }}>
            <Button sx={{ mr: 2 }} onClick={onClose}>
              <Typography color="primary.contrastText">Cancel</Typography>
            </Button>
            <Button type="submit">
              <Typography color="primary.contrastText">Report</Typography>
            </Button>
          </FlexRow>
        </Box>
      </Box>
    </BaseModal>
  );
};
