import React from "react";
import { Box, IconButton, ModalProps, Paper, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { BaseModal } from "./BaseModal";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { ReactComponent as MFAIcon } from "../../assets/svgs/mfa.svg";
import { LoadingViewInComponent } from "../LoadingViewInComponent";

interface Props extends Partial<ModalProps> {
  open: boolean;
  loading?: boolean;
  title?: string;
  showTitle?: boolean;
  subtitle?: string;
  disableClose?: boolean;
  onClose: () => void;
}

export const BaseMFAModal = ({
  open,
  loading = false,
  showTitle = true,
  disableClose = false,
  title = "",
  subtitle = "",
  onClose,
  children,
  ...rest
}: Props) => {
  return (
    <BaseModal
      {...rest}
      disableEscapeKeyDown
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        onClose();
      }}
    >
      <Box
        component={Paper}
        elevation={0}
        sx={{ pb: 4, px: 3, pt: 2, borderRadius: 4, position: "relative", flexDirection: "column", width: 375 }}
      >
        <FlexRow sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <FlexRow sx={{ alignItems: "center" }}>
            {showTitle && (
              <>
                <MFAIcon width={22} height={22} />
                <Typography variant="subtitle1" color="#A3AED0" fontWeight="600" sx={{ ml: 1 }}>
                  {title || "Two Factor Authentication"}
                </Typography>
              </>
            )}
          </FlexRow>
          <IconButton sx={{ mr: -2 }} disabled={disableClose} onClick={onClose}>
            <CloseRoundedIcon sx={{ color: "#A3AED0" }} />
          </IconButton>
        </FlexRow>
        {!!subtitle && (
          <FlexCol sx={{ mt: 1 }}>
            <Typography variant="tiny" color="#FFFFFF80">
              {subtitle}
            </Typography>
          </FlexCol>
        )}
        {children}
        <LoadingViewInComponent visible={loading} sx={{ backgroundColor: "#00000080" }} />
      </Box>
    </BaseModal>
  );
};
