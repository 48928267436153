import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { USER_GENDER } from "../types";

export class OnboardingDto {
  @IsString()
  @MinLength(6, { message: "Invalid username at least 6 chars" })
  @MaxLength(32, { message: "Invalid username, max 32 chars" })
  @IsNotEmpty({ message: "Add Username" })
  public username: string;

  @IsOptional()
  @IsEnum(USER_GENDER)
  public gender: USER_GENDER;

  @IsOptional()
  @IsBoolean()
  public subscribeEmail: boolean;

  @IsOptional()
  @IsBoolean()
  public allowUserTracking: boolean;

  @IsOptional()
  @IsArray()
  public interestes: Array<string>;
}
