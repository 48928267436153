import React from "react";
import { Button, ModalProps } from "@mui/material";

import { BaseMFAModal } from "../BaseMFAModal";
import { FlexCol } from "../../FlexCol";
import { MFACodeInput } from "../../MFACodeInput";
import { useWithdraw } from "../../../hooks/WithdrawContext";
import { WithdrawModalType } from "../../../types";

interface Props extends Partial<ModalProps> {}

export const WithdrawMFACodeModal = ({ ...rest }: Props) => {
  const { values, setValues, verifyCode } = useWithdraw();

  return (
    <BaseMFAModal
      {...rest}
      open={values.modal === WithdrawModalType.CODE_VERIFY}
      loading={values.loadings.mfaVerifying}
      subtitle="To continue the withdrawal, Please enter the 6-digit verification code generated by the app."
      onClose={() => setValues({ ...values, modal: undefined })}
    >
      <FlexCol sx={{ mt: 2 }}>
        <FlexCol>
          <MFACodeInput
            error={values.errors.invalidCode}
            onChange={(code) => setValues({ ...values, verifyCode: code })}
          />
        </FlexCol>
        <FlexCol alignItems="center" sx={{ mt: 3 }}>
          <Button variant="contained" sx={{ borderRadius: 40, width: "50%" }} onClick={verifyCode}>
            Authenticate
          </Button>
        </FlexCol>
      </FlexCol>
    </BaseMFAModal>
  );
};
