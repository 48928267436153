import React from "react";
import moment from "moment";
import { Grid, Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";

import { getPaymentMethod } from "../../../../utils";
import { EarnigHistoryProps } from "./EarnigHistoryProps";
import { NoHistory } from "./NoHistory";
import { StatusLabel } from "./StatusLabel";
import { TableCell } from "./TableCell";
import { FlexCol } from "../../../../components";

export const HistoryDebit = ({ data, loading, hasMore, loadMore }: EarnigHistoryProps) => {
  return (
    <FlexCol sx={{ pb: 4 }}>
      <Grid
        item
        container
        sx={{ width: "100%", backgroundColor: "rgba(255, 255, 255, 0.05)", p: 1, mt: 3, borderRadius: 2 }}
      >
        <TableCell xs={1.5}>Date</TableCell>
        <TableCell xs={3}>User</TableCell>
        <TableCell xs={2}>Bolts</TableCell>
        <TableCell xs={4}>Payment Method</TableCell>
        <TableCell xs={1.5}>Status</TableCell>
      </Grid>

      {!loading && data.length === 0 && <NoHistory />}

      <InfiniteScroll pageStart={1} hasMore={hasMore} loadMore={loadMore} useWindow>
        {data.map((row, index) => (
          <Grid item container mt={1.5} key={index} alignItems="center">
            <TableCell xs={1.5} sx={{ fontSize: 12, color: "#efefef80" }}>
              {moment(row.createdAt).format("DD MMM, YYYY h:mm a")}
            </TableCell>
            <TableCell xs={3} sx={{ fontSize: 14, fontWeight: 600 }}>
              {row.sender?.username || ""}
            </TableCell>
            <TableCell xs={2} sx={{ fontSize: 16, fontWeight: 600, display: "flex" }}>
              ${row.bolt}
            </TableCell>
            <TableCell xs={4} sx={{ fontSize: 14, fontWeight: 400 }}>
              {getPaymentMethod(row.paymentMethod)}
            </TableCell>
            <TableCell xs={1.5} sx={{ fontSize: 16, fontWeight: 600, display: "flex" }}>
              <StatusLabel status={row.status} />
            </TableCell>
          </Grid>
        ))}

        {loading &&
          [...Array(10)].map((_, index) => (
            <Grid item container mt={1.5} key={index} alignItems="center">
              <TableCell xs={1.5}>
                <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: "80%" }} />
              </TableCell>
              <TableCell xs={3}>
                <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: "80%" }} />
              </TableCell>
              <TableCell xs={2}>
                <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: "80%" }} />
              </TableCell>
              <TableCell xs={4}>
                <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: "80%" }} />
              </TableCell>
              <TableCell xs={1.5}>
                <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4, height: 30, width: "80%" }} />
              </TableCell>
            </Grid>
          ))}
      </InfiniteScroll>
    </FlexCol>
  );
};
