import React from "react";

import { NAV_BAR_HEIGHT } from "../../constants";
import { FlexCol } from "../../components";
import { CarouselComponent } from "./carousel";
import { FlashbackContextProvider } from "./FlashbackContext";

export const FlashbackPage = () => {
  return (
    <FlashbackContextProvider>
      <FlexCol sx={{ py: 8, minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`, justifyContent: "center" }}>
        <CarouselComponent />
      </FlexCol>
    </FlashbackContextProvider>
  );
};
