import React from "react";
import { apiClient } from "../../config";
import { User } from "../../types";

export interface LiveCarouselData {
  user: User;
  title: string;
  description: string;
  streamEndpoint: string;
  thumbnail: string;
  liveCount: number;
}

export const useLiveCarousel = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<LiveCarouselData[]>([]);
  const loadData = React.useCallback(async () => {
    setLoading(true);
    try {
      const { data: res } = await apiClient.get("live/featured");
      setData(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return { loading, data };
};
