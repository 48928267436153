import { Fragment, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";

import { FlexCol, FlexRow, LiveCarousel } from "../../components";
import { CarouselCard } from "./CarouselCard";
import { BinanceLogoSvg } from "./BinanceLogoSvg";
import { TrendList } from "./trending-list";
import { homeCards, useHomePageContext } from "./HomePageContext";

export const CardsColumn = () => {
  const { homeAccordions } = useHomeAccordions();

  return (
    <FlexCol sx={{ rowGap: 2 }}>
      {homeAccordions.map((item) => (
        <Fragment key={item.id}>{item.content}</Fragment>
      ))}
    </FlexCol>
  );
};

const useHomeAccordions = () => {
  const { showLiveOnBoltInCardsColumn, updateContext: updateHomePageContext } = useHomePageContext();

  const homeAccordions = useMemo(() => {
    if (showLiveOnBoltInCardsColumn) {
      updateHomePageContext({ activeCard: homeCards.liveOnBolt });

      return [
        {
          id: homeCards.liveOnBolt,
          content: <LiveOnBoltAccordion />,
        },
        {
          id: homeCards.trends,
          content: <TrendsAccordion />,
        },
        // {
        //   id: homeCards.binancePay,
        //   content: <BinancePayAccordion />,
        // },
      ];
    }

    updateHomePageContext({ activeCard: homeCards.trends });

    return [
      {
        id: homeCards.trends,
        content: <TrendsAccordion />,
      },
      // {
      //   id: homeCards.binancePay,
      //   content: <BinancePayAccordion />,
      // },
    ];
  }, [showLiveOnBoltInCardsColumn]);

  return { homeAccordions };
};

const CustomAccordion = ({ sx, ...rest }: AccordionProps) => {
  return (
    <Accordion
      sx={{
        borderRadius: 3,
        overflow: "hidden",

        "&:before": {
          display: "none",
        },

        "&:first-of-type": {
          borderRadius: 3,
        },

        "&:last-of-type": {
          borderRadius: 3,
        },

        "&.Mui-expanded": {
          margin: 0,
        },
      }}
      {...rest}
    />
  );
};

const LiveOnBoltAccordion = () => {
  const theme = useTheme();

  const accordionValue = homeCards.liveOnBolt;

  const { activeCard, updateContext: updateHomePageContext } = useHomePageContext();

  return (
    <CustomAccordion
      expanded={activeCard === accordionValue}
      onChange={(_, isExpanded) => {
        updateHomePageContext({ activeCard: isExpanded ? accordionValue : false });
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", columnGap: 1 }}>
          <Box sx={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ width: 10, height: 10, bgcolor: theme.palette.error.main, borderRadius: "50%" }} />
          </Box>

          <Typography variant="h6" sx={{ fontFamily: "NFUltra-Regular", fontSize: { xs: 14 } }}>
            LIVE ON BOLT
          </Typography>
        </FlexRow>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <LiveCarousel />
      </AccordionDetails>
    </CustomAccordion>
  );
};

const TrendsAccordion = () => {
  const accordionValue = homeCards.trends;

  const { activeCard, updateContext: updateHomePageContext } = useHomePageContext();

  return (
    <CustomAccordion
      expanded={activeCard === accordionValue}
      onChange={(_, isExpanded) => {
        updateHomePageContext({ activeCard: isExpanded ? accordionValue : false });
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", columnGap: 1 }}>
          <Box sx={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <TrendingUpRoundedIcon />
          </Box>

          <Typography variant="h6" sx={{ fontFamily: "NFUltra-Regular", fontSize: { xs: 14 } }}>
            TRENDS
          </Typography>
        </FlexRow>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Box sx={{ px: 3, pb: 3 }}>
          <TrendList />
        </Box>
      </AccordionDetails>
    </CustomAccordion>
  );
};

const BinancePayAccordion = () => {
  const accordionValue = homeCards.binancePay;

  const { activeCard, updateContext: updateHomePageContext } = useHomePageContext();

  return (
    <CustomAccordion
      expanded={activeCard === accordionValue}
      onChange={(_, isExpanded) => {
        updateHomePageContext({ activeCard: isExpanded ? accordionValue : false });
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", columnGap: 1 }}>
          <Box sx={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <BinanceLogoSvg color="#f0b90b" width={20} height={20} />
          </Box>

          <Typography variant="h6" sx={{ fontFamily: "NFUltra-Regular", fontSize: { xs: 14 } }}>
            BINANCE PAY
          </Typography>
        </FlexRow>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <CarouselCard />
      </AccordionDetails>
    </CustomAccordion>
  );
};
