import { all, fork, put, take } from "redux-saga/effects";

import { apiClient } from "../../config";
import { BoltError, ToastService } from "../../services";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchRadioStations(): any {
  while (true) {
    yield take(actions.FETCH_RADIO_STATIONS + START);
    try {
      const { data } = yield apiClient.get(`/radiostations?page=1&limit=500`);

      // const radio = data.data.map((rad: any) => {
      //   // const sound = new Howl({
      //   //   src: rad.url,
      //   //   html5: true,
      //   //   format: ['dolby', 'webm', 'mp3'],
      //   //   preload: false
      //   // });
      //   const sound = new Clappr.Player({
      //     parent: "#player",
      //     source: rad.url,
      //   })

      //     return {
      //       ...rad,
      //       sound
      //     }
      // })

      yield put({
        type: actions.FETCH_RADIO_STATIONS + SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      yield put({
        type: actions.FETCH_RADIO_STATIONS + FAIL,
        payload: error.response.data,
      });
    }
  }
}

function* fetchTrendingRadioStations() {
  while (true) {
    yield take(actions.FETCH_TRENDING_RADIO_STATIONS + START);

    try {
      const { data } = yield apiClient.get(`/trendings/radioStations?limit=500`);
      yield put({ type: actions.FETCH_TRENDING_RADIO_STATIONS + SUCCESS, payload: data });
    } catch (error: any) {
      ToastService.showErrorMessage(BoltError(error).message);
      yield put({
        type: actions.FETCH_TRENDING_RADIO_STATIONS + FAIL,
        payload: { ...BoltError(error) },
      });
    }
  }
}

export default function* radioStationsSaga() {
  yield all([fork(fetchRadioStations), fork(fetchTrendingRadioStations)]);
}
