import { AutoGraph } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

export const Ai = () => {
  const theme = useTheme();

  return (
    <Box sx={{ p: 4, textAlign: "center" }}>
      <AutoGraph sx={{ fontSize: 32, color: theme.palette.text.secondary }} />

      <Typography color="text.secondary" variant="body2">
        Coming soon!
      </Typography>
    </Box>
  );
};
