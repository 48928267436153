import React from "react";
import { Container, List, ListItem, ListItemText, SxProps, Typography } from "@mui/material";

interface Props {
  title: string;
  sx?: SxProps;
}

export const SettingsPanelLayout = ({ children, title, sx }: React.PropsWithChildren<Props>) => {
  return (
    <Container sx={{ p: 4, ml: 0, ...sx }} maxWidth="md">
      <List>
        <ListItem>
          <ListItemText>
            <Typography variant="h6" fontWeight="600">
              {title}
            </Typography>
          </ListItemText>
        </ListItem>
        {children}
      </List>
    </Container>
  );
};
