import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ModalProps,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import { BaseModal } from "./BaseModal";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { LoadingViewInComponent } from "../LoadingViewInComponent";
import { userSelector } from "../../store/auth/selector";
import { syncUserAction } from "../../store/auth/actions";
import { socketSelector } from "../../store/socket/selector";
import { ReactComponent as CopyIcon } from "../../assets/svgs/copy.svg";
import { ReactComponent as ViewIcon } from "../../assets/svgs/view.svg";
import ZoomIcon from "../../assets/pngs/zoom.png";
import StreamYardIcon from "../../assets/pngs/streamYard.png";
import ObsIcon from "../../assets/pngs/obs.png";
import RestreamIcon from "../../assets/pngs/restream.png";
import IvsPlayer from "../LiveStreamPlayer/IvsPlayer";

interface Props extends Partial<ModalProps> {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  onBack: () => void;
  data?: any;
}

export const StreamPreviewModal = ({ open, onClose, onNext, onBack, ...props }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { socketConnected, notification } = useSelector(socketSelector);
  const [showStreamKey, setShowStreamKey] = useState(false);
  const [loadedVideo, setLoadedVideo] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);

  const streamUrl = `rtmps://${user?.streamProfile?.streamEndpoint}:443/app/` || "";
  const streamKey = user?.streamProfile?.streamKey || "";
  const streamLink = `${document.location.origin}/${user?.username}`;
  const playUrl = user?.streamProfile?.url || "";

  const zoomHelp =
    "https://support.zoom.us/hc/en-us/articles/115001777826-Livestreaming-meetings-or-webinars-on-a-custom-site";
  const obsHelp = "https://resources.antmedia.io/docs/open-broadcaster-software-publishing";
  const restreamHelp = "https://support.restream.io/en/articles/369436-connect-a-custom-rtmp-channel";
  const streamyardHelp = "https://streamyard.com/blog/how-to-live-stream/custom-rtmp-live-streaming/";

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text || "");
  };

  useEffect(() => {
    if (socketConnected && notification?.channelId === user?.id) {
      dispatch(syncUserAction());
    }
  }, [notification]);

  return (
    <BaseModal
      {...props}
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(7px)",
      }}
    >
      <Box
        component={Paper}
        className="-webkit-chatbox-scrollbar"
        sx={{
          py: 2,
          borderRadius: 6,
          position: "relative",
          width: 468,
          height: "90%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2} sx={{ overflow: "auto", flex: 1, px: 2, pt: 1, pb: 4 }}>
          <Grid container item alignItems="center" spacing={1} py={2}>
            <Grid item xs="auto">
              <IconButton onClick={onBack} size="small">
                <ArrowBackIosNewRoundedIcon sx={{ cursor: "pointer", opacity: 0.5 }} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  color: "#A3AED0",
                  fontWeight: 600,
                  fontSize: { xs: 18, md: 20 },
                  lineHeight: "30px",
                }}
              >
                Create stream
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                sx={{ textTransform: "none", height: 34, borderRadius: 29 / 2, px: 4.5 }}
                onClick={onNext}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: "18.55px" }}>
                  Next
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} px={2}>
              <Grid item xs={12}>
                <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 10, lineHeight: "15px", mt: 2.5 }}>
                  Stream Preview
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {user?.streamProfile?.isLive ? (
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "black",
                      marginTop: -1,
                      minHeight: { xs: 160, sm: 200 },
                    }}
                  >
                    <IvsPlayer
                      ref={playerRef}
                      style={{ transition: "all 0.8s ease" }}
                      height="100%"
                      width="100%"
                      url={playUrl}
                      isLowLatency
                      muted
                      playing
                      onPlay={() => setLoadedVideo(true)}
                    />
                    <LoadingViewInComponent visible={!loadedVideo} sx={{ backgroundColor: "#00000080" }} />
                  </Box>
                ) : (
                  <FlexCol
                    sx={{
                      backgroundColor: "#000",
                      minHeight: "215px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: -1,
                    }}
                  >
                    <CircularProgress />
                    <Typography sx={{ fontWeight: 300, fontSize: 10, lineHeight: "15px", mt: 3 }}>
                      Connect streaming software to go live
                    </Typography>
                  </FlexCol>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px", mt: 1 }}>
                  Server URL
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FlexRow sx={{ position: "relative" }}>
                  <TextField
                    value={streamUrl}
                    name="url"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "80px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                  <FlexRow sx={{ alignItems: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "rgba(60, 67, 111, 1)",
                        ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                        position: "absolute",
                        right: 4,
                        height: "23.57px",
                      }}
                      onClick={() => copyText(streamUrl)}
                    >
                      <CopyIcon />
                      <Typography sx={{ fontWeight: 400, fontSize: 7, lineHeight: "10.5px", ml: 1 }}>COPY</Typography>
                    </Button>
                  </FlexRow>
                </FlexRow>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px", mt: 1 }}>
                  Stream Key
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FlexRow sx={{ position: "relative" }}>
                  <TextField
                    value={streamKey}
                    type={showStreamKey ? "text" : "password"}
                    name="username"
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "170px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                  <FlexRow sx={{ alignItems: "center" }}>
                    <Typography
                      sx={{ cursor: "pointer", position: "absolute", right: "80px", mt: 0.5 }}
                      onClick={() => setShowStreamKey(!showStreamKey)}
                    >
                      <ViewIcon />
                    </Typography>
                    {/* <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(60, 67, 111, 1)",
                    ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                    position: "absolute",
                    right: 80,
                    height: "23.57px",
                  }}
                >
                  <Typography sx={{ fontWeight: 400, fontSize: 7, lineHeight: "10.5px" }}>RESET</Typography>
                </Button> */}
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "rgba(60, 67, 111, 1)",
                        ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                        position: "absolute",
                        right: 4,
                        height: "23.57px",
                      }}
                      onClick={() => copyText(streamKey)}
                    >
                      <CopyIcon />
                      <Typography sx={{ fontWeight: 400, fontSize: 7, lineHeight: "10.5px", ml: 1 }}>COPY</Typography>
                    </Button>
                  </FlexRow>
                </FlexRow>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: 1.5, mb: 0.5 }}
                >
                  Live Stream link
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FlexRow sx={{ position: "relative" }}>
                  <TextField
                    name="url"
                    type="url"
                    defaultValue={streamLink}
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "80px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                  <FlexRow sx={{ alignItems: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "rgba(60, 67, 111, 1)",
                        ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                        position: "absolute",
                        right: 4,
                        height: "23.57px",
                      }}
                      onClick={() => copyText(streamLink)}
                    >
                      <CopyIcon />
                      <Typography sx={{ fontWeight: 400, fontSize: 7, lineHeight: "10.5px", ml: 1 }}>COPY</Typography>
                    </Button>
                  </FlexRow>
                </FlexRow>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "#A3AED0",
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: "21px",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Learn how to stream through clients below
                </Typography>
                <FlexRow sx={{ maxWidth: "244px", mx: "auto", flexWrap: "wrap", justifyContent: "center", py: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(60, 67, 111, 1)",
                      ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                      height: "36px",
                      width: "107px",
                      borderRadius: "10.42px",
                    }}
                    onClick={() => window.open(zoomHelp)}
                  >
                    <img src={ZoomIcon} alt="" />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(60, 67, 111, 1)",
                      ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                      height: "36px",
                      width: "107px",
                      borderRadius: "10.42px",
                      ml: 1,
                    }}
                    onClick={() => window.open(streamyardHelp)}
                  >
                    <img src={StreamYardIcon} alt="" />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(60, 67, 111, 1)",
                      ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                      height: "36px",
                      width: "107px",
                      borderRadius: "10.42px",
                      mt: 2,
                    }}
                    onClick={() => window.open(obsHelp)}
                  >
                    <img src={ObsIcon} alt="" />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(60, 67, 111, 1)",
                      ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                      height: "36px",
                      width: "107px",
                      borderRadius: "10.42px",
                      mt: 2,
                      ml: 1,
                    }}
                    onClick={() => window.open(restreamHelp)}
                  >
                    <img src={RestreamIcon} alt="" />
                  </Button>
                </FlexRow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
};
