import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useForm } from "react-hook-form";

import { FlexCol } from "./FlexCol";
import { ResetPasswordDto } from "../dtos";
import { LoadingView } from "./LoadingView";
import { useQuery } from "../hooks/useQuery";
import { apiClient } from "../config";
import { BoltError, ToastService } from "../services";

const resolver = classValidatorResolver(ResetPasswordDto);

export const PasswordResetForm = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordDto>({ resolver, defaultValues: { password: "", confirmPassword: "" } });

  const onSubmit = async (data: ResetPasswordDto) => {
    setErrorMessage("");
    setLoading(true);
    try {
      await apiClient.post("/auth/resetPassword", { ...data, email: query.get("email"), token: query.get("token") });
      setErrorMessage("");
      reset();
      ToastService.showSuccessMessage("Your password has been changed successfully!");
      navigate("/login");
    } catch (error) {
      setErrorMessage(BoltError(error).message);
    }
    setLoading(false);
  };

  return (
    <FlexCol sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}>
      <Typography variant="h4">Reset Password</Typography>
      <Typography variant="caption" color="primary.light" sx={{ my: 3 }}>
        Enter a new password for <strong>{query.get("email")}</strong>
      </Typography>
      <Box component="form" sx={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          label="New Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors.password?.message}
          {...register("password")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Re-enter Password"
          type="password"
          id="confirmPassword"
          autoComplete="current-password"
          error={getValues().password !== getValues().confirmPassword || !!errors.confirmPassword}
          helperText={
            getValues().password && getValues().password !== getValues().confirmPassword
              ? "Password doesn't match"
              : errors.confirmPassword?.message
          }
          {...register("confirmPassword")}
        />
        {errorMessage && (
          <Alert variant="outlined" severity="error" sx={{ my: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <FlexCol sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, px: 8, textTransform: "none", borderRadius: 50 }}
          >
            <Typography variant="subtitle1" color="white" fontWeight="600">
              Reset Password
            </Typography>
          </Button>
        </FlexCol>
      </Box>
      <LoadingView visible={loading} />
    </FlexCol>
  );
};
