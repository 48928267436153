import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Container, Grid } from "@mui/material";

import { NAV_BAR_HEIGHT } from "../../constants";
import { ScrollToTopButton } from "../../components";
import { searchSelector } from "../../store/search/selector";
import { NotificationItem } from "./NotificationItems";
import { LiveNavigationSkeleton } from "../../components/skeletons";
import { fetchNotificationsAction, markAllReadNotificationAction } from "../../store/notification/actions";
import { notificationSelector } from "../../store/notification/selector";
import { MomentUtils } from "../../utils";

export const Notifications = () => {
  const dispatch = useDispatch();
  const { searchInput } = useSelector(searchSelector);
  const { items, loading, page, hasMore, unreadCount } = useSelector(notificationSelector);

  useEffect(() => {
    dispatch(fetchNotificationsAction());
    MomentUtils.setShortLocale();
    return () => {
      MomentUtils.setFullLocale();
    };
  }, []);

  return (
    <Container
      sx={{
        pt: { xs: 2, sm: 3 },
        pb: 4,
      }}
      disableGutters
      maxWidth="lg"
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          item
          container
          xs={12}
          md={6}
          sm={4}
          sx={{
            top: NAV_BAR_HEIGHT,
            marginBottom: { md: 4, xs: 16 },
            width: { md: "50%", sm: "80%", xs: "90%" },
          }}
        >
          <Grid item container xs={12} justifyContent="flex-end">
            <Button disabled={unreadCount < 1} onClick={() => dispatch(markAllReadNotificationAction())}>
              Mark All Read
            </Button>
          </Grid>
          <Grid item xs={12}>
            <InfiniteScroll
              pageStart={1}
              loadMore={() => !loading && dispatch(fetchNotificationsAction({ page: page + 1 }))}
              hasMore={hasMore}
              useWindow
            >
              {items.map((item, index) => (
                <NotificationItem item={item} key={index} />
              ))}
            </InfiniteScroll>
            {loading && [...Array(6)].map((item, index) => <LiveNavigationSkeleton key={index} />)}
          </Grid>
        </Grid>
      </Grid>
      <ScrollToTopButton />
    </Container>
  );
};
