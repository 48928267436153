import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Alert, Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import ConnectWalletWrapper from "../../hooks/ConnectWalletWrapper";
import { useQuery } from "../../hooks/useQuery";
import { authSelector } from "../../store/auth/selector";
import { fetchEmailSignUpAction, initAuthAction } from "../../store/auth/actions";
import { FlexCol, FlexRow, LoadingView } from "../../components";
import { SorryModal } from "../../components/modals/SorryModal";
import { enumFromStringValue } from "../../utils/formatter";
import { LoginButtons } from "./LoginButtons";
import { EmailLoginForm } from "./EmailLoginForm";
import { EmailSignupForm } from "./EmailSignupForm";
import { SIGNUP_STEP } from "./authComps";
import { CreateUserDto } from "../../dtos";
import { TOSForm } from "./TOSForm";
import { PolicyForm } from "./PolicyForm";
import { OnboardingCarousel } from "./OnboardingCarousel";
import { ReactComponent as BoltPlusAuthLogo } from "../../assets/svgs/boltPlusAuthLogo.svg";
import BackgroundImage from "../../assets/svgs/auth/auth_bg.svg";
import EuroBanner from "../../assets/pngs/carousel/EuroBanner.png";
import EuroLogo from "../../assets/pngs/carousel/EuroLogo.png";
import GenBLogo from "../../assets/pngs/carousel/GenBLogo.png";
import GenBanner from "../../assets/pngs/carousel/GenBanner.png";
import MotorBanner from "../../assets/pngs/carousel/MotorBanner.png";
import MotorLogo from "../../assets/pngs/carousel/MotorLogo.png";
import ShiftBanner from "../../assets/pngs/carousel/ShiftBanner.png";
import ShiftLogo from "../../assets/pngs/carousel/ShiftLogo.png";
import WedoBanner from "../../assets/pngs/carousel/WedoBanner.png";
import WedoLogo from "../../assets/pngs/carousel/WedoLogo.png";
import LoginPageBackground from "./LoginPageBackground";

export enum ActionType {
  loginAction = "loginAction",
  loginEmail = "loginEmail",
  signupEmail = "signupEmail",
}

interface Props {}

export const AppLogo = (props: any) => {
  return <BoltPlusAuthLogo width={200} height={(200 * 31) / 120} {...props} />;
};

export const LoginPage = (props: Props) => {
  const { isLoggedIn, error, loading, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  let category = ActionType.loginAction;
  if (query.get("category") && typeof query.get("category") === "string") {
    const temp = enumFromStringValue(ActionType, query.get("category")!);
    category = temp === undefined ? ActionType.loginAction : temp;
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [actionType, setActionType] = useState<ActionType>(category);
  const [showModal, setShowModal] = useState(false);
  const [signupStep, setStep] = useState(SIGNUP_STEP.form);
  const [submitData, setSubmitData] = useState<CreateUserDto>();
  const [localLoading, setLocalLoading] = useState(false);

  const isSignupAction = actionType === ActionType.signupEmail;

  useEffect(() => {
    if (isLoggedIn && !error) {
      if (query.get("redirect") && typeof query.get("redirect") === "string") {
        navigate(query.get("redirect")!);
      } else {
        navigate("/discover");
      }
    } else if (error && error.message) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("");
    }
  }, [error, isLoggedIn, navigate]);

  useEffect(() => {
    if (!user || !user?.id) {
      dispatch(initAuthAction());
    }
  }, []);

  useEffect(() => {
    if (signupStep !== SIGNUP_STEP.form) {
      setLocalLoading(true);
      setTimeout(() => {
        setLocalLoading(false);
      }, 500);
    }
  }, [signupStep]);

  useEffect(() => {
    setErrorMessage("");
  }, [actionType]);

  const handleAuthAction = () => {
    const newAction = actionType === ActionType.loginAction ? ActionType.signupEmail : ActionType.loginAction;
    setActionType(newAction);
  };

  const setEmailLogin = () => {
    setActionType(ActionType.loginEmail);
  };

  const handleBackAction = () => {
    setActionType(ActionType.loginAction);
    setStep(SIGNUP_STEP.form);
  };

  const promotions: any = [
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/bloombergtv.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/bloombergtv-logo.png",
      tags: ["BreakingNews", "Finance", "BusinessStories"],
    },
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/smurftv.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/smurftv-logo.png",
      tags: ["Smurfs", "ForTheKids", "FamilyTime"],
    },
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/realdisasterchannel.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/realdisasterchannel-logo.png",
      tags: ["NaturalDisaster", "CrisisResponse", "TrueCrime"],
    },
    {
      background: WedoBanner,
      logo: WedoLogo,
      tags: ["Action&Adventure", "FreeMovies", "Cinephiles"],
    },
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/introuble.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/introuble-logo.png",
      tags: ["Adrenaline", "Edgy", "ExtremeSports"],
    },
    {
      background: ShiftBanner,
      logo: ShiftLogo,
      tags: ["Fashion", "Exotic", "Swimsuits"],
    },
    {
      background: EuroBanner,
      logo: EuroLogo,
      tags: ["GlobalNews", "EuropeNow", "BreakingNews"],
    },
    {
      background: MotorBanner,
      logo: MotorLogo,
      tags: ["DragRace", "PetrolHead", "BurningRubber"],
    },
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/123go.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/123go-logo.png",
      tags: ["MythBusting", "BeautyTips", "LifeHacks"],
    },
    {
      background: "https://cdn.boltplus.tv/boltplus-global/assets/image/aztecainternational.jpg",
      logo: "https://cdn.boltplus.tv/boltplus-global/assets/image/aztecainternational-logo.png",
      tags: ["NuestraVision", "Telenovela", "RealityTV"],
    },
    {
      background: GenBanner,
      logo: GenBLogo,
      tags: ["BlackCulture", "Afrobeats", "BreakingBoundaries"],
    },
  ];

  const isSm = useMediaQuery("(max-width:768px)");
  const isXs = useMediaQuery("(max-width:400px)");
  return (
    <ConnectWalletWrapper isLogin>
      <LoadingView visible={loading} />
      <SorryModal
        open={showModal}
        title="&#128561; Oops! We're still working on this..."
        confirmTitle="Okay"
        description="Thanks for your interest! Not all of our features are ready yet. Please register your interest to use our brand-new version of boltplus.tv !"
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      />
      <Box
        sx={{
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          width: "100%",
        }}
      >
        <LoginPageBackground promotions={promotions} />
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          sx={{
            background: "rgb(20 24 52)",
            position: "absolute",
            zIndex: isSm ? 9 : 2,
            top: isSm ? 0 : 37,
            right: isSm ? 0 : 62,
            height: isSm ? "100vh" : "calc(100% - 74px)",
            borderRadius: isSm ? 0 : "12px",
            padding: isSm ? "10px 30px" : "30px 40px",
            width: isXs ? "100%" : "400px",
          }}
        >
          <LoadingView visible={localLoading} />
          {signupStep === SIGNUP_STEP.form && (
            <FlexCol
              sx={{
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  overflow: "auto",
                  // display: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <AppLogo />
                  <Typography sx={{ marginTop: "12px", marginBottom: "12px", fontSize: 14 }}>
                    {isSignupAction ? "Create a" : "Login with your"} BOLT+ account
                  </Typography>
                </div>

                {errorMessage && (
                  <Alert variant="outlined" severity="error" sx={{ mt: 2, mb: 1, width: 300 }}>
                    {errorMessage}
                  </Alert>
                )}
                {actionType === ActionType.loginAction && (
                  <LoginButtons setErrorMessage={setErrorMessage} setEmailLogin={setEmailLogin} />
                )}
                {actionType === ActionType.loginEmail && <EmailLoginForm setErrorMessage={setErrorMessage} />}
                {actionType === ActionType.signupEmail && (
                  <EmailSignupForm setErrorMessage={setErrorMessage} setStep={setStep} setSubmitData={setSubmitData} />
                )}
                {actionType !== ActionType.loginEmail && (
                  <FlexRow sx={{ alignItems: "center", marginTop: "12px" }}>
                    <Typography sx={{ fontSize: 12 }} color="primary.light" style={{ whiteSpace: "nowrap" }}>
                      {isSignupAction ? "Already have" : "Don't you have"} an account?
                    </Typography>
                    <Button
                      size="small"
                      onClick={handleAuthAction}
                      sx={{
                        marginLeft: 1,
                        paddingX: 1.5,
                        fontSize: 12,
                        fontWeight: 600,
                        borderRadius: 22,
                        color: "#2F80ED",
                        border: "1px solid #ffffff0d",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {isSignupAction ? "Login here" : "Sign up for BOLT+"}
                    </Button>
                  </FlexRow>
                )}
                {actionType === ActionType.loginEmail && (
                  <Button
                    size="small"
                    onClick={handleBackAction}
                    startIcon={<KeyboardArrowLeftIcon />}
                    sx={{
                      marginTop: 6,
                      paddingX: 2,
                      fontSize: 14,
                      fontWeight: 600,
                      color: "white",
                      borderRadius: 4,
                      borderWidth: 0,
                      background: "#ffffff1a",
                    }}
                  >
                    Go Back
                  </Button>
                )}
              </Box>
            </FlexCol>
          )}

          {signupStep === SIGNUP_STEP.tos && (
            <TOSForm onBack={() => setStep(SIGNUP_STEP.form)} onAgree={() => setStep(SIGNUP_STEP.policy)} />
          )}
          {signupStep === SIGNUP_STEP.policy && (
            <PolicyForm
              onBack={() => setStep(SIGNUP_STEP.tos)}
              onAgree={() => dispatch(fetchEmailSignUpAction(submitData))}
            />
          )}
        </Grid>
      </Box>
    </ConnectWalletWrapper>
  );
};
