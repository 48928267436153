import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Chip, Container, Paper, Skeleton, Typography } from "@mui/material";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { FlexCol, FlexRow, RadioFilter, RadioCard } from "../../../components";
import { fetchTrendingRadioStationsAction, setSelectedRadioStation } from "../../../store/radio/actions";
import { radioStationSelector } from "../../../store/radio/selector";
import { AxiosResponseFollowedRadioStations, FollowedRadio, FollowedRadioStation, RadioStation } from "../../../types";
import { AudioPlayerContext } from "../../../hooks/AudioPlayerWrapper";
import { apiClient } from "../../../config";
import { ConfirmationModal } from "../../../components/modals";
import { NAV_BAR_HEIGHT } from "../../../constants";

export const TrendingRadioTab = () => {
  const dispatch = useDispatch();
  const fetchTrendingRadioStations = useCallback(() => dispatch(fetchTrendingRadioStationsAction()), [dispatch]);
  const audioPlayerContext = useContext(AudioPlayerContext);
  const { data, loading, selected: selectedRadio } = useSelector(radioStationSelector);
  const [isPostingFollow, setPostingFollow] = useState(false);
  const [isLoadingFollowings, setLoadingFollowings] = useState(false);
  const [alreadyFollowing, setAlreadyFollowing] = useState(false);
  const [unfollowModalVisible, setUnfollowModalVisible] = useState(false);
  const [followedRadioStations, setFollowedRadioStations] = useState<FollowedRadio[]>([]);

  useEffect(() => {
    fetchTrendingRadioStations();
  }, [fetchTrendingRadioStations]);

  const handleSelectRadio = (radio: RadioStation | FollowedRadioStation) => {
    audioPlayerContext?.setRadio(radio);
    dispatch(setSelectedRadioStation(radio));
  };

  useEffect(() => {
    const checkChannelFollowed = async () => {
      try {
        setLoadingFollowings(true);
        const { data: followings } = (await apiClient.get(
          "/radioStations/follow"
        )) as AxiosResponseFollowedRadioStations;
        if (followings) {
          setFollowedRadioStations(followings);
        }
        setLoadingFollowings(false);
      } catch (error) {
        setLoadingFollowings(false);
      }
    };
    checkChannelFollowed();
  }, [alreadyFollowing]);

  useEffect(() => {
    if (selectedRadio?.id || selectedRadio?._id) {
      const foundChannel: FollowedRadio | undefined = followedRadioStations.find(
        (item) => item.radioStation._id === selectedRadio?.id || item.radioStation._id === selectedRadio?._id
      );
      if (foundChannel) {
        setAlreadyFollowing(true);
      } else {
        setAlreadyFollowing(false);
      }
    }
  }, [selectedRadio?.id, selectedRadio?._id]);

  const cancelUnfollowing = () => {
    setUnfollowModalVisible(false);
  };

  const postFollowChannel = async () => {
    try {
      if (selectedRadio?.id || selectedRadio?._id) {
        setPostingFollow(true);
        const { status } = await apiClient.post(
          `/radioStations/follow/${selectedRadio.id || selectedRadio._id}`,
          JSON.stringify({ follow: !alreadyFollowing })
        );
        if (status === 204) {
          setAlreadyFollowing((prev) => !prev);
        }
        cancelUnfollowing();
        setPostingFollow(false);
      }
    } catch (error) {
      cancelUnfollowing();
      setPostingFollow(false);
    }
  };

  const checkFollowingsList = () => {
    if (followedRadioStations.length) {
      return (
        <FlexRow sx={{ flexWrap: "wrap", justifyContent: "center" }}>
          {followedRadioStations
            ?.filter((item) => !!item.radioStation?._id)
            .map((item, index) => {
              return (
                <RadioCard
                  item={item.radioStation}
                  selected={
                    selectedRadio?.id === item?.radioStation?._id || selectedRadio?._id === item?.radioStation?._id
                  }
                  key={index}
                  onClick={() => handleSelectRadio(item?.radioStation)}
                />
              );
            })}
        </FlexRow>
      );
    }
    return (
      <Typography color="gray" sx={{ alignSelf: "center" }}>
        No channels
      </Typography>
    );
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        pt: { lg: NAV_BAR_HEIGHT / 8 + 3 },
        px: { xs: 2.5, md: 6 },
      }}
      disableGutters
      maxWidth={false}
    >
      <FlexCol sx={{ flex: 1, pb: { xs: 10, md: 0 } }}>
        <FlexCol
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "wrap",
            alignItems: { xs: "stretch", lg: "center" },
          }}
        >
          <FlexCol sx={{ minHeight: selectedRadio ? 80 : 0, flex: 1, mb: 2 }}>
            {!!selectedRadio && (
              <FlexRow
                component={Paper}
                elevation={0}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 2,
                  alignItems: "center",
                  p: 3,
                  justifyContent: "space-between",
                }}
              >
                <FlexRow>
                  <Avatar variant="square" src={selectedRadio.logo} />
                  <FlexCol sx={{ ml: 4 }}>
                    <Typography fontWeight="600" sx={{ fontSize: { xs: "13px", md: "16px" } }}>
                      {selectedRadio.title}
                    </Typography>
                    <Chip
                      label="NOW PLAYING"
                      size="small"
                      sx={{
                        borderRadius: 1,
                        fontSize: { xs: 6, md: 7 },
                        width: { xs: 60, md: 70 },
                        height: { xs: 14, md: 20 },
                        mt: 1,
                      }}
                    />
                  </FlexCol>
                </FlexRow>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    height: 29,
                    width: 110,
                    borderRadius: 29 / 2,
                    background: alreadyFollowing
                      ? " #1F2234;"
                      : "linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%)",
                  }}
                  onClick={() => {
                    if (alreadyFollowing) {
                      setUnfollowModalVisible(true);
                    } else {
                      postFollowChannel();
                    }
                  }}
                  disabled={isLoadingFollowings || isPostingFollow}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: "600", fontSize: { xs: "12px", md: "14px" } }}>
                    {alreadyFollowing ? "Followed" : "Follow"}
                  </Typography>
                  {!alreadyFollowing && <AddOutlinedIcon fontSize="small" />}
                </Button>
              </FlexRow>
            )}
          </FlexCol>
          <FlexCol sx={{ flex: 1, mb: 2, alignItems: { xs: "center", md: "flex-end" } }}>
            <RadioFilter />
          </FlexCol>
        </FlexCol>
        <FlexCol component={Paper} elevation={0} sx={{ pl: { xs: 3, md: 4 }, py: 2, flex: 1, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: "600", fontSize: { xs: "14px", md: "19px" } }}>
            Trending Radio channels
          </Typography>
          <FlexRow sx={{ flexWrap: "wrap" }}>
            {data.map((item, index) => (
              <RadioCard
                item={item}
                selected={selectedRadio?.id === item.id}
                key={index}
                onClick={() => handleSelectRadio(item)}
              />
            ))}
            {!data?.length &&
              !!loading &&
              Array(30)
                .fill(0)
                .map((_, index) => (
                  <FlexCol key={index} sx={{ mr: 2, mb: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{ opacity: 0.6, borderRadius: 4, width: { xs: 91, md: 121 }, height: { xs: 91, md: 121 } }}
                    />
                    <Typography variant="h5">
                      <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4 }} />
                    </Typography>
                  </FlexCol>
                ))}
          </FlexRow>
        </FlexCol>
      </FlexCol>
      <FlexCol
        component={Paper}
        sx={{
          ml: { xs: 1, md: 4 },
          width: 284,
          height: "fit-content",
          borderRadius: 2,
          p: 2,
          display: { xs: "none", lg: "flex" },
        }}
      >
        <FlexRow sx={{ alignItems: "center" }}>
          <FavoriteBorderRoundedIcon />
          <Typography variant="subtitle2" sx={{ ml: 1, fontWeight: "600" }}>
            Followed Radio channels
          </Typography>
        </FlexRow>
        <Typography variant="tiny" color="gray" sx={{ my: 1 }}>
          Follow your favourite Radio channels to access them quickly in this panel
        </Typography>
        {isLoadingFollowings
          ? Array(5)
              .fill(0)
              .map((_, index) => (
                <FlexCol key={index} sx={{ mb: 2, justifyContent: "center" }}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      opacity: 0.6,
                      borderRadius: 4,
                      width: { xs: 91, md: 121 },
                      height: { xs: 91, md: 121 },
                      alignSelf: "center",
                    }}
                  />
                  <Typography variant="h5">
                    <Skeleton variant="text" animation="wave" sx={{ opacity: 0.4 }} />
                  </Typography>
                </FlexCol>
              ))
          : checkFollowingsList()}
      </FlexCol>
      {unfollowModalVisible && (
        <ConfirmationModal
          modalVisible={unfollowModalVisible}
          title={`Unfollow ${selectedRadio?.title}?`}
          submitFn={postFollowChannel}
          cancelFn={cancelUnfollowing}
          submitLabel="Unfollow"
          cancelLabel="Discard"
        />
      )}
    </Container>
  );
};
