import React from "react";
import { Box, IconButton, ModalProps, Paper, SxProps, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { BaseModal } from "../BaseModal";
import { FlexCol } from "../../FlexCol";
import { FlexRow } from "../../FlexRow";
import { ReactComponent as DollarIcon } from "../../../assets/svgs/dollar.svg";
import { LoadingViewInComponent } from "../../LoadingViewInComponent";

interface Props extends Partial<ModalProps> {
  open: boolean;
  loading?: boolean;
  title?: string;
  showHeader?: boolean;
  showTitle?: boolean;
  subtitle?: string;
  disableClose?: boolean;
  containerStyle?: SxProps;
  onClose: () => void;
}

export const BaseWithdrawModal = ({
  open,
  loading = false,
  showHeader = true,
  showTitle = true,
  disableClose = false,
  title = "",
  subtitle = "",
  onClose,
  containerStyle,
  children,
  ...rest
}: Props) => {
  return (
    <BaseModal
      disableEscapeKeyDown
      {...rest}
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        onClose();
      }}
    >
      <Box
        component={Paper}
        elevation={0}
        sx={{
          p: 3,
          pt: 2,
          borderRadius: 4,
          position: "relative",
          flexDirection: "column",
          width: 300,
          ...containerStyle,
        }}
      >
        <FlexCol position="relative">
          {showHeader && (
            <FlexRow sx={{ justifyContent: "space-between", alignItems: "center" }}>
              {showTitle && (
                <FlexRow sx={{ alignItems: "center" }}>
                  <DollarIcon width={20} />
                  <Typography variant="caption" color="#A3AED0" fontWeight="600" sx={{ ml: 1 }}>
                    Withdraw Earnings
                  </Typography>
                </FlexRow>
              )}
              <IconButton sx={{ ml: "auto", mr: -2 }} onClick={onClose}>
                <CloseRoundedIcon sx={{ color: "#A3AED0" }} />
              </IconButton>
            </FlexRow>
          )}
          {children}
        </FlexCol>
        <LoadingViewInComponent visible={loading} sx={{ backgroundColor: "#00000080" }} />
      </Box>
    </BaseModal>
  );
};
