import { Fab, Fade, SxProps } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  sx?: SxProps;
}

export const ScrollToTopButton = ({ sx }: Props) => {
  const [hasOffset, setHasOffset] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 200) {
        setHasOffset(true);
      } else {
        setHasOffset(false);
      }
    });
    return () => window.removeEventListener("scroll", () => {});
  }, []);
  return (
    <Fade in={hasOffset}>
      <Fab
        color="primary"
        size="small"
        sx={{ position: "fixed", bottom: "2%", right: "2%", ...sx }}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Fade>
  );
};
