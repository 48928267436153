import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { FlexCol, LiveNavigationBar } from "../../components";
import { searchSelector } from "../../store/search/selector";
import { HomeRadioTab } from "./radioTabs/HomeRadioTab";
import { FollowingsRadioTab } from "./radioTabs/FollowingsRadioTab";
import { NAV_BAR_HEIGHT } from "../../constants";
import { TrendingRadioTab } from "./radioTabs/TrendingRadioTab";

export const LiveRadioPage = () => {
  const { category = "home" } = useParams();
  const { searchInput } = useSelector(searchSelector);

  return (
    <FlexCol sx={{ height: "100%" }}>
      <FlexCol sx={{ display: { md: "none" } }}>
        <LiveNavigationBar />
      </FlexCol>
      <FlexCol
        sx={{
          height: `calc(100% + ${NAV_BAR_HEIGHT}px)`,
          mt: { lg: -NAV_BAR_HEIGHT / 8 },
          overflow: "auto",
        }}
      >
        {category === "home" && <HomeRadioTab />}
        {category === "trending" && <TrendingRadioTab />}
        {category === "following" && <FollowingsRadioTab />}
      </FlexCol>
    </FlexCol>
  );
};
