import React from "react";
import { Button, ListItem, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";

import { SettingsPanelLayout } from "../../layouts";
import { userSelector } from "../../store/auth/selector";
import { SettingSwitch } from "./SettingSwitch";

export const SecurityPage = () => {
  const user = useSelector(userSelector);

  const items = React.useMemo(
    () => [
      {
        title: "Password",
        description: "Set a unique password to protect your account.",
        actionComponent: (
          <Button variant="outlined" size="small">
            Change Password
          </Button>
        ),
        status: false,
      },
      {
        title: "Two-step verification",
        description: "Require a security key or code in addition to your password.",
        actionComponent: <SettingSwitch checked={user?.otpEnabled} />,
        status: false,
      },
    ],
    []
  );

  return (
    <SettingsPanelLayout title="Security">
      {items.map((item, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={item.title}
            secondary={item.description}
            primaryTypographyProps={{
              color: "#A3AED0",
              fontSize: 16,
            }}
            secondaryTypographyProps={{
              color: "#ffffff80",
              fontWeight: 300,
              fontSize: 12,
              mt: 1,
            }}
          />
          {item.actionComponent}
        </ListItem>
      ))}
    </SettingsPanelLayout>
  );
};
