import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

const INPUT_COUNT = 6;

interface Props {
  length?: number;
  onChange?: (value: string) => void;
  error?: boolean;
}

export const MFACodeInput = ({ length = INPUT_COUNT, error = false, onChange = () => {} }: Props) => {
  const [values, setValues] = React.useState(["", "", "", "", "", ""]);
  const refs = React.useRef<Array<HTMLInputElement>>([]);

  React.useEffect(() => {
    if (refs.current && refs.current[0]) {
      refs.current[0].focus();
    }
  }, []);

  React.useEffect(() => {
    onChange(values.join(""));
  }, [values, length]);

  const handleChange = React.useCallback(
    (value: string, index: number) => {
      const newValues = [...values];
      newValues[index] = value?.at(-1) || "";
      setValues(newValues);
      if (!refs.current) {
        return;
      }
      if (refs.current[index + 1] && value) {
        refs.current[index + 1].focus();
      }
    },
    [values]
  );

  const handlePaste = React.useCallback((value: string) => {
    if (new RegExp(`^\\d{${length}}$`, "gm").test(value)) {
      setValues(Array.from(Array(length).keys()).map((_, index) => value[index]));
      if (refs.current && refs.current[length - 1]) {
        refs.current[length - 1].focus();
      }
    }
  }, []);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent, index: number) => {
      const { key, ctrlKey, metaKey } = e;
      if (!/^[0-9]*$/.test(e.key) && key !== "Backspace" && key !== "ArrowLeft" && key !== "ArrowRight") {
        if ((ctrlKey || metaKey) && key === "v") {
          return;
        }
        e.preventDefault();
      }
      if (key === "Backspace" && index !== 0 && !values[index]?.trim() && refs.current) {
        e.preventDefault();
        refs.current[index - 1].focus();
        const newValues = [...values];
        newValues[index - 1] = "";
        setValues(newValues);
        return;
      }

      if (key === "ArrowLeft" && index > 0) {
        e.preventDefault();
        refs.current[index - 1].focus();
        return;
      }

      if (key === "ArrowRight" && index < length) {
        e.preventDefault();
        refs.current[index + 1].focus();
      }
    },
    [values]
  );

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <Typography variant="caption" component="span" color="#A3AED0" fontWeight={600}>
          Verification code
        </Typography>
      </Grid>
      <Grid container item xs={12} columnSpacing={1}>
        {Array.from(Array(length).keys()).map((_, index) => (
          <Grid key={index} item xs={12 / length}>
            <TextField
              inputRef={(element) => {
                refs.current[index] = element;
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, index);
              }}
              error={error}
              variant="outlined"
              value={values[index]}
              inputMode="numeric"
              focused={!!values[index]}
              onChange={(e) => handleChange(e.target.value, index)}
              onPaste={(e) => {
                e.preventDefault();
                handlePaste(e.clipboardData.getData("Text"));
              }}
              sx={{
                width: "100%",
                aspectRatio: "1",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{ sx: { height: "100%", borderRadius: 2 } }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                pattern: "[0-9]*",
                style: {
                  fontSize: 20,
                  fontWeight: 600,
                  height: undefined,
                  aspectRatio: "1",
                  padding: 0,
                  textAlign: "center",
                },
                maxLength: 1,
                type: "number",
              }}
            />
          </Grid>
        ))}
        {error && (
          <Grid xs={12} item sx={{ mt: 1 }}>
            <Typography color="error" variant="caption">
              Incorrect verification code. Try again.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
