import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SkipPrevious } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useCurrentEpisode } from "./useCurrentEpisode";

export const PrevBtn = () => {
  const navigate = useNavigate();

  const { prevEpisode, categorySlug } = useCurrentEpisode();

  const onClick = useCallback(() => {
    navigate(`/p/bloomberg/${categorySlug}/${prevEpisode?.slug}`);
  }, [categorySlug, prevEpisode?.slug]);

  return (
    <Button
      variant="contained"
      startIcon={<SkipPrevious />}
      sx={{
        bgcolor: "rgba(255, 255, 255, 0.10)",
        borderRadius: 100,

        "&:hover": { bgcolor: "rgba(255, 255, 255, 0.20)" },
      }}
      onClick={onClick}
    >
      Prev
    </Button>
  );
};
