import React from "react";
import { BoxProps, Typography } from "@mui/material";

import { FlexRow } from "../../../../components";

type OverviewSectionInfoProps = {
  Icon: React.ElementType,
  label: string,
} & BoxProps;

export const OverviewSectionInfo = ({ Icon, label, sx }: OverviewSectionInfoProps) => {

  return (
    <FlexRow sx={{ alignItems: "center", bgcolor: "#ffffff1a", py: 0.3, px: 0.8, borderRadius: 1, ...sx }}>
      <Typography sx={{ fontSize: 12, color: "#A3AED0", textTransform: "uppercase", mr: 1 }}>{label}</Typography>
      <Icon style={{ height: 10, width: 10 }} />
    </FlexRow>
  )
}