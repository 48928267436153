import { handleActions } from "redux-actions";
import produce from "immer";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { NftCreator, NFTFeed, TwitterFeed, TwitterUser } from "../../types";

export interface NftCreatorState {
  nftCreator: {
    loading: boolean;
    success: boolean;
    error: any;
    data?: NftCreator;
    ids: Array<string>;
  };
  tweets: {
    [channelId: string]: {
      loading?: boolean;
      data?: Array<TwitterFeed>;
      includes?: { users?: Array<TwitterUser> };
      meta?: { next_token?: string; result_count?: 20 };
    };
  };
  communityFeeds: {
    [symbol: string]: {
      items: NFTFeed[];
      loading: boolean;
      hasMore: boolean;
      loadingMore: boolean;
      page: number;
      total: number;
      action: {
        loading: boolean;
      };
    };
  };
}

const initialState: NftCreatorState = {
  nftCreator: {
    loading: false,
    success: false,
    error: null,
    ids: [],
    data: undefined,
  },
  tweets: {},
  communityFeeds: {},
};

const reducer = handleActions<NftCreatorState, any>(
  {
    [actions.GET_NFT_CREATOR + START]: (state) =>
      produce(state, (draft) => {
        draft.nftCreator.loading = true;
        draft.nftCreator.success = false;
        draft.nftCreator.error = null;
        draft.nftCreator.data = undefined;
      }),
    [actions.GET_NFT_CREATOR + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nftCreator.loading = false;
        draft.nftCreator.success = true;
        draft.nftCreator.data = payload;
      }),
    [actions.GET_NFT_CREATOR + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.nftCreator.loading = false;
        draft.nftCreator.success = false;
        draft.nftCreator.error = payload;
      }),
    [actions.FOLLOW_NFT + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.nftCreator.data && state.nftCreator.data) {
          draft.nftCreator.data.followers = state.nftCreator.data.followers + (!payload.follow ? -1 : +1);
          draft.nftCreator.data.followed = payload.follow;
          draft.nftCreator.success = true;
        }
      }),
    [actions.FETCH_NFT_TWEETS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.channelId]: { ...state.tweets[payload.channelId], loading: true } };
      }),
    [actions.FETCH_NFT_TWEETS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = {
          ...state.tweets,
          [payload.channelId]: {
            loading: false,
            data: payload?.meta?.next_token
              ? [...(state.tweets[payload.channelId]?.data || []), ...(payload?.data || [])]
              : payload?.data,
            meta: payload?.meta,
            includes: payload?.includes,
          },
        };
      }),
    [actions.FETCH_NFT_TWEETS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tweets = { ...state.tweets, [payload.channelId]: { loading: false, data: [], meta: {} } };
      }),
    [actions.FETCH_NFT_COMMUNITY_FEEDS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        if (!payload.channelId) {
          return;
        }
        draft.communityFeeds = {
          ...state.communityFeeds,
          [payload.channelId]: { ...state.communityFeeds[payload.channelId], loading: true },
        };
      }),
    [actions.FETCH_NFT_COMMUNITY_FEEDS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (!payload.channelId) {
          return;
        }
        const currentLength =
          (state.communityFeeds[payload.channelId] &&
            state.communityFeeds[payload.channelId].items &&
            state.communityFeeds[payload.channelId].items.length) ||
          0;
        draft.communityFeeds = {
          ...state.communityFeeds,
          [payload.channelId]: {
            ...state.communityFeeds[payload.channelId],
            loading: false,
            items:
              payload.page === 1 ? payload.data : [...state.communityFeeds[payload.channelId].items, ...payload.data],
            page: payload.page,
            total: payload.total,
            hasMore: currentLength + payload.data.length < payload.total,
          },
        };
      }),
    [actions.FETCH_NFT_COMMUNITY_FEEDS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.communityFeeds = {
          ...state.communityFeeds,
          [payload.channelId]: {
            loading: false,
            hasMore: false,
          },
        };
      }),
  },
  initialState
);

export default reducer;
