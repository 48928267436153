import React from "react";
import { StreamCreateModal } from "./modals/StreamCreateModal";
import { StreamPreviewModal } from "./modals/StreamPreviewModal";

interface Props {
  open: boolean;
  onClose: () => void;
  onShow: () => void;
}

export const StreamClient = ({ open, onClose, onShow }: Props) => {
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  return (
    <>
      <StreamCreateModal
        open={open}
        onClose={onClose}
        onNext={() => {
          onClose();
          setShowPreviewModal(true);
        }}
      />
      <StreamPreviewModal
        open={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        onBack={() => {
          setShowPreviewModal(false);
          onShow();
        }}
        onNext={() => {
          setShowPreviewModal(false);
        }}
      />
    </>
  );
};
