export enum WithdrawModalType {
  FORM,
  CODE_SETUP,
  CODE_SETUP_SUCCESS,
  CODE_VERIFY,
  CODE_VERIFY_SUCCESS,
  BACKUP_CODE,
  WITHDRAW_CONFIRM,
  WITHDRAW_PROCESSING,
  WITHDRAW_SUCCESS,
  WITHDRAW_FAIL,
}

export enum WithdrawType {
  STRIPE = "stripe",
  BINANCE = "binance",
}

export enum BinanceDestination {
  PAY_ID = "PAY_ID",
  BINANCE_ID = "BINANCE_ID",
  EMAIL = "EMAIL",
}

export interface WithdrawContextProviderValues {
  secureWithdraw: boolean;
  withdrawToken: string;
  verifyCode: string;
  qrCode?: string;
  backupCode?: string[];
  type: WithdrawType;
  modal?: WithdrawModalType;
  withdrawAmount: number;
  withdrawCurrency: "USDT" | "BNB" | "BTC";
  convertedWithdrawAmount: number;
  balance: {
    [key in WithdrawType]: number;
  };
  binanceInfo: {
    destination: BinanceDestination;
    value: string;
    quoteId?: string;
  };
  errors: {
    invalidCode: boolean;
    message?: string;
  };
  loadings: {
    mfaSettingUp: boolean;
    mfaVerifying: boolean;
    processing: boolean;
    quoting: boolean;
  };
}
