import { IsString, IsOptional, IsEnum, ValidateIf, IsNotEmpty } from "class-validator";
import { REPORT_TYPE } from "../types";

export class ReportDto {
  @IsNotEmpty({ message: "Please provide the reason." })
  @IsString()
  public reason: string;

  @IsOptional()
  @IsEnum(REPORT_TYPE, { message: "Invalid entity type" })
  public entityType: string;

  @ValidateIf((e) => !!e.entityType)
  @IsString({ message: "Invalid resource." })
  public entityId: string;

  @ValidateIf((e) => e.reason === "other", { always: true })
  @IsNotEmpty({ message: "Please provide the reason." })
  @IsString()
  public content: string;
}
