import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ModalProps,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { BaseModal } from "./BaseModal";
import { CustomSelector } from "../CustomSelector";
import { ReactComponent as LanguageIcon } from "../../assets/svgs/language.svg";
import { ReactComponent as CameraPlusIcon } from "../../assets/svgs/cameraPlus.svg";
import { FlexRow } from "../FlexRow";
import { userSelector } from "../../store/auth/selector";
import { updateStreamProfileAction } from "../../store/profile/actions";
import { chooseLanguage, languages } from "../../constants";
import CustomSwitch from "../CustomSwitch";
import { SubCategorySelector } from "../SubCategorySelector";
import { LoadingView } from "../LoadingView";

interface Props extends Partial<ModalProps> {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
}

export const StreamCreateModal = ({ open, onClose, onNext, ...props }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [streamProfile, setStreamProfile] = useState(user?.streamProfile);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const hiddenPhotoInput: any = useRef(null);

  useEffect(() => {
    if (user) {
      setStreamProfile(user.streamProfile);
    }
  }, [user]);

  const onSubmit = () => {
    if (!streamProfile?.thumbnail) {
      setValidationError("Thumbnail is required");
      return;
    }
    if (!streamProfile?.title) {
      setValidationError("Stream title is required");
      return;
    }
    setValidationError("");
    setLoading(true);
    dispatch(
      updateStreamProfileAction({
        thumbnail: streamProfile.thumbnail,
        title: streamProfile.title,
        description: streamProfile.description,
        languages: streamProfile.languages,
        tags: streamProfile.tags,
        disableAutoRewindPost: streamProfile.disableAutoRewindPost,
        categories: streamProfile.categories || [],
        onFinish: () => {
          setLoading(false);
          onNext();
        },
      })
    );
  };

  const handleClose = (event: Event, reason: string) => {};

  // if (!open) {
  //   return null;
  // }

  return (
    <BaseModal
      {...props}
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(7px)",
      }}
    >
      <Box
        component={Paper}
        sx={{
          py: 2,
          borderRadius: 6,
          position: "relative",
          width: 468,
          height: "90%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2} sx={{ overflow: "auto", flex: 1, px: 2, pt: 1, pb: 4 }}>
          <Grid container item alignItems="center" spacing={1} py={2}>
            <Grid item xs="auto">
              <IconButton onClick={onClose} size="small">
                <CloseIcon sx={{ cursor: "pointer", opacity: 0.5 }} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  color: "#A3AED0",
                  fontWeight: 600,
                  fontSize: { xs: 18, md: 20 },
                  lineHeight: "30px",
                }}
              >
                Create stream
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                sx={{ textTransform: "none", height: 34, borderRadius: 29 / 2, px: 4.5 }}
                onClick={onSubmit}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: "18.55px" }}>
                  Next
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} px={2}>
              {validationError && (
                <Grid item xs={12}>
                  <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                    {validationError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                >
                  Thumbnail
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 300,
                    fontSize: 10,
                    lineHeight: "15px",
                    color: "rgba(163, 174, 208, 0.4)",
                    mt: 1.5,
                    mr: 6,
                  }}
                >
                  Upload your image here. Recommended size is <b>1280</b> x <b>720</b> px (16:9) and the file format
                  should be either <b>jpg</b> or <b>png</b>.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ position: "relative" }}>
                <Avatar
                  src={
                    typeof streamProfile?.thumbnail === "object"
                      ? URL.createObjectURL(streamProfile.thumbnail)
                      : streamProfile?.thumbnail
                  }
                  sx={{
                    backgroundColor: "#000",
                    minHeight: "215px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  Pixel Warrios
                </Avatar>
                <input
                  type="file"
                  ref={hiddenPhotoInput}
                  onChange={(e: any) => setStreamProfile({ ...streamProfile, thumbnail: e.target.files[0] })}
                  style={{ display: "none" }}
                  accept="image/jpg, image/png, image/jpeg"
                />
                <IconButton
                  onClick={() => hiddenPhotoInput?.current.click()}
                  sx={{
                    right: "20px",
                    top: "30px",
                    position: "absolute",
                    cursor: "pointer",
                    background: "rgba(183, 183, 183, 0.8)",
                    "&:hover": { background: "rgba(183, 183, 183, 0.8)" },
                  }}
                >
                  <CameraPlusIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                  Stream title
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e: any) => setStreamProfile({ ...streamProfile, title: e.target.value })}
                  value={streamProfile?.title}
                  name="streamTitle"
                  inputProps={{
                    style: {
                      backgroundColor: "#282C46",
                      border: "0.5px solid rgba(255, 255, 255, 0.15)",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "21px",
                      height: "20px",
                      padding: "7px",
                      paddingLeft: "13px",
                      paddingRight: "40px",
                      borderRadius: "6px",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                  Stream description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  onChange={(e: any) => setStreamProfile({ ...streamProfile, description: e.target.value })}
                  value={streamProfile?.description}
                  minRows={3}
                  style={{
                    width: "100%",
                    outline: "none",
                    fontFamily: "Poppins",
                    color: "white",
                    resize: "none",
                    backgroundColor: "#282C46",
                    border: "0.5px solid rgba(255, 255, 255, 0.15)",
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: "21px",
                    padding: "10px",
                    paddingLeft: "13px",
                    paddingRight: "40px",
                    borderRadius: "6px",
                    borderWidth: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FlexRow>
                  <LanguageIcon />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px", ml: 1 }}
                  >
                    Language
                  </Typography>
                </FlexRow>
              </Grid>
              <Grid item xs={12}>
                <CustomSelector
                  value={languages.find((item) => item.value === streamProfile?.languages?.[0]) || chooseLanguage}
                  options={languages}
                  onClick={(language) => setStreamProfile({ ...streamProfile, languages: [language?.value] })}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px", ml: 1 }}
                >
                  Category
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SubCategorySelector
                  mounted={open}
                  selectedItems={streamProfile.categories}
                  onChange={(values) => setStreamProfile({ ...streamProfile, categories: values })}
                />
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs>
                  <Typography sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}>
                    Post the Rewind
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: 10, lineHeight: "15px", color: "rgba(163, 174, 208, 0.4)" }}
                  >
                    Post the rewind to let your follows know
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <CustomSwitch
                    checked={!streamProfile?.disableAutoRewindPost}
                    onChange={(e) => {
                      setStreamProfile((prev: any) => ({
                        ...prev,
                        disableAutoRewindPost: !streamProfile?.disableAutoRewindPost,
                      }));
                    }}
                    sx={{
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#219653 !important",
                        opacity: 1,
                        border: 0,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LoadingView visible={loading} sx={{ position: "absolute" }} />
      </Box>
    </BaseModal>
  );
};
