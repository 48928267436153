import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Avatar, Box, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../store/auth/selector";

import { FlexRow } from "../../components";
import { DefaultAvatar } from "../../components/DefaultAvatar";
import { Notification, NotificationType } from "../../types";
import { markAsReadNotification } from "../../store/notification/actions";
import { reactionToRender } from "../../helpers";

interface Props {
  item?: Notification;
}

export const NotificationItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);

  const handleNotificationClick = React.useCallback(() => {
    // call mark as read api here
    if (!item?.read) {
      dispatch(markAsReadNotification({ id: item?.id, read: true }));
    }

    switch (item?.type) {
      case NotificationType.USER_FOLLOW:
      case NotificationType.STREAM_LIVE:
        navigate(`/${item?.sender?.username}`);
        break;
      case NotificationType.CHANNEL_LIVE:
      case NotificationType.STREAM_FEED_MENTION:
        navigate(`/feed/${item?.entity?.id}`);
        break;
      case NotificationType.STREAM_FEED_COMMENT:
      case NotificationType.STREAM_FEED_REACTION:
      case NotificationType.STREAM_FEED_COMMENT_MENTION:
      case NotificationType.STREAM_FEED_COMMENT_REACTION:
        navigate(`/feed/${item?.parentEntity?.id}`);
        break;
      default:
        break;
    }
  }, [item, navigate, dispatch]);

  const messageContent = React.useMemo(() => {
    const isReactionType =
      item?.type === NotificationType.STREAM_FEED_REACTION ||
      item?.type === NotificationType.STREAM_FEED_COMMENT_REACTION;
    return `${item?.formattedMessage!}${isReactionType ? ` ${reactionToRender(item?.entity?.type)}` : ""}` || "";
  }, [item]);

  return (
    <FlexRow
      sx={{
        alignItems: "center",
        my: 1,
        backgroundColor: "secondary.main",
        cursor: "pointer",
        padding: 2,
        borderRadius: 5,
        minHeight: 80,
        position: "relative",
      }}
      onClick={handleNotificationClick}
    >
      <FlexRow sx={{ flex: 1, alignItems: "center" }}>
        <Box sx={{ width: 40, height: 40, position: "relative" }}>
          <Avatar src={item?.sender?.photoUrl}>
            <DefaultAvatar />
          </Avatar>
        </Box>
        <Typography
          sx={{
            flex: 1,
            px: 2,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
            em: { opacity: 0.3 },
            fontSize: { sm: 14, xs: 12 },
          }}
          dangerouslySetInnerHTML={{
            __html: messageContent,
          }}
        />
      </FlexRow>
      <Typography
        variant="body2"
        sx={{
          ml: "auto",
          fontSize: 12,
          color: "text.secondary",
          textAlign: "right",
          wordBreak: "break-word",
        }}
      >
        {moment(item?.createdAt).fromNow(false)}
      </Typography>
      {!item?.read && (
        <Box
          sx={{
            position: "absolute",
            width: 8,
            height: 8,
            backgroundColor: "warning.main",
            borderRadius: 10,
            right: 14,
            top: 14,
          }}
        />
      )}
    </FlexRow>
  );
};
