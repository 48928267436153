import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Button, Chip, Typography } from "@mui/material";

import { FlexCol, FlexRow, LoadingView } from "../../components";
import { ReactComponent as EyeIcon } from "../../assets/svgs/studio/eye.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/studio/settings.svg";
import { ReactComponent as StopStreamIcon } from "../../assets/svgs/studio/stopStreaming.svg";
import { Channel } from "../../types";

type Props = {
  channel: Channel;
  isPlay?: boolean;
  muted?: boolean;
  onPlay?: any;
  setPlayerState?: any;
}

export const StudioStream = ({ channel, isPlay, muted, onPlay, setPlayerState }: Props) => {

  const playerRef = useRef<ReactPlayer>(null);
  const [loading, setLoading] = useState(true);
  const [enablePip, setEnablePip] = useState(true);

  return (
    <FlexCol sx={{ width: "100%", height: "100%" }}>
      <FlexRow sx={{ bgcolor: "#1E2034", pt: 0, px: 0, position: 'relative' }}>
        <ReactPlayer
          ref={playerRef}
          style={{ display: "flex", transition: 'all 800ms ease', maxHeight: "60vh", backgroundColor: "black" }}
          height="auto"
          width="100%"
          controls
          playing={isPlay}
          muted={muted}
          pip={enablePip}
          url={channel?.url}
          volume={0.5}
          onDisablePIP={() => setEnablePip(false)}
          onReady={() => setLoading(false)}
          onPlay={() => {
            if (onPlay) onPlay();
          }}
          onBuffer={() => setLoading(true)}
          onBufferEnd={() => setLoading(false)}
          onError={(e) => {
            // if (onError) onError(e);
            setLoading(false);
          }}
          onProgress={setPlayerState}
          onPause={setPlayerState}
          config={{
            file: {
              dashVersion: "4.4.1",
              hlsVersion: "1.3.4",
              hlsOptions: {
                debug: false,
                backBufferLength: 0,
                startPosition: 0,
                liveSyncDurationCount: 3,
                liveMaxLatencyDurationCount: 6,
              }
            },
          }}
        />
        <LoadingView visible={loading} sx={{ position: "absolute", zIndex: 1 }} />
      </FlexRow>
      <FlexCol sx={{ flexGrow: 1, alignItems: "center" }} height='100px'>
        <FlexRow className="-webkit-chatbox-scrollbar" sx={{ my: 1, px: 2, width: "100%", minHeight: 30, gap: 0.5, flexGrow: 1, overflowY: 'auto', justifyContent: "space-between" }}>
          <FlexCol sx={{ flex: 1, mr: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>Pixel Warrios' Stream</Typography>
            <Typography sx={{ color: "#ffffff80", fontSize: 11, mt: 0.5 }}>Pixel Warrios' Stream description description description description description</Typography>
            <Chip size="small" sx={{ borderRadius: 1.5, color: 'white', fontSize: 12, fontWeight: 600, mt: 1, px: 0.5, maxWidth: "max-content" }} label="276" icon={<EyeIcon />} />
          </FlexCol>
          <FlexCol sx={{ gap: 1 }}>
            <Button
              variant="contained"
              startIcon={<StopStreamIcon style={{ width: 18, height: 18, marginLeft: 6 }} />}
              sx={{ bgcolor: "#3C436F", borderRadius: 20, fontWeight: 600, fontSize: 14, py: 0.35, color: "#EB5757" }}
            >
              Stop streaming
            </Button>
            <Button
              variant="contained"
              startIcon={<SettingsIcon style={{ width: 18, height: 18 }} />}
              sx={{ bgcolor: "#3C436F", borderRadius: 20, fontWeight: 600, fontSize: 14, py: 0.35 }}
            >
              Settings
            </Button>
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};