import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";

import { FlexCol } from "./FlexCol";
import { OnboardingDto } from "../dtos";
import { LoadingView } from "./LoadingView";
import { authSelector } from "../store/auth/selector";
import { useQuery } from "../hooks/useQuery";
import { fetchLoginEmailAction, fetchOnboardingAction } from "../store/auth/actions";
import { TOSForm } from "./TOSForm";
import { PolicyForm } from "./PolicyForm";
import { apiClient } from "../config";
import { TrendingTag, USER_GENDER } from "../types";
import { FlexRow } from "./FlexRow";

const resolver = classValidatorResolver(OnboardingDto);

enum STEPS {
  tos,
  privacy,
  submit1,
  submit2,
}

export const OnboardingForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = React.useState(STEPS.tos);
  const [tags, setTags] = React.useState<Array<TrendingTag>>([]);
  const [selectedTags, setSelectedTags] = React.useState<Array<string>>([]);
  const dispatch = useDispatch();
  const [checkingUsername, setCheckingUsername] = React.useState(false);
  const { error, loading, user, isLoggedIn } = useSelector(authSelector);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDuplicatedUsername, setIsDuplicatedUsername] = React.useState(false);

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingDto>({
    resolver,
    defaultValues: { username: "", gender: USER_GENDER.MALE, subscribeEmail: true, allowUserTracking: false },
  });

  const onSubmit = (data: OnboardingDto) => {
    if (checkingUsername) {
      return;
    }
    setErrorMessage("");
    if (step === STEPS.submit1) {
      setStep(STEPS.submit2);
    } else {
      dispatch(fetchOnboardingAction({ ...data, interests: selectedTags }));
    }
  };

  const checkUserName = React.useMemo(
    () =>
      debounce(async (username: string) => {
        try {
          const { data } = await apiClient.post("/auth/userNameCheck", { username: username?.trim() });
          setIsDuplicatedUsername(!!data.exists);
        } catch (error) {
          setIsDuplicatedUsername(false);
        }
      }, 500),
    []
  );

  React.useEffect(() => {
    const username = watch("username");
    if (username?.length > 5) {
      checkUserName(username);
    }
  }, [watch("username")]);

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await apiClient.get("/trending-tags/");
        setTags(data);
      } catch (error) {
        setTags([]);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <FlexCol
      component={Paper}
      sx={{
        alignItems: "center",
        overflow: "hidden",
        margin: "auto",
        borderRadius: 4,
        width: "100%",
      }}
    >
      {step === STEPS.tos && <TOSForm hideBack onAgree={() => setStep(STEPS.privacy)} />}
      {step === STEPS.privacy && (
        <PolicyForm onBack={() => setStep(STEPS.tos)} onAgree={() => setStep(STEPS.submit1)} />
      )}
      {(step === STEPS.submit1 || step === STEPS.submit2) && (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ width: "100%" }}>
          <FlexRow
            sx={{
              backgroundColor: "secondary.dark",
              alignItems: "center",
              justifyContent: "space-between",
              px: 4,
              height: 80,
              width: "100%",
            }}
          >
            <FlexCol sx={{ flex: 1 }}>
              {step === STEPS.submit1 && (
                <>
                  <Typography variant="subtitle1" sx={{ flex: 1, fontWeight: 600 }}>
                    Welcome on Bolt+
                  </Typography>
                  <Typography variant="caption">Please complete your profile</Typography>
                </>
              )}
              {step === STEPS.submit2 && (
                <FlexRow sx={{ alignItems: "center" }}>
                  <IconButton sx={{ ml: -4 }} onClick={() => setStep(STEPS.submit1)}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Choose interests
                  </Typography>
                </FlexRow>
              )}
            </FlexCol>
            <FlexCol sx={{ alignItems: "center", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: "none", borderRadius: 20, px: 3, py: 0.5 }}
              >
                <Typography variant="body2">{step === STEPS.submit1 ? "Next" : "Finish"}</Typography>
              </Button>
            </FlexCol>
          </FlexRow>
          {step === STEPS.submit1 && (
            <FlexCol sx={{ p: 4, pt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                error={!!errors.username || isDuplicatedUsername}
                size="small"
                helperText={isDuplicatedUsername ? "Username already exists." : errors.username?.message}
                {...register("username")}
              />
              <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="genderLabel">Gender</InputLabel>
                <Select
                  label="Gender"
                  labelId="genderLabel"
                  id="gender"
                  defaultValue={getValues().gender}
                  {...register("gender")}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="non-binary">Non Binary</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                sx={{ mt: 2 }}
                componentsProps={{ typography: { variant: "tiny", sx: { mt: 2 } } }}
                label="I would prefer not to receive marketing messages from BOLT Global"
                control={
                  <Checkbox
                    id="subscribeEmail"
                    size="small"
                    defaultChecked={getValues().subscribeEmail}
                    {...register("subscribeEmail")}
                  />
                }
              />
              <FormControlLabel
                componentsProps={{ typography: { variant: "tiny", sx: { mt: 2 } } }}
                label="Share my registration data with BOLT Global’s content providers for marketing purposes"
                control={<Checkbox size="small" id="allowUserTracking" {...register("allowUserTracking")} />}
              />
            </FlexCol>
          )}
          {step === STEPS.submit2 && (
            <FlexRow sx={{ p: 4, flexWrap: "wrap", maxHeight: 300, overflow: "auto" }}>
              {tags.map(({ tag, id }, index) => (
                <Button
                  variant="contained"
                  color={selectedTags.includes(id) ? "primary" : "secondary"}
                  key={index}
                  onClick={() =>
                    setSelectedTags(
                      selectedTags.includes(id) ? selectedTags.filter((item) => item !== id) : [...selectedTags, id]
                    )
                  }
                  sx={{ mr: 1, mb: 2, py: 0.5, borderRadius: 10, px: 2 }}
                >
                  <Typography variant="caption">{tag?.name}</Typography>
                </Button>
              ))}
              {tags.length === 0 && (
                <Typography color="primary.light" sx={{ textAlign: "center", flex: 1 }}>
                  No available interests
                </Typography>
              )}
            </FlexRow>
          )}
          {errorMessage && (
            <Alert variant="outlined" severity="error" sx={{ my: 2 }}>
              {errorMessage}
            </Alert>
          )}
        </Box>
      )}
      <LoadingView visible={loading} />
    </FlexCol>
  );
};
