import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, MenuProps } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { CustomOption } from "../types";

type Props = {};

const defaultFilters: CustomOption[] = [
  { label: "New", value: "new" },
  { label: "Hot", value: "hot" },
  { label: "Explore", value: "explore" },
];

export const PostFilter = (props: Props) => {

  const [filter, setFilter] = useState(defaultFilters[0].value);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handler = () => {
      setIsOpen(false);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
  };

  const menuProps: Partial<MenuProps> = {
    variant: 'menu',
    disableScrollLock: true,
  };

  return (
    <FormControl
      sx={{
        fontSize: 12,
        ml: 1,
        minWidth: 100,
        borderRadius: 2,
        backgroundColor: '#282C46',
        "& fieldset": { borderWidth: 0 },
        '& .MuiOutlinedInput-root': {
          '& .MuiSelect-select': { py: 0.2, textAlign: 'center', fontSize: 14 },
          '&.Mui-focused fieldset': { borderWidth: 0 }
        }
      }}
      size='small' color='secondary'>
      <Select
        value={filter}
        open={isOpen}
        onChange={handleFilterChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        displayEmpty={false}
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={menuProps}
      >
        {defaultFilters.map(({ label, value }, index) => <MenuItem key={index} value={value}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  )
};
