import React, { useMemo } from "react";
import { Button, Typography } from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayArrowRounded";

import InfoIcon from "@mui/icons-material/InfoRounded";
import FileIcon from "@mui/icons-material/InsertDriveFileRounded";
import TwitterIcon from "@mui/icons-material/Twitter";

import { PROFILE_MENU_ITEM, PROFILE_MENU_TYPE } from "../../types";
import { FlexRow } from "../FlexRow";

interface Props {
  type?: PROFILE_MENU_TYPE;
  currentTab: PROFILE_MENU_ITEM;
  setCurrentTab: (tab: PROFILE_MENU_ITEM) => void;
  isShowTweets?: boolean;
}

type IconProp = { style?: React.CSSProperties };

export const ProfileMenuList = (props: Props) => {
  const { type, currentTab, setCurrentTab, isShowTweets = false } = props;

  const channelMenus = useMemo(
    () => [
      ...((isShowTweets && [
        {
          title: "Tweets",
          tab: PROFILE_MENU_ITEM.tweets,
          Icon: ({ style }: IconProp) => <TwitterIcon style={style} />,
        },
      ]) ||
        []),
      { title: "About", tab: PROFILE_MENU_ITEM.about, Icon: ({ style }: IconProp) => <InfoIcon style={style} /> },
    ],
    [isShowTweets]
  );

  const userMenus = [
    { title: "Posts", tab: PROFILE_MENU_ITEM.posts, Icon: ({ style }: IconProp) => <FileIcon style={style} /> },
    // { title: "Tweets", tab: PROFILE_MENU_ITEM.tweets, Icon: ({ style }: IconProp) => <TwitterIcon style={style} /> },
    { title: "Videos", tab: PROFILE_MENU_ITEM.videos, Icon: ({ style }: IconProp) => <PlayIcon style={style} /> },
    { title: "About", tab: PROFILE_MENU_ITEM.about, Icon: ({ style }: IconProp) => <InfoIcon style={style} /> },
  ];

  const menuButtons = type === PROFILE_MENU_TYPE.Channel ? channelMenus : userMenus;

  return (
    <FlexRow
      sx={{
        mt: 3,
        mx: { md: 0, sm: 2, xs: 2 },
        flexWrap: "nowrap",
        width: `calc(100% - 32px)`,
        overflowX: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {menuButtons.map(({ tab, title, Icon }) => (
        <Button
          key={tab}
          variant="text"
          sx={{
            mr: { xs: 1.2 },
            px: 1,
            minWidth: "max-content",
            borderRadius: 2,
            bgcolor: currentTab === tab ? "#FFFFFF1A" : "transparent",
            "&:hover": { bgcolor: "#FFFFFF1A" },
          }}
          onClick={() => setCurrentTab(tab)}
        >
          <Icon style={{ color: currentTab === tab ? "#6535E9" : "#FFFFFF4D", width: 20, height: 20 }} />
          <Typography
            variant="body1"
            sx={{
              ml: { xs: 0.6, md: 0.8 },
              color: currentTab === tab ? "white" : "secondary.light",
              fontWeight: { xs: 400, sm: 600 },
              fontSize: { xs: 12, sm: 15 },
            }}
          >
            {title}
          </Typography>
        </Button>
      ))}
    </FlexRow>
  );
};
