import React, { useRef, useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { FlexCol } from "../../../FlexCol";

interface Props {
  selected?: string;
  onSelectNewFile?: (file: File) => void;
  onRemoveNewFile?: () => void;
  onSelect?: (url: string) => void;
}

export const ThumbFileSelector = ({
  selected,
  onSelectNewFile = () => {},
  onRemoveNewFile = () => {},
  onSelect = () => {},
}: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState<File>();

  const onAddNewFile = (file: File) => {
    if (!file) {
      return;
    }
    setNewFile(file);
    onSelectNewFile(file);
  };

  return (
    <>
      {!newFile && (
        <FlexCol
          component={Paper}
          sx={{
            width: 100,
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "secondary.light",
            cursor: "pointer",
            aspectRatio: "16/9",
          }}
          onClick={() => fileInput.current?.click()}
        >
          <AddPhotoAlternateIcon />
          <input
            type="file"
            ref={fileInput}
            multiple={false}
            accept="image/*"
            onChange={(e) => onAddNewFile(e.target?.files?.[0]!)}
            style={{ display: "none" }}
          />
        </FlexCol>
      )}
      {!!newFile && (
        <FlexCol
          width={100}
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            borderColor: "primary.main",
            borderWidth: 2,
            cursor: "pointer",
            position: "relative",
            borderStyle: selected?.startsWith("blob:") ? "solid" : "none",
          }}
          onClick={() => onSelect(URL.createObjectURL(newFile))}
        >
          <img
            alt="thumbnail"
            src={URL.createObjectURL(newFile)}
            width="100%"
            style={{ aspectRatio: "16/9", objectFit: "cover" }}
          />
          <IconButton
            size="small"
            sx={{ position: "absolute", right: 0.5, bgcolor: "#00000020" }}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveNewFile();
              setNewFile(undefined);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexCol>
      )}
    </>
  );
};
