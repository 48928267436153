import { createSelector } from "reselect";
import { RootState } from "../../types/store";

const getStreamerState = ({ streamer }: RootState) => streamer;

export const streamerSelector = createSelector([getStreamerState], (streamer) => streamer);
export const streamerTweetsSelector = (twitterId?: string) =>
  createSelector([getStreamerState], ({ tweets }) => tweets[twitterId || ""] || {});
export const streamerRecordsSelector = createSelector([getStreamerState], ({ records }) => records || {});
export const streamerLiveSelector = createSelector([getStreamerState], ({ liveStreamers }) => liveStreamers);
export const streamDataSelector = createSelector([getStreamerState], ({ streamData }) => streamData);
