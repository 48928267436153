import React, { useCallback, useState } from "react";
import { Avatar, Box, IconButton, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LinkIcon } from "../../../../assets/svgs/users/link.svg";
import { ReactComponent as VerifiedIcon } from "../../../../assets/svgs/verified.svg";
import { AnalyticsFeedVideo, FeedContentTextBody, FlexCol, FlexRow, LinkPreview } from "../../../../components";
import { DefaultAvatar } from "../../../../components/DefaultAvatar";
import { ReadMore } from "../../../../components/ReadMore";
import { StreamFeed } from "../../../../types";
import { Formatter, MomentUtils } from "../../../../utils";
import ZoomedViewModal from "../../../feed/ZoomedViewModal";
import TimeFromNow from "../../../../components/TimeFromNow";

type Props = {
  item: StreamFeed;
  sx?: SxProps;
  rebolt?: boolean;
};

export const ContentFeedItem = ({ sx, item, rebolt = false }: Props) => {
  const navigate = useNavigate();
  const [zoomedViewOpen, setZoomedViewOpen] = useState(false);
  const [contentIndex, setContentIndex] = useState<number>(0);

  const handleMediaClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>, content: number) => {
      e.stopPropagation();
      setZoomedViewOpen(true);
      setContentIndex(content);
    },
    []
  );

  const mediaCount = item.reboltFeed?.items.length || item.items?.length;
  let includedUrls = item?.text ? Formatter.parseUrls(item?.text) : [];
  const reboltFeed = item?.reboltFeed;

  if (rebolt && reboltFeed?.text) {
    includedUrls = Formatter.parseUrls(reboltFeed.text);
  }

  const shouldRenderUrlMeta = includedUrls.length > 0 && ((rebolt && !reboltFeed?.items.length) || !item?.items.length);

  const mediaItem = useCallback(
    (index: number) => {
      if (item) {
        let selectedMediaArray = item.items;
        if (item.reboltFeed?.items) {
          selectedMediaArray = item.reboltFeed.items;
        }
        if (selectedMediaArray.length && index < selectedMediaArray.length) {
          if (selectedMediaArray[index].type === "image") {
            return (
              <img
                src={selectedMediaArray[index].url}
                alt="post"
                style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "cover" }}
              />
            );
          }
          return (
            <AnalyticsFeedVideo url={selectedMediaArray[index].url} onClick={(e: any) => handleMediaClick(e, index)} />
          );
        }
      }
      return null;
    },
    [item]
  );

  return (
    <FlexCol sx={{ ...sx }}>
      <FlexRow
        sx={{
          alignItems: "center",
          pr: 0.5,
          mb: (rebolt && item?.reboltFeed?.text) || item?.text ? undefined : 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textDecoration: "none" }}>
          <Avatar
            src={item?.creator?.photoUrl}
            alt={item?.creator?.firstName}
            sx={{
              borderColor: "primary.main",
              borderWidth: 2,
              borderStyle: "solid",
              height: 34,
              width: 34,
            }}
            imgProps={{ referrerPolicy: "no-referrer" }}
          >
            <DefaultAvatar />
          </Avatar>
          <Typography variant="subtitle1" color="primary.contrastText" sx={{ ml: 1, fontWeight: 600 }}>
            {item?.creator?.username}
          </Typography>
          <VerifiedIcon style={{ marginLeft: 5 }} />
        </Box>
        <TimeFromNow sx={{ ml: "auto" }}>{MomentUtils.getFormattedDate(item.createdAt)}</TimeFromNow>
        <IconButton aria-label="open" size="medium" onClick={() => navigate(`/feed/${item.id}`)} sx={{ ml: 0.5 }}>
          <LinkIcon />
        </IconButton>
      </FlexRow>

      {(!!item?.reboltFeed?.text?.trim() || !!item?.text?.trim()) && (
        <ReadMore
          sx={{ my: 1 }}
          maxLine={3}
          active={
            (item?.reboltFeed?.text?.length && item?.reboltFeed?.text?.length > 250) ||
            (!!item?.text?.length && item?.text?.length > 250)
          }
        >
          <FeedContentTextBody
            draftContent={item.reboltFeed?.draftContent || item.draftContent}
            text={item.reboltFeed?.text || item.text}
          />
        </ReadMore>
      )}

      {((rebolt && !!item?.reboltFeed?.items?.length) || !!item?.items?.length) && (
        <FlexRow
          sx={{
            gap: "3px",
            width: "100%",
            height: "auto",
            bgcolor: "background.paper",
            flexWrap: "wrap",
          }}
        >
          {[...Array(mediaCount)].map((_, index) => (
            <Box key={index} sx={{ height: 80, width: 140 }} onClick={(e) => handleMediaClick(e, index)}>
              {mediaItem(index)}
            </Box>
          ))}
        </FlexRow>
      )}

      {shouldRenderUrlMeta && (
        <FlexCol sx={{ overflow: "hidden", width: "100%", bgcolor: "background.paper" }}>
          <LinkPreview url={includedUrls[0]} />
        </FlexCol>
      )}
      {zoomedViewOpen && (
        <ZoomedViewModal
          item={item}
          home
          contentIndex={contentIndex}
          setContentIndex={setContentIndex}
          onClose={() => setZoomedViewOpen(false)}
        />
      )}
    </FlexCol>
  );
};
