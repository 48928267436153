import { useMemo, useState } from "react";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";

import { streamFeedSelector } from "../../store/feed/selector";
import { fetchHomeStreamFeed } from "../../store/feed/actions";
import { authSelector } from "../../store/auth/selector";
import { useSocketContext } from "../../hooks";

export const RefreshToSeeNewPosts = () => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { user } = useSelector(authSelector);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { items, loading } = useSelector(streamFeedSelector);

  const {
    values: { latestFeed },
  } = useSocketContext();

  const hasNewPosts = useMemo(() => {
    if (!latestFeed) {
      return false;
    }

    if (latestFeed.creator === user?.id) {
      return false;
    }

    if (latestFeed.feedId === items[0]?.id) {
      return false;
    }

    return true;
  }, [latestFeed, user?.id, items]);

  const onRefresh = () => {
    setShouldRefresh(true);

    dispatch(fetchHomeStreamFeed());

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const isRefreshing = useMemo(() => {
    return shouldRefresh && loading;
  }, [shouldRefresh, loading]);

  if (!hasNewPosts) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "sticky",
        top: 80,
        zIndex: theme.zIndex.appBar - 1,
        minHeight: 40,
      }}
    >
      <Button
        variant="contained"
        onClick={onRefresh}
        endIcon={
          isRefreshing ? (
            <Box sx={{ width: 20, height: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress size={15} sx={{ color: theme.palette.common.white }} />
            </Box>
          ) : (
            <RefreshIcon />
          )
        }
      >
        Refresh to see new posts
      </Button>
    </Box>
  );
};
