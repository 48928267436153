import { Box, Typography, useTheme } from "@mui/material";

import { LiveItem } from "./LiveItem";
import { useLiveItems } from "./useLiveItems";

export const LiveNow = () => {
  const theme = useTheme();

  const { liveItems } = useLiveItems();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 3, width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 1.5 }}>
        <Typography sx={{ fontFamily: "NFUltra-Regular", textTransform: "uppercase" }}>Live Now</Typography>

        <Box sx={{ width: 10, height: 10, bgcolor: theme.palette.error.main, borderRadius: "50%" }} />
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", gap: 2 }}>
        {(liveItems || []).map((item, index) => (
          <LiveItem key={`live-item-${index}`} {...item} />
        ))}
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          startIcon={<KeyboardArrowDownIcon />}
          sx={{ color: "rgba(255, 255, 255, 0.50)", px: 2, borderRadius: "10px" }}
        >
          Show more
        </Button>
      </Box> */}
    </Box>
  );
};
