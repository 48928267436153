import { Avatar, Box, Button, Collapse, Divider, Icon, IconButton, Paper, Typography, Zoom } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { FeedContentTextBody, FeedGiphyContent, FlexCol, FlexRow, MiniLinkPreview } from "../../components";
import { DefaultAvatar } from "../../components/DefaultAvatar";
import { ReadMore } from "../../components/ReadMore";
import { Formatter } from "../../utils";
import { ReactComponent as Heart } from "../../assets/svgs/heart2.svg";
import {
  addStreamCommentReaction,
  addStreamReplyReaction,
  feedCommentLike,
  removeStreamCommentReaction,
  removeStreamReplyReaction,
} from "../../store/feedDetails/actions";
import {
  addStreamListFeedCommentReaction,
  addStreamListFeedReplyReaction,
  removeStreamListFeedCommentReaction,
  removeStreamListFeedReplyReaction,
} from "../../store/feed/actions";
import { userSelector } from "../../store/auth/selector";
import ReactionButtons from "../../components/ReactionButtons";
import ReactionPopover from "../../components/ReactionPopover";
import CustomIconButton from "../../components/CustomIconButton";
import { capitalizeFirstCharacter, reactionToRender } from "../../helpers";
import { CommentType, ReactionType, ReplyType } from "../../types";

type Props = {
  feedId: string;
  comment?: CommentType;
  reply?: ReplyType;
  parentCommentId?: string;
  home?: boolean;
  rebolt?: boolean;
};

const CommentContent = ({ feedId, comment, reply, parentCommentId, home, rebolt }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleReaction = useCallback(
    (reaction: ReactionType["type"]) => {
      if (home) {
        if (comment) {
          dispatch(
            addStreamListFeedCommentReaction({ streamFeedId: feedId, streamFeedCommentId: comment?.id, reaction })
          );
        } else {
          dispatch(
            addStreamListFeedReplyReaction({
              streamFeedId: feedId,
              streamFeedCommentId: parentCommentId,
              replyId: reply?.id,
              reaction,
            })
          );
        }
      } else if (comment) {
        dispatch(
          addStreamCommentReaction({
            streamFeedCommentId: comment?.id,
            reaction,
            rebolt,
          })
        );
      } else {
        dispatch(addStreamReplyReaction({ streamFeedCommentId: parentCommentId, replyId: reply?.id, reaction }));
      }
    },
    [dispatch, comment, reply, parentCommentId, feedId, home, user, rebolt]
  );

  const handleRemoveReaction = useCallback(
    (reaction: ReactionType["type"]) => {
      if (home) {
        if (comment) {
          dispatch(
            removeStreamListFeedCommentReaction({ streamFeedId: feedId, streamFeedCommentId: comment?.id, reaction })
          );
        } else {
          dispatch(
            removeStreamListFeedReplyReaction({
              streamFeedId: feedId,
              streamFeedCommentId: parentCommentId,
              replyId: reply?.id,
              reaction,
            })
          );
        }
      } else if (comment) {
        dispatch(
          removeStreamCommentReaction({
            streamFeedCommentId: comment?.id,
            reaction,
            rebolt,
          })
        );
      } else {
        dispatch(removeStreamReplyReaction({ streamFeedCommentId: parentCommentId, replyId: reply?.id, reaction }));
      }
    },
    [dispatch, comment, reply, parentCommentId, feedId, home, user, rebolt]
  );

  const handleShowReaction = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const reactedTo =
    comment?.reactions.find((reactionItem) => reactionItem.reacted) ||
    reply?.reactions.find((reactionItem) => reactionItem.reacted);

  const includedUrls = React.useMemo(() => {
    return comment?.text ? Formatter.parseUrls(comment?.text) : [];
  }, [comment?.text]);

  return (
    <Box
      component={Paper}
      elevation={0}
      sx={{
        zIndex: !comment || reply ? 5 : 10,
        borderRadius: reply ? 0 : 2.5,
        pl: 2.25,
        pr: 3.5,
        paddingBottom: 1.625,
        backgroundColor: "#1C2039",
        boxShadow: reply ? undefined : "0px 0px 20px rgba(0, 0, 0, 0.35)",
      }}
    >
      <FlexRow sx={{ gap: 1.25, alignItems: "stretch" }}>
        <FlexCol sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <FlexCol sx={{ alignItems: "center", justifyContent: "flex-start" }}>
            {reply && (
              <Box sx={{ width: 0, height: "7px", mb: "8px", border: "1px solid rgba(255, 255, 255, 0.15)" }} />
            )}
            <Box
              component={Link}
              to={`/${comment?.creator?.username || reply?.creator?.username}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: "none",
                pt: reply ? 0.15 : 1.875,
              }}
            >
              <Avatar
                src={comment?.creator?.photoUrl || reply?.creator?.photoUrl}
                alt={comment?.creator?.firstName || reply?.creator?.firstName}
                sx={{
                  borderColor: "primary.main",
                  borderWidth: 1,
                  borderStyle: "solid",
                  height: 30,
                  width: 30,
                }}
              >
                <DefaultAvatar />
              </Avatar>
            </Box>
          </FlexCol>
        </FlexCol>
        <FlexCol sx={{ flexGrow: 1 }}>
          <FlexRow sx={{ alignItems: "center", paddingTop: reply ? 1.75 : 1.875 }}>
            <Box
              component={Link}
              to={`/${comment?.creator?.username || reply?.creator?.username}`}
              sx={{ textDecoration: "none" }}
            >
              <Typography
                variant="subtitle1"
                color="primary.contrastText"
                sx={{ fontWeight: 600, fontSize: 12, color: "white" }}
              >
                {comment?.creator?.username || reply?.creator?.username}
              </Typography>
            </Box>
            {/* <VerifiedIcon style={{ marginLeft: theme.spacing(1) }} /> */}
            <Typography
              variant="tiny"
              sx={{ ml: 1.25, fontWeight: 300, fontSize: 10, color: "rgba(255, 255, 255, 0.5)" }}
            >
              {moment(comment?.createdAt || reply?.createdAt).fromNow()}
            </Typography>
          </FlexRow>
          <FlexRow className="comment-box">
            {(comment?.text?.trim() || reply?.text?.trim()) && (
              <ReadMore
                maxLine={5}
                active={(comment?.text?.length || reply?.text?.length || 0) > 250}
                backgroundColor={reply ? undefined : "secondary.dark"}
              >
                <FeedContentTextBody
                  draftContent={comment?.draftContent}
                  text={comment?.text || reply?.text || ""}
                  fontSize={12}
                />
              </ReadMore>
            )}
          </FlexRow>
          {!!comment?.giphy && <FeedGiphyContent data={comment?.giphy!} sx={{ mt: 2 }} />}
          {includedUrls?.length > 0 && <MiniLinkPreview url={includedUrls[0]} />}
          <FlexRow
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1.5,
              pt: 0.625,
            }}
          >
            {reactedTo !== undefined && (
              <CustomIconButton
                sx={{ marginLeft: -1 }}
                small
                label={capitalizeFirstCharacter(reactedTo.type)}
                textVibrant
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveReaction(reactedTo.type);
                }}
              >
                <span style={{ fontSize: 14, lineHeight: "100%" }}>{reactionToRender(reactedTo.type)}</span>
              </CustomIconButton>
            )}
            {reactedTo === undefined && (
              <CustomIconButton sx={{ marginLeft: -1 }} label="React" onClick={handleShowReaction} small>
                <Heart style={{ width: 12 }} />
              </CustomIconButton>
            )}
            <ReactionPopover {...{ anchorEl, setAnchorEl, handleReaction }} />
            <ReactionButtons
              {...{
                small: true,
                reactions: comment?.reactions || reply?.reactions,
                reactionCount: comment?.reactionCount,
                commentId: comment?.id || reply?.id,
              }}
            />
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </Box>
  );
};

export default CommentContent;
