import { Box } from "@mui/material";

import { HomeNavItem } from "./HomeNavItem";
import { DiscoverNavItem } from "./DiscoverNavItem";
import { ListenNavItem } from "./ListenNavItem";
import { ProfileNavItem } from "./ProfileNavItem";
import { AddBtn } from "./AddBtn";

export const BottomNavigation = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(15, 18, 43, 0.6)",
        borderTop: "1px solid rgba(255, 255, 255, 0.05)",
        boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(40px)",
        zIndex: 100,
        display: { md: "none" },
        height: { xs: 65 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <HomeNavItem />

        <DiscoverNavItem />

        <AddBtn />

        <ListenNavItem />

        <ProfileNavItem />
      </Box>
    </Box>
  );
};
