import { useState } from "react";
import { Button } from "@mui/material";

import { QuickPostCreateModal } from "../modals";
import { BottomNavItem } from "./BottomNavItem";
import { PlusSvg } from "./PlusSvg";

export const AddBtn = () => {
  const [showPostModal, setShowPostModal] = useState(false);

  return (
    <>
      <BottomNavItem>
        <Button
          variant="contained"
          sx={{
            borderRadius: 100,
            minWidth: 56,
            height: 40,
            p: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setShowPostModal(true)}
        >
          <PlusSvg style={{ fontSize: 24 }} />
        </Button>
      </BottomNavItem>

      {showPostModal && <QuickPostCreateModal open={showPostModal} onClose={() => setShowPostModal(false)} />}
    </>
  );
};
