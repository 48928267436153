import React, { useEffect } from "react";
import { Fab, Menu, MenuItem, SxProps, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FlexRow } from "./FlexRow";
import { FlexCol } from "./FlexCol";

export interface Option {
  icon?: any;
  value: string;
  label: string;
  nativeName?: string;
}

type Props = {
  value?: Option;
  options: Option[];
  sx?: SxProps;
  onClick: (value: Option) => void;
};

export const CustomSelector = ({ value, sx, options, onClick }: Props) => {
  const theme = useTheme();
  const [selected, setSelected] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (item: Option) => {
    onClick(item);
    setSelected(item);
    setAnchorEl(undefined);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  useEffect(() => {
    if (!selected && options.length > 0) {
      setSelected(options[0]);
    }
  }, [options.length]);

  return (
    <FlexCol sx={{ position: "relative" }}>
      <Fab
        sx={{
          textTransform: "none",
          boxShadow: "none",
          height: 50,
          width: "100%",
          pl: 3,
          bgcolor: theme.palette.mode === "dark" ? "#282C46" : "background.paper",
          border: "1px solid #282C46",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "15px",
          ...sx,
        }}
        color="secondary"
        variant="extended"
        onClick={handleClick}
      >
        <FlexRow sx={{ alignItems: "center" }}>
          {selected?.icon && <FlexCol sx={{ mr: "10px" }}>{selected?.icon}</FlexCol>}
          <Typography variant="caption" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
            {selected?.label}
          </Typography>
        </FlexRow>
        <KeyboardArrowDownIcon sx={{ jcolor: theme.palette.mode === "dark" ? "white" : "black" }} />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        classes={{ paper: "-webkit-chatbox-scrollbar" }}
      >
        {options.map(({ label, value, icon }) => (
          <MenuItem
            selected={selected?.value === value}
            key={value}
            onClick={() => handleMenuItemClick({ label, value, icon })}
          >
            <FlexRow sx={{ alignItems: "center" }}>
              {icon && <FlexCol sx={{ mr: "10px" }}>{icon}</FlexCol>}
              <Typography variant="caption" sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                {label}
              </Typography>
            </FlexRow>
          </MenuItem>
        ))}
      </Menu>
    </FlexCol>
  );
};
