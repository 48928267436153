import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import { useNavigate } from "react-router-dom";
import { Box, Chip, IconButton, Menu, MenuItem, Slider, Tooltip, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import moment from "moment";
import OpenPlayerJS from "openplayerjs";

import PlayIcon from "@mui/icons-material/PlayArrowSharp";
import PauseIcon from "@mui/icons-material/PauseSharp";
import PipIcon from "@mui/icons-material/FeaturedVideo";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

import { CaptionControl, LevelControl, TitleBar } from "./components";
import { FlexCol } from "../FlexCol";
import { FlexRow } from "../FlexRow";
import { BoltPlayerState } from "../../types";
import { Formatter } from "../../utils";
import { ReactComponent as VolumeMuteIcon } from "../../assets/svgs/volume-mute.svg";
import { ReactComponent as VolumeMinIcon } from "../../assets/svgs/volume-min.svg";
import { ReactComponent as VolumeHalfIcon } from "../../assets/svgs/volume-half.svg";
import { ReactComponent as VolumeMaxIcon } from "../../assets/svgs/volume-max.svg";
import { ReactComponent as VideoPopoutIcon } from "../../assets/svgs/pipIcon.svg";
import { LiveDuration } from "../LiveDuration";

const spin = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

type Props = {
  title: string;
  description: string;
  isPopup: boolean;
  isFloatingVideoPlayer: boolean;
  player?: OpenPlayerJS;
  state: BoltPlayerState;
  type: "live" | "normal";
  isChatOpen?: boolean;
  togglePip?: () => void;
  handlePopout?: () => void;
  handleChatClose?: () => void;
  closeFloatingPlayer?: () => void;
  backToProfile?: () => void;
};

export const INITIAL_VOLUME = 40;

const PlayerControls = (props: Props) => {
  const {
    title,
    description,
    isPopup,
    isFloatingVideoPlayer,
    player,
    state,
    type,
    isChatOpen,
    togglePip,
    handlePopout,
    handleChatClose,
    closeFloatingPlayer,
    backToProfile,
  } = props;

  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0);
  const prevVolume = useRef(INITIAL_VOLUME / 100);
  const navigate = useNavigate();
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState("Auto");

  const handleSeekSliderChange = (event: Event, newValue: number | number[]) => {
    // player?.seekTo(newValue as number);
  };

  const addedResolution = (index: number) => {
    // if (!hlsPlayer) {
    //   return 0;
    // }
    // if (hlsPlayer?.levels.length === 0 || hlsPlayer?.levels.length === index + 1) return false;
    // const reversedLevels = [...(hlsPlayer?.levels || [])]?.reverse();
    // const findIndex = reversedLevels.findIndex(
    //   (item) => item.height === reversedLevels[index].height && item !== reversedLevels[index]
    // );
    // if (findIndex === -1) return false;
    // return reversedLevels[index].height < reversedLevels[index + 1].height;
  };

  const onUpdateVolume = useCallback(
    (value: number) => {
      if (!player) {
        return;
      }
      player.getMedia().muted = value === 0;
      player.getMedia().volume = (value as number) / 100;
      setMuted(value === 0);
      if (value !== 0) {
        prevVolume.current = (value as number) / 100;
      }
      setVolume((value as number) / 100);
    },
    [player]
  );

  useEffect(() => {
    onUpdateVolume(INITIAL_VOLUME);
  }, [onUpdateVolume]);

  return (
    <>
      <FlexCol
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          opacity: !playing ? 1 : 0,
          ":hover": { animation: playing ? `${spin} 0.5s ease-in-out` : "", opacity: 1 },
        }}
        // onDoubleClick={() => setPlaying(!playing)}
      >
        <FlexRow alignItems="center" justifyContent="space-between" sx={{ px: { xs: 0, sm: 1 } }}>
          <TitleBar title={title} description={description} isFloatingPlayer={isFloatingVideoPlayer} />
          {isFloatingVideoPlayer && (
            <IconButton
              sx={{
                p: 1,
                borderRadius: 6,
                position: "absolute",
                top: { xs: -1, sm: 8 },
                right: { xs: 0, sm: 10 },
                zIndex: 10,
              }}
              onClick={closeFloatingPlayer}
            >
              <CloseIcon />
            </IconButton>
          )}
        </FlexRow>
        {isFloatingVideoPlayer && (
          <FlexRow justifyContent="center" sx={{ mt: 2 }}>
            <IconButton style={{ borderRadius: 4, padding: 6, marginRight: 30 }} onClick={backToProfile}>
              <OpenInFullIcon />
            </IconButton>
            <IconButton
              style={{ borderRadius: 4, padding: 6 }}
              onClick={() => {
                playing ? player?.getMedia().pause() : player?.getMedia().play();
                setPlaying(!playing);
              }}
            >
              {playing ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
          </FlexRow>
        )}
        {!isPopup && !isChatOpen && !isFloatingVideoPlayer && (
          <IconButton
            disableRipple
            sx={{
              color: "white",
              mr: 1,
              alignSelf: "flex-end",
              transition: "all 0.3s ease-out",
              mb: -10,
              zIndex: 5,
              "&:hover": { transform: "scale(1.3) translateX(-10px)" },
            }}
            onClick={handleChatClose}
          >
            <KeyboardArrowLeftIcon />
            <MarkChatUnreadIcon />
          </IconButton>
        )}
        <FlexCol sx={{ zIndex: isFloatingVideoPlayer ? -1 : 2 }}>
          <FlexCol
            sx={{
              width: "100%",
              height: { xs: 80, md: 150, lg: 200 },
              px: { xs: 1, sm: 2 },
              pb: { xs: 0, sm: 2 },
              background: "linear-gradient(180deg, rgba(10, 12, 31, 0) 51.93%, #00000080)",
              alignItems: "end",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <FlexRow sx={{ width: "100%", height: "100%", flex: 1, alignItems: "end" }}>
              <FlexRow alignItems="center">
                <IconButton
                  onClick={() => {
                    playing ? player?.getMedia().pause() : player?.getMedia().play();
                    setPlaying(!playing);
                  }}
                  style={{ borderRadius: 4.5, marginRight: 10, width: 35, height: 35 }}
                >
                  {!playing && <PlayIcon />}
                  {!!playing && type === "normal" && <PauseIcon />}
                  {!!playing && type === "live" && <PauseIcon />}
                </IconButton>
                <FlexRow
                  sx={{ position: "relative", "&:hover .volume-slider": { display: "block" } }}
                  className="relative bolt-player"
                >
                  <IconButton
                    style={{ borderRadius: 4.5 }}
                    onClick={() => {
                      onUpdateVolume(muted ? prevVolume.current * 100 : 0);
                    }}
                  >
                    {muted && <VolumeMuteIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume < 0.4 && <VolumeMinIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume >= 0.4 && volume < 0.9 && <VolumeHalfIcon style={{ width: 18, height: 18 }} />}
                    {!muted && volume >= 0.9 && <VolumeMaxIcon style={{ width: 18, height: 18 }} />}
                  </IconButton>
                  <Box
                    className="volume-slider"
                    sx={{
                      display: "none",
                      position: "absolute",
                      height: 120,
                      bottom: { xs: "40%", sm: "100%" },
                      left: { xs: "-20%", sm: "4%" },
                      py: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Slider
                      orientation="vertical"
                      value={muted ? 0 : volume * 100}
                      min={0}
                      max={100}
                      sx={{
                        height: { xs: 80, sm: 100 },
                        "& .MuiSlider-thumb": {
                          height: 14,
                          width: 14,
                          backgroundColor: "#fff",
                          border: "2px solid currentColor",
                          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": { boxShadow: "inherit" },
                          "&:before": { display: "none" },
                          "&:after": { height: 12 },
                        },
                        "& .MuiSlider-track": { width: 3 },
                      }}
                      onChange={(_, value) => {
                        onUpdateVolume(value as number);
                      }}
                    />
                  </Box>
                </FlexRow>
              </FlexRow>
              {type === "normal" && (
                <FlexRow sx={{ my: 0.5, ml: 0.5 }}>
                  <Typography className="text-white select-none text-xs w-8">
                    {Formatter.getDuration(state?.playedSeconds)}
                  </Typography>
                  <Typography className="text-xs leading-tight px-px select-none">/</Typography>
                  <Typography className="text-white select-none text-xs w-8">
                    {Formatter.getDuration(player?.getOptions()?.progress?.duration || state?.loadedSeconds)}
                  </Typography>
                </FlexRow>
              )}

              <FlexCol sx={{ flex: 1, mx: 2 }}>
                <Slider
                  value={state?.playedSeconds}
                  min={0}
                  max={player?.getOptions()?.progress?.duration || 0}
                  aria-label="Default"
                  // valueLabelFormat={(value: number) => moment.utc(value * 1000).format(value >= 3600 ? "HH:mm:ss" : "mm:ss")}
                  // valueLabelDisplay="auto"
                  sx={{
                    display: type === "live" ? "none" : { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
                    "& .MuiSlider-thumb": {
                      height: 14,
                      width: 14,
                      backgroundColor: "#fff",
                      border: "2px solid currentColor",
                    },
                    "& .MuiSlider-track": { width: 3 },
                  }}
                  // onChange={(_, value) => player?.seekTo(value as number)}
                />
              </FlexCol>
              <FlexRow sx={{ alignItems: "center", height: 30 }}>
                <LevelControl player={player!} />
                <CaptionControl player={player!} />
                {!isPopup && (
                  <>
                    <Tooltip title="Pip Mode" placement="top">
                      <IconButton style={{ borderRadius: 4.5 }} onClick={togglePip} sx={{ mr: 1 }}>
                        <PipIcon sx={{ width: 18, height: 18 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Popout Video" placement="top">
                      <IconButton
                        style={{ borderRadius: 4.5 }}
                        onClick={() => {
                          if (handlePopout) handlePopout();
                          player?.getMedia().pause();
                          setPlaying(false);
                        }}
                        sx={{ mr: 1, display: { xs: "none", md: "inline-flex" } }}
                      >
                        <VideoPopoutIcon style={{ width: 18, height: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Full Screen" placement="top">
                  <IconButton
                    style={{ borderRadius: 4.5 }}
                    onClick={() => {
                      // @ts-ignore
                      screenfull.request(player.getInternalPlayer());
                    }}
                  >
                    <FullScreenIcon sx={{ width: 22, height: 22 }} />
                  </IconButton>
                </Tooltip>
              </FlexRow>
            </FlexRow>
            {type === "normal" && (
              <Slider
                value={state.playedSeconds}
                min={0}
                max={player?.getOptions()?.progress?.duration || 0}
                sx={{
                  mx: "auto",
                  width: { xs: "90%", sm: "95%" },
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
                  "& .MuiSlider-thumb": {
                    height: 14,
                    width: 14,
                    backgroundColor: "#fff",
                    border: "2px solid currentColor",
                    "&:after": { height: 12 },
                  },
                  "& .MuiSlider-track": { width: 3 },
                }}
                onChange={handleSeekSliderChange}
              />
            )}
          </FlexCol>
        </FlexCol>
      </FlexCol>
      {type === "live" && !isFloatingVideoPlayer && (
        <FlexCol
          className="live-counter"
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            alignItems: "flex-end",
            px: { xs: 0, sm: 1 },
            pt: { xs: 1, sm: 2 },
            pointerEvents: "none",
          }}
        >
          <FlexRow sx={{ mr: 2, zIndex: 5, alignItems: "center" }}>
            {/* 
            <LiveDuration startDate={moment().utc().startOf("D").toDate()} />
            <Chip color="error" label="LIVE" size="small" sx={{ fontWeight: "600", px: 0.5 }} />
            */}
          </FlexRow>
        </FlexCol>
      )}
    </>
  );
};

export default PlayerControls;
