import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Typography } from "@mui/material";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useForm } from "react-hook-form";

import { FlexCol, FlexRow } from "../../components";
import { LogInDto } from "../../dtos";
import { fetchLoginEmailAction } from "../../store/auth/actions";
import { autoFillTextFieldSxProps, textSXProps, textFieldInputProps } from "./authComps";

const resolver = classValidatorResolver(LogInDto);

interface Props {
  setErrorMessage: (msg: string) => void
}

export const EmailLoginForm = (props: Props) => {

  const { setErrorMessage } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm<LogInDto>({ resolver, defaultValues: { email: "", password: "" } });

  const onSubmit = (data: LogInDto) => {
    setErrorMessage("");
    dispatch(fetchLoginEmailAction(data));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" sx={{ backgroundColor: 'transparent', width: 300, mt: 2 }}>
      <TextField
        required
        fullWidth
        placeholder="Email Address *"
        type="email"
        size="small"
        autoComplete="email"
        autoFocus
        error={!!errors.email}
        helperText={errors.email?.message}
        {...register("email")}
        InputProps={textFieldInputProps}
        InputLabelProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps, textTransform: "lowercase" }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        placeholder="Password *"
        type="password"
        size="small"
        autoComplete="current-password"
        error={!!errors.password}
        helperText={errors.password?.message}
        {...register("password")}
        InputProps={textFieldInputProps}
        InputLabelProps={textFieldInputProps}
        sx={{ ...autoFillTextFieldSxProps, ...textSXProps }}
      />
      <FlexCol sx={{ alignItems: "center" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 1, mb: 2, width: 112, height: 30, textTransform: "none", borderRadius: 4 }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Log in</Typography>
        </Button>
        <FlexRow sx={{ alignItems: "center", mt: "26px" }}>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }} color="primary.light">Forgot your password?</Typography>
          <Button variant="outlined" size="small" href="/reset-password" sx={{ ml: 1, px: 1.5, fontSize: 12, fontWeight: 600, color: '#2F80ED', borderRadius: 4, border: "1px solid rgba(255, 255, 255, 0.05)" }}>
            Click here
          </Button>
        </FlexRow>
      </FlexCol>
    </Box>
  );
};
