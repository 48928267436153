import { EarningType } from "../types";

export const getPaymentMethod = (method?: string) => {
  switch (method) {
    case "stripe_transaction":
    case "stripe_payout_transaction":
      return EarningType.APPLE_GOOGLE_PAY;
    case "binance_transaction":
    case "binance_payout_transaction":
      return EarningType.BINANCE_PAY;
    // case "boltx_transaction":
    // return EarningType.BOLTX;
    default: return method;
  }
};

