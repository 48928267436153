import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, MenuProps, SxProps, GlobalStyles } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { LayoutOption, LayoutOptionType } from "../../types";

type Props = {
  sx?: SxProps;
  mode: LayoutOption,
  setMode: (mode: LayoutOption) => void,
  hasFullOption?: boolean
};

export const LayoutFilters: LayoutOption[] = [
  { label: "Default Layout", value: LayoutOptionType.default_layout },
  { label: "Custom Layout", value: LayoutOptionType.custom_layout }
];

export const LayoutSelect = ({ sx, mode, setMode, hasFullOption = true }: Props) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handler = () => setIsOpen(false);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const handleFilterChange = (event: SelectChangeEvent) => {
    const temp = LayoutFilters.find((one) => one.value === event.target.value);
    setMode(temp!);
  };

  const menuProps: Partial<MenuProps> = {
    variant: 'menu',
    disableScrollLock: true,
  };

  return (
    <FormControl
      sx={{
        height: 28,
        minWidth: 140,
        borderRadius: 2,
        backgroundColor: '#282C46',
        alignSelf: { xs: "flex-start", sm: "flex-start", md: "center" },
        "& fieldset": { borderWidth: 0, height: 28, },
        '& .MuiOutlinedInput-root': {
          height: 28,
          '& .MuiSelect-select': { textAlign: 'start', fontSize: 14 },
          '&.Mui-focused fieldset': { borderWidth: 0 }
        },
        ...sx
      }}
      size='small'
      color='secondary'
    >
      <GlobalStyles styles={{ "& .MuiList-root": { paddingTop: "0 !important" } }} />
      <Select
        value={mode.value}
        open={isOpen}
        onChange={handleFilterChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        displayEmpty={false}
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={menuProps}
      >
        {LayoutFilters.map(({ label, value }, index) => <MenuItem key={index} value={value} sx={{ fontSize: 14 }}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  )
};
