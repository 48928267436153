import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Typography } from "@mui/material";

import { FlexCol, FeedCard, PostBar } from "../../../components";
import { authSelector } from "../../../store/auth/selector";
import { createStreamFeed, fetchUserStreamFeed } from "../../../store/feed/actions";
import { userStreamFeedSelector } from "../../../store/feed/selector";
import { EmptySkeleton } from "../../home/EmptySkeleton";
import { Formatter } from "../../../utils";

interface Props {
  hidePostBar?: boolean;
  userId: string;
}

export const UserFeedList = ({ hidePostBar = false, userId }: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const {
    items = [],
    loading = false,
    page = 1,
    hasMore = false,
    action: { loading: creatingFeed = false } = {},
  } = useSelector(userStreamFeedSelector(userId));

  const [resetCreator, setResetCreator] = useState(0);
  const laodUserFeed = useCallback((data: any) => dispatch(fetchUserStreamFeed(data)), [dispatch]);

  const onSubmit = (data: { file?: File; text?: string; uploadId?: number }) => {
    dispatch(createStreamFeed({ ...data, onSuccess: () => setResetCreator(Date.now()) }));
  };

  useEffect(() => {
    laodUserFeed({ userId });
  }, [userId]);

  return (
    <FlexCol sx={{ mt: hidePostBar ? 2 : 4, rowGap: 2 }}>
      {!hidePostBar && (
        <PostBar
          showAvatar
          loading={creatingFeed}
          avatarUrl={user?.photoUrl}
          resetCreator={resetCreator}
          onSubmit={onSubmit}
        />
      )}
      {!items?.length && !loading && (
        <Typography variant="subtitle2" sx={{ textAlign: "center", mt: hidePostBar ? 1 : 5, color: "#FFFFFF80" }}>
          No posts
        </Typography>
      )}
      <InfiniteScroll
        pageStart={1}
        loadMore={() => !loading && laodUserFeed({ userId, page: page + 1 })}
        hasMore={hasMore}
        useWindow
      >
        <FlexCol sx={{ rowGap: 2 }}>
          {items.map((item, index) => (
            <FeedCard key={index} item={item} streamerId={userId} />
          ))}
        </FlexCol>
      </InfiniteScroll>

      {loading && (
        <>
          {Formatter.nArray(6).map((index) => (
            <EmptySkeleton key={index} />
          ))}
        </>
      )}
    </FlexCol>
  );
};
