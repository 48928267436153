import { useCallback } from "react";
import { Box } from "@mui/material";

import { Slider, SliderProps } from "./Slider";
import { RowAnimation } from "./RowAnimation";
import { useDiscoverPageContext } from "./DiscoverPageContext";
import {
  BloombergCollection,
  BloombergCollectionItem,
  ChannelCollectionItem,
  Collection,
  RewindCollectionItem,
} from "./types";

export const Sliders = () => {
  const { collections, selectedItem, updateContext: updateDiscoverContext } = useDiscoverPageContext();

  const getSliderProps = useCallback(
    (payload: { collection: Collection; collectionIndex: number }) => {
      const { collection, collectionIndex } = payload;

      const title = collection.title;

      const items = collection.items.map((item, itemIndex) => {
        const order = itemIndex;
        const thumbnail = item.thumbnail;
        const isLive = (item as any).isLive || false;
        const isSelected = selectedItem.row === collectionIndex && selectedItem.column === itemIndex;

        const onItem = () => {
          updateDiscoverContext({
            selectedItem: {
              row: collectionIndex,
              column: itemIndex,
              logo:
                collection.type === "channel"
                  ? (item as ChannelCollectionItem).logo
                  : collection.type === "bloomberg"
                  ? collection.icon || ""
                  : collection.type === "rewind"
                  ? (item as RewindCollectionItem).user.photoUrl
                  : "",
              collectionName: collection.title,
              description: item.shortDescription || item.description,
              slug:
                collection.type === "channel"
                  ? (item as ChannelCollectionItem).channelId
                  : collection.type === "bloomberg"
                  ? `p/bloomberg/${(collection as BloombergCollection).slug}/${(item as BloombergCollectionItem).slug}`
                  : collection.type === "rewind"
                  ? `${(item as RewindCollectionItem).user.username}?rewind=${(item as RewindCollectionItem).id}`
                  : "",
              video:
                collection.type === "channel"
                  ? (item as ChannelCollectionItem).url
                  : collection.type === "bloomberg"
                  ? (item as BloombergCollectionItem).media.url
                  : collection.type === "rewind"
                  ? (item as RewindCollectionItem).url
                  : "",
            },
          });
        };

        return {
          order,
          thumbnail,
          isLive,
          isSelected,
          onItem,
        };
      });

      return {
        title,
        items,
      } as SliderProps;
    },
    [selectedItem]
  );

  return (
    <Box sx={{ position: "relative", zIndex: 1 }}>
      {collections.map((collection, collectionIndex) => {
        const sliderProps = getSliderProps({ collection, collectionIndex });

        return (
          <RowAnimation key={`discover-slider-${collectionIndex}`} rowNum={collectionIndex}>
            <Slider {...sliderProps} />
          </RowAnimation>
        );
      })}
    </Box>
  );
};
